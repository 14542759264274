import {Component,OnDestroy,OnInit,ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {ActivatedRoute,Router} from '@angular/router';
import {Compte,TypeCompte} from "@domain/admin/comptabilite/compte";
import {TranslateService} from '@ngx-translate/core';
import {FloatingButtonAction,TypeAction} from '@share/component/floating-button/floating-button';
import {PageHeaderItem} from '@share/component/page-header/page-header';
import {BehaviorSubject} from 'rxjs';
import {ComptabilitePlanComptableService} from "@components/admin/comptabilite/plan-comptable/comptabilite-plan-comptable.service";
import {ToastrService} from 'ngx-toastr';
import {TypeCodeErreur} from '@domain/common/http/result';
import {FieldContainer} from '@domain/settings/field';
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {filter,finalize,first} from "rxjs/operators";

/**
 * Détail d'un compte comptable du menu Comptablité / Plan Comptable
 *
 * @author Angeline Ha
 * @date 29/02/2024
 */
@Component({
    host: {'data-test-id': 'compte-comptable'},
    templateUrl: './compte-comptable.component.html'
})
export class CompteComptableComponent implements OnInit, OnDestroy {
    /** Référence vers l'enum pour l'utiliser dans le template */
    readonly Onglets = Onglets;

    /** Compte comptable */
    compte: Compte;

    /** Wrapper pour les champs attribut */
    fieldContainer: FieldContainer;

    /** Liste des différents onglets disponibles dans un compte */
    listeTabItems: Array<PageHeaderItem>;

    /** Onglet courant */
    _selectedItem: PageHeaderItem;

    /* Accesseurs de l'onglet courant */
    get selectedItem(): PageHeaderItem { return this._selectedItem; }
    set selectedItem(pageHeaderItem: PageHeaderItem) {
        this._selectedItem = pageHeaderItem;
        this._selectedItem.isLoaded = true;
    }

    /** Formulaire */
    @ViewChild('form') form: NgForm;

    /** Indique s'il faut afficher le formulaire en création ou en édition */
    isCreation: boolean;

    /** Liste des actions possibles */
    listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

    /** Chargement en cours */
    isPending: boolean = false;

    /**
     * Constructeur
     *
     * @param translateService Service de traduction
     * @param router Router
     * @param route Route d'accès à cette page
     * @param planComptableService Service plan comptable
     * @param toastrService Service de gestion des toasts
     * @param confirmService Service de confirmation
     */
    constructor(private translateService: TranslateService,
                private router: Router,
                private route: ActivatedRoute,
                private planComptableService: ComptabilitePlanComptableService,
                private toastrService: ToastrService,
                private confirmService: ConfirmService) {
    }

    /**
     * Initialisation
     */
    ngOnInit(): void {
        //Indique au composant plan comptable qu'on affiche le détail d'un compte comptable
        this.planComptableService.isInDetailCompte = true;

        //Initialisation des onglets
        this.listeTabItems = [{
            code: Onglets.GENERALITES,
            libelle: this.translateService.instant('admin.comptabilite.planComptable.compteComptable.onglets.generalites')
        }];

        //Sélection du premier onglet par défaut
        this.selectedItem = this.listeTabItems[0];

        //Récupération de l'identifiant du compte
        this.route.params.pipe(first()).subscribe(params => {
            let numeroCompte = params['numero'];

            //Indique si on est en mode création
            this.isCreation = numeroCompte === '0';

            //Définition de la liste des actions possibles
            this.defineListeActionsPossibles();

            //Récupération du compte
            if (this.isCreation) {
                //Création d'un nouveau compte
                this.compte = new Compte({
                    numero: '',
                    libelle: '',
                    type: TypeCompte.NON_DEFINI,
                    actif: true,
                    attribut1: '',
                    attribut2: '',
                    attribut3: '',
                    attribut4: '',
                    new: true
                });

                //Définition du fieldContainer pour la gestion des champs 'attribut'
                this.fieldContainer = new FieldContainer({
                    field1: this.compte.attribut1,
                    field2: this.compte.attribut2,
                    field3: this.compte.attribut3,
                    field4: this.compte.attribut4
                });
            } else {
                //Récupération du compte existant
                this.planComptableService.getCompte(numeroCompte).subscribe(result => {
                    if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
                        this.compte = new Compte(result.data.compte);

                        //Définition du fieldContainer pour la gestion des champs 'attribut'
                        this.fieldContainer = new FieldContainer({
                            field1: this.compte.attribut1,
                            field2: this.compte.attribut2,
                            field3: this.compte.attribut3,
                            field4: this.compte.attribut4
                        });
                    } else {
                        TypeCodeErreur.showError(result.codeErreur, this.translateService, this.toastrService);
                    }
                });

                //Ajout de l'accès aux onglets "Usage" et "Historique" accessibles uniquement lorsque l'objet existe déjà
                this.listeTabItems.push({
                    code: Onglets.USAGE,
                    libelle: this.translateService.instant('admin.comptabilite.planComptable.compteComptable.onglets.usage')
                },{
                    code: Onglets.HISTORIQUE,
                    libelle: this.translateService.instant('admin.comptabilite.planComptable.compteComptable.onglets.historique')
                });
            }
        });
    }

    /**
     * Destruction
     */
    ngOnDestroy() {
        //Indique au composant planComptable qu'on n'affiche plus le détail d'un compte
        this.planComptableService.isInDetailCompte = false;
    }

    /**
     * Définition de la valeur de la liste des actions possibles
     */
    defineListeActionsPossibles() {
        //Liste des actions
        const listeActions: Array<FloatingButtonAction> = [{
            type: TypeAction.PRIMARY,
            icone: 'nio icon-sauvegarde',
            libelle: 'global.actions.enregistrer',
            doAction: () => this.saveCompte(),
            isDisabled: () => !this.form?.valid
        }];

        //Ajout de l'action de suppression lorsqu'on est en modification
        if (!this.isCreation) {
            listeActions.push({
                type: TypeAction.SECONDARY,
                icone: 'nio icon-suppression',
                libelle: 'global.actions.supprimer',
                doAction: () => this.deleteCompte()
            });
        }

        this.listeActions.next(listeActions);
    }

    /**
     * Changement d'onglet
     *
     * @param selectedItem Onglet sélectionné
     */
    onSelectedItemChange(selectedItem: PageHeaderItem) {
        //Mise à jour de l'onglet sélectionné
        this.selectedItem = selectedItem;
    }

    /**
     * Retour vers la liste des comptes
     */
    onGoBack() {
        this.router.navigate(['../..'], {relativeTo: this.route});
    }

    /**
     * Enregistrement du compte
     */
    saveCompte() {
        //Début d'enregistrement
        this.isPending = true;

        //Récupération de la valeur des champs 'attribut'
        this.compte.attribut1 = this.fieldContainer.myField1;
        this.compte.attribut2 = this.fieldContainer.myField2;
        this.compte.attribut3 = this.fieldContainer.myField3;
        this.compte.attribut4 = this.fieldContainer.myField4;

        //Enregistrement du compte
        this.planComptableService.saveCompte(this.compte)
            .pipe(first(), finalize(() => this.isPending = false))
            .subscribe(result => {
                if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
                    //Toast de succès
                    this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

                    if (this.isCreation) {
                        //On n'est plus en mode création
                        this.isCreation = false;

                        //Navigation avec le bon id
                        this.router.navigate(['../', result.data.compte.numero], {relativeTo: this.route});

                        //Ajout de l'action de suppression
                        this.defineListeActionsPossibles();
                    }

                    //Récupération du compte
                    this.compte = new Compte(result.data.compte);

                    //Refresh la liste des comptes
                    this.planComptableService.refreshListeComptes();

                } else if (result.codeErreur === TypeCodeErreur.ERROR_ALREADY_USED) {
                    //Toast d'erreur : le numero existe déjà pour un autre compte
                    this.toastrService.error(this.translateService.instant('admin.comptabilite.planComptable.errorNumeroAlreadyExists'));
                } else if (result.codeErreur === TypeCodeErreur.ERROR_SAVE) {
                    //Toast d'erreur
                    this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
                }
            });
    }

    /**
     * Suppression du compte
     */
    deleteCompte() {
        //Début de la suppression
        this.isPending = true;

        //Popup de confirmation
        this.confirmService.showConfirm(this.translateService.instant('admin.comptabilite.planComptable.confirmSuppression'))
            .pipe(filter(isConfirmed => isConfirmed)).subscribe({
            next: () => {
                //Suppression du compte
                this.planComptableService.deleteCompte(this.compte.numero)
                    .pipe(first(), finalize(() => this.isPending = false))
                    .subscribe(result => {
                        if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
                            //Indique au composant plan comptable de refresh la liste des comptes
                            this.planComptableService.refreshListeComptes();

                            //Retour vers la liste des comptes
                            this.onGoBack();

                            //Toast de succès
                            this.toastrService.success(this.translateService.instant('global.success.suppression'));
                        } else {
                            //Toast d'erreur
                            TypeCodeErreur.showError(result?.codeErreur,this.translateService,this.toastrService);
                        }
                    });
            },
            complete: () => this.isPending = false
        })
    }

    /**
     * Retourne true si l'onglet a été affiché / chargé une première fois, false sinon
     */
    isTabLoaded(code: string): boolean {
        return this.listeTabItems.find(tab => tab.code == code)?.isLoaded === true;
    }

}

/**
 * Enum des différents onglets d'un compte comptable
 */
export enum Onglets {
    GENERALITES = "Généralités",
    USAGE = "Usage",
    HISTORIQUE = "Historique"
}
