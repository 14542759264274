import {Component,OnInit,TemplateRef,ViewChild} from '@angular/core';
import {AbstractObjetWorkflowComponent,OngletsAdmin} from "@components/workflow/abstract-objet-workflow.component";
import {Facture} from "@domain/facture/facture";
import {ActivatedRoute,Params,Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {Store} from "@ngrx/store";
import {AppState} from "@domain/appstate";
import {ToastrService} from "ngx-toastr";
import {WorkflowService} from "@components/workflow/workflow.service";
import {ChainageService} from "@components/chainage/chainage.service";
import {AnalytiqueService} from "@components/analytique/analytique.service";
import {TypeEntiteService} from "@components/type-entite/type-entite.service";
import {ReportingService} from "@components/reporting/reporting.service";
import {MatDialog} from "@angular/material/dialog";
import {PleaseWaitService} from "@share/component/please-wait/please-wait.service";
import {TypeAction,TypePortee,TypePorteeInt} from "@domain/workflow/workflow";
import {FactureService} from "@components/facture/facture.service";
import {TypeEntiteParamFC} from "@domain/typeentite/typeEntiteParam";
import {Field} from "@domain/settings/field";
import {FactureGeneralitesComponent} from "@components/facture/detail/generalites/facture-generalites.component";
import {ReleveFacture} from "@domain/facture/releve-facture";
import {FactureContentieux} from "@domain/facture/facture-contentieux";
import {Alerte,NiveauAlerte} from "@domain/common/alerte/alerte";
import {SettingsFCState} from "@domain/settings/settings";
import {MapAction} from "@domain/workflow/mapAction";
import {AnalytiqueComponent} from "@components/analytique/analytique.component";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {Observable,of} from "rxjs";
import {Location} from "@angular/common";
import {FactureReferenceListComponent} from "@components/facture/detail/contentieux/facture-reference-list/facture-reference-list.component";
import {TypeProfil} from "@domain/user/user";
import {FactureContentieuxComponent} from "@components/facture/detail/contentieux/facture-contentieux.component";
import {filterFirstNotNull} from "@share/utils/rxjs-custom-operator";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {SuiviBudgetComponent} from "@components/budget/suivi/suivi-budget.component";
import {MontantPipe} from "@share/pipe/montant";
import {FactureEcartsComponent} from "@components/facture/detail/contentieux/ecarts/facture-ecarts.component";
import {AlerteComponent} from "@components/workflow/alerte.component";
import {first} from 'rxjs/operators';
import {SettingsService} from "@components/settings/settings.service";
import {SuiviBudget} from "@domain/budget/suivi-budget";

/**
 * Détail d'une facture
 *
 * @author Laurent Convert
 * @date 11/01/2023
 */
@Component({
	host: {'data-test-id': 'facture'},
	selector: 'facture',
	templateUrl: './facture.component.html'
})
export class FactureComponent extends AbstractObjetWorkflowComponent<Facture,SettingsFCState> implements OnInit {
	/** Déclaration pour accès dans le template */
	TypePortee = TypePortee;
	TypePorteeInt = TypePorteeInt;
    TypeAlerteEcartMontant = TypeAlerteEcartMontant;
	readonly OngletsAdmin = OngletsAdmin;

	/** Généralités */
	@ViewChild(FactureGeneralitesComponent)
	private factureGeneralitesComponent: FactureGeneralitesComponent;

	/** Contentieux */
	@ViewChild(FactureContentieuxComponent)
	private factureContentieuxComponent: FactureContentieuxComponent;

	/** Liste des factures de référence du contentieux */
	@ViewChild(FactureReferenceListComponent)
	private factureReferenceListComponent: FactureReferenceListComponent;

	/** Répartition analytique */
	@ViewChild(AnalytiqueComponent)
	private analytique: AnalytiqueComponent;

    /** Composant enfant SuiviBudget */
    @ViewChild(SuiviBudgetComponent)
    suiviBudgetComponent: SuiviBudgetComponent;

    /** Template pour l'alerte sur les écarts de montant  */
	@ViewChild("tplAlerteEcart")
	private tplAlerteEcart: TemplateRef<any>;

    /** Template d'information de la désactivation des alertes  */
    @ViewChild("tplAlerteDesactivee")
    private tplAlerteDesactivee: TemplateRef<any>;

    /** Template pour l'alerte sur les écarts de montant  */
    @ViewChild("tplAlerteMontant")
    private tplAlerteMontant: TemplateRef<any>;

	/** Relevé de facture associé à la facture */
	releve: ReleveFacture;

	/** Contentieux associé à la facture */
	contentieux: FactureContentieux;

	/** Paramètres du type entité pour la portée facture */
	typeEntiteParam: TypeEntiteParamFC;

	/** Identifiant de la période courante à utiliser pour la sélection par défaut du MIB */
	idPeriodeCourante: number;

	/** Indicateur de modification possible de l'analytique */
	canModifierAnalytique: boolean = false;

    /** Nombre de préférences analytiques disponibles */
	preferenceAnalytiqueCount: number = 0;

	/** Liste des champs customs */
	listeFieldParam: Field[];

	/** Empèche le chargement des onglets tant que ce n'est pas chargé */
	secondaryTabsAllowed: boolean = false;

	/** ID du relevé de facture */
	idReleve: number;

	/**
	 * Constructeur
	 * @param translateService  Service de traductions
	 * @param store             Store de l'appli
	 * @param toastrService     Service de gestion des toast
	 * @param activatedRoute    Route active
	 * @param router            Service de routing
	 * @param workflowService   Service des objets workflow
	 * @param settingsService Service des objets workflow lié aux PJ
	 * @param chainageService   Service des chainages
	 * @param analytiqueService Service de l'analytique
	 * @param factureService    Service de gestion des factures
	 * @param typeEntiteService Service des types entité
	 * @param reportingService  Service de gestion des rapports
	 * @param matDialog         Service de popup
	 * @param pleaseWaitService Service d'affichage du loading
	 * @param location          Location (URL)
     * @param confirmService    Service de dialog
     * @param montantPipe       Pipe de formatage des montants
	 */
	constructor(
		protected translateService: TranslateService,
		protected store: Store<AppState>,
		protected toastrService: ToastrService,
		protected activatedRoute: ActivatedRoute,
		protected router: Router,
		protected workflowService: WorkflowService,
		protected settingsService: SettingsService,
		protected chainageService: ChainageService,
		protected analytiqueService: AnalytiqueService,
		protected factureService: FactureService,
		private typeEntiteService: TypeEntiteService,
		protected reportingService: ReportingService,
		protected matDialog: MatDialog,
		private pleaseWaitService: PleaseWaitService,
		protected location: Location,
        private confirmService: ConfirmService,
        private montantPipe: MontantPipe
	) {
		super(
			translateService,
			store,
			toastrService,
			activatedRoute,
			router,
			workflowService,
			chainageService,
			analytiqueService,
			reportingService,
			matDialog,
			location,
			TypePortee.FC,
			factureService
		);
	}

	/**
	 * Initialisation du composant
	 */
	async ngOnInit(): Promise<void> {
		//Récupération de l'ID du relevé de factures
		this.idReleve = this.activatedRoute.snapshot.params['idReleve'] as number;

		//Récupération du bouton d'enregistrement, seulement si le relevé n'est pas importé
		if (!this.releve?.importe) {
			this.listeActions.pipe(filterFirstNotNull()).subscribe(listeActions => {
				//Récupération du bouton d'enregistrement de l'objet WF
				let saveButton = listeActions?.find(action => action.code === this.ACTION_ENREGISTRER);

				//Vérification du bouton
				if (saveButton != null) {
					//Récupération de la condition d'affichage initiale
					const isVisible = saveButton.isVisible;

					//Wrapper de la condition pour pouvoir afficher le bouton suivant le contexte
					saveButton.isVisible = () => {
						//Affichage du bouton si la condition initiale l'autorise ou si la facture possède une action de complétion et qu'on est sur l'onglet du contentieux (seule cette partie est modifiable dans ce cas)
						return isVisible() || (this.canCompleter && this.selectedItem?.code === Tab.CONTENTIEUX);
					}
				}
			});
		}

		//Exécution du ngOnInit parent
		await super.ngOnInit();
	}

	/**
	 * Construit l'objet à partir des données brutes non typées.
	 *
	 * @param data données brutes non typées
	 * @returns une instance de l'objet
	 */
	protected buildLoadedObjet(data: any): Facture {
		//Instanciation d'un objet facture
		const facture: Facture = new Facture(data.facture);

		//Récupération du type entité
		this.typeEntiteParam = data.typeEntiteParam;

		//Récupération du relevé associé à la facture
		this.releve = facture.factureReleve;

		//Si la facture n'a pas de contentieux
		if (!facture.contentieux) {
			//On l'initialise
			facture.contentieux = new FactureContentieux({
				idFactCont: 0,
				idFacture: facture.idFacture,
				alerte: true,
				litige: false
			} as FactureContentieux);
		}

		//Récupération du contentieux
		this.contentieux = facture.contentieux

		//Récupération de l'identifiant de la période courante
		this.idPeriodeCourante = data.idPeriodeCourante;

		//Récupération des zones prédéfinies
		this.listeFieldParam = data.listeFieldParam;

		//Mise à jour de l'analytique
		this.updateAnalytique(facture,data.preferenceAnalytiqueCount);

		//Activation des onglets secondaires si au moins une sauvegarde a été faite
		this.secondaryTabsAllowed = facture.idFacture > 0 && facture.numero != null;

		//En création
		if (facture.idFacture == 0) {
			//Définition des actions de base
			facture.listeHistoWorkflow = new MapAction({canModifier: {possible: true,possibleMasse: false}});
		}

		//Renvoi de l'objet à charger
		return facture;
	}

	/**
	 * Mise à jour de l'analytique
	 *
	 * @param facture La facture en cours
	 * @param preferenceAnalytiqueCount Nombre de préférences analytique de l'utilisateur associé à la facture
	 */
	updateAnalytique(facture: Facture,preferenceAnalytiqueCount: number) {
		//Mise à jour du nombre de préférences analytiques
		this.preferenceAnalytiqueCount = preferenceAnalytiqueCount;

        //Vérification que la facture est en modification
		if (facture?.canModifier()) {
			//vérification du cas d'une facture liée à un OD parent (suivant le mode de réconciliation)
			if (facture.od?.idOd > 0) {
				//OD lié : vérification de la configuration du type entité du parent
				this.typeEntiteService.isAnalytiqueHeriteModifiable(facture.od.typeEntite.idTypeEntite,TypePortee.FC,TypePortee.OD)
					.subscribe(isEditable => {
						//Mise à jour du contrôle modification de l'analytique
						this.canModifierAnalytique = isEditable;
					});
			} else {
				//OD non lié : modification de l'analytique possible
				this.canModifierAnalytique = true;
			}
		} else {
			//Pas de modification de l'analytique possible
			this.canModifierAnalytique = false;
		}
	}

	/**
	 * Traite les erreurs lors de l'enregistrement de l'objet
	 *
	 * @param result Le résultat de l'enregistrement
	 */
	onSaveError(result: Result): void {
		//Erreur de la validation côté back
		if (result.codeErreur == TypeCodeErreur.ERROR_DOUBLON) {
			this.toastrService.error(this.translateService.instant('facture.errors.doublon'));
		} else {
			//Traitement des erreurs génériques
			super.onSaveError(result);
		}
	}

	/**
	 * Construit la liste des alertes qui sera affichée dans le page header
	 * Ajoute à la volée une alerte soit d'information soit de warning en fonction des écarts de montants et du contentieux
	 */
	buildListeAlertes(objetWorkflow: Facture = this.objetWorkflow) {
		super.buildListeAlertes(objetWorkflow);

        //Alerte - écart avec le devis
        if (objetWorkflow.ecartDevis || objetWorkflow.ecartDevisMontant) {
            //Alerte active
            if (this.contentieux?.alerte) {
				this.listeAlertes.add(new Alerte({
                    niveau: NiveauAlerte.WARNING,
                    template: {
                        tpl: this.tplAlerteEcart,
                        ctx: {action: this.openEcarts.bind(this)}
                    }
				}));
        } else {
                //Alerte désactivée - écart avec le devis
				this.listeAlertes.add(new Alerte({
                    niveau: NiveauAlerte.NO_CONTROL,
					template: {
                        tpl: this.tplAlerteDesactivee,
                        ctx: {action: this.openAlerteDesactivee.bind(this,TypeAlerteEcartMontant.FACTURE_ECART_DEVIS)}
					}
				}));
            }
        }
        //Alerte - écart avec le montant de contrôle
        else if (!!objetWorkflow.montantControle && objetWorkflow.montant !== objetWorkflow.montantControle) {
            //Alerte active
            if (this.contentieux?.alerte) {
                this.listeAlertes.add(new Alerte({
                    niveau: NiveauAlerte.WARNING,
                    titre: 'facture.alerte.factureMontantControle.titre',
                    message: 'facture.alerte.factureMontantControle.message',
                    traductionParams: {
                        montant: this.montantPipe.transform(objetWorkflow.montant,objetWorkflow.devise),
                        montantControle: this.montantPipe.transform(objetWorkflow.montantControle,objetWorkflow.devise)
                    }
                }));
            } else {
                //Alerte désactivée
                this.listeAlertes.add(new Alerte({
                    niveau: NiveauAlerte.NO_CONTROL,
                    template: {
                        tpl: this.tplAlerteDesactivee,
                        ctx: {action: this.openAlerteDesactivee.bind(this,TypeAlerteEcartMontant.FACTURE_MONTANT_CONTROLE)}
                    }
                }));
			}
		}
	}

	/**
	 * Construit un nouvel objet.
	 *
	 * @returns une instance de l'objet
	 */
	protected buildNewObjet(): Promise<Facture> {
		return new Promise<Facture>((resolve,reject) => {
			//Récupération des données nécessaires à la création
			const idReleve: number = this.routeState?.idReleve;
			const idTypeEntite: number = this.routeState?.idTypeEntite;

			//Vérification des paramètres
			if (idReleve != null && idTypeEntite != null) {
				//Chargement d'un nouvel objet
				((this.objetService as FactureService).loadNewObjet(idReleve,idTypeEntite)).subscribe(result => {
					//Résolution de la promesse avec le nouvel objet créé
					resolve(this.buildLoadedObjet(result.data));
				},() => {
					//Rejet de la promesse
					reject();
				});
			} else {
				if (this.user.fonction === TypeProfil.COMPTABLE) {
					//Rejet de la promesse avec '-1' pour ne pas afficher de message générique
					reject(-1);

					//au moins un paramètre manquant : message d'erreur
					this.toastrService.error(this.translateService.instant('facture.errors.erreurParametresCreation'));

					//Retour à la liste des relevés
					this.router.navigate(['ListeReleve']);
				} else {
					//Rejet de la promesse
					reject(TypeCodeErreur.ERROR_PERMISSION);

					//Retour à la liste des relevés
					this.router.navigate(['ListeReleve']);
				}
			}
		});
	}

	/**
	 * Initialise le contexte de la consultation de l'objet courant
	 *
	 * @param activatedRouteParams query params de la route courante
	 */
	protected initRoutingContext(activatedRouteParams: Params): void {
        if (!!activatedRouteParams?.idLot) {
			if (this.user.fonction == TypeProfil.ADMINISTRATEUR || this.user.fonction == TypeProfil.SOUS_ADMINISTRATEUR) {
				this.routingContext = {
					returnRoute: `Admin/Comptabilite/LotsComptables/Lot/${activatedRouteParams.idLot}`,
					extras: {
						queryParams: {
							tabIndex: 3
						}
					}
				};
			} else {
				//Définition de la route de retour au lot
				this.routingContext = {
					returnRoute: `Lot/${activatedRouteParams.idLot}`,
					extras: {
						queryParams: {
							tabIndex: 3
						}
					}
				};
			}
        } else if (!!activatedRouteParams?.idOd) {
            //Définition de la route de retour à l'OD
            this.routingContext = {
                returnRoute: `OD/${activatedRouteParams.idOd}`,
                extras: {
                    queryParams: {
                        tab: 'FRAIS'
                    }
                }
            };
        } else if (!!activatedRouteParams?.idReleve && (this.user?.fonction == TypeProfil.COMPTABLE || this.user?.fonction === TypeProfil.ADMINISTRATEUR || this.user?.fonction == TypeProfil.SOUS_ADMINISTRATEUR)) {
            //Définition de la route de retour au relevé (Comptable et admin)
            this.routingContext = {
                returnRoute: `ReleveFacture/${activatedRouteParams.idReleve}`,
                extras: {
				queryParams: {
                        tab: 'FACTURES'
                    }
				}
			};
		} else {
			//Définition de la route de retour à la liste des factures pour les profils qui n'ont pas accès aux relevés, c-a-d tous sauf le comptable et l'admin
			this.routingContext = {
				returnRoute: 'ListeFacture'
			};
		}
    }

	/**
	 * Vérifie si le formulaire est valide. Utilisé pour autoriser l'enregistrement d'un formulaire ou des traitements admin.
	 *
	 * @return boolean True si le formulaire est valide, False sinon
	 */
	isValid(): boolean {
		let isValid;

		//Vérification des différents cas qui permettent d'enregistrer l'objet
		if (this.objetWorkflow.canModifier() || this.user?.fonction === TypeProfil.ADMINISTRATEUR) {
			//Cas nominal : objet en modification ou connexion en tant qu'admin : vérification de la validité de tous les formulaires / composants
			isValid = this.factureGeneralitesComponent?.isValid()
				//Vérification de l'analytique (le composant peut être absent si l'analytique est configurée pour être au niveau de la ligne de détail - Non implémenté (TODO))
				&& (!this.analytique || this.analytique.isValid())
				//Vérification du contentieux (le composant peut être absent notamment lors de la création)
				&& (!this.factureContentieuxComponent || this.factureContentieuxComponent?.isValid());
		} else if (this.objetWorkflow.canCompleter()) {
			//Objet déjà émis, mais avec un segment WF de complétion permettant de modifier uniquement le contentieux : vérification de la validité du formulaire / composant du contentieux
			isValid = !this.factureContentieuxComponent || this.factureContentieuxComponent?.isValid();
		} else {
			//Pas de modification possible
			isValid = false;
		}

		return isValid;
	}

    /**
     * Envoie l'objet au back pour enregistrement.
     *
     * @return Observable<Result> Le résultat de l'enregistrement
     */
    protected saveObjet(): Observable<Result> {
        //Retourne un observable indiquant s'il faut désactiver les litiges des factures de référence sélectionnées
        const $isDisabledLitige = (): Observable<boolean> => {
            //Vérification de la présence de litige sur les factures sélectionnées
            if (this.factureReferenceListComponent?.hasFactureReferenceLitige()) {
                //Message d'alerte et retour du choix de l'utilisateur
                return this.confirmService.showConfirm('Voulez-vous lever le litige pour les factures en contentieux ?',{type: 'oui-non'}).pipe(first());
            } else {
                //Pas de désactivation
                return of(false);
            }
        }

        //Retourne un observable indiquant s'il faut désactiver les alertes des factures de référence sélectionnées
        const $isDisabledAlerte = (): Observable<boolean> => {
            //Vérification d'activation de l'alerte
            if (!this.contentieux.alerte && this.factureReferenceListComponent?.hasFactureReferenceAlerte()) {
                //Message d'alerte et retour du choix de l'utilisateur
                return this.confirmService.showConfirm("Voulez-vous désactiver les alertes sur les écarts pour l'ensemble des factures associées à cet ordre de mission ?",{type: 'oui-non'}).pipe(first());
            } else {
                //Pas de désactivation
                return of(false);
            }
        }

        //Récupération du choix de la désactivation des litiges
        return $isDisabledLitige().mergeMap(isDisabledLitige => {
            //Récupération du choix de la désactivation des alertes
            return $isDisabledAlerte().mergeMap(isDisabledAlerte => {
                //Enregistrement
                return this.objetService.save(this.beforeSaveObjet(this.objetWorkflow),{
                    isDisabledLitige: isDisabledLitige,
                    isDisabledAlerte: isDisabledAlerte
                });
            })
        });
    }

    /**
     * {@inheritDoc}
     */
    protected quickReload(id: number): void {
        //Rechargement de l'objet
        this.reloadObjet(id).pipe(first()).subscribe(value => {
            //Rechargement de la liste des factures de référence
            this.factureReferenceListComponent?.refresh();
        });
    }

	/**
	 * Recharge l'objet après un enregistrement.
	 *
	 * @param objetId Identifiant de l'objet
	 */
	protected reloadObjetAfterSave(objetId: any): Observable<Facture> {
		const isCreation = this.objetWorkflow.getId() === 0 && objetId > 0;

		//On se branche après le rechargement de l'objet grâce à un map sur l'observable
		return super.reloadObjetAfterSave(objetId).map((objet) => {
			//Dans le cas d'une création la route (URL) doit être mise à jour
			if (isCreation) {
				//Remplacement de l'URL courante
                if (this.user.fonction === TypeProfil.COMPTABLE) {
                    //URL pour le comptable qui a accès au relevé
                    this.location.replaceState(`/ReleveFacture/${this.releve.idFactureReleve}/Facture/${objetId}`);
                } else {
                    //URL pour les autres profils
				this.location.replaceState(`/Facture/${objetId}`);
                }

				//Initialisation des onglets qui deviennent disponibles suite à la création
				this.initTabs();
			} else {
				//Rechargement de la liste des factures de référence
				this.factureReferenceListComponent?.refresh();
			}

            //Chargement des informations du suivi budgétaire
            this.suiviBudgetComponent?.loadSuiviBudget();

			return objet;
		});
	}

	/**
	 * Préparation de la facture avant sauvegarde.
	 *
	 * @param facture facture à préparer
	 * @returns Facture préparée
	 */
	protected beforeSaveObjet(facture: Facture): Facture {
		//Met à jour od à partir des valeurs saisies
		this.factureGeneralitesComponent.beforeSave();

		//Retour
		return facture;
	}

	/**
	 * Initialisation des onglets
	 */
	initTabs(creation?: boolean): void {
		//Définition des onglets
		this.listeTabItems = [{
			code: Tab.GENERALITES,
			libelle: this.translateService.instant('facture.navigation.generalites')
		}];

		if (this.secondaryTabsAllowed) {
			this.listeTabItems.push(
				{
					code: Tab.DETAIL,
					libelle: this.translateService.instant('facture.navigation.detail')
				},{
					code: Tab.CONTENTIEUX,
					libelle: this.translateService.instant('facture.navigation.contentieux')
				},{
					code: Tab.COMPLEMENTS,
					libelle: this.translateService.instant('facture.navigation.complements')
				}
			);
		}

		//Ajout de l'onglet outils
		super.initTabs();
	}

	/**
	 * Ouvre la popin de visualisation des écarts entre devis / facture
	 */
	openEcarts() {
        this.factureService.openEcarts(this.objetWorkflow,this.contentieux,FactureEcartsComponent);
	}

    /**
     * Ouvre la popin de détail de l'alerte
     *
     * @param type Type de l'alerte
     */
    openAlerteDesactivee(type: TypeAlerteEcartMontant) {
        //Construction de l'alerte à partir du template prévu à cet effet
        const alerte = new Alerte({
            niveau: NiveauAlerte.WARNING,
            template: {
                tpl: this.tplAlerteMontant,
                ctx: {
                    type: type,
                    objet: this.objetWorkflow
                }
            }
        });

        //Affichage
        this.matDialog.open(AlerteComponent,{
            data: {
                alertes: [alerte]
            }
        });
    }

    /**
     * Rafraichissement de la liste des factures de référence
     */
    refreshListeFactureReference() {
        this.factureReferenceListComponent?.refresh();
    }

	/**
	 * Appelé avant d'exécuter une action workflow sur l'objet en vérifiant l'absence d'alerte bloquante
	 *
	 * @param typeAction le type d'action workflow
	 */
	protected checkBeforeActionWorkflow(typeAction: TypeAction): Observable<boolean> {
		//Vérification de la présence d'alerte bloquante dans le budget
		if (this.suiviBudgetComponent.listeSuivi.some((suivi: SuiviBudget) => suivi.niveauAlerte === NiveauAlerte.ERROR)) {
			//Message d'erreur
			this.toastrService.error(this.translateService.instant('global.errors.budget'));

			//Retour de l'échec de la vérification
			return of(false);
		} else {
			//Continuation du processus d'exécution de l'action
			return super.checkBeforeActionWorkflow(typeAction);
		}
	}
}

/**
 * Enum des différents onglets disponibles sur la page
 */
export enum Tab {
	GENERALITES = 'GENERALITES',
	DETAIL = 'DETAIL',
	CONTENTIEUX = 'CONTENTIEUX',
	COMPLEMENTS = 'COMPLEMENTS',
}

/**
 * Enum des différents types gérés par le template d'alerte d'écart sur les montants
 */
export enum TypeAlerteEcartMontant {
    FACTURE_MONTANT_CONTROLE = 'factureMontantControle',
    FACTURE_ECART_DEVIS = 'factureEcartDevis',
}
