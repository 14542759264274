import {Component,OnDestroy,OnInit} from '@angular/core';
import {MessageAdmin} from "@domain/dashboard/message-admin";
import {DashboardService} from "../../dashboard.service";
import {filter,first} from "rxjs/operators";
import {ListView} from "@domain/common/list-view";
import {InfosListItemComponent} from "./infos-list-item.component";
import {TranslateService} from "@ngx-translate/core";
import {MatDialog} from "@angular/material/dialog";
import {InfosListExtendedComponent} from "./infos-list-extended/infos-list-extended.component";
import {Subscription} from "rxjs";
import {Store} from "@ngrx/store";
import {AppState} from "@domain/appstate";

/**
 * Liste des Infos (notifications) du Dashboard.
 */
@Component({
    host: {'data-test-id': 'infos-list'},
    selector: 'infos-list',
    templateUrl: './infos-list.component.html'
})
export class InfosListComponent implements OnInit, OnDestroy {

    /** Liste des informations */
    liste: ListView<MessageAdmin, InfosListItemComponent>;

    /** Backup de la liste des informations destinée à l'envoi dans la popup */
    listeBackup: ListView<MessageAdmin, InfosListItemComponent>;

    /** Souscription au changement d'utilisateur pour une suppression dans le ngOnDestroy */
    userSouscription: Subscription;

    /**
     * Constructeur
     *
     * @param translateService le service de traduction
     * @param dashboardService le service du Dashboard
     * @param matDialog le composant des popups
     * @param store Store d'état de l'application
     */
    constructor(private translateService: TranslateService,
                private dashboardService: DashboardService,
                private matDialog: MatDialog,
                private store: Store<AppState>) {
    }

    /**
     * Initialisation du composant
     */
    ngOnInit(): void {
        //Initialisation de la liste
        this.liste = new ListView<MessageAdmin, InfosListItemComponent>({
            title: this.translateService.instant('dashboard.listeInfos.title'),
            component: InfosListItemComponent,
            isFrontendList: true,
            isDashboardList: true
        });

        //Sélection du profil de l'utilisateur
        this.userSouscription = this.store.select(state => state.session.user).pipe(filter(value => !!value)).subscribe(() => {
            //On lance un chargement de liste
            this.loadListe();
        });
    }

    /** Chargement des infos de la liste */
    private loadListe() {
        //Chargement des informations
        this.dashboardService.getListeMessages().pipe(first()).subscribe(data => {
            if (data.length > 5) {
                //On clone la liste courante dans le backup et on lui affecte les données renvoyées
                this.listeBackup = new ListView<MessageAdmin, InfosListItemComponent>({
                    component: InfosListItemComponent,
                    isFrontendList: true,
                    isDashboardList: true,
                    data: {
                        listeResultats: data,
                        numPage: 0,
                        nbPagesTotal: 0,
                        nbObjetsTotal: 0,
                        nbObjetsParPage: 0,
                        nbObjetsDansPage: 0,
                    }
                });

                //On ajoute le bouton d'ouverture de la popup
                this.liste.listeActions = [{
                    icon: 'search',
                    onPress: () => this.viewAll()
                }]
            }

            //Pour ce qui est de la liste courante, on la réduit aux 5 premiers éléments
            this.liste.data.listeResultats = data.slice(0, 5);
        });
    }

    /**
     * S'il y a plus de 5 messages à afficher, on propose d'ouvrir une popup pour tous les consulter
     * (en effet, la liste dashboard est limitée à 5 éléments)
     */
    private viewAll(): void {
        this.matDialog.open(InfosListExtendedComponent, {
            maxHeight: '80%',
            disableClose: false,
            data: this.listeBackup
        });
    }

    /**
     * Destruction du composant
     */
    ngOnDestroy() {
        //On oublie pas de se désabonner
        this.userSouscription && this.userSouscription.unsubscribe();
    }
}
