import {Component,Inject,OnInit} from '@angular/core';
import {ListView,TypeComparaison} from "@domain/common/list-view";
import {Enveloppe} from "@domain/budget/enveloppe";
import {EnveloppeListItemComponent} from "@components/admin/entreprise/utilisateurs/user-detail/tabs/user-generalites/select-enveloppes-popin/enveloppe-list-item/enveloppe-list-item.component";
import {TranslateService} from "@ngx-translate/core";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

/**
 * Popin de sélection des enveloppes lors de l'ajout d'une habilitation
 */
@Component({
	host: {'data-test-id': 'select-enveloppes-popin'},
	templateUrl: './select-enveloppes-popin.component.html'
})
export class SelectEnveloppesPopinComponent implements OnInit {
	/** Liste des enveloppes */
	listeEnveloppes: ListView<Enveloppe,EnveloppeListItemComponent>;

	/**
	 * Constructeur
	 *
	 * @param data les données en entrée de la popin
	 * @param translateService le moteur de traduction
	 */
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { idLienRoleUser: number,listeIdEnveloppe: number[] },
		private translateService: TranslateService
	) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		let uri: string = '';

		//Si l'on est en consultation
		if (this.data.idLienRoleUser) {
			//Récupération des enveloppes qui ne sont pas encore rattachées à cette habilitation
			uri = `/controller/Budget/getEnveloppesForHabilitation?idLienRoleUser=${this.data.idLienRoleUser}`
		} else {
			//Sinon, en création, récupération de toutes les enveloppes
			uri = '/controller/Budget/getEnveloppes';
		}

		/** Initialisation de la liste */
		this.listeEnveloppes = new ListView<Enveloppe,EnveloppeListItemComponent>({
			uri: uri,
			title: this.translateService.instant('admin.entreprise.utilisateurs.detail.generalites.infosGenerales.selectEnveloppesPopin.liste.title'),
			component: EnveloppeListItemComponent,
			defaultOrder: 'libelle',
			listeFilters: [
				{
					clef: 'reference',
					isDefault: true,
					typeComparaison: TypeComparaison[TypeComparaison.LIKE],
					title: this.translateService.instant('filter.reference')
				},{
					clef: 'libelle',
					isDefault: true,
					typeComparaison: TypeComparaison[TypeComparaison.LIKE],
					title: this.translateService.instant('filter.libelle')
				}
			],
			extraOptions: {
				listeIdEnveloppe: this.data.listeIdEnveloppe,
				canChangeEnveloppe: true
			}
		});
	}

	/**
	 * Le bouton "Tout sélectionner" est-il désactivé
	 */
	isSelectAllDisabled(): boolean {
		return this.listeEnveloppes.listeObjetsSectionnes?.length === this.listeEnveloppes.data?.listeResultats.length;
	}

	/**
	 * Le bouton "Tout désélectionner" est-il désactivé
	 */
	isUnselectAllDisabled(): boolean {
		return this.listeEnveloppes.listeObjetsSectionnes?.length === 0;
	}
}