<div class="lvi-content">
    <div class="avatar">
        <span>{{ avatar() }}</span>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a class="label" (click)="extraOptions.onSelect(data)">{{ data.libelle }}</a>
        </div>
        <ul class="lvi-attrs">
            <li *ngIf="groupe()">
                <strong [translate]="'admin.bibliotheque.reporting.reportingFiltres.groupe'"></strong>
                <span>{{ groupe() }}</span>
            </li>
            <li *ngIf="data.userFile">
                <strong [translate]="'admin.bibliotheque.reporting.reportingFiltres.fichier'"></strong>
                <span>{{ data.userFile }}</span>
            </li>
            <li>
                <strong [translate]="'admin.bibliotheque.reporting.reportingFiltres.actif'"></strong>
                <span>{{ (data.actif ? 'global.oui' : 'global.non') | translate }}</span>
            </li>
            <li>
                <strong [translate]="'admin.bibliotheque.reporting.reportingFiltres.standard'"></strong>
                <span>{{ (data.standard ? 'global.oui' : 'global.non') | translate }}</span>
            </li>
            <li *ngIf="data.nbRole != null">
                <strong [translate]="'admin.bibliotheque.reporting.reportingFiltres.nbRoles'"></strong>
                <span>{{ data.nbRole }}</span>
            </li>
        </ul>
    </div>
    <div>
        <i class="primary" [class.text-muted]="!!pendingReportName" [class.clickable]="!pendingReportName" style="font-size:40px;" [ngClass]="'nio icon-reporting'" [matTooltip]="'admin.bibliotheque.reporting.extraction' | translate" matTooltipPosition="left" (click)="onExtractionClick()"></i>
    </div>
</div>
