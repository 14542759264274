import {Observable} from 'rxjs';
import {Result} from '../../../domain/common/http/result';

/**
 * Interface représentant un uploader de fichier
 */
export interface FileUploader {
    /** Données **/
    url: string;
    deleteUrl?: string;
    queue?: Array<FileWithProgress>;
    autoRemove?: boolean;
    /** Activation du mode compte à rebours lors de l'ajout de fichier */
    isCountDown?: boolean;
    /** Upload multiple */
    multiple?: boolean;

    /** Méthodes **/
    addItem?: () => void;
    uploadAll?: () => void;
    uploadItem?: (idxItem: number) => Array<Observable<number>>;
    removeItem?: (idxItem: number) => void;
    removeItemByName?: (name: string) => void;
    deleteItem?: (idxItem: number) => Observable<Result>
    onItemAdded?: (file: FileWithProgress) => void;
    onItemUploaded?: (result: any,file: FileWithProgress) => void;
    onItemUploading?: (file: FileWithProgress) => void;
}

export interface FileWithProgress extends File {
    /** Données **/
    progress?: Observable<number>;
    ProgressType?: ProgressType;

    /** True si le fichier est envoyé */
    isUploaded?: boolean;
    /** Indique si l'intégration du fichier est ko */
    isKo?: boolean;
    /** Le retour de l'upload, le type est variable selon le type d'upload */
    result?:  any | Result;
    /** Id du document créé à partir de ce fichier */
    idDocument?: number;
}

/**
 * Type de progression de l'uploader
 */
export enum ProgressType {
    None,
    Uploading,
    Countdown,
}

/**
 * Type d'anomalie émis suite à une erreur lors de l'ajout d'un document
 */
export enum InvalidType {
    SIZE,
    EXTENSION,
    CREDENTIAL,
    NAME,
}