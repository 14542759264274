import { Component } from "@angular/core";

/**
 * Onglet frais missions
 *
 * @author Guillaume TRAVOT
 * @date 26/04/2024
 */
@Component({
    host: {'data-test-id': 'indemnites-frais-missions'},
    templateUrl: './indemnites-frais-missions.component.html'
})
export class IndemnitesFraisMissionsComponent {}
