import {Component} from "@angular/core";
import {IComponentWithRoutedTabs} from "@domain/admin/recherche/component-with-routed-tabs";
import {PageHeaderItem} from "@share/component/page-header/page-header";

/**
 * Sous-menu Populations du menu Entreprise
 *
 * @author Tom JEAMMET
 */
@Component({
    host: {'data-test-id': 'entreprise-populations'},
    templateUrl: './entreprise-populations.component.html'
})
export class EntreprisePopulationsComponent implements IComponentWithRoutedTabs {
    /** Liste statique des onglets */
    public static listeOnglets: Array<PageHeaderItem> = [{
        code: undefined,
        libelle: 'admin.entreprise.populations.title',
        url: 'Admin/Entreprise/Populations'
    }];
}
