<div class="lvi-content">
    <div class="avatar">
        <i class="nio icon-profil"></i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a class="label">{{ data.displayItem() }}</a>
        </div>
        <ul class="lvi-attrs" *ngIf="attributes">
            <ng-container *ngFor="let attr of attributes">
                <li *ngIf="!!attr.valeur">
                    <strong [translate]="attr.clef"></strong>
                    {{ attr.valeur }}
                </li>
            </ng-container>
        </ul>
    </div>
</div>
