import {Component,OnInit} from '@angular/core';
import {ListView,TypeComparaison,TypeFilter} from "@domain/common/list-view";
import {TranslateService} from "@ngx-translate/core";
import {FloatingButtonAction,TypeAction} from "@share/component/floating-button/floating-button";
import {MatDialog} from "@angular/material/dialog";
import {DelegationCreationComponent} from "./creation/delegation-creation.component";
import {LienRoleUser} from "@domain/workflow/lienRoleUser";
import {DelegationsService} from "@components/delegations/delegations.service";
import {first} from "rxjs/operators";
import {BehaviorSubject} from "rxjs";
import {DelegationListItemComponent} from "@share/component/delegation/delegation-list-item/delegation-list-item.component";
import {DelegationHistoryPopinComponent} from "@share/component/delegation/delegation-history-popin/delegation-history-popin.component";
import {Sorting} from "@domain/common/list-view/sorting";

@Component({
	templateUrl: './delegations-list.component.html'
})
/**
 * Composant de liste des délégations
 */
export class DelegationsListComponent implements OnInit {
	/** Liste des actions possibles */
	listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

	/** Liste des habilitations de l'utilisateur */
	listeUserDelegationRecues: LienRoleUser[] = [];

	/** ListView des habilitations reçues de l'utilisateur */
	listeDelegationRecue: ListView<LienRoleUser,DelegationListItemComponent>;

	/** Liste des habilitations de l'utilisateur */
	listeUserDelegationAccordees: LienRoleUser[] = [];

	/** ListView des habilitations accordées de l'utilisateur */
	listeDelegationAccordee: ListView<LienRoleUser,DelegationListItemComponent>;

	/**
	 * Constructeur
	 *
	 * @param translateService le service de traduction
	 * @param matDialog la fenêtre modale de création/modification
	 * @param delegationService le service de gestion des délégations
	 */
	constructor(
		private translateService: TranslateService,
		private matDialog: MatDialog,
		private delegationService: DelegationsService) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		this.initListeDelegationRecue();
		this.initListeDelegationAccordee();

		//On vérifie s'il est possible de créer une délégation avec l'utilisateur actuellement connecté
		this.delegationService.isDelegationCreatable().pipe(first()).subscribe(isCreatable => {
			if (isCreatable) {
				//Définition de l'action de création
				this.listeActions.next([{
					type: TypeAction.PRIMARY,
					icone: 'nio icon-ajouter',
					libelle: 'global.actions.creer',
					doAction: () => this.createModifyDeleg()
				}]);
			}
		});
	}

	/**
	 * Initialisation des délégations reçues
	 */
	initListeDelegationRecue(): void {
		//Initialisation de la liste des habilitations
		this.listeDelegationRecue = new ListView<LienRoleUser,DelegationListItemComponent>({
			title: this.translateService.instant('admin.entreprise.utilisateurs.detail.generalites.historiqueDelegationRecues'),
			component: DelegationListItemComponent,
			uri: `/controller/Perso/listeDelegationRecue`,
			listeActions: [{
				icon: "history",
				tooltip: this.translateService.instant('admin.entreprise.utilisateurs.detail.generalites.habilitations.showHistory'),
				onPress: () => this.showHistoryDelegationRecues()
			}],
			extraOptions: {
				canModifier: false,
				canShowDeleguant: true,
				canShowDelegue: false,
			},
			listeFilters: [
				{
					clef: 'role.fonction',
					title: this.translateService.instant('admin.entreprise.utilisateurs.detail.generalites.habilitations.fonction'),
					listeValues: [
						{
							code: '2',
							libelle: this.translateService.instant('profil.responsable')
						},{
							code: '4',
							libelle: this.translateService.instant('profil.assistant')
						}
					]
				},{
					clef: 'role.libelle',
					title: this.translateService.instant('admin.entreprise.utilisateurs.detail.generalites.habilitations.role'),
					typeComparaison: TypeComparaison[TypeComparaison.LIKE],
					isDefault: true
				},{
					clef: 'userDelegant.matricule,userDelegant.nom,userDelegant.prenom',
					title: this.translateService.instant('admin.entreprise.utilisateurs.detail.generalites.habilitations.delegant'),
					typeComparaison: TypeComparaison[TypeComparaison.LIKE],
					isDefault: true
				},{
					clef: 'dateDebut',
					title: this.translateService.instant('admin.entreprise.utilisateurs.detail.generalites.habilitations.dateDebut'),
					type: TypeFilter[TypeFilter.DATE],
					typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
					isDefault: true,
				},{
					clef: 'dateFin',
					title: this.translateService.instant('admin.entreprise.utilisateurs.detail.generalites.habilitations.dateFin'),
					type: TypeFilter[TypeFilter.DATE],
					typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
					isDefault: true,
				},{
					clef: '*population.libelle',
					title: this.translateService.instant('admin.entreprise.utilisateurs.detail.generalites.habilitations.population'),
					typeComparaison: TypeComparaison[TypeComparaison.LIKE],
					isDefault: true
				},
			]
		});

		//Définition des colonnes de tri
		this.listeDelegationRecue.columns = [
			{key: 'role.fonction',title: 'admin.entreprise.utilisateurs.detail.generalites.habilitations.fonction'},
			{key: 'role.libelle',title: 'admin.entreprise.utilisateurs.detail.generalites.habilitations.role'},
			{key: 'userDelegant.nom',title: 'admin.entreprise.utilisateurs.detail.generalites.habilitations.delegant'},
			{key: 'dateDebut',title: 'admin.entreprise.utilisateurs.detail.generalites.habilitations.dateDebut'},
			{key: 'dateFin',title: 'admin.entreprise.utilisateurs.detail.generalites.habilitations.dateFin'},
			{key: 'population.libelle',title: 'admin.entreprise.utilisateurs.detail.generalites.habilitations.population'}
		];

		//Ajout du tri de la liste selon l'ordre voulu
		this.listeDelegationRecue.sorting = new Sorting(this.listeDelegationRecue.columns,"dateDebut");
	}

	/**
	 * Initialisation des délégations accordées
	 */
	initListeDelegationAccordee(): void {
		//Initialisation de la liste des habilitations
		this.listeDelegationAccordee = new ListView<LienRoleUser,DelegationListItemComponent>({
			title: this.translateService.instant('admin.entreprise.utilisateurs.detail.generalites.historiqueDelegationAccordees'),
			uri: `/controller/Perso/listeDelegationAccordee`,
			component: DelegationListItemComponent,
			listeActions: [{
				icon: "history",
				tooltip: this.translateService.instant('admin.entreprise.utilisateurs.detail.generalites.habilitations.showHistory'),
				onPress: () => this.showHistoryDelegationAccordees()
			}],
			extraOptions: {
				canModifier: true,
				canShowDeleguant: false,
				canShowDelegue: true,
				canChangeEnveloppe: false,
				titleAdd: 'admin.entreprise.utilisateurs.detail.generalites.habilitations.titleConsultationAccordee',
				refresh: () => this.listeDelegationAccordee.refresh()
			},
			listeFilters: [
				{
					clef: 'role.fonction',
					title: this.translateService.instant('admin.entreprise.utilisateurs.detail.generalites.habilitations.fonction'),
					listeValues: [
						{
							code: '2',
							libelle: this.translateService.instant('profil.responsable')
						},{
							code: '4',
							libelle: this.translateService.instant('profil.assistant')
						}
					]
				},{
					clef: 'role.libelle',
					title: this.translateService.instant('admin.entreprise.utilisateurs.detail.generalites.habilitations.role'),
					typeComparaison: TypeComparaison[TypeComparaison.LIKE],
					isDefault: true
				},{
					clef: 'user.matricule,user.nom,user.prenom',
					title: this.translateService.instant('admin.entreprise.utilisateurs.detail.generalites.habilitations.delegue'),
					typeComparaison: TypeComparaison[TypeComparaison.LIKE],
					isDefault: true
				},{
					clef: 'dateDebut',
					title: this.translateService.instant('admin.entreprise.utilisateurs.detail.generalites.habilitations.dateDebut'),
					type: TypeFilter[TypeFilter.DATE],
					typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
					isDefault: true,
				},{
					clef: 'dateFin',
					title: this.translateService.instant('admin.entreprise.utilisateurs.detail.generalites.habilitations.dateFin'),
					type: TypeFilter[TypeFilter.DATE],
					typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
					isDefault: true,
				},{
					clef: '*population.libelle',
					title: this.translateService.instant('admin.entreprise.utilisateurs.detail.generalites.habilitations.population'),
					typeComparaison: TypeComparaison[TypeComparaison.LIKE],
					isDefault: true
				},
			]
		});

		//Définition des colonnes de tri
		this.listeDelegationAccordee.columns = [
			{key: 'role.fonction',title: 'admin.entreprise.utilisateurs.detail.generalites.habilitations.fonction'},
			{key: 'role.libelle',title: 'admin.entreprise.utilisateurs.detail.generalites.habilitations.role'},
			{key: 'user.nom',title: 'admin.entreprise.utilisateurs.detail.generalites.habilitations.delegue'},
			{key: 'dateDebut',title: 'admin.entreprise.utilisateurs.detail.generalites.habilitations.dateDebut'},
			{key: 'dateFin',title: 'admin.entreprise.utilisateurs.detail.generalites.habilitations.dateFin'},
			{key: 'population.libelle',title: 'admin.entreprise.utilisateurs.detail.generalites.habilitations.population'}
		];

		//Ajout du tri de la liste selon l'ordre voulu
		this.listeDelegationAccordee.sorting = new Sorting(this.listeDelegationAccordee.columns,"dateDebut");
	}

	/**
	 * Ouverture de la popin des délégations reçues
	 */
	showHistoryDelegationRecues(): void {
		this.matDialog.open(DelegationHistoryPopinComponent,{
			data: {
				uriHistory: `/controller/Perso/listeDelegationRecueHistory`,
				canShowDelegue: false,
				canShowDeleguant: true,
			},
			panelClass: 'mat-dialog-without-margin',
			width: '80%'
		});
	}

	/**
	 * Ouverture de la popin des délégations accordées
	 */
	showHistoryDelegationAccordees(): void {
		this.matDialog.open(DelegationHistoryPopinComponent,{
			data: {
				uriHistory: `/controller/Perso/listeDelegationAccordeeHistory`,
				canShowDelegue: true,
				canShowDeleguant: false,
			},
			panelClass: 'mat-dialog-without-margin',
			width: '80%'
		});
	}

	/**
	 * Création/modification d'une délégation
	 */
	createModifyDeleg(deleg?: LienRoleUser) {
		this.matDialog.open(DelegationCreationComponent,{
			data: {
				//Permet d'envoyer new LienRoleUser() si deleg n'est pas fourni, et de récupérer les valeurs de deleg s'il l'est
				deleg: {...new LienRoleUser(),...deleg}
			},
			width: '80%'
		}).afterClosed().subscribe({
			next: data => {
				if (data) {
					//Rafraichissement de la liste
					this.listeDelegationAccordee.refresh();
				}
			}
		});
	}
}