import {Directive,OnDestroy} from '@angular/core';
import {Store} from "@ngrx/store";
import {AppState} from "@domain/appstate";
import {SearchState} from "@reducers/search";
import {filter,first} from "rxjs/operators";
import {MatDialog} from "@angular/material/dialog";
import {RechercheComponent} from "@share/layout/recherche/recherche.component";
import {RechercheService} from "@services/admin/recherche/recherche.service";
import {Subscription} from "rxjs";
import * as searchActions from "@reducers/search";

@Directive({
    selector: '[recherche]'
})
export class RechercheDirective implements OnDestroy {
    /** Indicateur de barre de recherche active */
    private isSearchBarActive: boolean = false;

    /** Souscription */
    private subscription: Subscription;

    /** Référence vers le handler de pression sur une touche */
    private readonly keydownHandler: any;

    /**
     * Constructeur
     */
    constructor(private store: Store<AppState>,private matDialog: MatDialog,private rechercheService: RechercheService) {
        //Abonnement au clic sur le menu de recherche
        this.subscription = this.store.select<SearchState>(s => s.search).pipe(filter(search => !!search.searchData)).subscribe(session => {
            //Affichage de la barre de recherche
            this.displaySearchBar();
        });

        //Binding de la méthode de détection de la combinaison de touches MAJ+R
        this.keydownHandler = this.onKeydown.bind(this);

        //Abonnement à la combinaison de touches MAJ+R
        document.addEventListener('keydown', this.keydownHandler);
    }

    /**
     * Détection de la combinaison de touches MAJ+R
     *
     * @param event événement
     */
    private onKeydown(event: KeyboardEvent): void {
        //Vérification qu'aucun élément du DOM n'a le focus (afin de ne pas en perturber la saisie)
        const isFocusAvailable: boolean = document.activeElement === document.body || document.activeElement?.attributes[0]?.value == 'tab';

        //Si touche SHIFT + R et pas d'autre touche spéciale et focus disponible
        if (event.shiftKey && event.key === 'R' && !event.ctrlKey && !event.altKey && !event.metaKey && isFocusAvailable) {
            //Interception de la touche R pour éviter que chaque ouverture de recherche commence par un R inutile dans l'input
            event.preventDefault();

            //Affichage de la barre de recherche
            this.displaySearchBar();
        }
    }

    /**
     * Affichage de la barre de recherche
     */
    displaySearchBar(): void {
        //Si la barre de recherche n'est pas déjà active et que le service est prêt
        if (!this.isSearchBarActive && this.rechercheService.isReady) {
            //Activation de la barre de recherche
            this.isSearchBarActive = true;

            //Ouverture de la popup
            this.matDialog.open(RechercheComponent, {
                width: "80%",
                position: { top: '200px' },
                panelClass: 'no-scroll-dialog',
                disableClose: false
            }).afterClosed().pipe(first()).subscribe(() => {
                //Désactivation de la barre de recherche lors de la fermeture
                this.isSearchBarActive = false;

                //Réinitialisation du store de recherche
                this.store.dispatch({
                    type: searchActions.SEARCH_FROM_MENU,
                    payload: false
                });
            });
        }
    }

    /**
     * Destruction de la directive
     */
    ngOnDestroy() {
        //Résiliation des abonnements
        this.subscription.unsubscribe();
        document.removeEventListener('keydown', this.keydownHandler);
        this.rechercheService.destroy();
    }
}
