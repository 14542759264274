import { Component } from "@angular/core";

/**
 * Onglet construction WF
 *
 * @author Guillaume TRAVOT
 * @date 26/04/2024
 */
@Component({
    host: {'data-test-id': 'liste-wf'},
    templateUrl: './liste-wf.component.html'
})
export class ListeWfComponent {}
