import {DatePipe} from "@angular/common";
import {AfterViewInit,Component,OnInit,ViewChild} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute,NavigationEnd,NavigationStart,Router} from "@angular/router";
import {AppState} from "@domain/appstate";
import {Page} from "@domain/common/http/list-result";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {Filter,ListView,TypeComparaison,TypeFilter} from "@domain/common/list-view";
import {Lot,LotAvanceDTO,LotFactureDTO,LotItemType,LotListItem,LotNdfDTO} from "@domain/comptabilite/lot";
import {StatutArchivage} from "@domain/comptabilite/statut-archivage";
import {SettingsAVState,SettingsFCState,SettingsGlobalState,SettingsNFState} from "@domain/settings/settings";
import {TypePortee} from "@domain/workflow/workflow";
import {Store} from "@ngrx/store";
import {TranslateService} from "@ngx-translate/core";
import * as settingsActions from "@reducers/settings";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {FloatingButtonAction,TypeAction} from "@share/component/floating-button/floating-button";
import {ListViewComponent} from '@share/component/list-view/list-view.component';
import {PageHeaderItem} from "@share/component/page-header/page-header";
import {filterFirstNotNull} from "@share/utils/rxjs-custom-operator";
import {ToastrService} from "ngx-toastr";
import {BehaviorSubject,combineLatest,Subscription} from "rxjs";
import {filter,finalize,first} from "rxjs/operators";
import {LotAddAvanceItemComponent} from "./add/lot-add-avance-item.component";
import {LotAddFactureItemComponent} from "./add/lot-add-facture-item.component";
import {LotAddNdfItemComponent} from "./add/lot-add-ndf-item.component";
import {LotAddObjectComponent} from "./add/lot-add-object.component";
import {ComptabiliteService} from "./comptabilite.service";
import {LotGeneraliteComponent} from "./generalite/lot-generalite.component";
import {TypeDateLotComptable} from "@domain/admin/comptabilite/optionsTransverses";
import {TypeProfil,User} from "@domain/user/user";
import {PleaseWaitService} from "@share/component/please-wait/please-wait.service";

/**
 * Composant pour les lots comptables
 */
@Component({
    selector: 'lot',
    templateUrl: './lot.component.html'
})
export class LotComponent implements OnInit,AfterViewInit {
    protected readonly Onglets = Onglets;

    /** Généralités */
    @ViewChild(LotGeneraliteComponent)
    lotGeneraliteComponent: LotGeneraliteComponent;

    /** Liste des onglets */
    listeTabItems: Array<PageHeaderItem> = [];

    /** Onglet sélectionné */
    selectedItem: PageHeaderItem = null;

    /** Index de l'onglet sélectionné */
    selectedIndex: number;

    /** Liste des actions possibles */
    listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

    /** Listes */
    //Note de frais
    listeNDF: ListView<LotNdfDTO, LotAddNdfItemComponent>;
    //Avance
    listeAV: ListView<LotAvanceDTO, LotAddAvanceItemComponent>;
    //Facture
    listeFC: ListView<LotFactureDTO, LotAddFactureItemComponent>;

    /** Listes locales lors de la création */
    //Note de frais
    @ViewChild("listeViewNDFCreation") listeNDFCreationComponent: ListViewComponent<LotNdfDTO, LotAddNdfItemComponent>;
    listeNDFCreation: ListView<LotNdfDTO, LotAddNdfItemComponent>;
    //Avance
    @ViewChild("listeViewAVCreation") listeAVCreationComponent: ListViewComponent<LotAvanceDTO, LotAddAvanceItemComponent>;
    listeAVCreation: ListView<LotAvanceDTO, LotAddAvanceItemComponent>;
    //Facture
    @ViewChild("listeViewFCCreation") listeFCCreationComponent: ListViewComponent<LotFactureDTO, LotAddFactureItemComponent>;
    listeFCCreation: ListView<LotFactureDTO, LotAddFactureItemComponent>;

    /** Indicateur de modification possible */
    canModifier: boolean = false;

    /** Paramètres */
    settings: SettingsGlobalState;

    /** Paramètres NF */
    nfSettings: SettingsNFState;
    
    /** Souscription aux settings NF pour une suppression dans le ngOnDestroy */
    nfSettingsSouscription: Subscription;

    /** Paramètres AV */
    avSettings: SettingsAVState;

    /** Souscription aux settings AV pour une suppression dans le ngOnDestroy */
    avSettingsSouscription: Subscription;

    /** Paramètres FC */
    fcSettings: SettingsFCState;
    
    /** Souscription aux settings FC pour une suppression dans le ngOnDestroy */
    fcSettingsSouscription: Subscription;

    /** Souscription aux évènements de navigation */
    navigationSouscription: Subscription;

    /** Lot comptable */
    lot: Lot = null;

    /** Lot "light" (le même que sur la liste) permettant d'afficher les informations du header (onglets) */
    lotHeader: LotListItem = null;

    /** Chargement en cours */
    isLoading: boolean = false;

    /** State */
    state: {[p: string]: any};

    /** Utilisateur connecté */
    user: User;

    /** Droit autorisant l'accès à l'archivage du lot (demat) */
    canSeeArchive: boolean = false;
    /** Droit autorisant l'accès au détail du lot */
    canSeeDetail: boolean = false;

    /** Indique si le détail est affiché */
    isShowDetail: boolean = false;
    /** Indique si le détail a déjà été affiché au moins une fois */
    isDetailLoaded: boolean = false;

    constructor(
        private store: Store<AppState>,
        private activatedRoute: ActivatedRoute,
        private translateService: TranslateService,
        private router: Router,
        private toastrService: ToastrService,
        private lotService: ComptabiliteService,
        private confirmService: ConfirmService,
        private matDialog: MatDialog,
        private datePipe: DatePipe,
        private pleaseWaitService: PleaseWaitService
    ) {
        //Récupération du state le cas échéant
        this.state = this.router.getCurrentNavigation()?.extras?.state;

        //Écoute des événements back du navigateur
        //Pour un évènement NavigationStart on doit attendre le prochain NavigationEnd pour que le composant enfant soit chargé par l'activation de la route (nécessaire à la méthode updateTab)
        this.navigationSouscription = this.router.events
            .pipe(filter(event => event instanceof NavigationStart && event.navigationTrigger === 'popstate'))
            .mergeMap(event => this.router.events.pipe(first(event => event instanceof NavigationEnd)) )
            .subscribe((event) => {
                //Affichage de l'onglet correspondant à la route
                this.updateTab();
            });
    }

    /**
     * Initialisation du composant
     */
    ngOnInit(): void {
        //Chargement du paramétrage
        this.store.dispatch({
            type: settingsActions.LOAD_SETTINGS,
            payload: TypePortee.CPT
        });

        //note: On ne peut pas utiliser d'observable.forkJoin pour les routesparams
        //Sélection du paramétrage
        combineLatest([
            this.store.select(state => state.settings?.[TypePortee.CPT]).pipe(filterFirstNotNull()),
            this.store.select(state => state.session.user).pipe(filterFirstNotNull())
        ]).subscribe(data => {
            //Récupération des données issues du store
            this.settings = data[0];
            this.user = data[1];

            //Récupération des droits de visualisation
            this.canSeeDetail = this.lotService.canSeeDetailLot(this.user);
            this.canSeeArchive = this.lotService.canSeeArchiveLot(this.user);

            //Définition des onglets
            if (this.canSeeDetail) {
                //Accès aux onglets du détail du lot
                this.listeTabItems.push({
                    code: Onglets.GENERALITES,
                    libelle: this.translateService.instant('lot.navigation.generalites')
                },{
                    code: Onglets.NDF,
                    libelle: this.translateService.instant('lot.navigation.ndf')
                },{
                    code: Onglets.AVANCE,
                    libelle: this.translateService.instant('lot.navigation.avance')
                },{
                    code: Onglets.FACTURE,
                    libelle: this.translateService.instant('lot.navigation.facture')
                });
            }

            //Accès à l'onglet "Archivage"
            if (this.canSeeArchive) {
                //Ajout à la liste des onglets affichés
                this.listeTabItems.push({
                    code: Onglets.ARCHIVAGE,
                    libelle: this.translateService.instant('lot.navigation.archivage')
                });
            }

            //Récupération des paramètres de navigation
            this.activatedRoute.params.pipe(first()).subscribe(params => {
                if (params.idLot == 0) {
                    this.canModifier = this.user.fonction === TypeProfil.COMPTABLE;

                    //Initialisation du lot
                    this.lot = new Lot();
                    this.lot.libelle = this.translateService.instant('lot.generalites.defaultTitle', {"date": this.datePipe.transform(Date.now(), 'short')});
                    this.lot.periode = this.settings.listePeriodes.find(value => value.idPeriode === this.settings.idPeriodeEnCours);

                    //En création le header n'existe pas mais, on récupère celui en cours de saisie
                    this.lotHeader = this.lot;

                    //Définition de la date du lot le cas échéant (date du jour par défaut)
                    switch (this.settings.typeDateLot) {
                        case TypeDateLotComptable.PREMIER_JOUR_PERIODE:
                            this.lot.date = this.lot.periode.dateDebut;
                            break;
                        case TypeDateLotComptable.DERNIER_JOUR_PERIODE:
                            this.lot.date = this.lot.periode.dateFin;
                            break;
                    }

                    this.loadListes(0);
                } else {
                    //Chargement du lot comptable
                    this.loadLot(params.idLot);

                    //Chargement des listes
                    this.loadListes(params.idLot);
                }
                this.applyStorage();
            });

            //Activation de l'onglet à l'initialisation
            this.activatedRoute.queryParams.pipe(first()).subscribe(queryParams => {
                this.updateTab(queryParams['tabIndex']);
            });

            //Actions disponibles pour le comptable uniquement
            if (this.user?.fonction === TypeProfil.COMPTABLE) {
                //Définition de la liste des actions
                this.listeActions.next([
                    {
                        type: TypeAction.PRIMARY,
                        icone: 'nio icon-sauvegarde',
                        libelle: 'global.actions.enregistrer',
                        doAction: () => this.saveLot(),
                        isVisible: () => this.lot?.idLot === 0
                    },
                    {
                        type: TypeAction.SECONDARY,
                        icone: 'nio icon-suppression',
                        libelle: 'global.actions.supprimer',
                        doAction: () => this.deleteLot(),
                        isVisible: () => this.lot?.idLot !== 0
                    },
                    {
                        type: TypeAction.SECONDARY,
                        icone: 'nio icon-suppression',
                        libelle: 'lot.actions.remove.frais',
                        doAction: () => this.removeObjectFromLot(this.listeNDFCreation.data.listeResultats.filter(result => result.isSelected), LotItemType.NDF),
                        isVisible: () => this.listeNDFCreation?.nbSelectedItems > 0
                    },
                    {
                        type: TypeAction.SECONDARY,
                        icone: 'nio icon-suppression',
                        libelle: 'lot.actions.remove.avance',
                        doAction: () => this.removeObjectFromLot(this.listeAVCreation.data.listeResultats.filter(result => result.isSelected), LotItemType.AVANCE),
                        isVisible: () => this.listeAVCreation?.nbSelectedItems > 0
                    },
                    {
                        type: TypeAction.SECONDARY,
                        icone: 'nio icon-suppression',
                        libelle: 'lot.actions.remove.facture',
                        doAction: () => this.removeObjectFromLot(this.listeFCCreation.data.listeResultats.filter(result => result.isSelected), LotItemType.FACTURE),
                        isVisible: () => this.listeFCCreation?.nbSelectedItems > 0
                    }
                ]);
            }
        });
    }

    /**
     * Apres initialisation de la vue
     */
    ngAfterViewInit(): void {
        //Selection du paramétrage NDF lorsque l'onglet est sélectionné
        this.nfSettingsSouscription = this.store.select(state => state.settings?.[TypePortee.NF]).pipe(filterFirstNotNull()).subscribe(settings => {
            this.nfSettings = settings;
            
            //Ajout des valeurs aux filtres de liste nf
            setTimeout(() => {
                let statutFilter = this.listeNDF?.listeFilters?.find(f => f.clef === 'statut.code');
                if (!!statutFilter) {
                    statutFilter.listeValues = [...settings.filtresStatut];
                }
                let typeFilter = this.listeNDF?.listeFilters?.find(f => f.clef === 'typeEntite.code');
                if (!!typeFilter) {
                    typeFilter.listeValues = [...settings.typeEntiteList];
                }
            });
        });
    
        //Selection du paramétrage Avance lorsque l'onglet est sélectionné
        this.avSettingsSouscription = this.store.select(state => state.settings?.[TypePortee.AV]).pipe(filterFirstNotNull()).subscribe(settings => {
            this.avSettings = settings;

            //Ajout des valeurs aux filtres de liste av
            setTimeout(() => {
                let statutFilter = this.listeAV?.listeFilters?.find(f => f.clef === 'statut.code');
                if (!!statutFilter) {
                    statutFilter.listeValues = [...settings.filtresStatut];
                }
            });
        });
    
        //Selection du paramétrage Facture lorsque l'onglet est sélectionné
        this.fcSettingsSouscription = this.store.select(state => state.settings?.[TypePortee.FC]).pipe(filterFirstNotNull()).subscribe(settings => {
            this.fcSettings = settings;

            //Ajout des valeurs aux filtres de liste fc
            setTimeout(() => {
                let statutFilter = this.listeFC?.listeFilters?.find(f => f.clef === 'statut.code');
                if (!!statutFilter) {
                    statutFilter.listeValues = [...settings.filtresStatut];
                }
                let typeFilter = this.listeFC?.listeFilters?.find(f => f.clef === 'typeEntite.code');
                if (!!typeFilter) {
                    typeFilter.listeValues = [...settings.typeEntiteList];
                }
            });
        });
    }
    
    /**
     * Destruction du composant
     */
    ngOnDestroy(): void {
        //On n'oublie pas de se désabonner
        this.nfSettingsSouscription?.unsubscribe();
        this.avSettingsSouscription?.unsubscribe();
        this.fcSettingsSouscription?.unsubscribe();
        this.navigationSouscription?.unsubscribe();
    }

    /**
     * Ajoute les items du storage au lot comptable
     */
    private applyStorage(): void {
        let pleaseWaitRef;

        //On récupère les listes d'objets stockés dans le state
        const listeNDFStorage: number[] = this.lotService.getListeIdsForLot(LotItemType.NDF);
        const listeAVStorage: number[] = this.lotService.getListeIdsForLot(LotItemType.AVANCE);
        const listeFCStorage: number[] = this.lotService.getListeIdsForLot(LotItemType.FACTURE);

        //Si le lot a été créé en comptabilisation en masse depuis un relevé
        const listeIdsForLot: number[] = this.lotService.getListeIdsForLot(LotItemType.RELEVE);
        if (listeIdsForLot != null && listeIdsForLot.length >= 1) {
            //Récupération de l'identifiant du relevé
            const idReleve: number = listeIdsForLot[0];
            if (!!idReleve) {
                //Modale de chargement
                pleaseWaitRef = this.pleaseWaitService.show();

                //Chargement des factures pour le relevé donné
                this.lotService.listeFacturesLotFromReleve(idReleve)
                    .pipe(first(),finalize(() => pleaseWaitRef.close()))
                    .subscribe((result: Result) => {
                        //Vérification du résultat
                        if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
                            //Ajout des factures à la liste
                            this.addPageToLot(LotItemType.FACTURE,result?.data?.listeFactures);
                        } else {
                            //Message d'erreur
                            TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
                        }
                    });

                //Supprime les items du local storage
                this.lotService.clearItems(LotItemType.RELEVE);
            }
        }

        //S'il y a bien des objets et que la page a fini d'initialiser les listes
        if ((listeNDFStorage || listeAVStorage || listeFCStorage) && this.listeNDFCreation && this.listeAVCreation && this.listeFCCreation) {
            //Cas des NDF
            if (listeNDFStorage) {
                //On supprime la liste
                this.lotService.clearItems(LotItemType.NDF);

                //Ajout de la liste au lot
                setTimeout(() => {
                    this.addItemsToLot(LotItemType.NDF,listeNDFStorage);
                });
            }

            //Cas des factures
            if (listeFCStorage) {
                //On supprime la liste
                this.lotService.clearItems(LotItemType.FACTURE);

                //Ajout de la liste au lot
                setTimeout(() => {
                    this.addItemsToLot(LotItemType.FACTURE,listeFCStorage);
                });
            }

            //Cas des avances
            if (listeAVStorage) {
                //On supprime la liste
                this.lotService.clearItems(LotItemType.AVANCE);

                //Ajout de la liste au lot
                setTimeout(() => {
                    this.addItemsToLot(LotItemType.AVANCE,listeAVStorage);
                });
            }
        }
    }

    /**
     * Chargement du Lot
     * @param idLot identifiant du lot
     */
    private loadLot(idLot: number) {
        //Vérification du droit d'accès au détail du lot
        if (this.canSeeDetail) {
            //Chargement du lot via le service
            this.lotService.loadLot(idLot).pipe(first()).subscribe({
                next: lot => {
                    //Mise a jour du lot
                    this.lot = lot?.data?.lot;

                    //On récupère déjà le lot, inutile de récupérer le header (puisqu'un lot étend un header)
                    this.lotHeader = this.lot;
                }
            });
        } else {
            //Chargement du lot via le service
            this.lotService.loadLotListItem(idLot).pipe(first()).subscribe({
                next: result => {
                    //Récupération du header
                    this.lotHeader = result?.data?.lot;
                }
            });
        }
    }

    /**
     * Chargement des listes rattachées au lot
     * @param idLot identifiant du lot
     */
    private loadListes(idLot: number) {
        //Création de listes locales en cas de création de lot
        if (this.canModifier) {
            //Définition des listes
            this.listeNDFCreation = new ListView<LotNdfDTO, LotAddNdfItemComponent>({
                title: this.translateService.instant('lot.detail.listes.listeNDF.title'),
                component: LotAddNdfItemComponent,
                listeActions: [{
                    icon: "add",
                    onPress: () => this.openAddObjectDialog(LotItemType.NDF)
                }],
                isFrontendList: true,
                isFilter: false,
                extraOptions: {
                    isLotCreation: true
                }
            });
            this.listeAVCreation = new ListView<LotAvanceDTO, LotAddAvanceItemComponent>({
                title: this.translateService.instant('lot.detail.listes.listeAV.title'),
                component: LotAddAvanceItemComponent,
                listeActions: [{
                    icon: "add",
                    onPress: () => this.openAddObjectDialog(LotItemType.AVANCE)
                }],
                isFrontendList: true,
                isFilter: false,
                extraOptions: {
                    isLotCreation: true
                }
            });
            this.listeFCCreation = new ListView<LotFactureDTO, LotAddFactureItemComponent>({
                title: this.translateService.instant('lot.detail.listes.listeFC.title'),
                component: LotAddFactureItemComponent,
                listeActions: [{
                    icon: "add",
                    onPress: () => this.openAddObjectDialog(LotItemType.FACTURE)
                }],
                isFrontendList: true,
                isFilter: false,
                extraOptions: {
                    isLotCreation: true
                }
            });
    
            //Initialisation de la pagination des listes
            setTimeout(() => {
                this.listeNDFCreationComponent?.refreshListPagination();
                this.listeFCCreationComponent?.refreshListPagination();
                this.listeAVCreationComponent?.refreshListPagination();
            });
        } else {
            //Définition des listes
            this.listeNDF = new ListView<LotNdfDTO, LotAddNdfItemComponent>({
                uri: `/controller/Lot/filtreNotes/${idLot}`,
                title: this.translateService.instant('lot.detail.listes.listeNDF.title'),
                component: LotAddNdfItemComponent,
                extraOptions: {
                    isLotCreation: false
                },
                isFilter: true,
                defaultOrder: '-idNDF',
                listeFilters: [
                {
                    clef: 'idNDF',
                    title: this.translateService.instant('ndf.liste.filtres.id'),
                    isDefault: true,
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                },
                {
                    clef: 'numeroPiece',
                    title: this.translateService.instant('ndf.liste.filtres.numero'),
                    isDefault: true,
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                },{
                    clef: 'user.matricule',
                    title: this.translateService.instant('ndf.liste.filtres.matricule'),
                    isDefault: true,
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                },{
                    clef: 'user.prenom',
                    title: this.translateService.instant('ndf.liste.filtres.prenom'),
                    isDefault: true,
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                },{
                    clef: 'user.nom',
                    title: this.translateService.instant('ndf.liste.filtres.nom'),
                    isDefault: true,
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                },{
                    clef: 'objet',
                    title: this.translateService.instant('ndf.liste.filtres.objet'),
                    isDefault: true,
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                },{
                    clef: 'montantRemboursable',
                    title: this.translateService.instant('ndf.liste.filtres.montantRemboursable'),
                    type: TypeFilter[TypeFilter.DECIMAL]
                },{
                    clef: 'montantARembourser',
                    title: this.translateService.instant('ndf.liste.filtres.montantARembourser'),
                    type: TypeFilter[TypeFilter.DECIMAL]
                },{
                    clef: 'devise.code',
                    title: this.translateService.instant('ndf.liste.filtres.devise'),
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                },{
                    clef: 'typeEntite.code',
                    title: this.translateService.instant('ndf.liste.filtres.type'),
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                },{
                    clef: 'statut.code',
                    title: this.translateService.instant('ndf.liste.filtres.statut'),
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                },{
                    clef: 'od.idOd',
                    title: this.translateService.instant('ndf.liste.filtres.om'),
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                }]
            });
           
            //Définition des colonnes de tri
            this.listeNDF.columns = [
                { key: 'idNDF', title: 'ndf.liste.columns.idNDF' },
                { key: 'numeroPiece', title: 'ndf.liste.columns.numeroPiece' },
                { key: 'objet', title: 'ndf.liste.columns.objet' },
                { key: 'od.idOd', title: 'ndf.liste.columns.od' },
                { key: 'user.matricule', title: 'ndf.liste.columns.user.matricule' },
                { key: 'statut.code', title: 'ndf.liste.columns.statut' }
            ];
            this.listeNDF.isPersistFilters = true;
            this.listeNDF.className = 'LotNdfListComponent';

            this.listeAV = new ListView<LotAvanceDTO, LotAddAvanceItemComponent>({
                uri: `/controller/Lot/filtreAvances/${idLot}`,
                title: this.translateService.instant('lot.detail.listes.listeAV.title'),
                component: LotAddAvanceItemComponent,
                extraOptions: {
                    isLotCreation: false
                },
                isFilter: true,
                defaultOrder: '-idAvance',
                listeFilters: [
                {
                    clef: 'idAvance',
                    title: this.translateService.instant('avance.liste.filtres.numero'),
                    isDefault: true,
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                },{
                    clef: 'user.matricule',
                    title: this.translateService.instant('avance.liste.filtres.matricule'),
                    isDefault: true,
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                },{
                    clef: 'user.prenom',
                    title: this.translateService.instant('avance.liste.filtres.prenom'),
                    isDefault: true,
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                },{
                    clef: 'user.nom',
                    title: this.translateService.instant('avance.liste.filtres.nom'),
                    isDefault: true,
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                },{
                    clef: 'libelleObjet',
                    title: this.translateService.instant('avance.liste.filtres.libelleObjet'),
                    isDefault: true,
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                },{
                    title: this.translateService.instant("avance.liste.filtres.montant"),
                    clef: "montant",
                    type: TypeFilter[TypeFilter.DECIMAL]
                },{
                    clef: 'devise.code',
                    title: this.translateService.instant('avance.liste.filtres.devise'),
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                },{
                    clef: 'nature',
                    title: this.translateService.instant('avance.liste.filtres.type'),
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE],
                    listeValues: [{
                        code: 'ESP',
                        libelle: this.translateService.instant('avance.liste.filtres.typeESP')
                    }, {
                        code: 'VIR',
                        libelle: this.translateService.instant('avance.liste.filtres.typeVIR')
                    }]
                },{
                    clef: 'statut.code',
                    title: this.translateService.instant('avance.liste.filtres.statut'),
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                },{
                    clef: 'od.idOd',
                    title: this.translateService.instant('avance.liste.filtres.om'),
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                }]
            });

            //Définition des colonnes de tri
            this.listeAV.columns = [
                { key: 'idAvance', title: 'avance.liste.columns.idAvance' },
                { key: 'libelleObjet', title: 'avance.liste.columns.libelleObjet' },
                { key: 'od.idOd', title: 'avance.liste.columns.idOd' },
                { key: 'nature', title: 'avance.liste.columns.nature' },
                { key: 'user.matricule', title: 'avance.liste.columns.user.matricule' },
                { key: 'statut.code', title: 'avance.liste.columns.statut' }
            ];
            this.listeAV.isPersistFilters = true;
            this.listeAV.className = 'LotAvanceListComponent';

            this.listeFC = new ListView<LotFactureDTO, LotAddFactureItemComponent>({
                uri: `/controller/Lot/filtreFactures/${idLot}`,
                title: this.translateService.instant('lot.detail.listes.listeFC.title'),
                component: LotAddFactureItemComponent,
                extraOptions: {
                    isLotCreation: false
                },
                isFilter: true,
                defaultOrder: '-idFacture',
                listeFilters: [
                {
                    clef: 'idFacture',
                    title: this.translateService.instant('facture.liste.columns.idFacture'),
                    isDefault: true,
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                },
                {
                    clef: 'numero',
                    title: this.translateService.instant('facture.liste.filtres.numero'),
                    isDefault: true,
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                },{
                    clef: 'user.matricule',
                    title: this.translateService.instant('facture.liste.filtres.matricule'),
                    isDefault: true,
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                },{
                    clef: 'user.prenom',
                    title: this.translateService.instant('facture.liste.filtres.prenom'),
                    isDefault: true,
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                },{
                    clef: 'user.nom',
                    title: this.translateService.instant('facture.liste.filtres.nom'),
                    isDefault: true,
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                },{
                    clef: 'libelle',
                    title: this.translateService.instant('facture.liste.filtres.objet'),
                    isDefault: true,
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                },{
                    clef: 'type',
                    title: this.translateService.instant('facture.liste.filtres.type'),
                    isDefault: true,
                    listeValues: [{
                        code: 'FAC',
                        libelle: this.translateService.instant('facture.liste.filtres.typeFAC')
                    },{
                        code: 'AVO',
                        libelle: this.translateService.instant('facture.liste.filtres.typeAVO')
                    }]
                },{
                    clef: 'typeEntite.code',
                    title: this.translateService.instant('facture.liste.filtres.type'),
                    isDefault: true,
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                },{
                    title: this.translateService.instant("facture.liste.filtres.montant"),
                    clef: "montant",
                    type: TypeFilter[TypeFilter.DECIMAL]
                },{
                    clef: 'devise',
                    title: this.translateService.instant('facture.liste.filtres.devise'),
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                },{
                    clef: 'statut.code',
                    title: this.translateService.instant('facture.liste.filtres.statut'),
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                },{
                    clef: 'listeLiensFacOD.idOd',
                    title: this.translateService.instant('facture.liste.filtres.reconciliation'),
                    isDefault: true,
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                }]
            });

            //Définition des colonnes de tri
            this.listeFC.columns = [
                { key: 'idFacture', title: 'facture.liste.columns.idFacture' },
                { key: 'numero', title: 'facture.liste.columns.numero' },
                { key: 'type', title: 'facture.liste.columns.type' },
                { key: 'user.matricule', title: 'facture.liste.columns.user.matricule' },
                { key: 'statut.code', title: 'facture.liste.columns.statut' }
            ];
            this.listeFC.isPersistFilters = true;
            this.listeFC.className = 'LotFactureListComponent';
        }
    }

    /**
     * Permet l'ajout d'un objet à un lot lors de la création de celui-ci
     * @param type Portée
     */
    private openAddObjectDialog(type: LotItemType) {
        this.matDialog.open(LotAddObjectComponent, {
            data: {
                itemType: type,
                isLotCreation: this.lot.idLot == null || this.lot.idLot == 0,
                listeNotes: this.lot.listeNotes,
                listeAvances: this.lot.listeAvances,
                listeFactures: this.lot.listeFactures,
            }
        }).afterClosed().subscribe({
            next: data => {
                if (data && data.length) {
                    this.addItemsToLot(type,data);
                }
            }
        });
    }

    /**
     * Ajout au lot d'une liste d'items
     *
     * @param type Type des objets à ajouter
     * @param listeIds Liste des identifiants des objets à ajouter
     */
     addItemsToLot(type: LotItemType, listeIds: number[]) {
        switch (type) {
            case LotItemType.NDF:
                //On crée le filtre avec les items pré-existants
                const ndfFilter: Filter = {
                    clef: "idNDF",
                    typeComparaison: TypeComparaison[TypeComparaison.IN],
                    listeObjects: [ ...this.listeNDFCreation?.data?.listeResultats?.map(ndf => ndf.idNDF) ]
                };

                //Ajout des nouveaux items au filtre
                listeIds?.forEach(idNDF => {
                    //Si la ndf n'est pas déjà dans la liste
                    if (!ndfFilter?.listeObjects?.some(result => result === idNDF)) {
                        ndfFilter?.listeObjects?.push(idNDF);
                    }
                });

                this.listeNDFCreation.isLoading = true;
                //On charge la liste des objets de la liste
                this.lotService.loadListeNdf(ndfFilter).subscribe(page => {
                    //On ajoute les objets chargés à la liste
                    this.addPageToLot(type, page);
                });
                break;
            case LotItemType.AVANCE:
                //On réduit les items à manipuler à ceux qui sont sélectionnés
                const avanceFilter: Filter = {
                    clef: "idAvance",
                    typeComparaison: TypeComparaison[TypeComparaison.IN],
                    listeObjects: [ ...this.listeAVCreation?.data?.listeResultats?.map(avance => avance.idAvance) ]
                };

                //Ajout des nouveaux items au filtre
                listeIds?.forEach(idAvance => {
                    //Si la ndf n'est pas déjà dans la liste
                    if (!avanceFilter?.listeObjects?.some(result => result === idAvance)) {
                        avanceFilter?.listeObjects?.push(idAvance);
                    }
                });

                this.listeAVCreation.isLoading = true;
                //On charge la liste des objets de la liste
                this.lotService.loadListeAvances(avanceFilter).subscribe(page => {
                    //Fermeture de la popup => On ajoute les objets chargés à la liste
                    this.addPageToLot(type, page);
                });
                break;
            case LotItemType.FACTURE:
                //On réduit les items à manipuler à ceux qui sont sélectionnés
                const factureFilter: Filter = {
                    clef: "idFacture",
                    typeComparaison: TypeComparaison[TypeComparaison.IN],
                    listeObjects: [ ...this.listeFCCreation?.data?.listeResultats?.map(facture => facture.idFacture) ]
                };

                //Ajout des nouveaux items au filtre
                listeIds?.forEach(idFacture => {
                    //Si la facture n'est pas déjà dans la liste
                    if (!factureFilter?.listeObjects?.some(result => result === idFacture)) {
                        factureFilter?.listeObjects?.push(idFacture);
                    }
                });

                this.listeFCCreation.isLoading = true;
                //On charge la liste des objets de la liste
                this.lotService.loadListeFactures(factureFilter).subscribe(page => {
                    //Fermeture de la popup => On ajoute les objets chargés à la liste
                    this.addPageToLot(type, page);
                });
                break;
        }
    }
    
    /**
     * Ajoute les objets aux listes adéquates et recalcule les différents montants.
     *
     * @param type Type des objets à ajouter
     * @param data Liste des objets à ajouter
     */
    private addPageToLot(type: LotItemType, data: Page<LotNdfDTO> | Page<LotAvanceDTO> | Page<LotFactureDTO>) {
        if (data) {
            if (LotComponent.isPageNdf(data,type)) {
                //Mise à jour de la liste des NDF ajoutées au lot
                this.listeNDFCreation.initDataFrom(data,LotNdfDTO);
                this.listeNDFCreation.isLoading = false;
                this.listeNDFCreationComponent.refreshListPagination();
                
                //Mise à jour des informations du lot
                this.lot.listeNotes = [ ...this.listeNDFCreation.data?.listeResultats?.map(ndf => ndf.idNDF) ];
                this.lot.nbNdf = this.lot.listeNotes?.length;
            } else if (LotComponent.isPageAvance(data,type)) {
                //Mise à jour de la liste des Avances ajoutées au lot
                this.listeAVCreation.initDataFrom(data,LotAvanceDTO);
                this.listeAVCreation.isLoading = false;
                this.listeAVCreationComponent.refreshListPagination();
    
                //Mise à jour des informations du lot
                this.lot.listeAvances = [ ...this.listeAVCreation.data?.listeResultats?.map(avance => avance.idAvance) ];
                this.lot.nbAvance = this.lot.listeAvances.length;
            } else if (LotComponent.isPageFacture(data,type)) {
                //Mise à jour de la liste des Factures ajoutées au lot
                this.listeFCCreation.initDataFrom(data,LotFactureDTO);
                this.listeFCCreation.isLoading = false;
                this.listeFCCreationComponent.refreshListPagination();
    
                //Mise à jour des informations du lot
                this.lot.listeFactures = [ ...this.listeFCCreation.data?.listeResultats?.map(facture => facture.idFacture) ];
                this.lot.nbFacture = this.lot.listeFactures.length;
            }

            //On recalcule les montants
            this.lot.computeMontants(this.listeNDFCreation.data.listeResultats, this.listeAVCreation.data.listeResultats, this.listeFCCreation.data.listeResultats)
        }
    }

    /**
     * Retrait d'une liste d'objets du lot lors de la création de celui-ci
     *
     * @param objects Liste des objets workflow à retirer
     * @param type Type des objets à retirer
     */
    private removeObjectFromLot(objects: LotNdfDTO[] | LotAvanceDTO[] | LotFactureDTO[], type: LotItemType) {
        if (LotComponent.isListNdf(objects, type)) {
            //Retire les ndf à créer dans le lot, qui sont dans la liste des objets à retirer
            objects.forEach(obj => {
                if (this.listeNDFCreationComponent.removeItem(obj)) {
                    this.listeNDFCreation.nbSelectedItems--;
                }
            });
            this.lot.nbNdf -= objects.length;

            //Filtre la liste des ndf du lot sur celles qui ne sont pas dans la liste des ndf à retirer
            this.lot.listeNotes = this.lot.listeNotes.filter(note => !objects.map(obj => obj.idNDF).includes(note));
        } else if (LotComponent.isListAvance(objects, type)) {
            //Retire les avances à créer dans le lot, qui sont dans la liste des objets à retirer
            objects.forEach(obj => {
                if (this.listeAVCreationComponent.removeItem(obj)) {
                    this.listeAVCreation.nbSelectedItems--;
                }
            });
            this.lot.nbAvance -= objects.length;

            //Filtre la liste des avances du lot sur celles qui ne sont pas dans la liste des avances à retirer
            this.lot.listeAvances = this.lot.listeAvances.filter(avance => !objects.map(obj => obj.idAvance).includes(avance));
        } else if (LotComponent.isListFacture(objects, type)) {
            //Retire les factures à créer dans le lot, qui sont dans la liste des objets à retirer
            objects.forEach(obj => {
                if (this.listeFCCreationComponent.removeItem(obj)) {
                    this.listeFCCreation.nbSelectedItems--;
                }
            });
            this.lot.nbFacture -= objects.length;

            //Filtre la liste des factures du lot sur celles qui ne sont pas dans la liste des factures à retirer
            this.lot.listeFactures = this.lot.listeFactures.filter(facture => !objects.map(obj => obj.idFacture).includes(facture));
        }

        //On recalcule les montants
        this.lot.computeMontants(this.listeNDFCreation.data.listeResultats, this.listeAVCreation.data.listeResultats, this.listeFCCreation.data.listeResultats)
    }

    /**
     * Vérifie et cast la liste en LotNdfDTO[] le cas échéant
     *
     * @param obj   Objet à vérifier
     * @param type  Type supposé de l'objet
     * @return {boolean} true si l'objet est du bon type
     */
    private static isListNdf(obj: LotNdfDTO[] | LotAvanceDTO[] | LotFactureDTO[], type: LotItemType): obj is LotNdfDTO[] {
        return type == LotItemType.NDF;
    }
    
    /**
     * Vérifie et cast la page en Page<LotNdfDTO> le cas échéant
     *
     * @param obj   Objet à vérifier
     * @param type  Type supposé de l'objet
     * @return {boolean} true si l'objet est du bon type
     */
    private static isPageNdf(obj: Page<LotNdfDTO> | Page<LotAvanceDTO> | Page<LotFactureDTO>, type: LotItemType): obj is Page<LotNdfDTO> {
        return type == LotItemType.NDF;
    }

    /**
     * Vérifie et cast la liste en LotAvanceDTO[] le cas échéant
     *
     * @param obj   Objet à vérifier
     * @param type  Type supposé de l'objet
     * @return {boolean} true si l'objet est du bon type
     */
    private static isListAvance(obj: LotNdfDTO[] | LotAvanceDTO[] | LotFactureDTO[], type: LotItemType): obj is LotAvanceDTO[] {
        return type == LotItemType.AVANCE;
    }
    
    /**
     * Vérifie et cast la page en Page<LotAvanceDTO> le cas échéant
     *
     * @param obj   Objet à vérifier
     * @param type  Type supposé de l'objet
     * @return {boolean} true si l'objet est du bon type
     */
    private static isPageAvance(obj: Page<LotNdfDTO> | Page<LotAvanceDTO> | Page<LotFactureDTO>, type: LotItemType): obj is Page<LotAvanceDTO> {
        return type == LotItemType.AVANCE;
    }

    /**
     * Vérifie et cast la liste en LotFactureDTO[] le cas échéant
     *
     * @param obj   Objet à vérifier
     * @param type  Type supposé de l'objet
     * @return {boolean} true si l'objet est du bon type
     */
    private static isListFacture(obj: LotNdfDTO[] | LotAvanceDTO[] | LotFactureDTO[], type: LotItemType): obj is LotFactureDTO[] {
        return type == LotItemType.FACTURE;
    }
    
    /**
     * Vérifie et cast la page en Page<LotFactureDTO> le cas échéant
     *
     * @param obj   Objet à vérifier
     * @param type  Type supposé de l'objet
     * @return {boolean} true si l'objet est du bon type
     */
    private static isPageFacture(obj: Page<LotNdfDTO> | Page<LotAvanceDTO> | Page<LotFactureDTO>, type: LotItemType): obj is Page<LotFactureDTO> {
        return type == LotItemType.FACTURE;
    }

    /**
     * Sauvegarde d'un Lot comptable
     */
    private saveLot() {
        //Vérification de la présence d'objets dans le lot et de la validité du formulaire
        if ((this.listeNDFCreation?.data.listeResultats.length > 0 || this.listeAVCreation?.data.listeResultats.length > 0 || this.listeFCCreation?.data.listeResultats.length > 0) && this.lotGeneraliteComponent?.form.valid) {
            this.lot.listeNotes = this.listeNDFCreation?.data.listeResultats.map(ndf => ndf.idNDF);
            this.lot.listeAvances = this.listeAVCreation?.data.listeResultats.map(av => av.idAvance);
            this.lot.listeFactures = this.listeFCCreation?.data.listeResultats.map(fc => fc.idFacture);

            //Chargement en cours
            this.isLoading = true;

            this.lotService.createLot(this.lot).subscribe({
                next: result => {
                    //Vérification de l'enregistrement
                    if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
                        //Navigation vers le lot
                        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                            this.router.navigate(['Lot', result.data?.idLot]);
                        })

                        //Chargement terminé
                        this.isLoading = false;

                        //Message de succès
                        this.toastrService.success(this.translateService.instant('global.success.enregistrement'));
                    } else {
                        //Chargement terminé
                        this.isLoading = false;
                        
                        //Message d'erreur
                        this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
                    }
                }
            });
            //Dans le cas ou aucun objet n'est dans le lot en création
        } else if (this.listeNDFCreation?.data.listeResultats.length == 0 && this.listeAVCreation?.data.listeResultats.length == 0 && this.listeFCCreation?.data.listeResultats.length == 0) {
            //Message d'erreur
            this.toastrService.error(this.translateService.instant('lot.actions.emptyList'));
        } else {
            //Message d'erreur
            this.toastrService.error(this.translateService.instant('global.errors.formInvalid'));
        }
    }

    /**
     * Changement d'onglet
     */
    onSelectedItemChange(selectedItem: PageHeaderItem) {
        if (this.selectedItem?.code !== selectedItem?.code) {
            if (selectedItem.code === Onglets.ARCHIVAGE) {
                //Navigation vers la route associée à l'onglet de l'archivage
                this.router.navigate(['Archivage'],{relativeTo: this.activatedRoute});
            } else {
                //Navigation vers la route associée au composant de la route courante
                this.router.navigate(['.'],{relativeTo: this.activatedRoute});
            }

            //Mise à jour de l'onglet sélectionné
            this.selectedItem = selectedItem;
            this.selectedIndex = this.listeTabItems.findIndex(tab => tab.code === this.selectedItem?.code);

            //Sélection du paramétrage lors du changement d'onglet
            switch (selectedItem.code) {
                case Onglets.NDF:
                    if (!this.nfSettings && this.listeNDF) {
                        this.store.dispatch({
                            type: settingsActions.LOAD_SETTINGS,
                            payload: TypePortee.NF
                        });
                    }
                    break;
                case Onglets.AVANCE:
                    if (!this.avSettings && this.listeAV) {
                        this.store.dispatch({
                            type: settingsActions.LOAD_SETTINGS,
                            payload: TypePortee.AV
                        });
                    }
                    break;
                case Onglets.FACTURE:
                    if (!this.fcSettings && this.listeFC) {
                        this.store.dispatch({
                            type: settingsActions.LOAD_SETTINGS,
                            payload: TypePortee.FC
                        });
                    }
                    break;
                default:
                    break;
            }
        }
    }

    /**
     * Met à jour l'onglet courant
     *
     * @param tabIndex Index de l'onglet à sélectionner. Si omis, il sera déterminé automatiquement en fonction de la route.
     * Si le code est indiqué dans la propriété 'data' de la route enfant il sera sélectionné, sinon c'est le 1er de la liste.
     */
    updateTab(tabIndex?: number) {
        let newTab;
        let codeTab;

        //Recherche de l'onglet par son index
        if (tabIndex != null) {
            //Récupération de l'onglet correspondant
            newTab = this.listeTabItems[tabIndex];
        } else if ((codeTab = this.activatedRoute.snapshot.firstChild?.data['onglet']) != null) {
            //Rechercher récupération de l'onglet correspondant au code indiqué dans les data de la route enfant
            newTab = this.listeTabItems.find(tab => tab.code === codeTab);
        } else {
            //Le 1er par défaut
            newTab = this.listeTabItems[0];
        }

        //Vérification de l'onglet
        if (newTab) {
            //Activation !
            newTab.selected = true;
            this.onSelectedItemChange(newTab);
        }

        //Affichage du détail si on n'est pas sur une route enfant
        this.isShowDetail = !this.activatedRoute.snapshot.firstChild;

        //Mise à jour de l'indicateur de faux lazy loading du détail
        if (!this.isDetailLoaded && this.isShowDetail) {
            this.isDetailLoaded = true;
        }
    }

    /**
     * Retour arrière
     */
    onGoBack() {
        //On vient d'un relevé
        if (!!this.state) {
            //Navigation vers le relevé
            this.router.navigate([this.state.path,this.state.idReleve])
        } else {
            //On vient de la liste des lots
            if ([TypeProfil.ADMINISTRATEUR,TypeProfil.SOUS_ADMINISTRATEUR].includes(this.user.fonction)) {
                //Navigation vers la liste pour les admins
                this.router.navigate(['../..'],{relativeTo: this.activatedRoute});
            } else {
                //Navigation vers la liste
                this.router.navigate(['ListeLot']);
            }
        }
    }

    /**
     * Récupère le libelle du statut d'archivage du lot comptable
     */
    getStatutArchivage() {
        switch (this.lotHeader.statutArchivage) {
            case StatutArchivage.EN_ATTENTE:
                return this.translateService.instant("lot.synchro.enAttente");
            case StatutArchivage.SYNCHRONISE:
                return this.translateService.instant("lot.synchro.synchronise", {date: this.datePipe.transform(this.lotHeader.dateArchivage, 'shortDate')});
            case StatutArchivage.ECHEC:
                return this.translateService.instant("lot.synchro.echec");
            case StatutArchivage.PARTIEL:
                return this.translateService.instant("lot.synchro.partiel");
            default:
                return "";
        }
    }

    /**
     * Supression du lot
     */
    deleteLot() {
        //Message de confirmation
        this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation')).pipe(filter(isConfirmed => isConfirmed)).subscribe({
            next: () => {
                this.lotService.deleteLot(this.lot.idLot).subscribe({
                    next: value => {
                        if (value?.codeErreur == 0) {
                            //Navigation vers la liste
                            this.router.navigate(['ListeLot']);
                        } else {
                            //Message d'erreur
                            this.toastrService.error(this.translateService.instant("global.errors.suppression"));
                        }
                    }
                });
            }
        });
    }
}

/**
 * Enum pour les noms des différents onglets de la page de détail d'un lot.
 */
export enum Onglets {
    GENERALITES = 'GENERALITES',
    NDF = 'NDF',
    AVANCE = 'AVANCE',
    FACTURE = 'FACTURE',
    ARCHIVAGE = 'ARCHIVAGE',
}