import {Component,Input,OnChanges,SimpleChanges,ViewChild} from '@angular/core';

import {SettingsNFState} from '@domain/settings/settings';
import {ZoneUtilisateurComponent} from '@components/zone-utilisateur/zone-utilisateur.component';
import {OMPService} from "@components/omp/omp.service";
import {ODService} from "@components/od/od.service";
import {NiveauAlerte} from "@domain/common/alerte/alerte";
import {Ndf} from "@domain/ndf/ndf";
import {SaisieTemps} from "@domain/od/saisie-temps";

@Component({
    selector: 'ndf-generalites',
    templateUrl: './ndf-generalites.component.html'
})
export class NDFGeneralitesComponent implements OnChanges {
    //Constantes
    NiveauAlerte = NiveauAlerte;

    /** Note de frais **/
    @Input() ndf: Ndf;

    /** Indicateur de mise à jour possible **/
    @Input() canModifier: boolean;

    /** Paramétrage de la note de frais **/
    @Input() settings: SettingsNFState;

    /** Comptes bancaires du collab */
    @Input() listeComptesBancaires: Array<any>;

    /** Indique si le compte bancaire est désactivé */
    @Input() isCompteBancaireDisabled: boolean;

    /** Composant enfant zone utilisateur */
    @ViewChild(ZoneUtilisateurComponent) childZoneUtilisateurComponent: ZoneUtilisateurComponent;

    /** Saisie des temps lié à l'od de la NDF */
    saisieTemps: SaisieTemps;

    /**
     * Constructeur
     */
    constructor(private ompService: OMPService,private odService: ODService) {
    }

    /**
     * Comparaison de périodes
     */
    comparePeriodes(p1: any,p2: any): boolean {
        //Comparaison des identifiants
        return p1?.idPeriode && p2?.idPeriode && p1.idPeriode == p2.idPeriode;
    }

    /**
     * Comparaison de comptes bancaires
     */
    compareComptesBancaires(c1: any,c2: any): boolean {
        //Comparaison des identifiants
        return c1?.idCompte && c2?.idCompte && c1.idCompte == c2.idCompte;
    }

    /**
     * Détection des modifications
     */
    ngOnChanges(changes: SimpleChanges) {
        //Vérification du compte bancaire
        if (this.ndf && this.listeComptesBancaires?.length && !this.ndf.compte) {
            //Mise à jour du compte bancaire
            this.ndf.compte = this.listeComptesBancaires.find(c => c.defaut);
        }

        //Si on a une modification de la ndf et que l'od n'est pas null
        if (changes.ndf?.currentValue != null && changes.ndf.currentValue.od != null) {
            this.odService.loadSaisieTemps(changes.ndf.currentValue.od.idOd,true).subscribe((saisie) => {
                //On récupère la saisie des temps
                this.saisieTemps = saisie;
            });
        }
    }

    /**
     * Contrôle de la validité du formulaire
     */
    public isValid(): boolean {
        return this.childZoneUtilisateurComponent.isValid();
    }

    /**
     * Met à jour le dto avant la sauvegarde
     */
    public beforeSave(): void {
        //Appelle le composant enfant zone utilisateur pour intégrer les données du formulaire zone utilisateur
        this.ndf.listeZU = this.childZoneUtilisateurComponent.getListeZoneUtilisateursBeforeSave();
    }

    /** Direction l'OMP ! */
    navigateToOMP() {
        if (this.ndf.omPermanent) {
            this.ompService.navigateToOMP(this.ndf.omPermanent.idOMPermanent);
        } else {
            throw Error("NDF non liée à un OMP !");
        }
    }

    /** Direction l'OD ! */
    navigateToOD() {
        if (this.ndf.od) {
            this.odService.navigateToOD(this.ndf.od.idOd);
        } else {
            throw Error("NDF non liée à un OD !");
        }
    }
}
