import {ListeAlertes} from "../common/alerte/listeAlertes";
import {ListItem} from "../common/list-view/list-item";
import {User} from "../user/user";
import {Statut} from "../workflow/statut";
import {Fournisseur} from "./fournisseur";
import {ReleveFacture} from "./releve-facture";
import {FactureContentieux} from "./facture-contentieux";
import {AbstractObjetWorkflow} from "../workflow/abstract-objet-workflow";
import {TypePortee} from "../workflow/workflow";
import {MapAction} from "../workflow/mapAction";
import {TranslateService} from "@ngx-translate/core";
import {FactureLienOd} from "@domain/facture/facture-lien-od";
import {UserPipeTransform} from "@share/pipe/user.pipe";

/**
 * Modèle représentant une facture listée
 */
export class ListeFacture extends AbstractObjetWorkflow implements ListItem {
    idFacture: number;
    factureReleve: ReleveFacture;
    statut: Statut;
    numero: string;
    type: string;
    date: Date;
    montant: number;
    montantControle: number;
    devise: string;
    fournisseur: Fournisseur;
    litige: boolean;
    user: User;
    listeAlertes: ListeAlertes;
    contentieux: FactureContentieux;
    listeHistoWorkflow: MapAction;
    ecartDevis: boolean;
    ecartDevisMontant: boolean;
    listeLiensFacOD: Array<FactureLienOd>;
    budgetOk: boolean;

    /* Champs transients pour l'affichage des alertes sur les montants */
    montantTotal: number;
    montantDevis: number;
    alerteEcartDevis: boolean;
    alerteMontantControle: boolean;

    constructor(dto: any) {
        super();
        if (dto) {
            this.idFacture = dto.idFacture;
            if (dto.factureReleve) { this.factureReleve = new ReleveFacture(dto.factureReleve) }
            this.statut = dto.statut as Statut;
            this.numero = dto.numero;
            this.type = dto.type;
            if (dto.date) { this.date = new Date(dto.date) }
            this.montant = dto.montant;
            this.montantControle = dto.montantControle;
            this.devise = dto.devise;
            if (dto.fournisseur) { this.fournisseur = new Fournisseur(dto.fournisseur) }
            this.litige = dto.litige;
            this.user = dto.user as User;
            if (dto.listeAlertes) { this.listeAlertes = new ListeAlertes(dto.listeAlertes as ListeAlertes); }
            if (dto.contentieux) { this.contentieux = new FactureContentieux(dto.contentieux) }
            if (dto.listeHistoWorkflow) { this.listeHistoWorkflow = new MapAction(dto.listeHistoWorkflow); }
            this.montantTotal = dto.montantTotal;
            this.montantDevis = dto.montantDevis;
            this.ecartDevis = dto.ecartDevis;
            this.ecartDevisMontant = dto.ecartDevisMontant;
            this.alerteEcartDevis = dto.alerteEcartDevis;
            this.alerteMontantControle = dto.alerteMontantControle;
            this.listeLiensFacOD = dto.listeLiensFacOD;
			this.masque = dto.masque;
            this.budgetOk = dto.budgetOk;
        }
    }

    /** Indicateur d'affichage de l'élément */
    isDisplayed?: boolean;

    /** Indicateur de sélection de l'élément */
    isSelected?: boolean;

    /**
     * Récupération de l'identifiant de l'objet
     * @returns l'identifiant de l'objet
     */
    getKey(): number {
        return this.idFacture;
    }

    getId(): number {
        return this.idFacture;
    }

    getPortee(): TypePortee {
        return TypePortee.FC;
    }

    getListeAlertes(): ListeAlertes {
        return this.listeAlertes;
    }

    getMapAction(): MapAction {
        return this.listeHistoWorkflow;
    }

    setListeAlertes(listeAlertes: ListeAlertes): void {
        this.listeAlertes = listeAlertes;
    }

    /**
     * Getter pour le collaborateur
     */
    getCollaborateur(): String {
        if (!this.user) { return null; }
        else return UserPipeTransform(this.user);
    }

    /**
     * Getter pour la liste de rôles format texte
     */
    getRoles(): string {
        return this.getMapAction()?.roles?.map(r => r.libelle).join("\n");
    }

    /**
     * Getter pour le nombre de rôles
     */
    getRolesCount(): number {
        return this.getMapAction()?.roles?.length;
    }

    /**
     * Getter pour l'ID Collab
     */
    getIdCollab(): number {
        return this.user?.idUser;
    }

    /**
     * Getter pour l'intitulé de l'objet
     * @param translateService service de traduction
     */
    getLibelleObjet(translateService: TranslateService): string {
        return '' + (this.numero || translateService.instant('facture.liste.objetNonDefini'));
    }

    /**
     * Getter pour la validité de l'objet
     */
    isValid(): boolean {
        //Hardcodé en attendant l'implémentation des données calculées
        return true;
    }

    /**
     * Getter de l'identifiant de l'OD de réconciliation
     */
    get idOd(): number | undefined {
        //Un seul OD malgré le fait que le modèle soit une liste...
        return this.listeLiensFacOD?.length > 0 ? this.listeLiensFacOD[0].idOd : null;
    }

    /**
     * Le budget de l'objet est-il conforme, afin d'autoriser une action workflow sur celui-ci
     */
    isBudgetOk(): boolean {
        return this.budgetOk;
    }
}
