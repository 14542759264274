import {Component,EventEmitter,Input,OnChanges,OnDestroy,OnInit,Output,SimpleChanges,TemplateRef,ViewChild} from '@angular/core';
import {FormControl,FormGroupDirective,NgForm,NgModel} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {debounceTime,filter,finalize,map,switchMap,tap} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {ErrorStateMatcher} from '@angular/material/core';
import {Observable,Subscription} from 'rxjs';
import {MatInput} from '@angular/material/input';

import {AutocompleteOptions} from './autocomplete';
import {environment} from '@environments/environment';
import {AutocompleteService} from './autocomplete.service';
import {FloatLabelType} from "@angular/material/form-field";
import {SearchType} from "@domain/common/list-view/sorting";
import {AutocompleteType} from "@share/component/autocomplete/options/autocomplete-type";
import {DatePipe} from "@angular/common";
import {MontantPipe} from "@share/pipe/montant";

@Component({
    selector: 'autocomplete',
    exportAs: 'autocomplete',
    templateUrl: './autocomplete.component.html',
    styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent implements OnInit,OnDestroy,OnChanges {
    /** Options de l'autocomplete */
    @Input() options: AutocompleteOptions;

    /** Type générique d'autocomplete */
    @Input() type: AutocompleteType;

    /** Filtres de l'autocomplete */
    @Input() filter?: any;

    /** Placeholder */
    @Input() placeholder: string;

    /** Autocomplete à partir d'un custom input */
    @Input() fromCustomInput: boolean = false;

    /** Utilisé pour définir le comportement du floatLabel (auto, always ou never) */
    @Input() floatLabel?: FloatLabelType = 'auto';

    /** Libellé du champ */
    @Input() label: string;

    /** Permet d'afficher du contenu après le label flottant */
    @Input() labelExtra?: TemplateRef<any>;

    /** Caractère obligatoire du champ */
    @Input() required?: boolean = false;

    /** Utilisé pour définir l'id de l'input */
    @Input() inputId: string;

    /** Caractère actif du champ */
    @Input() disabled?: boolean = false;

    /** Indique si l'input doit être vidé après la sélection */
    @Input() private isReinitAfterSelect?: boolean = false;

    /** Popup de recherche : Pas de recherche tant qu'un filtre n'a pas été renseigné */
    @Input() isSearchWhenNoFilter?: boolean;

    /** Sélection d'un élément **/
    @Output() onSelect: EventEmitter<any> = new EventEmitter<any>();

    /** Champ de saisie */
    @ViewChild('input') input: MatInput;

    /** Observable qui permet de reset la liste des items quand un event est envoyé */
    @Input() reinitListeObs: Observable<void>;

    /** Erreur material à afficher */
    @Input() matError?: { error: string, css?: string };

    /** Message material à afficher */
    @Input() matHint?: { libelle: string,css?: string };

    /** Contrôle */
    autocompleteControl = new FormControl();

    /** Liste des éléments */
    listeItems: Array<any>;

    /** Indicateur de chargement */
    isLoading: boolean = false;

    /** Gestion des erreurs */
    errorStateMatcher: AutocompleteErrorStateMatcher;

    /** Liste des souscriptions */
    listeSouscriptions: Array<Subscription> = new Array<Subscription>();

    /**
     * Constructeur
     */
    constructor(private http: HttpClient,private ngModel: NgModel,private translateService: TranslateService,
                private autocompleteService: AutocompleteService,private datePipe: DatePipe,private montantPipe: MontantPipe) {
        //Binding de l'affichage
        this.displayItem = this.displayItem.bind(this);
    }

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        //Vérification des données
        if (!this.options && !this.type)
            //Déclenchement d'une exception
            throw 'Le type ou les options doivent être renseignés';

        //Lecture des options si nécessaire
        this.options = this.options || this.autocompleteService.getOptionsForType(this.type);

        //Définition du mode de recherche
        this.options.searchType = this.options.searchType || SearchType.STARTS_WITH;

        //Mise à jour des options en fonction de l'attribut isSearchWhenNoFilter défini sur l'autocomplete
        if (this.isSearchWhenNoFilter != null) {
            this.options.isSearchWhenNoFilter = this.isSearchWhenNoFilter;
        }

        //Définition de la valeur de l'autocomplete
        this.listeSouscriptions.push(this.ngModel.valueChanges.pipe(
            filter(() => !this.input.focused)
        ).subscribe(value => this.autocompleteControl.setValue(this.ngModel.model)));

        //Définition des règles de validation
        this.errorStateMatcher = new AutocompleteErrorStateMatcher(this.ngModel,this);

        //Filtrage des options possibles
        this.listeSouscriptions.push(this.autocompleteControl.valueChanges.pipe(
            debounceTime(500),
            filter(i => i?.length > 0),
            tap(() => {
                //Suppression des valeurs existantes
                this.listeItems = null;

                //Indicateur de chargement
                this.isLoading = true;
            }),
            switchMap(value => this.http.post(environment.baseUrl+(typeof this.options.url == 'function' && this.options.url(this.filter) || this.options.url),{
                listeFilter: [{
                    clef: this.options.listeFilters(this.translateService,this.filter).filter(f => f.isDefault).map(f => f.clef).join(','),
                    valeur: this.options.searchType == SearchType.STARTS_WITH ? `${value}%` : `%${value}%`
                },...(this.options.listeStaticFilters?.(this.filter) || [])],
                defaultOrder: this.options.getOrderBy ? this.options.getOrderBy() : this.options.listeFilters(this.translateService,this.filter).filter(f => f.isDefault).map(f => f.clef).join(',')
            }).pipe(
                map((result: any) => this.options.mapResult && this.options.mapResult(result,true) || result.listeResultats),
                finalize(() => {
                    //Fin du chargement
                    this.isLoading = false;
                })
            ))
        ).subscribe({
            next: listeItems =>  {
                this.listeItems = listeItems
            }
        }));

        //Définition de la valeur par défaut du placeholder
        this.placeholder = this.placeholder ?? this.translateService.instant('autocomplete.title');

        // si on a initialisé l'observable pour réinitialiser la liste
        if (this.reinitListeObs != null) {
            this.listeSouscriptions.push(
                //on souscrit à l'observable pour reset la liste
                this.reinitListeObs.subscribe(() => {
                    this.listeItems = null;
                })
            );
        }

    }

    /**
     * Destruction du composant
     */
    ngOnDestroy() {
        //Annulation des souscriptions
        this.listeSouscriptions.forEach(s => s.unsubscribe());
    }

    /**
     * Interception d'un changement de valeur
     */
    onOptionSelected(value: any) {
        //Définition du modèle lié à l'autocomplete
        this.ngModel.reset(value);

        //Sélection d'un élément
        this.onSelect.emit(value);

        //Si on doit réinitialiser l'input après la sélection
        if (this.isReinitAfterSelect) {
            //On vide l'input et la liste de résultats
            this.input.value = '';
            this.listeItems = null;
        }
    }

    /**
     * Vérification du changement de valeur de la saisie
     */
    onChange(event: any) {
        //Vérification de la valeur saisie
        if (!event.target.value)
            //Réinitialisation du modèle
            this.onOptionSelected(null);
    }

    /**
     * Affichage de la popup de recherche
     */
    showSearch() {
        //Affichage de la popup
        this.autocompleteService.showSearch({
            label: this.label,
            options: this.options,
            filter: this.filter
        }).subscribe({
            next: (item) => {
                //Vérification de l'élément sélectionné
                if (item) {
                    //Mise à jour de l'option
                    this.onOptionSelected(item);

                    //Mise à jour de la valeur dans l'autocomplete
                    this.autocompleteControl.setValue(item);
                }
            }
        });
    }

    /**
     * Affichage d'un élément
     */
    displayItem(item) {
        //Affichage de l'élément
        return this.options?.displayItem(item,this.translateService,this.datePipe,this.montantPipe);
    }

    /**
     * Permet de regarder l'attribut disabled afin de gérer la désactivation de l'autocomplete
     */
    ngOnChanges(changes: SimpleChanges): void {
        //Si la nouvelle valeur de l'attribut disabled est différente de la précédente on change sa valeur
        if (changes['disabled']?.previousValue != changes['disabled']?.currentValue) {
            this.disabled ? this.autocompleteControl.disable() : this.autocompleteControl.enable();
        }

        //Mise à jour des options en fonction du changement de l'attribut isSearchWhenNoFilter
        if (this.options != null && changes['isSearchWhenNoFilter']?.previousValue != changes['isSearchWhenNoFilter']?.currentValue) {
            this.options.isSearchWhenNoFilter = this.isSearchWhenNoFilter;
        }
    }

    /**
     * Remise à zéro des données
     */
    public reset() {
        //Remise à zéro de la valeur
        this.autocompleteControl.setValue(null);
    }
}

/**
 * Contrôle des erreurs de l'autocomplete
 */
class AutocompleteErrorStateMatcher implements ErrorStateMatcher {
    /**
     * Constructeur
     */
    constructor(private ngModel: NgModel,private autocompleteComponent: AutocompleteComponent) {
    }

    /**
     * Vérification
     */
    isErrorState(control: FormControl | null,form: FormGroupDirective | NgForm | null): boolean {
        //Vérification de la validité de la saisie
        return (this.ngModel.control.errors != null && Object.keys(this.ngModel.control.errors).length > 0)
            || !!this.autocompleteComponent?.matError?.error;

    }
}
