import {Component,Inject,OnInit,Type} from '@angular/core';
import {ListView,TypeComparaison,TypeFilter} from "@domain/common/list-view";
import {CompteComptableUsageListItemComponent} from "./compte-comptable-usage-list-item.component";
import {TypeUsage} from "../compte-comptable-usage.component";
import {TranslateService} from "@ngx-translate/core";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {CompteUsage} from "@domain/admin/comptabilite/compte-usage/compte-usage";
import {Page} from "@domain/common/http/list-result";
import {CompteUsageUser} from "@domain/admin/comptabilite/compte-usage/compte-usage-user";
import {CompteUsageTaxe} from "@domain/admin/comptabilite/compte-usage/compte-usage-taxe";
import {DatePipe,DecimalPipe} from "@angular/common";
import {CompteUsagePrestation} from "@domain/admin/comptabilite/compte-usage/compte-usage-prestation";
import {CompteUsageModeRemb} from "@domain/admin/comptabilite/compte-usage/compte-usage-mode-remb";
import {TypeRemb} from "@domain/admin/comptabilite/mode-remb";
import {CompteUsageFournisseur} from "@domain/admin/comptabilite/compte-usage/compte-usage-fournisseur";
import {CompteUsageCarte} from "@domain/admin/comptabilite/compte-usage/compte-usage-carte";
import {CompteUsageAvanceDevise} from "@domain/admin/comptabilite/compte-usage/compte-usage-avance-devise";
import {CompteUsageAvanceCollab} from "@domain/admin/comptabilite/compte-usage/compte-usage-avance-collab";
import {TypePrestation} from "@domain/prestation/prestation";

/**
 * Composant d'affichage des compteurs d'usages d'un compte comptable
 *
 * @author Laurent Convert
 * @date 24/09/2024
 */
@Component({
	host: {'data-test-id': 'compte-comptable-usage-list'},
	templateUrl: './compte-comptable-usage-list.component.html'
})
export class CompteComptableUsageListComponent implements OnInit {

	/** Liste des compteurs */
	listeUsage: ListView<CompteUsage,CompteComptableUsageListItemComponent<CompteUsage>>;

	/**
	 * Constructeur
	 *
	 * @param data Données injectées dans la popin
	 * @param translateService Service de traduction
	 * @param decimalPipe Formatage des nombres
	 * @param datePipe Formatage des dates
	 */
	constructor(@Inject(MAT_DIALOG_DATA) public data: { numero: string,typeUsage: TypeUsage },
				private translateService: TranslateService,
				private decimalPipe: DecimalPipe,
				private datePipe: DatePipe) {
	}

	/**
	 * Handler de l'initialisation du composant
	 */
	ngOnInit() {
		switch (this.data.typeUsage) {
			case TypeUsage.AVANCE_COLLAB:
				this.listeUsage = this.initListCompteUsageAvanceCollab();
				break;
			case TypeUsage.AVANCE_DEVISE:
				this.listeUsage = this.initListCompteUsageAvanceDevise();
				break;
			case TypeUsage.CARTE_AFFAIRE:
				this.listeUsage = this.initListCompteUsageCarte();
				break;
			case TypeUsage.FOURNISSEUR:
				this.listeUsage = this.initListCompteUsageFournisseur();
				break;
			case TypeUsage.MODE_REMB:
				this.listeUsage = this.initListCompteUsageModeRemb();
				break;
			case TypeUsage.PRESTATION:
				this.listeUsage = this.initListCompteUsagePrestation();
				break;
			case TypeUsage.TAXE:
				this.listeUsage = this.initListCompteUsageTaxe()
				break;
			case TypeUsage.UTILISATEUR:
				this.listeUsage = this.initListCompteUsageUser();
				break;
		}
	}

	/**
	 * Initialisation de la liste des avances collab associées au compte comptable
	 */
	private initListCompteUsageAvanceCollab() {
		//Initialisation de la liste pour le type
		let listeUsage: ListView<CompteUsageAvanceCollab,CompteComptableUsageListItemComponent<CompteUsageAvanceCollab>> = this.initListeCompteUsage(CompteUsageAvanceCollab);

		//Mappage des résultats en instances
		listeUsage.mapResult = (result: Page<CompteUsageAvanceCollab>): Page<CompteUsageAvanceCollab> => {
			result.listeResultats = result.listeResultats?.map(obj => new CompteUsageAvanceCollab(obj));
			return result;
		};

		//Initialisation des filtres
		listeUsage.listeFilters = [{
			title: this.translateService.instant('admin.entreprise.utilisateurs.internes.nom'),
			clef: 'nom',
			isDefault: true,
			typeComparaison: TypeComparaison[TypeComparaison.LIKE]
		},{
			title: this.translateService.instant('admin.entreprise.utilisateurs.internes.prenom'),
			clef: 'prenom',
			isDefault: true,
			typeComparaison: TypeComparaison[TypeComparaison.LIKE]
		},{
			title: this.translateService.instant('admin.entreprise.utilisateurs.internes.matricule'),
			clef: 'matricule',
			isDefault: true,
			typeComparaison: TypeComparaison[TypeComparaison.LIKE]
		},{
			title: this.translateService.instant('admin.comptabilite.planComptable.compteComptable.usage.actif'),
			clef: 'isActif',
			type: TypeFilter[TypeFilter.BOOLEAN]
		}];

		//Définition des colonnes de tri
		listeUsage.columns = [
			{ key: 'nom', title: 'admin.entreprise.utilisateurs.internes.nom' },
			{ key: 'prenom', title: 'admin.entreprise.utilisateurs.internes.prénom' },
			{ key: 'matricule', title: 'admin.entreprise.utilisateurs.internes.matricule' },
		];

		//Persistance des filtres
		listeUsage.isPersistFilters = true;
		listeUsage.className = 'ListCompteUsageAvanceCollab';

		//Ajout du tri de la liste selon l'ordre voulu
		listeUsage.defaultOrder = "nom,prenom";

		return listeUsage;
	}

	/**
	 * Initialisation de la liste des avances devise associées au compte comptable
	 */
	private initListCompteUsageAvanceDevise() {
		//Initialisation de la liste pour le type
		let listeUsage: ListView<CompteUsageAvanceDevise,CompteComptableUsageListItemComponent<CompteUsageAvanceDevise>> = this.initListeCompteUsage(CompteUsageAvanceDevise);

		//Mappage des résultats en instances
		listeUsage.mapResult = (result: Page<CompteUsageAvanceDevise>): Page<CompteUsageAvanceDevise> => {
			result.listeResultats = result.listeResultats?.map(obj => new CompteUsageAvanceDevise(obj));
			return result;
		};

		//Initialisation des filtres
		listeUsage.listeFilters = [{
			title: this.translateService.instant('admin.comptabilite.planComptable.compteComptable.usage.code'),
			clef: 'code',
			isDefault: true,
			typeComparaison: TypeComparaison[TypeComparaison.LIKE]
		},{
			title: this.translateService.instant('admin.comptabilite.planComptable.compteComptable.usage.libelle'),
			clef: 'libelle',
			isDefault: true,
			typeComparaison: TypeComparaison[TypeComparaison.LIKE]
		},{
			title: this.translateService.instant('admin.comptabilite.planComptable.compteComptable.usage.actif'),
			clef: 'isActive',
			isDefault: true,
			typeComparaison: TypeComparaison[TypeComparaison.LIKE]
		}];

		//Définition des colonnes de tri
		listeUsage.columns = [
			{ key: 'code', title: 'admin.comptabilite.planComptable.compteComptable.usage.code' },
			{ key: 'libelle', title: 'admin.comptabilite.planComptable.compteComptable.usage.libelle' },
			{ key: 'isActive', title: 'admin.comptabilite.planComptable.compteComptable.usage.actif' },
		];

		//Persistance des filtres
		listeUsage.isPersistFilters = true;
		listeUsage.className = 'ListCompteUsageAvanceDevise';

		//Ajout du tri de la liste selon l'ordre voulu
		listeUsage.defaultOrder = "code";

		return listeUsage;
	}

	/**
	 * Initialisation de la liste des cartes associées au compte comptable
	 */
	private initListCompteUsageCarte() {
		//Initialisation de la liste pour le type
		let listeUsage: ListView<CompteUsageCarte,CompteComptableUsageListItemComponent<CompteUsageCarte>> = this.initListeCompteUsage(CompteUsageCarte);

		//Mappage des résultats en instances
		listeUsage.mapResult = (result: Page<CompteUsageCarte>): Page<CompteUsageCarte> => {
			result.listeResultats = result.listeResultats?.map(obj => new CompteUsageCarte(obj));
			return result;
		};

		//Initialisation des filtres
		listeUsage.listeFilters = [{
			title: this.translateService.instant('admin.comptabilite.planComptable.compteComptable.usage.raisonSociale'),
			clef: 'fournisseur.raisonSociale',
			isDefault: true,
			typeComparaison: TypeComparaison[TypeComparaison.LIKE]
		},{
			title: this.translateService.instant('admin.comptabilite.planComptable.compteComptable.usage.libelleContrat'),
			clef: 'contrat.libelle',
			isDefault: true,
			typeComparaison: TypeComparaison[TypeComparaison.LIKE]
		},{
			title: this.translateService.instant('admin.comptabilite.planComptable.compteComptable.usage.numCarte'),
			clef: 'numCarteClair',
			isDefault: true,
			typeComparaison: TypeComparaison[TypeComparaison.LIKE]
		},{
			title: this.translateService.instant('admin.comptabilite.planComptable.compteComptable.usage.collaborateur'),
			clef: 'user.matricule,user.nom,user.prenom',
			isDefault: true,
			typeComparaison: TypeComparaison[TypeComparaison.LIKE]
		}];

		//Définition des colonnes de tri
		listeUsage.columns = [
			{ key: 'numCarteClair', title: 'admin.comptabilite.planComptable.compteComptable.usage.numCarte' },
			{ key: 'fournisseur.raisonSociale', title: 'admin.comptabilite.planComptable.compteComptable.usage.raisonSociale' },
			{ key: 'contrat.libelle', title: 'admin.comptabilite.planComptable.compteComptable.usage.libelleContrat' },
			{ key: 'user.nom', title: 'admin.entreprise.utilisateurs.internes.nom' },
			{ key: 'user.prenom', title: 'admin.entreprise.utilisateurs.internes.prenom' },
			{ key: 'user.matricule', title: 'admin.entreprise.utilisateurs.internes.matricule' },
		];

		//Persistance des filtres
		listeUsage.isPersistFilters = true;
		listeUsage.className = 'ListCompteUsageCarte';

		//Ajout du tri de la liste selon l'ordre voulu
		listeUsage.defaultOrder = "fournisseur.raisonSociale,contrat.libelle,numCarteClair";

		return listeUsage;
	}

	/**
	 * Initialisation de la liste des fournisseurs associés au compte comptable
	 */
	private initListCompteUsageFournisseur() {
		//Initialisation de la liste pour le type
		let listeUsage: ListView<CompteUsageFournisseur,CompteComptableUsageListItemComponent<CompteUsageFournisseur>> = this.initListeCompteUsage(CompteUsageFournisseur);

		//Mappage des résultats en instances
		listeUsage.mapResult = (result: Page<CompteUsageFournisseur>): Page<CompteUsageFournisseur> => {
			result.listeResultats = result.listeResultats?.map(obj => new CompteUsageFournisseur(obj));
			return result;
		};

		//Initialisation des filtres
		listeUsage.listeFilters = [{
			title: this.translateService.instant('admin.comptabilite.planComptable.compteComptable.usage.raisonSociale'),
			clef: 'raisonSociale',
			isDefault: true,
			typeComparaison: TypeComparaison[TypeComparaison.LIKE]
		},{
			title: this.translateService.instant('admin.comptabilite.planComptable.compteComptable.usage.codeFournisseur'),
			clef: 'codeFournisseur',
			isDefault: true,
			typeComparaison: TypeComparaison[TypeComparaison.LIKE]
		},{
			title: this.translateService.instant('admin.comptabilite.planComptable.compteComptable.usage.serviceFacture'),
			clef: 'orga.libelle',
			isDefault: true,
			typeComparaison: TypeComparaison[TypeComparaison.LIKE]
		}];

		//Définition des colonnes de tri
		listeUsage.columns = [
			{ key: 'raisonSociale', title: 'admin.comptabilite.planComptable.compteComptable.usage.raisonSociale' },
			{ key: 'codeFournisseur', title: 'admin.comptabilite.planComptable.compteComptable.usage.codeFournisseur' },
			{ key: 'orga.libelle', title: 'admin.comptabilite.planComptable.compteComptable.usage.serviceFacture' },
		];

		//Persistance des filtres
		listeUsage.isPersistFilters = true;
		listeUsage.className = 'ListCompteUsageFournisseur';

		//Ajout du tri de la liste selon l'ordre voulu
		listeUsage.defaultOrder = "raisonSociale";

		return listeUsage;
	}

	/**
	 * Initialisation de la liste des modes de remboursement associés au compte comptable
	 */
	private initListCompteUsageModeRemb() {
		//Initialisation de la liste pour le type
		let listeUsage: ListView<CompteUsageModeRemb,CompteComptableUsageListItemComponent<CompteUsageModeRemb>> = this.initListeCompteUsage(CompteUsageModeRemb);

		//Mappage des résultats en instances
		listeUsage.mapResult = (result: Page<CompteUsageModeRemb>): Page<CompteUsageModeRemb> => {
			result.listeResultats = result.listeResultats?.map(obj => new CompteUsageModeRemb(obj));
			return result;
		};

		//Initialisation des filtres
		listeUsage.listeFilters = [{
			title: this.translateService.instant('admin.comptabilite.planComptable.compteComptable.usage.libelle'),
			clef: 'libelle',
			isDefault: true,
			typeComparaison: TypeComparaison[TypeComparaison.LIKE]
		},{
			title: this.translateService.instant('admin.comptabilite.planComptable.compteComptable.usage.type'),
			clef: 'typeRemb',
			listeValues: TypeRemb.listeFilters(this.translateService)
		},{
			title: this.translateService.instant('admin.comptabilite.planComptable.compteComptable.usage.actif'),
			clef: 'isActif',
			type: TypeFilter[TypeFilter.BOOLEAN]
		}];

		//Définition des colonnes de tri
		listeUsage.columns = [
			{ key: 'libelle', title: 'admin.comptabilite.planComptable.compteComptable.usage.libelle' },
			{ key: 'typeRemb', title: 'admin.comptabilite.planComptable.compteComptable.usage.type' },
			{ key: 'isActif', title: 'admin.comptabilite.planComptable.compteComptable.usage.actif' },
		];

		//Persistance des filtres
		listeUsage.isPersistFilters = true;
		listeUsage.className = 'ListCompteUsageModeRemb';

		//Ajout du tri de la liste selon l'ordre voulu
		listeUsage.defaultOrder = "libelle";

		return listeUsage;
	}

	/**
	 * Initialisation de la liste des prestations associées au compte comptable
	 */
	private initListCompteUsagePrestation() {
		//Initialisation de la liste pour le type
		let listeUsage: ListView<CompteUsagePrestation,CompteComptableUsageListItemComponent<CompteUsagePrestation>> = this.initListeCompteUsage(CompteUsagePrestation);

		//Mappage des résultats en instances
		listeUsage.mapResult = (result: Page<CompteUsagePrestation>): Page<CompteUsagePrestation> => {
			result.listeResultats = result.listeResultats?.map(obj => new CompteUsagePrestation(obj));
			return result;
		};

		//Initialisation des filtres
		listeUsage.listeFilters = [{
			title: this.translateService.instant('admin.comptabilite.planComptable.compteComptable.usage.libelle'),
			clef: 'libelle',
			isDefault: true,
			typeComparaison: TypeComparaison[TypeComparaison.LIKE]
		},{
			title: this.translateService.instant('admin.comptabilite.planComptable.compteComptable.usage.type'),
			clef: 'type',
			listeValues: [TypePrestation.FORFAIT,TypePrestation.PLAFOND,TypePrestation.REEL].map(tp => {
				return {
					code: String(tp.valueOf()),
					libelle: this.translateService.instant('admin.comptabilite.planComptable.compteComptable.usage.typePrestation.' + TypePrestation[tp])
				};
			})
		},{
			title: this.translateService.instant('admin.comptabilite.planComptable.compteComptable.usage.actif'),
			clef: 'isActif',
			type: TypeFilter[TypeFilter.BOOLEAN]
		}];

		//Définition des colonnes de tri
		listeUsage.columns = [
			{ key: 'libelle', title: 'admin.comptabilite.planComptable.compteComptable.usage.libelle' },
			{ key: 'type', title: 'admin.comptabilite.planComptable.compteComptable.usage.type' },
			{ key: 'isActif', title: 'admin.comptabilite.planComptable.compteComptable.usage.actif' },
		];

		//Persistance des filtres
		listeUsage.isPersistFilters = true;
		listeUsage.className = 'ListCompteUsagePrestation';

		//Ajout du tri de la liste selon l'ordre voulu
		listeUsage.defaultOrder = "libelle";

		return listeUsage;
	}

	/**
	 * Initialisation de la liste des taxes associées au compte comptable
	 */
	private initListCompteUsageTaxe() {
		//Initialisation de la liste pour le type
		let listeUsage: ListView<CompteUsageTaxe,CompteComptableUsageListItemComponent<CompteUsageTaxe>> = this.initListeCompteUsage(CompteUsageTaxe);

		//Mappage des résultats en instances
		listeUsage.mapResult = (result: Page<CompteUsageTaxe>): Page<CompteUsageTaxe> => {
			result.listeResultats = result.listeResultats?.map(obj => new CompteUsageTaxe(obj,this.decimalPipe,this.datePipe));
			return result;
		};

		//Initialisation des filtres
		listeUsage.listeFilters = [{
			title: this.translateService.instant('admin.comptabilite.planComptable.compteComptable.usage.libelleDomaine'),
			clef: 'domaine.libelle',
			isDefault: true,
			typeComparaison: TypeComparaison[TypeComparaison.LIKE]
		},{
			title: this.translateService.instant('admin.comptabilite.planComptable.compteComptable.usage.zone'),
			clef: 'departementAdmin.libelle,regionAdmin.libelle,territoire.libelle,pays.libelle',
			isDefault: true,
			typeComparaison: TypeComparaison[TypeComparaison.LIKE]
		},{
			title: this.translateService.instant('admin.comptabilite.planComptable.compteComptable.usage.taux'),
			clef: 'listeTaux.taux',
			type: TypeFilter[TypeFilter.DECIMAL]
		},{
			title: this.translateService.instant('admin.comptabilite.planComptable.compteComptable.usage.date'),
			clef: 'listeTaux.date',
			type: TypeFilter[TypeFilter.DATE]
		}];

		//Définition des colonnes de tri
		listeUsage.columns = [
			{ key: 'domaine.libelle', title: 'admin.comptabilite.planComptable.compteComptable.usage.libelleDomaine' },
			{ key: 'departementAdmin.libelle,regionAdmin.libelle,territoire.libelle,pays.libelle', title: 'admin.comptabilite.planComptable.compteComptable.usage.zone' },
			{ key: 'listeTaux.taux', title: 'admin.comptabilite.planComptable.compteComptable.usage.taux' },
			{ key: 'listeTaux.date', title: 'admin.comptabilite.planComptable.compteComptable.usage.date' },
		];

		//Persistance des filtres
		listeUsage.isPersistFilters = true;
		listeUsage.className = 'ListCompteUsageTaxe';

		//Ajout du tri de la liste selon l'ordre voulu
		listeUsage.defaultOrder = "domaine.libelle";

		return listeUsage;
	}

	/**
	 * Initialisation de la liste des utilisateurs associés au compte comptable
	 */
	private initListCompteUsageUser() {
		//Initialisation de la liste pour le type
		let listeUsage: ListView<CompteUsageUser,CompteComptableUsageListItemComponent<CompteUsageUser>> = this.initListeCompteUsage(CompteUsageUser);

		//Mappage des résultats en instances
		listeUsage.mapResult = (result: Page<CompteUsageUser>): Page<CompteUsageUser> => {
			result.listeResultats = result.listeResultats?.map(obj => new CompteUsageUser(obj));
			return result;
		};

		//Initialisation des filtres
		listeUsage.listeFilters = [{
			title: this.translateService.instant('admin.entreprise.utilisateurs.internes.nom'),
			clef: 'nom',
			isDefault: true,
			typeComparaison: TypeComparaison[TypeComparaison.LIKE]
		},{
			title: this.translateService.instant('admin.entreprise.utilisateurs.internes.prenom'),
			clef: 'prenom',
			isDefault: true,
			typeComparaison: TypeComparaison[TypeComparaison.LIKE]
		},{
			title: this.translateService.instant('admin.entreprise.utilisateurs.internes.matricule'),
			clef: 'matricule',
			isDefault: true,
			typeComparaison: TypeComparaison[TypeComparaison.LIKE]
		},{
			title: this.translateService.instant('admin.comptabilite.planComptable.compteComptable.usage.actif'),
			clef: 'isActif',
			type: TypeFilter[TypeFilter.BOOLEAN]
		}];

		//Définition des colonnes de tri
		listeUsage.columns = [
			{ key: 'nom', title: 'admin.entreprise.utilisateurs.internes.nom' },
			{ key: 'prenom', title: 'admin.entreprise.utilisateurs.internes.prénom' },
			{ key: 'matricule', title: 'admin.entreprise.utilisateurs.internes.matricule' },
		];

		//Persistance des filtres
		listeUsage.isPersistFilters = true;
		listeUsage.className = 'ListCompteUsageUser';

		//Ajout du tri de la liste selon l'ordre voulu
		listeUsage.defaultOrder = "nom,prenom";

		return listeUsage;
	}

	/**
	 * Initialisation de la liste de base des usages
	 *
	 * @param model Type des usages
	 */
	private initListeCompteUsage<T extends CompteUsage>(model: Type<T>) {
		//Initialisation de la liste
		return new ListView<T,CompteComptableUsageListItemComponent<T>>({
			uri: `/controller/Compte/${this.data.numero}/listeUsage/${this.data.typeUsage}`,
			title: this.translateService.instant(`admin.comptabilite.planComptable.compteComptable.usage.typeUsage.${this.data.typeUsage}`),
			component: CompteComptableUsageListItemComponent,
			isFilter: true
		});
	}

}