import {TypeRemb} from "../mode-remb";
import {CompteUsage} from "./compte-usage";
import {EntityAttribute} from "@share/component/autocomplete/options/entity-attribute";
import {TranslateService} from "@ngx-translate/core";

/**
 * Usages d'un compte comptable
 * Mode de remboursement associé à un compte comptable
 *
 * @author Laurent Convert
 * @date 24/09/2024
 */
export class CompteUsageModeRemb implements CompteUsage {
	libelle: string;
	typeRemb: TypeRemb;
	actif: boolean;

	/**
	 * Constructeur
	 *
	 * @param dto Source
	 */
	constructor(dto: any) {
		Object.assign(this,dto);
	}

	/**
	 * Récupération de l'identifiant de l'objet
	 */
	getKey(): string {
		return this.libelle;
	}

	/**
	 * Affichage d'un élément
	 */
	displayItem(): string {
		return this.libelle;
	}

	/**
	 * Liste les propriétés de l'objet pour lesquelles il faut afficher un badge 'clé:valeur'
	 *
	 * @param translateService Service de traduction
	 */
	listDisplayedAttributes(translateService?: TranslateService): Array<EntityAttribute> {
		return [{
			clef: 'admin.comptabilite.planComptable.compteComptable.usage.libelle',
			valeur: this.libelle
		},{
			clef: 'admin.comptabilite.planComptable.compteComptable.usage.type',
			valeur: TypeRemb.traduction(this.typeRemb,translateService)
		},{
			clef: 'admin.comptabilite.planComptable.compteComptable.usage.actif',
			valeur: translateService.instant(`global.${this.actif ? 'oui' : 'non'}`)
		}];
	}
}