import {Component,OnDestroy,OnInit,ViewChild} from '@angular/core';
import {PageHeaderItem} from '@share/component/page-header/page-header';
import {Pays} from "@domain/geographie/pays";
import {NgForm} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';
import {FloatingButtonAction,TypeAction} from '@share/component/floating-button/floating-button';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute,Router} from '@angular/router';
import {GeographieService} from '@services/admin/geographie/geographie.service';
import {ToastrService} from 'ngx-toastr';
import {Territoire} from "@domain/geographie/territoire";
import {TypeCodeErreur} from '@domain/common/http/result';
import {first} from "rxjs/operators";

/**
 * Composant d'affichage du détail d'un pays
 *
 * @author Angeline Ha
 * @date 08/11/2023
 */
@Component({
    host: {'data-test-id': 'pays'},
    selector: 'pays',
    templateUrl: './pays.component.html'
})
export class PaysComponent implements OnInit, OnDestroy {

    /** Liste des différents onglets disponibles dans un Pays */
    listeTabItems: Array<PageHeaderItem>;

    /** Onglet courant */
    selectedItem: PageHeaderItem;

    /** Référence vers l'enum pour l'utiliser dans le template */
    Onglets = Onglets;

    /** Pays à afficher */
    pays: Pays;

    /** Formulaire */
    @ViewChild('form') form: NgForm;

    /** Indique s'il faut afficher le formulaire en création ou en édition */
    isCreation: boolean;

    /** Liste des actions possibles */
    listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

    /**
     * Constructeur
     *
     * @param translateService  Service de traduction
     * @param router            Router
     * @param route             Route d'accès à cette page
     * @param geographieService Service géographie
     * @param toastrService     Service de gestion des toasts
     */
    constructor(private translateService: TranslateService,
                private router: Router,
                private route: ActivatedRoute,
                private geographieService: GeographieService,
                private toastrService: ToastrService) {
    }

    /**
     * Initialisation
     */
    ngOnInit(): void {
        //Indique au composant géographie qu'on affiche le détail d'un pays
        this.geographieService.isShowDetail = true;

        //Récupération de l'identifiant du pays
        this.route.params.pipe(first()).subscribe(params => {
            const idPays = params['idPays'];

            //Indique si on est en mode création
            this.isCreation = idPays === '0';

            //Récupération du pays
            if (this.isCreation) {
                //Création d'un nouveau pays
                this.pays = {
                    idPays: null,
                    libelle: "",
                    libelleNettoye: "",
                    code2: "",
                    code3: "",
                    nomTVA: "",
                    devise: "",
                    idZone: null,
                    zone: null,
                    listeTerritoires: new Array<Territoire>(),
                    actif: true
                };
            } else {
                //Récupération du pays existant
                this.geographieService.getPaysById(idPays).subscribe(data => {
                    if (data.codeErreur === TypeCodeErreur.NO_ERROR) {
                        this.pays = data.data.pays;
                    } else {
                        TypeCodeErreur.showError(data.codeErreur, this.translateService, this.toastrService);
                    }
                });

            }
        });

        //Initialisation des onglets
        this.listeTabItems = [{
            code: Onglets.GENERALITES,
            libelle: this.translateService.instant('admin.bibliotheque.geographie.territoire.onglets.generalites')
        }];

        //Sélection du premier onglet par défaut
        this.selectedItem = this.listeTabItems[0];

        //Définition de la liste des actions possibles
        this.listeActions.next([{
            type: TypeAction.PRIMARY,
            icone: 'nio icon-sauvegarde',
            libelle: 'global.actions.enregistrer',
            doAction: () => this.savePays(),
            isDisabled: () => !this.form?.valid
        }])
    }

    /**
     * Destruction
     */
    ngOnDestroy() {
        //Indique au composant géographie qu'on n'affiche plus le détail d'un pays
        this.geographieService.isShowDetail = false;
    }

    /**
     * Changement d'onglet
     *
     * @param selectedItem Onglet sélectionné
     */
    onSelectedItemChange(selectedItem: PageHeaderItem) {
        //Mise à jour de l'onglet sélectionné
        this.selectedItem = selectedItem;
    }

    /**
     * Retour vers la liste des pays
     */
    onGoBack() {
        this.router.navigate([`Admin/Bibliotheque/Geographie/Pays`]);
    }

    /**
     * Enregistrement du pays
     */
    savePays() {
        //Enregistrement du pays
        this.geographieService.savePays(this.pays).subscribe(result => {
            if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
                //Toast de succès
                this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

                if (this.isCreation) {

                    //On n'est plus en mode création
                    this.isCreation = false;

                    //Récupération de l'identifiant
                    this.pays.idPays = result.data.pays.idPays;

                    //Navigation avec le bon id
                    this.router.navigate(['../' + result.data.pays.idPays], {relativeTo: this.route});

                }
            } else if (result.codeErreur === TypeCodeErreur.ERROR_DOUBLON) {
                //Toast d'erreur : le code existe déjà pour un autre pays
                this.toastrService.error(this.translateService.instant('admin.bibliotheque.geographie.pays.erreurCode'))
            } else if (result.codeErreur === TypeCodeErreur.ERROR_SAVE) {
                //Toast d'erreur
                this.toastrService.error(this.translateService.instant('global.errors.enregistrement'))
            }
        });
    }

}

/**
 * Enum des différents onglets d'un territoire
 */
export enum Onglets {
    GENERALITES = "Généralités"
}
