import {ListItem} from "../../../common/list-view/list-item";
import {Log} from "../../log/log";

/**
 * Historisation des modifications sur le compte
 *
 * @author Laurent Convert
 * @date 02/10/2024
 */
export interface HistoriqueCompteComptable extends Log,ListItem {
	/** Numéro du compte comptable */
	reference: string;
	/** Nature (zone) du changement */
	nature: TypeNature;
}

/** Enumération des natures des historiques des comptes comptables */
export enum TypeNature {
	COMPTE = 1,
}

/** Enumération des champs historisés de l'objet */
export enum Clef {
	NUMERO = "compte",
	LIBELLE = "libelle",
	FIELD1 = "field_1",
	FIELD2 = "field_2",
	FIELD3 = "field_3",
	FIELD4 = "field_4",
	TYPE = "type",
	ACTIF = "actif",
}