import {Component,OnInit,ViewChild} from '@angular/core';
import {EntrepriseService} from "@services/admin/entreprise/entreprise.service";
import {RgpdService} from "@services/admin/entreprise/rgpd.service";
import {Entreprise} from "@domain/admin/entreprise/entreprise";
import {RgpdParam} from "@domain/admin/entreprise/rgpd-param";
import {Alerte} from "@domain/common/alerte/alerte";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {TranslateService} from "@ngx-translate/core";
import {PageHeaderItem} from "@share/component/page-header/page-header";
import {ToastrService} from "ngx-toastr";
import {finalize,first} from 'rxjs/operators';
import {BehaviorSubject} from "rxjs";
import {FloatingButtonAction,TypeAction} from "@share/component/floating-button/floating-button";
import {NgForm} from "@angular/forms";
import {LicenceService} from "@services/admin/entreprise/licence.service";
import {Licence} from "@domain/admin/licence/licence";
import {Store} from "@ngrx/store";
import {AppState} from "@domain/appstate";
import {Session} from "@domain/security/session";
import {Router} from "@angular/router";
import {isRouteAllowed} from "@core/security/role-admin-helpers";
import {IComponentWithRoutedTabs} from "@domain/admin/recherche/component-with-routed-tabs";

/**
 * Enum pour les noms des différents onglets de la page Entreprise
 */
export enum Onglets {
    GENERALITES = "generalites",
    LICENCE = "licence",
    RGPD = "rgpd"
}

/**
 * Sous-menu Description du menu Entreprise
 *
 * @author Alexandre Soulard
 * @date 14/10/2022
 */
@Component({
    host: {'data-test-id': 'description'},
    templateUrl: './description.component.html'
})
export class DescriptionComponent implements OnInit, IComponentWithRoutedTabs {
    /** Liste statique des onglets */
    public static listeOnglets: Array<PageHeaderItem> = [
        {
            code: Onglets.GENERALITES,
            libelle: 'admin.entreprise.description.onglets.generalites',
            url: 'Admin/Entreprise/Description/Generalites'
        }, {
            code: Onglets.LICENCE,
            libelle: 'admin.entreprise.description.onglets.licence',
            url: 'Admin/Entreprise/Description/Licence'
        }, {
            code: Onglets.RGPD,
            libelle: 'admin.entreprise.description.onglets.rgpd',
            url: 'Admin/Entreprise/Description/Rgpd'
        }
    ];

    /** Paramètre permettant d'afficher l'alerte pour les profils restreints */
    profilRestreintInfo: Alerte;

    /** Liste des différents onglets disponibles dans le menu "Description" de l'entreprise */
    listeTabItems: Array<PageHeaderItem> = DescriptionComponent.listeOnglets;

    /** Onglet courant */
    selectedItem: PageHeaderItem;

    /** Liste des actions possibles */
    listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

    /** Entreprise présente dans la table ns_entrep, utilisée pour remplir les différents champs */
    entreprise: Entreprise;

    /** Licence de l'entreprise, contenue dans ns_licence */
    licence: Licence;

    /** Ensemble des paramètres RGPD de l'entreprise */
    rgpdParams: RgpdParam;

    /** Référence vers l'enum pour l'utiliser dans le template */
    Onglet = Onglets;

    /** Formulaire RGPD */
    @ViewChild('entrepForm')
    entrepForm: NgForm;

    /** Formulaire Entreprise */
    @ViewChild('rgpdForm')
    rgpdForm: NgForm;

    /** Sauvegarde en cours */
    isSaving: boolean;

    /** Session */
    private session: Session;

    /**
     * Constructeur
     *
     * @param translateService  Service de traduction
     * @param entrepriseService Service de l'entreprise pour récupérer les informations nécessaires pour le formulaire
     * @param rgpdService       Service pour gérer les paramètres RGPD (récupération et sauvegarde)
     * @param licenceService    Service de la licence de l'entreprise pour récupérer les informations nécessaires au formulaire
     * @param toastrService     Service pour afficher les messages de succès, d'erreurs, ...
     * @param store             Store
     * @param router            Router
     */
    constructor(
        protected translateService: TranslateService,
        private entrepriseService: EntrepriseService,
        private rgpdService: RgpdService,
        private licenceService: LicenceService,
        private toastrService: ToastrService,
        private store: Store<AppState>,
        private router: Router
    ) {
    }

    /**
     * Appelée lors de l'initialisation du composant
     */
    ngOnInit() {
        //Récupération de la session
        this.store.select(state => state.session).subscribe((session: Session) => {
            //Stockage de la session
            this.session = session;

            //Filtrage des onglets autorisés
            this.listeTabItems = this.listeTabItems.filter(i => isRouteAllowed(this.router, this.session, i.url));

            //Permet de charger les différentes informations nécessaires pour l'affichage des formulaires
            this.entrepriseService.getEntreprise().subscribe((result: Result) => {
                this.entreprise = new Entreprise(result.data.entreprise);

                //Chargement de la licence de l'entreprise
                this.licenceService.loadLicence().subscribe(data => {
                    this.licence = data.data.licence;

                    //Si l'enseigne est non définie, alors elle est égale à la raison sociale
                    if (this.entreprise.enseigne == null) {
                        this.entreprise.enseigne = this.licence.raisonSociale;
                    }
                })
            });

            //Récupération des paramètres RGPD
            this.rgpdService.consultRgpdParams().subscribe((res: Result) => {
                this.rgpdParams = res.data.rgpdParam;
            });

            //Définition de la liste des actions possibles
            this.listeActions.next([
                {
                    type: TypeAction.PRIMARY,
                    icone: 'nio icon-sauvegarde',
                    libelle: 'global.actions.enregistrer',
                    isVisible: () => this.selectedItem?.code === this.Onglet.GENERALITES,
                    isDisabled: () => this.entrepForm?.invalid,
                    doAction: () => this.saveEntreprise()
                }, {
                    type: TypeAction.PRIMARY,
                    icone: 'nio icon-sauvegarde',
                    libelle: 'global.actions.enregistrer',
                    isVisible: () => this.selectedItem?.code === this.Onglet.RGPD,
                    isDisabled: () => this.rgpdForm?.invalid,
                    doAction: () => this.saveRgpd()
                }
            ]);
        });
    }

    /**
     * Changement d'onglet
     *
     * @param selectedItem Onglet sélectionné
     */
    onSelectedItemChange(selectedItem: PageHeaderItem) {
        //Mise à jour de l'onglet sélectionné
        this.selectedItem = selectedItem;

        //Si l'onglet sélectionné est l'onglet RGPD alors on ajoute l'alerte sur la page
        if (selectedItem.code === Onglets.RGPD) {
            //Permet d'initialiser l'alerte présente sur la page
            this.profilRestreintInfo = {
                niveau: -1,
                titre: this.translateService.instant('admin.entreprise.description.profilsRestreints.informationPresentes'),
                message: ""
            };
        } else {
            //On enlève l'alerte située en haut de la page si on est pas sur l'onglet RGPD
            this.profilRestreintInfo = null;
        }
    }

    /**
     * Permet de mettre à jour l'entreprise de la table ns_entrep dans la base de données
     */
    saveEntreprise() {
        //Sauvegarde en cours
        this.isSaving = true;

        this.entreprise.adresse = this.entreprise.adresseModel.adresse;

        this.entrepriseService.saveEntreprise(this.entreprise)
            .pipe(first(), finalize(() => this.isSaving = false))
            .subscribe({
                next: result => {
                    //Si le code d'erreur du retour est différent de NO_ERROR alors c'est que l'entreprise ne s'est pas enregistrée correctement dans la base de données
                    if (result.codeErreur != TypeCodeErreur.NO_ERROR) {
                        //Affichage d'un message d'erreur quand l'enregistrement de l'entreprise en base n'a pas fonctionné correctement
                        this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
                    } else {
                        //Affichage d'un message de succès si l'enregistrement de l'entreprise en base s'est passé correctement
                        this.toastrService.success(this.translateService.instant('global.success.enregistrement'));
                    }
                },
                error: err => {
                    //Si jamais il y a une erreur lors de l'enregistrement alors un message d'erreur s'affiche
                    this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
                }
            });
    }

    /**
     * Méthode appelée lors de la sauvegarde des paramètres RGPD
     */
    saveRgpd() {
        //Sauvegarde en cours
        this.isSaving = true;

        //Appel au service
        this.rgpdService.saveRgpdParam(this.rgpdParams)
            .pipe(first(), finalize(() => this.isSaving = false))
            .subscribe((result: Result) => {
                if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
                    //La sauvegarde des paramètres RGPD a réussi
                    this.toastrService.success(this.translateService.instant('global.success.enregistrement'));
                } else {
                    //La sauvegarde des paramètres RGPD a échoué
                    this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
                }
            });
    }
}
