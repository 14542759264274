import {Component} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {DistanceItem} from "@domain/admin/bibliotheque/geographie/distanceItem";
import {GeographieService} from "@services/admin/geographie/geographie.service";
import {Router} from "@angular/router";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";
import {filter} from "rxjs/operators"
import {TypeCodeErreur} from '@domain/common/http/result';
import {Origine} from "@domain/geolocalisation/localisation";

/**
 * Composant d'affichage d'une distance dans la liste des distances
 *
 * @author Angeline Ha
 * @date 02/02/2024
 */
@Component({
    host: {'data-test-id': 'distance-item'},
    templateUrl: './distance-item.component.html'
})
export class DistanceItemComponent extends ListViewItem<DistanceItem> {

    /** Accès à l'enum dans la vue */
    readonly Origine = Origine;

    /**
     * Constructeur
     *
     * @param geographieService Service géographie
     * @param router            Router de l'application
     * @param confirmService    Service de confirmation
     * @param translateService  Service de traduction
     * @param toastrService     Service de gestion des toasts
     */
    constructor(private geographieService: GeographieService,
                private router: Router,
                private confirmService: ConfirmService,
                private translateService: TranslateService,
                private toastrService: ToastrService) {
        super();
    }

    /**
     * Affichage du détail d'une distance
     */
    goToDetailDistance() {
        this.router.navigate([`Admin/Bibliotheque/Geographie/Distance/${this.data.idDistance}`]);
    }

    /**
     * Suppression de la distance
     */
    deleteDistance() {
        //Popup de confirmation
        this.confirmService.showConfirm(this.translateService.instant('admin.bibliotheque.geographie.distance.confirmSuppression'))
            .pipe(filter(isConfirmed => isConfirmed)).subscribe({
            next: () => {
                //Suppression de la distance
                this.geographieService.deleteDistance(this.data.idDistance).subscribe(result => {
                    if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
                        //Refresh la liste des distances
                        this.geographieService.listeDistances.refresh();

                        //Toast de succès
                        this.toastrService.success(this.translateService.instant('global.success.suppression'))
                    } else {
                        //Toast d'erreur
                        this.toastrService.error(this.translateService.instant('global.errors.suppression'))
                    }
                })
            }
        })
    }

}
