import {Injectable,Pipe,PipeTransform} from '@angular/core';
import {TypeProfil} from "@domain/user/user";
import {OrgaDTO} from "@domain/od/od";

/**
 * Pipe de formatage des informations d'un utilisateur<br /><br />
 *
 * value : Utilisateur à afficher<br />
 * display (optionnel) : Type d'information à afficher :<br />
 * <ul>
 *     <li>user (défaut) : nom, prénom et matricule</li>
 *     <li>orga : numéro et libellé du service</li>
 *     <li>fonctions : codes des profils attribués</li>
 * </ul>
 */
@Injectable({providedIn: 'root'})
@Pipe({
    name: 'user'
})
export class UserPipe implements PipeTransform {

    /**
     * Formatage de l'utilisateur passé en paramètre
     *
     * @param value Utilisateur à afficher
     * @param display (optionnel) : Type d'information à afficher :<br />
     * <ul>
     *     <li>user (défaut) : nom, prénom et matricule</li>
     *     <li>orga : numéro et libellé du service</li>
     *     <li>fonctions : codes des profils attribués</li>
     * </ul>
     */
    transform(value: { nom?: string,prenom?: string,matricule?: string,orga?: OrgaDTO,fonctions?: string },display?: 'user'|'orga'|'fonctions'|'nomPrenomMatricule'): string {
        switch (display) {
            case 'orga':
                return this.formatOrga(value.orga);
            case 'fonctions':
                return this.formatProfils(value.fonctions);
            case 'nomPrenomMatricule':
            default:
                return UserPipeTransform(value);
        }
    }

    /**
     * Retourne une chaîne formatée de la liste des fonctions de l'utilisateur
     *
     * @param fonctions Fonctions de l'utilisateur
     */
    formatProfils(fonctions: string): string {
        let profils: string[] = [];

        for (const f of fonctions?.split(' ')) {
            if (f === TypeProfil.COMPTABLE.valueOf().toString()) {
                profils.push("CPT");
            } else if (f === TypeProfil.RESPONSABLE.valueOf().toString()) {
                profils.push("RSP");
            } else if (f === TypeProfil.COLLABORATEUR.valueOf().toString()) {
                profils.push("COL");
            } else if (f === TypeProfil.ASSISTANT.valueOf().toString()) {
                profils.push("AST");
            } else if (f === TypeProfil.SOUS_ADMINISTRATEUR.valueOf().toString()) {
                profils.push("SSA");
            } else if (f === TypeProfil.FOURNISSEUR.valueOf().toString()) {
                profils.push("FRN");
            }
        }

        return profils.join(' ');
    }

    /**
     * Retourne une chaîne formatée du service affecté
     *
     * @param orga Organisme de l'utilisateur
     */
    formatOrga(orga: OrgaDTO): string {
        //Vérification d'un orgo
        if (orga) {
            //Retour de la chaîne formatée
            return `${orga.numService} - ${orga.libelle}`;
        }

        //Pas d'orga, pas de chocolat
        return null;
    }

}

/**
 * Fonction de formatage d'un objet User / UserDto sous la forme : "nom prénom (matricule)"
 * 
 * @param value L'utilisateur à formater
 */
export function UserPipeTransform(value: { nom?: string,prenom?: string,matricule?: string }): string {
    if (value != null) {
        return (`${value.nom?.toUpperCase() ?? ''} ${value.prenom ?? ''}` + (value.matricule ? ` (${value.matricule})` : ``)).trim();
    } else {
        return "";
    }
}