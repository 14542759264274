import {Component,OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {AppState} from "@domain/appstate";
import {ListView,TypeComparaison,TypeFilter} from "@domain/common/list-view";
import {LotListItem} from "@domain/comptabilite/lot";
import {TypePortee} from "@domain/workflow/workflow";
import {Store} from "@ngrx/store";
import {TranslateService} from "@ngx-translate/core";
import * as settingsActions from "@reducers/settings";
import {BehaviorSubject} from "rxjs";
import {FloatingButtonAction,TypeAction} from "@share/component/floating-button/floating-button";
import {LotListItemComponent} from "./lot-list-item.component";
import {filterFirstNotNull} from "@share/utils/rxjs-custom-operator";
import {TypeProfil} from "@domain/user/user";
import {ListeAlertes} from "@domain/common/alerte/listeAlertes";
import {Alerte,NiveauAlerte} from "@domain/common/alerte/alerte";
import {Page} from "@domain/common/http/list-result";
import {DematService} from "@services/admin/parametre/demat/demat.service";
import {filter,finalize,first} from "rxjs/operators";
import {TypeCodeErreur} from "@domain/common/http/result";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {PleaseWaitService} from "@share/component/please-wait/please-wait.service";
import {ToastrService} from "ngx-toastr";
import {ComptabiliteService} from "../comptabilite.service";

/**
 * Composant d'affichage de lot comptable en liste
 */
@Component({
    host: {'data-test-id': 'lot-list'},
    selector: 'lot-list',
    templateUrl: './lot-list.component.html'
})
export class LotListComponent implements OnInit {

    /** Liste des lots */
    liste: ListView<LotListItem,LotListItemComponent>;

    /** Liste des actions possibles */
    listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

    constructor(
        private translateService: TranslateService,
        private store: Store<AppState>,
        private router: Router,
        private dematService: DematService,
        private comptabiliteService: ComptabiliteService,
        private toastrService: ToastrService,
        private confirmService: ConfirmService,
        private pleaseWaitService: PleaseWaitService,
    ) {
    }

    async ngOnInit() {
        //Chargement du paramétrage
        this.store.dispatch({
            type: settingsActions.LOAD_SETTINGS,
            payload: TypePortee.CPT
        });

        //Récupération de l'utilisateur connecté depuis la session
        this.store.select(state => state.session?.user).pipe(filterFirstNotNull()).subscribe(user => {
            //Définition de la liste
            this.liste = new ListView<LotListItem,LotListItemComponent>({
                uri: `/controller/Lot/filtreLots`,
                title: this.translateService.instant('lot.liste.title'),
                component: LotListItemComponent,
                isFilter: true,
                defaultOrder: '-idLot',
                mapResult: (result: Page<LotListItem>) => {
                    //Alerte visible uniquement pour l'admin / sous admin
                    if (user.fonction !== TypeProfil.COMPTABLE) {
                        //Ajout de l'alerte pour chaque lot si le nb de PJ archivées n'est pas bon
                        result.listeResultats?.forEach((lot: LotListItem) => {
                            if ((lot.nbPJArchivees ?? 0) < lot.nbPJTotal) {
                                lot.listeAlertes = new ListeAlertes();
                                lot.listeAlertes.add(new Alerte({
                                    niveau: NiveauAlerte.ERROR,
                                    message: this.translateService.instant("admin.parametres.demat.archivage.liste.alertePJTooltip")
                                }));
                            }
                        });
                    }

                    //Extraction de l'objet concerné dans le résultat
                    return result;
                },
                onRefresh: () => {
                    //On vérifie que l'utilisateur peut intéragir avec la demat
                    if (this.comptabiliteService.canSeeArchiveLot(user)) {
                        //Mise à jour de la notification de lots en attente d'archivage
                        this.dematService.checkHasLotEnAttente();
                    }
                },
                listeFilters: [
                {
                    clef: 'idLot',
                    isDefault: true,
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE],
                    title: this.translateService.instant('lot.filtres.identifiant')
                },{
                    title: this.translateService.instant("lot.filtres.libelle"),
                    clef: "libelle",
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE],
                    isDefault: true
                },{
                    title: this.translateService.instant("lot.filtres.montant"),
                    clef: "montant",
                    type: TypeFilter[TypeFilter.DECIMAL],
                    isDefault: true
                },{
                    clef: 'nbNdf',
                    title: this.translateService.instant('lot.filtres.nbNote'),
                    type: TypeFilter[TypeFilter.DECIMAL],
                },{
                    clef: 'nbAvance',
                    title: this.translateService.instant('lot.filtres.nbAvance'),
                    type: TypeFilter[TypeFilter.DECIMAL],
                },{
                    clef: 'nbFacture',
                    title: this.translateService.instant('lot.filtres.nbFacture'),
                    type: TypeFilter[TypeFilter.DECIMAL],
                },{
                    clef: 'periode.libelle',
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE],
                    title: this.translateService.instant('lot.filtres.periode')
                },{
                    clef: 'date',
                    type: TypeFilter[TypeFilter.DATE],
                    typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
                    title: this.translateService.instant('lot.filtres.date')
                }],
                extraOptions: {
                    user: user,
                    isClickable: true
                }
            });
            // Pour faire en sorte que la liste soit filtrable via du CONTAINS
            this.liste.className = 'LotListComponent';
            this.liste.isPersistFilters = true;
            this.liste.columns = [
                { key: 'idLot', title: 'lot.liste.columns.idLot' },
                { key: 'libelle', title: 'lot.liste.columns.libelle' },
                { key: 'montant', title: 'lot.liste.columns.montant' },
                { key: 'nbNdf', title: 'lot.liste.columns.nbNdf' },
                { key: 'nbAvance', title: 'lot.liste.columns.nbAvance' },
                { key: 'nbFacture', title: 'lot.liste.columns.nbFacture' },
                { key: 'periode.libelle', title: 'lot.liste.columns.libellePeriode' },
                { key: 'date', title: 'lot.liste.columns.date' },
            ];

            if (user.fonction === TypeProfil.COMPTABLE) {
                //Définition de la liste des actions pour le Comptable
                this.listeActions.next([{
                    type: TypeAction.PRIMARY,
                    icone: 'nio icon-ajouter',
                    libelle: 'global.actions.creer',
                    doAction: () => this.createLot(),
                    isVisible: () => true
                }]);
            } else if ([TypeProfil.ADMINISTRATEUR,TypeProfil.SOUS_ADMINISTRATEUR].includes(user.fonction)) {
                //Définition de la liste des actions pour un admin / sous admin
                this.listeActions.next([{
                    type: TypeAction.PRIMARY,
                    icone: 'nio icon-archive',
                    libelle: 'admin.parametres.demat.archivage.liste.actions.synchroniser',
                    tooltip: () => 'admin.parametres.demat.archivage.liste.actions.tooltipSynchroniser',
                    doAction: () => this.archiverLots(),
                    isVisible: () => this.liste.nbSelectedItems > 0
                }]);
            }
        });
    }

    /**
     * Archivage en masse des lots sélectionnés.
     */
    archiverLots(): void {
        //S'il y a bien des éléments sélectionnés
        if (this.liste.nbSelectedItems > 0) {
            //Ouverture de la popup de confirmation
            this.confirmService.showConfirm(this.translateService.instant('admin.parametres.demat.archivage.confirmerSynchroLots')).pipe(filter(isConfirmed => isConfirmed)).subscribe({
                next: () => {
                    let waitDialogRef = this.pleaseWaitService.show();

                    //On envoie la demande de synchronisation
                    this.dematService.archiverLots(this.liste.listeObjetsSectionnes)
                        .pipe(first(),finalize(() => {
                            waitDialogRef.close();
                        }))
                        .subscribe(result => {
                            //Refresh de la liste
                            this.liste.refresh();

                            //S'il n'y a pas d'erreur
                            if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
                                //Message d'information
                                this.toastrService.success(this.translateService.instant('admin.parametres.demat.archivage.synchroDermarree'));
                            } else {
                                //Message d'erreur
                                this.toastrService.error(this.translateService.instant('admin.parametres.demat.archivage.synchroErreur'));
                            }
                        });
                }
            });
        }
    }

    /**
     * Création d'un lot
     */
    private createLot() {
        this.router.navigate(['Lot', 0]);
    }
}