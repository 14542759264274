import {NgModule} from '@angular/core';
import {LangueInfosComponent} from "@components/admin/bibliotheque/internationalisation/langue-infos/langue-infos.component";
import {ShareModule} from "@share/share.module";
import {LangueItemComponent} from "@components/admin/bibliotheque/internationalisation/langue-item/langue-item.component";
import {InternationalisationComponent} from "./internationalisation.component";
import {LangueInfosConfigurationComponent} from "@components/admin/bibliotheque/internationalisation/langue-infos/configuration/langue-infos-configuration.component";
import {DocumentModule} from "@components/document/document.module";
import {JsonSelectorComponent} from "@components/admin/bibliotheque/internationalisation/langue-infos/configuration/json-selector.component";
import {LangueInfosLibelleComponent} from "@components/admin/bibliotheque/internationalisation/langue-infos/libelles/langue-infos-libelle.component";
import {LibelleItemComponent} from "@components/admin/bibliotheque/internationalisation/langue-infos/libelles/libelle-item.component";
import {PopupLibelleComponent} from "@components/admin/bibliotheque/internationalisation/langue-infos/libelles/popup-libelle.component";
import {Routes} from "@angular/router";
import {AdminGuardProvider} from "@core/security/admin-guard.provider";
import {DroitAdmin} from "@core/security/droit-admin";
import {InternationalisationLanguesComponent} from "@components/admin/bibliotheque/internationalisation/langues/internationalisation-langues.component";
import {InternationalisationMessagesComponent} from "@components/admin/bibliotheque/internationalisation/messages/internationalisation-messages.component";
import {InternationalisationReferentielsComponent} from "@components/admin/bibliotheque/internationalisation/referentiels/internationalisation-referentiels.component";

export const bibliothequeInternationalisationRoutes : Routes = [
    {
        path: 'Langues',
        component: InternationalisationLanguesComponent,
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_INTERNATIONALISATION] }
    }, {
        path: 'Langue/:idLangue',
        component: LangueInfosComponent,
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_INTERNATIONALISATION] }
    }, {
        path: 'Messages',
        component: InternationalisationMessagesComponent,
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_INTERNATIONALISATION] }
    }, {
        path: 'Referentiels',
        component: InternationalisationReferentielsComponent,
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_INTERNATIONALISATION] }
    }, {
        path: '',
        pathMatch: 'full',
        redirectTo: 'Langues'
    }, {
        path: '**',
        redirectTo: 'Langues'
    }
];

@NgModule({
    imports: [ShareModule, DocumentModule],
    declarations: [
        InternationalisationComponent,
        LangueItemComponent,
        LibelleItemComponent,
        LangueInfosComponent,
        LangueInfosConfigurationComponent,
        LangueInfosLibelleComponent,
        PopupLibelleComponent,
        JsonSelectorComponent,
        InternationalisationLanguesComponent,
        InternationalisationMessagesComponent,
        InternationalisationReferentielsComponent
    ],
    entryComponents: [LangueItemComponent, LibelleItemComponent],
    exports: [
        InternationalisationComponent
    ],
    providers: []
})
export class InternationalisationModule {
}
