import {NgModule} from '@angular/core';


import {CoreComponentsModule} from '../core-components.module';
import {ListViewComponent} from './list-view.component';
import {ListViewSearchComponent} from './list-view-search.component';
import {ListViewService} from './list-view.service';
import {ListViewItemComponent} from './list-view-item.component';
import {ListViewSelectorDirective} from './list-view-selector.directive';
import {InfiniteScrollComponent} from './infinite-scroll.component';
import {PleaseWaitModule} from '../please-wait/please-wait.module';
import {InputNumberModule} from '../../directive/number/input-number.module';
import {ListViewHeaderComponent} from './list-view-header.component';
import {ListViewFooterComponent} from './list-view-footer.component';
import {SortingComponent} from './sorting/sorting.component';
import {CardAlerteModule} from "@share/component/card-alerte/card-alerte.module";
import {PreviewAlerteModule} from "@share/component/alerte/preview-alerte.module";
import {TooltipModule} from "../../directive/tooltip/tooltip.module";

@NgModule({
	imports: [CoreComponentsModule,PleaseWaitModule,InputNumberModule,CardAlerteModule,PreviewAlerteModule,TooltipModule],
    declarations: [ListViewComponent,ListViewSearchComponent,ListViewItemComponent,ListViewSelectorDirective,ListViewHeaderComponent,ListViewFooterComponent,InfiniteScrollComponent,SortingComponent],
    exports: [ListViewComponent,ListViewSearchComponent,ListViewItemComponent,ListViewSelectorDirective,ListViewHeaderComponent,ListViewFooterComponent],
    providers: [ListViewService]
})
export class ListViewModule {}