import { Component } from "@angular/core";

/**
 * Onglet paramètres
 *
 * @author Guillaume TRAVOT
 * @date 26/04/2024
 */
@Component({
    host: {'data-test-id': 'mobilite-parametres'},
    templateUrl: './mobilite-parametres.component.html'
})
export class MobiliteParametresComponent {}
