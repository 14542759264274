import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { ListView} from '@domain/common/list-view';
import { ResultatRecherche } from "@domain/admin/recherche/resultat-recherche";
import { RechercheItemComponent } from "@share/layout/recherche/recherche-item.component";
import { RechercheService } from "@services/admin/recherche/recherche.service";
import { Router } from "@angular/router";

/**
 * Composant de recherche de pages
 */
@Component({
    templateUrl: './recherche.component.html',
    styleUrls: ['./recherche.component.scss']
})
export class RechercheComponent implements OnInit, OnDestroy {

    /** Input de recherche dans le composant */
    @ViewChild('rechercheInput') rechercheInput: ElementRef<HTMLInputElement>;

    /** Liste des résultats */
    liste: ListView<ResultatRecherche, RechercheItemComponent>;

    /** ID du timeout */
    private timeoutId: NodeJS.Timeout = null;

    /** Réponse backend */
    isResponded: boolean = false;

    /** Index de l'élément sélectionné par le clavier */
    arrowSelectedIndex: number = null;

    /** Référence à l'événement keydown */
    keydownListener: any;

    /**
     * Constructeur
     */
    constructor(private matDialogRef: MatDialogRef<RechercheComponent>, private rechercheService: RechercheService, private router: Router) {}

    /**
     * Initialisation du composant
     */
    ngOnInit(): void  {
        //Abonnement aux pressions de touches
        this.keydownListener = this.onKeyDown.bind(this);
        document.addEventListener('keydown', this.keydownListener);

        //Création de la liste des résultats
        this.liste = new ListView<ResultatRecherche, RechercheItemComponent>({
            component: RechercheItemComponent,
            extraOptions: {
                matDialogRef: this.matDialogRef
            },
            nbObjetsParPage: 8,
            emptyMessage: 'admin.recherche.emptyResult'
        });

        //Forçage de la liste en locale
        this.liste.isLocalData = true;
    }

    /**
     * Détection de la pression sur une touche
     *
     * @param event événement concerné
     */
    onKeyDown(event: KeyboardEvent) {
        if (event.key === 'Escape') {
            //Fermeture de la fenêtre sur touche ESCAPE
            this.matDialogRef.close();
        } else if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
            //Interruption de l'évènement
            event.preventDefault();

            //Gestion du pavé directionnel
            this.handleArrowKeyboard(event.key);
        } else if (event.key === 'Enter') {
            this.navigateToKeyboardSelected();
        }
    }

    /**
     * Détection du relâchement d'une touche
     *
     * @param event événement concerné
     */
    onKeyup(event: KeyboardEvent): void {
        //Si le champ a déjà servi ou qu'il contient une valeur
        if (event.key != 'ArrowDown' && event.key != 'ArrowUp' && event.key != 'Enter' && event.target['value']?.length > 1) {
            //Efface le timeout précédent si une touche est pressée
            if (this.timeoutId !== null) { clearTimeout(this.timeoutId); }

            //Définit un nouveau timeout pour attendre 500ms après la dernière touche pressée
            this.timeoutId = setTimeout(() => this.getSuggestions(event.target['value']), 500);
        } else if (event.key != 'ArrowDown' && event.key != 'ArrowUp' && event.key != 'Enter') {
            //Reset du statut de réponse pour masquer la liste
            this.isResponded = false;
        }
    }

    /**
     * Appel au backend pour recherche des pages suggérées
     *
     * @param query requête utilisateur
     */
    private getSuggestions(query: string): void {
        //Si la requête est assez longue
        if (query?.length > 1) {
            //Reset du statut de réponse
            this.isResponded = false;

            //Chargement en cours
            this.liste.isLoading = true;

            //Reset de l'index sélectionné
            this.arrowSelectedIndex = null;

            //Appel au service pour obtenir les suggestions de pages
            this.rechercheService.getSuggestions(query).then((resultats) => {
                //Réponse obtenue
                this.isResponded = true;

                //Peuplement de la liste
                setTimeout(() => {
                    //Chargement des données
                    this.liste.data = {
                        nbObjetsParPage: 8,
                        nbObjetsDansPage: resultats.length,
                        nbObjetsTotal: resultats.length,
                        numPage: 1,
                        nbPagesTotal: 1,
                        listeResultats: resultats
                    }

                    //Présélection du premier élément
                    this.arrowSelectedIndex = 0;
                    if (this.liste.data.listeResultats?.length) { this.liste.data.listeResultats[0].isKeyboardSelected = true; }

                    //Fin du chargement
                    this.liste.isLoading = false;
                });
            });
        }
    }

    /**
     * Gestion du pavé directionnel
     *
     * @param arrow touche utilisée
     */
    handleArrowKeyboard(arrow: "ArrowDown"|"ArrowUp"): void {
        //Si la liste contient des résultats
        if (this.liste?.data?.listeResultats) {
            //Si aucune ligne n'est encore sélectionnée
            if (this.arrowSelectedIndex === null) {
                //Initialisation de la sélection
                this.arrowSelectedIndex = arrow == "ArrowDown" ? 0 : (this.liste.data.listeResultats.length - 1);
            } else if (!(this.arrowSelectedIndex == 0 && arrow == "ArrowUp") && !(this.arrowSelectedIndex == (this.liste.data.listeResultats.length - 1) && arrow == "ArrowDown")) {
                //Déplacement de l'index vers le haut ou vers le bas
                this.arrowSelectedIndex = arrow == "ArrowDown" ? (this.arrowSelectedIndex + 1) : (this.arrowSelectedIndex - 1);
            }

            //Itération sur les résultats
            for (let i = 0 ; i < this.liste.data.listeResultats.length ; i++) {
                //Statut de sélection de chaque ligne
                this.liste.data.listeResultats[i].isKeyboardSelected = this.arrowSelectedIndex == i;
            }
        }
    }

    /**
     * Navigation vers la route validée par la touche Enter
     */
    navigateToKeyboardSelected(): void {
        //Fermeture de la fenêtre
        this.matDialogRef.close();

        //Récupération de la route
        let route: string = this.liste?.data?.listeResultats?.find(r => r.isKeyboardSelected)?.route;

        //S'il y a bien un route
        if (route) {
            //Navigation vers la route
            this.router.navigateByUrl(route);
        }
    }

    /**
     * Destruction du composant
     */
    ngOnDestroy() {
        //Désabonnement
        document.removeEventListener('keydown', this.keydownListener);
    }
}
