import {Component} from '@angular/core';
import {ActivatedRoute,Router} from "@angular/router";
import {IataItem} from '@domain/admin/bibliotheque/geographie/iataItem';
import {ListViewItem} from '@domain/common/list-view/list-view-item';
import {TypeIata} from "@domain/geographie/iata";

/**
 * Composant d'un iata dans la liste des iata
 *
 * @author Angeline Ha
 * @date 13/02/2024
 */
@Component({
    host: {'data-test-id': 'iata-item'},
    templateUrl: './iata-item.component.html'
})
export class IataItemComponent extends ListViewItem<IataItem> {
    /** Accès à l'enum dans la vue */
    readonly TypeIata = TypeIata;

    /**
     * Constructeur
     *
     * @param router Router
     * @param route  Route active
     */
    constructor(private router: Router,
                private route: ActivatedRoute) {
        super();
    }

    /**
     * Affichage du détail d'un iata
     */
    goToDetailIata() {
        this.router.navigate([`Admin/Bibliotheque/Geographie/Iata/${this.data.idIataVille}`]);
    }

}
