import {NgModule} from "@angular/core";
import {Routes} from "@angular/router";
import {AdminGuardProvider} from "@core/security/admin-guard.provider";
import {ShareModule} from "@share/share.module";
import {FraisTaxesComponent} from "./frais-taxes.component";
import {ListeDomainesComponent} from "@components/admin/frais/taxes/liste-domaines/liste-domaines.component";
import {ListeTaxesComponent} from "@components/admin/frais/taxes/liste-taxes/liste-taxes.component";
import {DroitAdmin} from "@core/security/droit-admin";

export const fraisTaxesRoutes : Routes = [
    {
        path: 'ListeTaxes',
        component: ListeTaxesComponent,
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_GESTION_TAXE] }
    }, {
        path: 'ListeDomaines',
        component: ListeDomainesComponent,
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_GESTION_TAXE] }
    }, {
        path: '',
        pathMatch: 'full',
        redirectTo: 'ListeTaxes'
    }, {
        path: '**',
        redirectTo: 'ListeTaxes'
    }
];

@NgModule({
    imports: [ShareModule],
    declarations: [
        FraisTaxesComponent,
        ListeDomainesComponent,
        ListeTaxesComponent
    ],
    entryComponents: [],
    exports: [
        ListeDomainesComponent,
        ListeTaxesComponent
    ],
    providers: []
})
export class FraisTaxesModule {}
