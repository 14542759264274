import {Session} from "@domain/security/session";
import {Route,Router,Routes} from "@angular/router";
import {DroitAdmin} from "./droit-admin";
import {TypeProfil,User} from "@domain/user/user";

/** Path Admin par défaut */
export const defautPathAdmin: string = 'Admin';

/** Path vers une page blanche */
export const blankPath: string = 'Blank';

/**
 * Type des routes autorisées
 *
 * @param routeDefaut	Route par défaut trouvée ('**') ou null
 * @param routeSecours	Route de secours trouvée ou null
 */
type RouteAutorisee = {routeDefaut: string | null, routeSecours?: string | null};

/**
 * Vérifications des droits de l'utilisateur
 *
 * @param session               Session active
 * @param credentials           Droits Sous-Admin
 */
export function isAllowed(session: Session,credentials: DroitAdmin[]): boolean {
	//Initialisation
	let userCredentials: string[];

	//Si utilisateur Admin
	if (session.isAdmin) {
		//L'admin a tous les droits
		return true;
	} else if (session.user?.fonction == TypeProfil.SOUS_ADMINISTRATEUR) {
		//Sinon application des droits sous-admin
		userCredentials = session.user.listeDroits ?? [];
	}

	//Si des droits sous-admin sont requis
	if (credentials && credentials.length) {
		//Si l'utilisateur est sous-admin
		if (userCredentials) {
			//Parcours des droits autorisés
			for (const allowedCredential of credentials) {
				//Si l'utilisateur dispose du bon droit
				if (userCredentials.includes(allowedCredential)) {
					//Authorisation
					return true;
				}
			}
		}
	} else if (!session.isAdmin && !(session.user?.fonction == TypeProfil.SOUS_ADMINISTRATEUR)) {
		//Sinon si la session est celle d'un user standard, les routes classiques sont autorisées
		return true;
	}

	//Par défaut, interdiction de l'accès
	return false;
}

/**
 * Récupération des données d'autorisation pour l'URL
 *
 * @param routes    Routes du composant
 * @param url       URL à vérifier
 */
function getRouteCredentials(routes: Routes,url: string): DroitAdmin[] {
	//Initialisation
	let config: Routes;
	let remaining: number;

	//Fractionnement de l'URL
	let paths: string[] = url.split('/');

	//Si le premier niveau est différent de 'Admin'
	if (paths[0] != 'Admin') {
		//Aucune vérification
		return undefined;
	}

	//Décompte des niveaux à analyser
	remaining = paths.length - 1;

	//Récupération de la configuration de routage
	config = routes;

	//Parcours des niveaux du path
	for (const path of paths) {
		//On récupère un path sans query param
		const pathCleaned = path.split('?')[0];

		//s'il reste des niveaux à traiter
		if (remaining) {
			//Récupération des enfants
			config = config?.find((c: Route) => c.path == pathCleaned)?.children;

			//Si la config n'a pas été trouvée
			if (!config) {
				//Blocage de l'accès
				return undefined;
			}

			//Décrémentation du nombre de niveaux restant
			remaining--;
		} else {
			//Récupération de la route
			const route: Route = config?.find((c: Route) => c.path == pathCleaned);

			//Si la route n'a pas été trouvée
			if (!route) {
				//Blocage de l'accès
				return undefined;
			}

			//Retour des données d'autorisation pour l'URL
			return getMinimumCredentials(route.children ? route.children : [route]);
		}
	}
}

/**
 * Renvoie la route par défaut de niveau le plus bas en parcourant toutes les routes par défaut jusqu'à tomber sur une route qui n'a pas d'enfants
 *
 * @param routes	Routes à parcourir
 * @return {Route}	Route trouvée
 */
function getRouteParDefaut(routes: Routes): Route {
	//Récupère le path de la route par défaut
	let pathDefaut = routes.find(route => route.path === '**').redirectTo;

	//Récupère la vraie route correspondant au path par défaut
	let routeDefaut = routes.find(route => route.path === pathDefaut);

	//Si la route a des enfants
	if (routeDefaut?.children) {
		//On les parcourt pour aller chercher la route de dernier niveau
		return getRouteParDefaut(routeDefaut.children);
	} else {
		//S'il n'y a pas d'enfant, on renvoie cette route, c'est la bonne
		return routeDefaut;
	}
}

/**
 * Renvoie le path de la première route autorisée pour l'utilisateur.</br>
 * Les routes par défaut ('**') sont priorisées, et si aucune n'est trouvée alors c'est la première route valide trouvée qui est renvoyée
 *
 * @param routes	Liste des routes à parcourir
 * @param isAdmin	Indique si l'utilisateur est admin
 * @param user		User cherchant le path
 * @returns {string} Premier path disponible ("Blank" si aucun autre path trouvé)
 */
export function findFirstPathAdminAutorisee(routes: Routes,isAdmin: boolean,user: User): string {
	//Route trouvée (Blank par défaut)
	let routeResultat: string;

	//Récupère la route Admin
	const routeAdmin = routes?.find(r => r.path === defautPathAdmin);

	//Liste des droits de l'utilisateur
	let listeRoles = user.listeDroits;

	//Si on est Admin ou qu'on a les droits pour la route d'Admin par défaut
	if (isAdmin || getMinimumCredentials([getRouteParDefaut(routeAdmin.children)]).some(r => listeRoles.includes(r))) {
		//On renvoie vers la route Admin par défaut
		routeResultat = defautPathAdmin;
	} else {
		//Si on n'a pas les droits pour la route par défaut, on va chercher la première pour laquelle on a les droits
		let sousRouteResultat = findFirstSousRouteAdminAutorisee(routeAdmin.children,listeRoles,defautPathAdmin);

		//On va renvoyer le path de la route par défaut s'il y en a une sinon le path de la route de secours et en dernier recours le path de la page vide
		routeResultat = sousRouteResultat.routeDefaut ?? sousRouteResultat.routeSecours ?? blankPath;
	}

	//On renvoie le path de la route trouvée
	return routeResultat;
}

/**
 * Renvoie le path de la première route de dernier niveau autorisée pour l'utilisateur.</br>
 * Les routes ayant un paramètre dans le path sont ignorées
 *
 * @param routes		Routes à parcourir
 * @param listeRoles	Droits de l'utilisateur concerné
 * @param parentPath	Path de tous les parents de la route actuelle
 * @param routeSecours	Route de secours éventuellement trouvée
 * @returns {RouteAutorisee} Route autorisée trouvée
 */
function findFirstSousRouteAdminAutorisee(routes: Routes,listeRoles: Array<DroitAdmin>,parentPath: string,routeSecours?: string): RouteAutorisee {
	//On récupère le path de la route par défaut
	let pathDefaut = routes.find(route => route.path == '**')?.redirectTo ?? '';

	//On parcourt les routes qui ne sont pas celles par défaut et qui n'exigent pas un paramètre dans l'URL
	for (const route of routes?.filter(route => !['','**'].includes(route.path) && !route.path.includes(":"))) {
		//Path de la route actuellement vérifiée
		let monCurrentPath = parentPath + "/" + route.path;

		//Si la route comporte des droits et que l'utilisateur a au moins un droit correspondant ou qu'il n'y a pas de droits et pas d'enfants
		if (route.data?.sousAdminCredentials && (route.data.sousAdminCredentials as DroitAdmin[]).some(r => listeRoles.includes(r))) {
			//S'il s'agit du path par défaut
			if (route.path === pathDefaut) {
				//On a trouvé ce qu'on cherche, on renvoie le résultat
				return {routeDefaut: monCurrentPath, routeSecours: routeSecours};
			} else if (routeSecours == null) {
				//Si c'est une route de secours, on la garde en mémoire et on continue de chercher une route par défaut
				routeSecours = monCurrentPath;
			}
		}

		//Si la route a des sous routes
		if (route.children) {
			//On lance la recherche de route pour les enfants en récursif
			let routeAutorisee = findFirstSousRouteAdminAutorisee(route.children,listeRoles,monCurrentPath,routeSecours);

			//Si une route par défaut a été trouvée
			if (routeAutorisee.routeDefaut) {
				//On a ce qu'on veut, on renvoie la route
				return routeAutorisee;
			} else if (routeAutorisee.routeSecours && !routeSecours) {
				//Si on a trouvé une route de secours et qu'on en avait pas encore, on la garde sous le coude
				routeSecours = routeAutorisee.routeSecours;
			}
		}
	}

	//Si on renvoie ici, c'est qu'on n'a pas trouvé de route principale, on renvoie la route de secours (peut être undefined, si on n'en a pas trouvé non plus)
	return {routeDefaut: null, routeSecours: routeSecours};
}

/**
 * Détermination du niveau d'accès minimum requis pour découvrir la suite de la route
 *
 * @param routes	Arborescence de routes à étudier
 */
export function getMinimumCredentials(routes: Routes): DroitAdmin[] {
	//Initialisation
	const sousAdminCredentials: Set<DroitAdmin> = new Set<DroitAdmin>();

	//Parcours des routes
	for (const route of routes?.filter(r => r.path != '' && r.path != '**')) {
		//Credentials à utiliser
		let credentials: DroitAdmin[];

		//Si la route comporte des droits
		if (route.data?.sousAdminCredentials) {
			//Utilisation des droits de la route actuelle (prioritaire sur les enfants)
			credentials = route.data.sousAdminCredentials as DroitAdmin[];
		} else if (route.children) {
			//Sinon si la route a des enfants, alors récupération des droits minimum pour les enfants
			credentials = getMinimumCredentials(route.children);
		}

		//S'il y a des droits Sous-Admin spécifiques
		if (credentials?.length) {
			//Parcours des droits à ajouter
			for (const droit of credentials) {
				//Ajout des droits requis à la liste
				sousAdminCredentials.add(droit);
			}
		}
	}

	//S'il y a des droits Sous-Admin spécifiques
	if (sousAdminCredentials.size) {
		//Retour des droits Sous-Admin requis
		return Array.from(sousAdminCredentials);
	} else {
		//Sinon retour avec droits Admin requis
		return undefined;
	}
}

/**
 * Vérification de l'autorisation d'accès sur un onglet ou un menu
 *
 * @param router    Router du composant
 * @param session   Session active
 * @param url       URL de la route
 */
export function isRouteAllowed(router: Router,session: Session,url: string): boolean {
	//Route autorisée si url vaut null
	if (!url) { return true; }

	//Retour de l'autorisation
	return isAllowed(session,getRouteCredentials(router.config,url));
}
