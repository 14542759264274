import { Component } from "@angular/core";

/**
 * Onglet liste noire
 *
 * @author Guillaume TRAVOT
 * @date 26/04/2024
 */
@Component({
    host: {'data-test-id': 'authentification-liste-noire'},
    templateUrl: './authentification-liste-noire.component.html'
})
export class AuthentificationListeNoireComponent {}
