import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot,CanActivate,Router} from "@angular/router";
import {LoginService} from "@share/login/login.service";
import {Store} from "@ngrx/store";
import {AppState} from "@domain/appstate";
import * as sessionActions from "@reducers/session";
import {Session} from "@domain/security/session";
import {TypeProfil} from "@domain/user/user";
import {DroitAdmin} from "@core/security/droit-admin";

/**
 * Gardien des listes et objets wf - accès accordé aux admin comme aux user
 */
@Injectable()
export class ObjetsWFGuardProvider implements CanActivate {

	/**
	 * Constructeur
	 *
	 * @param router				Router
	 * @param loginService			Service de gestion du login et des mdp
	 * @param store					Store
	 */
	constructor(private router: Router,
				private loginService: LoginService,
				private store: Store<AppState>) {
	}

	/**
	 * Activation du composant.
	 *
	 * @param route route qu'on veut atteindre, dont on vérifie le droit d'activation
	 */
	async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
		let isLogged: boolean;
		let session: Session;

		//Récupération de la session
		session = this.loginService.getSession();

		//Si on a le paramètre de requête indiquant une "connexion en tant que"
		if (session?.isLogged && route.queryParamMap.get('connectAs') != null) {
			//On supprime le flag isAdmin, sinon on serait redirigé automatiquement vers la page de login local
			session.isAdmin = false;

			//Suppression du user puisqu'il va être rechargé suite au SESSION_FULFILLED. Si on ne le fait pas, des subscribe vont se déclencher inutilement
			session.user = null;

			//Activation du flag isConnectAs, principalement pour désactiver l'affichage du profil
			session.isConnectAs = true;

			//La session est à jour
			session.isUpToDate = true;

			//dispatch session
			this.store.dispatch({
				type: sessionActions.SESSION_FULFILLED,
				payload: session
			});

			isLogged = true;
		} else {
			if (session?.isLogged && (session.isAdmin || session.user?.fonction === TypeProfil.SOUS_ADMINISTRATEUR)) {
				//Récupération des éventuels droits sous-admin affectés à la route
				const sousAdminCredentials = route.routeConfig.data?.sousAdminCredentials as DroitAdmin[];

				//S'il y a des droits sous-admin
				if (sousAdminCredentials) {
					//Parcours des droits
					for(const droit of sousAdminCredentials) {
						//Si l'utilisateur a le droit requis
						if (session.isAdmin || session.user?.listeDroits?.includes(droit)) {
							//Autorisation
							return true;
						}
					}
				}

				//Redirection vers l'interface Admin
				this.router.navigate(['Admin']);
			} else if (session?.user?.fonction === TypeProfil.SOUS_ADMINISTRATEUR) { //Le sous-admin n'a rien à faire là : on le renvoie vers la page de login local forcé
				//Suppression de la redirection éventuelle
				if (session) session.redirect = null;

				//Déconnexion
				this.loginService.logout();

				//Redirection vers l'écran de connexion
				this.router.navigate(['SSO/LoginLocal']);
			} else if (session?.isPasswordExpired) {
				//Déconnexion
				this.loginService.logout();

				//L'user tente d'accéder à une page avec un login périmé on applique le plan "You shall not pass"
				this.router.navigate(['/Login']);
			} else {
				//Récupération de la connexion
				isLogged = session != null && session.isLogged;

				//Vérification de la redirection
				if (!isLogged) {
					//Déconnexion
					this.loginService.logout();

					//Redirection vers l'écran de connexion
					this.router.navigate(['/Login']);
				}
			}
		}

		//Vérification de la connexion
		return isLogged;
	}
}
