import {NgModule} from "@angular/core";
import {Routes} from "@angular/router";
import {AdminGuardProvider} from "@core/security/admin-guard.provider";
import {ShareModule} from "@share/share.module";
import {FraisIndemnitesComponent} from "./frais-indemnites.component";
import {IndemnitesParametresComponent} from "@components/admin/frais/indemnites/indemnites-parametres/indemnites-parametres.component";
import {IndemnitesReglesAttributionComponent} from "@components/admin/frais/indemnites/indemnites-regles-attribution/indemnites-regles-attribution.component";
import {IndemnitesAbattementsComponent} from "@components/admin/frais/indemnites/indemnites-abattements/indemnites-abattements.component";
import {IndemnitesFraisMissionsComponent} from "@components/admin/frais/indemnites/indemnites-frais-missions/indemnites-frais-missions.component";
import {DroitAdmin} from "@core/security/droit-admin";

export const fraisIndemnitesRoutes : Routes = [
    {
        path: 'FraisMissions',
        component: IndemnitesFraisMissionsComponent,
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_IJ_GESTION] }
    }, {
        path: 'ReglesAttribution',
        component: IndemnitesReglesAttributionComponent,
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_IJ_GESTION] }
    }, {
        path: 'Abattements',
        component: IndemnitesAbattementsComponent,
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_IJ_GESTION] }
    }, {
        path: 'Parametres',
        component: IndemnitesParametresComponent,
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_IJ_GESTION] }
    }, {
        path: '',
        pathMatch: 'full',
        redirectTo: 'FraisMissions'
    }, {
        path: '**',
        redirectTo: 'FraisMissions'
    }
];

@NgModule({
    imports: [ShareModule],
    declarations: [
        FraisIndemnitesComponent,
        IndemnitesParametresComponent,
        IndemnitesReglesAttributionComponent,
        IndemnitesAbattementsComponent,
        IndemnitesFraisMissionsComponent
    ],
    entryComponents: [],
    exports: [
        IndemnitesParametresComponent,
        IndemnitesReglesAttributionComponent,
        IndemnitesAbattementsComponent,
        IndemnitesFraisMissionsComponent
    ],
    providers: []
})
export class FraisIndemnitesModule {}
