import { Component } from "@angular/core";

/**
 * Onglet liens
 *
 * @author Guillaume TRAVOT
 * @date 26/04/2024
 */
@Component({
    host: {'data-test-id': 'personnalisation-liens'},
    templateUrl: './personnalisation-liens.component.html'
})
export class PersonnalisationLiensComponent {}
