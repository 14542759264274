import {CompteUsage} from "./compte-usage";
import {EntityAttribute} from "@share/component/autocomplete/options/entity-attribute";
import {UserPipeTransform} from "@share/pipe/user.pipe";

/**
 * Usages d'un compte comptable
 * Carte associée à un compte comptable
 *
 * @author Laurent Convert
 * @date 24/09/2024
 */
export class CompteUsageCarte implements CompteUsage {
	raisonSociale: string;
	libelleContrat: string;
	numCarte: string;
	nom: string;
	prenom: string;
	matricule: string;

	/**
	 * Constructeur
	 *
	 * @param dto Source
	 */
	constructor(dto: any) {
		Object.assign(this,dto);
	}

	/**
	 * Récupération de l'identifiant de l'objet
	 */
	getKey(): string {
		return this.numCarte;
	}

	/**
	 * Affichage d'un élément
	 */
	displayItem(): string {
		return this.numCarte;
	}

	/**
	 * Liste les propriétés de l'objet pour lesquelles il faut afficher un badge 'clé:valeur'
	 */
	listDisplayedAttributes(): Array<EntityAttribute> {
		return [{
			clef: 'admin.comptabilite.planComptable.compteComptable.usage.raisonSociale',
			valeur: this.raisonSociale
		},{
			clef: 'admin.comptabilite.planComptable.compteComptable.usage.libelleContrat',
			valeur: this.libelleContrat
		},{
			clef: 'admin.comptabilite.planComptable.compteComptable.usage.collaborateur',
			valeur: UserPipeTransform({nom: this.nom,prenom: this.prenom,matricule: this.matricule})
		}];
	}
}