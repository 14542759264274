import {Component,OnDestroy,OnInit,ViewChild} from '@angular/core';
import {PageHeaderItem} from "@share/component/page-header/page-header";
import {FloatingButtonAction,TypeAction} from "@share/component/floating-button/floating-button";
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute,Router} from "@angular/router";
import {VoyagesReferentielsCompagniesInfosComponent} from "@components/admin/voyages/referentiels/compagnies/compagnie-infos/voyages-referentiels-compagnies-infos.component";
import {MatDialog} from "@angular/material/dialog";
import {VoyagesReferentielsCompagniesComponent} from "@components/admin/voyages/referentiels/compagnies/voyages-referentiels-compagnies.component";
import {VoyagesReferentielsCartesVoyageursInfosComponent} from "@components/admin/voyages/referentiels/cartes-voyageurs/cartes-voyageurs-infos/voyages-referentiels-cartes-voyageurs-infos.component";
import {VoyagesReferentielsCartesVoyageursComponent} from "@components/admin/voyages/referentiels/cartes-voyageurs/voyages-referentiels-cartes-voyageurs.component";
import {VoyagesReferentielsClassesInfosComponent} from "@components/admin/voyages/referentiels/classes/classes-infos/voyages-referentiels-classes-infos.component";
import {VoyagesReferentielsClassesComponent} from "@components/admin/voyages/referentiels/classes/voyages-referentiels-classes.component";
import {BehaviorSubject,Subscription} from "rxjs";
import {IComponentWithRoutedTabs} from "@domain/admin/recherche/component-with-routed-tabs";

/**
 * Enum pour les noms des différents onglets de la page Référentiels du menu Voyages
 */
export enum Onglets {
    COMPAGNIES = "Compagnies",
    CARTES_VOYAGEURS = "CartesVoyageurs",
    CLASSES = "Classes",
    SYNCHRONISATIONS = "Synchronisations"
}

/**
 * Composant d'affichage des informations des référentiels dans le menu Voyages
 */
@Component({
    host: {'data-test-id': 'voyages-referentiels'},
    templateUrl: './voyages-referentiels.component.html'
})
export class VoyagesReferentielsComponent implements OnInit, OnDestroy, IComponentWithRoutedTabs {
    /** Liste statique des onglets */
    public static listeOnglets: Array<PageHeaderItem> = [{
        code: Onglets.COMPAGNIES,
        libelle: 'admin.voyages.referentiels.onglets.compagnies',
        url: 'Admin/Voyages/Referentiels/Compagnies'
    }, {
        code: Onglets.CARTES_VOYAGEURS,
        libelle: 'admin.voyages.referentiels.onglets.cartesVoyageurs',
        url: 'Admin/Voyages/Referentiels/CartesVoyageurs'
    }, {
        code: Onglets.CLASSES,
        libelle: 'admin.voyages.referentiels.onglets.classes',
        url: 'Admin/Voyages/Referentiels/Classes'
    }, {
        code: Onglets.SYNCHRONISATIONS,
        libelle: 'admin.voyages.referentiels.onglets.synchronisations',
        url: 'Admin/Voyages/Referentiels/Synchronisations'
    }];

    /** Liste des différents onglets disponibles dans le menu */
    listeTabItems: Array<PageHeaderItem> = VoyagesReferentielsComponent.listeOnglets;

    /** Onglet courant */
    selectedItem: PageHeaderItem;

    /** Référence vers l'enum des onglets pour utiliser dans le template */
    Onglet = Onglets;

    /** Index de l'onglet courant */
    selectedIndex: number;

    /** Indique si le header doit être affiché */
    isDisplayed: boolean;

    /** Souscription */
    private subscription: Subscription;

    /** Composant enfant liste des compagnies */
    @ViewChild(VoyagesReferentielsCompagniesComponent) compagniesComponent: VoyagesReferentielsCompagniesComponent;

    /** Composant enfant liste des cartes */
    @ViewChild(VoyagesReferentielsCartesVoyageursComponent) cartesComponent: VoyagesReferentielsCartesVoyageursComponent;

    /** Composant enfant liste des classes */
    @ViewChild(VoyagesReferentielsClassesComponent) classesComponent: VoyagesReferentielsClassesComponent;

    /** Liste des actions possibles */
    listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

    /**
     * Constructeur
     *
     * @param translateService Service de traduction
     * @param router Router de l'application
     * @param activatedRoute Service de routage
     * @param matDialog Boîte de dialogue
     */
    constructor(
        private translateService: TranslateService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private matDialog: MatDialog) {
    }

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        //Permet de générer la liste des différents onglets disponibles
        this.listeTabItems = VoyagesReferentielsComponent.listeOnglets;

        this.subscription = this.activatedRoute.url.subscribe(() => {
            //Récupération de la route
            const firstChild = this.activatedRoute.snapshot.firstChild;

            //Extraction du path
            const path = firstChild.url[0].path;

            //On vérifie si on trouve l'onglet demandé via l'url
            const matching = this.listeTabItems.find(i => i.code === path);

            //Si on trouve bien l'onglet correspondant
            if (!!matching) {
                //on met à jour le composant header
                setTimeout(() => {
                    this.selectedItem = matching;
                    this.selectedIndex = this.listeTabItems.findIndex(i => i.code === path);
                    this.isDisplayed = (Object.values(firstChild.params).findIndex(v => v !== undefined) === -1);
                });
            } else {
                //Sinon, on redirige sur le premier onglet et on met à jour le composant header
                setTimeout(() => {
                    this.selectedItem = this.listeTabItems[0];
                    this.selectedIndex = 0;
                    this.isDisplayed = (Object.values(firstChild.params).findIndex(v => v !== undefined) === -1);
                });
            }
        });

        //Définition de la liste des actions du bouton en bas à droite de l'écran
        this.listeActions.next([{
            type: TypeAction.PRIMARY,
            icone: 'nio icon-ajouter',
            libelle: 'global.actions.creer',
            doAction: () => this.createItem(),
        }]);
    }

    /**
     * Changement d'onglet
     *
     * @param selectedItem Onglet sélectionné
     */
    onSelectedItemChange(selectedItem: PageHeaderItem): void {
        if (!this.selectedItem) {
            this.selectedItem = selectedItem;
        }
        if (selectedItem?.code !== this.selectedItem?.code) {
            this.selectedItem = selectedItem;
            this.router.navigateByUrl(selectedItem.url);
        }
    }

    /**
     * Méthode appelée lors de la création d'un nouvel objet (Compagnie, Carte Voyageur, ...)
     */
    createItem() {
        if (this.selectedItem.libelle === this.translateService.instant('admin.voyages.referentiels.onglets.compagnies')) {
            //Ouverture de la popup de création
            this.matDialog.open(VoyagesReferentielsCompagniesInfosComponent, {
                data: {
                    idCompagnie: 0,
                },
                width: '80%'
            }).afterClosed().subscribe({
                next: refresh => {
                    if (refresh) {
                        //Rafraichissement de la liste
                        this.compagniesComponent.listeCompagnies.refresh();
                    }
                }
            });
        } else if (this.selectedItem.libelle === this.translateService.instant('admin.voyages.referentiels.onglets.cartesVoyageurs')) {
            //Ouverture de la popup de création
            this.matDialog.open(VoyagesReferentielsCartesVoyageursInfosComponent, {
                data: {
                    idCarte: 0
                },
                width: '80%'
            }).afterClosed().subscribe({
                next: refresh => {
                    if (refresh) {
                        //Rafraichissement de la liste
                        this.cartesComponent.listeCartesVoyages.refresh();
                    }
                }
            });
        } else if (this.selectedItem.libelle === this.translateService.instant('admin.voyages.referentiels.onglets.classes')) {
            //Ouverture de la popup de création
            this.matDialog.open(VoyagesReferentielsClassesInfosComponent, {
                data: {
                    idClasse: 0,
                },
                width: '80%'
            }).afterClosed().subscribe({
                next: refresh => {
                    if (refresh) {
                        //Rafraichissement de la liste
                        this.classesComponent.listeClasses.refresh();
                    }
                }
            });
        }

    }

    /**
     * Destruction du composant
     */
    ngOnDestroy() {
        //Résiliation de l'abonnement
        this.subscription.unsubscribe();
    }
}
