import {Component,ViewChild} from '@angular/core';
import {Store} from "@ngrx/store";
import {AppState} from "@domain/appstate";
import {NdfListComponent} from "../../../ndf/ndf-list/ndf-list.component";
import {Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {PleaseWaitService} from "@share/component/please-wait/please-wait.service";
import {NDFService} from "../../../ndf/ndf.service";
import {TranslateService} from "@ngx-translate/core";
import {WorkflowService} from "../../../workflow/workflow.service";
import {DashboardNdfListItemComponent} from "./dashboard-ndf-list-item.component";
import {IDashboardComponent} from "../../IDashboardComponent";
import {ListViewComponent} from "@share/component/list-view/list-view.component";
import {NdfListItemComponent} from "../../../ndf/ndf-list/ndf-list-item/ndf-list-item.component";
import {ListeNdf} from "@domain/ndf/liste-ndf";
import {TypeProfil} from "@domain/user/user";
import {SessionStorageService} from '@domain/common/services/session-storage.service';
import {CguService} from '@components/cgu/cgu.service';
import {ToastrService} from 'ngx-toastr';
import {ComptabiliteService} from "@components/comptabilite/comptabilite.service";

/**
 * Liste des NDF du Dashboard
 */
@Component({
    host: {'data-test-id': 'dashboard-ndf-list'},
    selector: 'dashboard-ndf-list',
    templateUrl: './dashboard-ndf-list.component.html'
})
export class DashboardNdfListComponent extends NdfListComponent implements IDashboardComponent<ListeNdf,NdfListItemComponent> {
    /** C'est une liste de dashboard */
    isDashboardList = true;

    /** Référence au composant de la liste (pour mise à jour au changement de profil) */
    @ViewChild('listViewComponent') listViewComponent: ListViewComponent<ListeNdf,NdfListItemComponent>;

    /**
     * Constructeur
     *
     * @param router le routeur
     * @param matDialog le composant des popups
     * @param pleaseWaitService le service de chargement
     * @param ndfService le service de gestion des NDF
     * @param translateService le service de traduction
     * @param store le store de l'appli
     * @param workflowService le service de gestion du workflow
     * @param sessionStorageService Service de gestion du Session Storage
     * @param cguService Service des CGU
     * @param toastrService Service de messages au format toast
     * @param comptabiliteService Service de gestion des lots comptables
     */
    constructor(protected router: Router,
                protected matDialog: MatDialog,
                protected pleaseWaitService: PleaseWaitService,
                protected ndfService: NDFService,
                protected translateService: TranslateService,
                protected store: Store<AppState>,
                protected workflowService: WorkflowService,
                protected sessionStorageService: SessionStorageService,
                protected cguService: CguService,
                protected toastrService: ToastrService,
                protected comptabiliteService: ComptabiliteService
    ) {
        super(router,matDialog,pleaseWaitService,ndfService,translateService,store,workflowService,sessionStorageService,cguService,toastrService,comptabiliteService);
    }

    /**
     * Spécificités de la liste
     */
    protected initListCore() {
        //Il s'agit d'une liste du dashboard
        this.liste.isDashboardList = true;

        //Définition list item du dashboard
        this.liste.component = DashboardNdfListItemComponent;

        //Adresse de redirection au clic sur le titre
        this.liste.redirect = "/ListeNDF";
    }

    /**
     * Spécificités de la liste
     */
    protected initListSpecific() {
        //Ajout du bouton de création à la liste
        this.liste.listeActions = [{
            icon: 'add',
            onPress: () => this.fonction == TypeProfil.COLLABORATEUR ? this.create() : this.createWithCollab(),
            isVisible: () => this.creationsPossibles.ndfPossible
        },{
            icon: 'search',
            onPress: () => this.router.navigate([this.liste.redirect]),
            isVisible: () => !this.creationsPossibles.ndfPossible
        }];
    }
}
