import { Component } from "@angular/core";

/**
 * Onglet statuts
 *
 * @author Guillaume TRAVOT
 * @date 26/04/2024
 */
@Component({
    host: {'data-test-id': 'liste-statuts'},
    templateUrl: './liste-statuts.component.html'
})
export class ListeStatutsComponent {}
