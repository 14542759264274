<div class="lvi-content">
    <div class="avatar">
        <i class="nio icon-profil"></i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a>{{ data.user.nom }} {{ data.user.prenom }}</a>
        </div>
        <ul class="lvi-attrs">
            <li>
                <strong [translate]="'workflow.roles.usage.matricule'"></strong>
                <span>{{ data.user.matricule }}</span>
            </li>
            <li>
                <strong [translate]="'workflow.roles.usage.service'"></strong>
                <span>{{ data.user | user:'orga' }}</span>
            </li>
            <li *ngIf="data.dateDebut">
                <strong [translate]="'workflow.roles.usage.dateDebut'"></strong>
                <span>{{ data.dateDebut | date:'shortDate' }}</span>
            </li>
            <li *ngIf="data.dateFin">
                <strong [translate]="'workflow.roles.usage.dateFin'"></strong>
                <span>{{ data.dateFin | date:'shortDate' }}</span>
            </li>
            <li>
                <strong [translate]="'workflow.roles.usage.actif'"></strong>
                <span [translate]="'global.' + (data.actif ? 'oui' : 'non')"></span>
            </li>
        </ul>
    </div>
</div>