import {Component,EventEmitter,Input,OnInit,Output} from '@angular/core';
import {forkJoin} from 'rxjs';
import {first} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {SettingsGlobalState} from '@domain/settings/settings';
import {environment} from '@environments/environment';
import {FileUploader,FileWithProgress} from '@share/directive/file-uploader/file-uploader';
import {mapPorteeByContext,mapPorteeDeleteByContext} from './document';
import {Result,TypeCodeErreur} from '@domain/common/http/result';

@Component({
    selector: 'document-uploader',
    host: {'data-test-id': 'document-uploader'},
    templateUrl: './document-uploader.component.html'
})
export class DocumentUploaderComponent implements OnInit {
    /** Upload de fichiers */
    fileUploader: FileUploader;

    /** Upload de fichiers customisé */
    @Input() fileUploaderCustom: FileUploader;

    /** Contexte d'utilisation des documents */
    @Input() context: string;

    /** Identifiant du collaborateur associé au document */
    @Input() idCollaborateur?: number;

    /** Indique si l'upload est lié à l'ocr */
    @Input() isOcr = false;

    /** Identifiant de l'objet associé au document */
    @Input() idObjet: number = 0;

    /** Clef de traduction pour le titre de l'uploader */
    @Input() title?: string = 'document.actions.ajouterPiecesJointes';

    /** Évènement généré à la suite de l'upload d'un document */
    @Output() onDocumentUploaded: EventEmitter<any> = new EventEmitter<any>();

    /** Évènement généré à la suite de la suppression d'un document */
    @Output() onDocumentRemoved: EventEmitter<void> = new EventEmitter<void>();

    /** Évènement généré à la suite de l'ajout d'un ou plusieurs documents par drop ou par sélection */
    @Output() onDocumentsAdded: EventEmitter<Array<FileWithProgress>> = new EventEmitter<Array<FileWithProgress>>();

    /** Paramétrage */
    @Input() settings: SettingsGlobalState;

    /**
     * Constructeur
     */
    constructor(private toastrService: ToastrService,private translateService: TranslateService, private toastService: ToastrService) {}

    /**
     * Initialisation
     */
    ngOnInit() {
        if (this.fileUploaderCustom) {
            this.fileUploader = this.fileUploaderCustom;
        } else {
            //Définition de l'upload de documents
            this.fileUploader = {
                url: `${environment.baseUrl}/controller/Document/upload?idTypeDocument=0&portee=${mapPorteeByContext[this.context]}`
                    + (this.idCollaborateur >= 0 ? `&idCollab=${this.idCollaborateur}`:'')
                    + `&isOCR=${this.isOcr}`,
                deleteUrl: `${environment.baseUrl}/controller/Document/deleteDocument/${mapPorteeDeleteByContext[this.context]}?id_document=`,
                onItemUploaded: this.onItemUploaded.bind(this),
                autoRemove: false,
                isCountDown: true,
            };
        }
    }

    /**
     * Upload d'un fichier
     */
    uploadItem(idxFile: number) {
        //Upload du fichier
        forkJoin(this.fileUploader.uploadItem(idxFile)).pipe(first()).subscribe();
    }

    /**
     * Reupload par l'user suite à un échec
     * @param idxFile index du fichier
     * @param file le fichier concerné
     */
    reuploadItem(idxFile: number, file: FileWithProgress) {
        if(file.result == null) {
            //La première étape n'a pas été faite on renvoie le document du début
            this.uploadItem(idxFile);
        }
        else {
            //La première étape est effectuée, on renvoie l'item au composant parent
            this.onItemUploaded(file.result, file);
        }
    }

    /**
     * Supression de l'item
     * @param idxFile id du fichier
     * @param file le fichier concerné
     */
    deleteItem(idxFile: number, file: FileWithProgress) {
        this.fileUploader.deleteItem(idxFile).pipe(first()).subscribe(
            (result: Result) => {
                if (result.codeErreur != TypeCodeErreur.NO_ERROR) {
                    this.toastService.error(this.translateService.instant('global.errors.suppression'));
                } else {
                    //Emission de l'évènement de fin de suppression
                    this.onDocumentRemoved.emit();
                }
            }
        );
    }

    /**
     * Finalisation de l'upload d'un fichier
     */
    onItemUploaded(result,file) {
        //Indique que le fichier a été envoyé
        file.isUploaded = true;
        file.result = result;

        if (result.codeErreur == TypeCodeErreur.ERROR_SAVE) {
            file.isKo = true;
            //Si extension valide
            if(!result.data.isExtensionValide) {
                //On affiche le message d'erreur extension invalide
                this.toastrService.error(this.translateService.instant('global.errors.extensionInvalide', { fileName: file.name }));
            }
            //Si taille invalide
            if(!result.data.isTailleValide) {
                //On affiche le message d'erreur taille invalide
                this.toastrService.error(this.translateService.instant('global.errors.tailleInvalide', { fileName: file.name }));
            }
            //Si nom invalide
            if(!result.data.isNomValide) {
                //On affiche le message d'erreur nom invalide
                this.toastrService.error(this.translateService.instant('global.errors.nomInvalide', { fileName: file.name }));
            }
        } else if (result.codeErreur == TypeCodeErreur.ERROR_PERMISSION) {
            //On notifie de l'échec
            this.toastrService.error(this.translateService.instant('global.errors.permission'));
        } else {
            //Document enregistré mais le nom a été modifié
            if(!!result.data.nomModifie) {
                //On affiche le message d'avertissement
                this.toastrService.warning(this.translateService.instant('global.errors.nomModifie', { fileName: file.name }));
            }
	
			//Emission de l'évènement de fin d'upload
            this.onDocumentUploaded.emit({ result,file });
        }
    }

    /**
     * Gestion du drop de fichiers
     */
    onFilesDrop(files: Array<any>) {
        let uploadedExtension;

        //Parcours des fichiers sélectionnés
        for (const file of files) {
            //Vérification du type de fichier
            if (file instanceof File) {
                //Vérification de la queue
                this.fileUploader.queue = this.fileUploader.queue || [];

                //Récupération de l'extension
                uploadedExtension = file.name.split('.').pop().toLowerCase();

                //Calcul de la taille en Ko
                const size = file.size / 1024;

                //Vérification de la taille max
                if (this.settings?.tailleMax != null &&  this.settings.tailleMax < size) {
                    //Si le fichier est trop gros
                    this.toastrService.error(this.translateService.instant('global.errors.tailleInvalide', { fileName: file.name }));
                } else if (this.settings && !this.settings.listeExtensions.some(extension => extension.toLowerCase() === uploadedExtension)) {
                    //Si extension invalide
                    //Affichage d'un message d'erreur
                    this.toastrService.error(this.translateService.instant('global.errors.fileBlocked',{ fileName: file.name }));
                } else {
                    //Ajout du fichier à la queue
                    this.fileUploader.queue.push(file);

                    //Déclenchement de l'intercepteur de fichier ajouté
                    this.fileUploader.onItemAdded && this.fileUploader.onItemAdded(file);
                }
            }
        }
    }

    /** Fichiers ajoutés et prêt à être uploadés */
    onAllItemsAdded(queue: Array<File>) {
        this.onDocumentsAdded.emit(queue);
    }

    /** Suppression de l'item par le nom */
    onItemRemoved(name: string) {
        this.fileUploader?.removeItemByName(name);

        //Emission de l'évènement de fin de suppression
        this.onDocumentRemoved.emit();
    }
}
