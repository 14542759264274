import {Component,OnDestroy,OnInit,ViewChild} from '@angular/core';
import {PageHeaderItem} from '@share/component/page-header/page-header';
import {TranslateService} from "@ngx-translate/core";
import {Territoire} from "@domain/geographie/territoire";
import {ActivatedRoute,Router} from "@angular/router";
import {GeographieService} from "@services/admin/geographie/geographie.service";
import {TypeCodeErreur} from "@domain/common/http/result";
import {PorteeTerritoireCode} from "@components/admin/bibliotheque/geographie/geographie.component";
import {BehaviorSubject} from 'rxjs';
import {FloatingButtonAction,TypeAction} from '@share/component/floating-button/floating-button';
import {ToastrService} from "ngx-toastr";
import {NgForm} from "@angular/forms";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {filter,finalize,first} from "rxjs/operators";

/**
 * Composant d'affichage du détail d'un territoire
 *
 * @author Angeline Ha
 * @date 02/11/2023
 */
@Component({
	host: {'data-test-id': 'territoire'},
	selector: 'territoire',
	templateUrl: './territoire.component.html'
})
export class TerritoireComponent implements OnInit, OnDestroy {

	/** Liste des différents onglets disponibles dans un Territoire */
	listeTabItems: Array<PageHeaderItem>;

	/** Onglet courant */
	selectedItem: PageHeaderItem;

	/** Référence vers l'enum pour l'utiliser dans le template */
	Onglets = Onglets;

	/** Territoire à afficher */
	territoire: Territoire;

	/** Formulaire */
	@ViewChild('form') form: NgForm;

	/** Indique s'il faut afficher le formulaire en création ou en édition */
	isCreation: boolean;

	/** Indicateur d'enregistrement en cours */
	isSaving: boolean = false;

	/** Liste des actions possibles */
	listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

	/**
	 * Constructeur
	 *
	 * @param translateService	Service de traduction
	 * @param router			Router
	 * @param route				Route d'accès à cette page
	 * @param geographieService	Service géographie
	 * @param toastrService		Service de gestion des toasts
	 * @param confirmService	Service de confirmation
	 */
	constructor(private translateService: TranslateService,
				private router: Router,
				private route: ActivatedRoute,
				private geographieService: GeographieService,
				private toastrService: ToastrService,
				private confirmService: ConfirmService) {
	}

	/**
	 * Initialisation
	 */
	ngOnInit(): void {
		//Indique au composant géographie qu'on affiche le détail d'un territoire
		this.geographieService.isShowDetail = true;

		//Initialisation des onglets
		this.listeTabItems = [{
			code: Onglets.GENERALITES,
			libelle: this.translateService.instant('admin.bibliotheque.geographie.territoire.onglets.generalites')
		}];

		//Sélection du premier onglet par défaut
		this.selectedItem = this.listeTabItems[0];

		//Récupération de l'identifiant du territoire
		this.route.params.pipe(first()).subscribe(params => {
			const idTerritoire = params['idTerritoire'];

			//Indique si on est en mode création
			this.isCreation = idTerritoire === '0';

			//Définition de la liste des actions possibles
			this.defineListeActionsPossibles();

			//Récupération du territoire
			if (this.isCreation) {
				//Création d'un nouveau territoire
				this.territoire = {
					idTerritoire: 0,
					code: "",
					libelle: "",
					libelleNettoye: "",
					actif: true,
					commentaire: "",
					pays: null,
					idPays: null,
					visibilite: "",
					listeVisibilites: [{
						id: PorteeTerritoireCode.IND,
						libelle: this.translateService.instant('admin.bibliotheque.geographie.territoire.indemnite')
					}, {
						id: PorteeTerritoireCode.OMP,
						libelle: this.translateService.instant('admin.bibliotheque.geographie.territoire.omp')
					}, {
						id: PorteeTerritoireCode.TAX,
						libelle: this.translateService.instant('admin.bibliotheque.geographie.territoire.taxe')
					}],
					dateUpdated: null,
				}
			} else {
				//Récupération du territoire existant
				this.geographieService.getTerritoireById(idTerritoire).subscribe(result => {
					if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
						this.territoire = result.data.territoire;

						this.initVisibilite();

					} else {
						//Message d'erreur
						TypeCodeErreur.showError(result.codeErreur, this.translateService, this.toastrService);
					}
				});

				//On ajoute l'onglet Périmètre d'un territoire seulement en modification
				this.listeTabItems.push({
					code: Onglets.PERIMETRE,
					libelle: this.translateService.instant('admin.bibliotheque.geographie.territoire.onglets.perimetre')
				});
			}
		});
	}

	/**
	 * Destruction
	 */
	ngOnDestroy() {
		//Indique au composant géographie qu'on n'affiche plus le détail d'un territoire
		this.geographieService.isShowDetail = false;
	}

	/**
	 * Définition de la valeur de la liste des actions possibles
	 */
	defineListeActionsPossibles() {
		//Liste des actions
		const listeActions: Array<FloatingButtonAction> = [{
			type: TypeAction.PRIMARY,
			icone: 'nio icon-sauvegarde',
			libelle: 'global.actions.enregistrer',
			doAction: () => this.saveTerritoire(),
			isDisabled: () => !this.form?.valid
		}];

		//Ajout de l'action de suppression lorsqu'on est en modification
		if (!this.isCreation) {
			listeActions.push({
				type: TypeAction.SECONDARY,
				icone: 'nio icon-suppression',
				libelle: 'global.actions.supprimer',
				doAction: () => this.deleteTerritoire()
			});
		}

		this.listeActions.next(listeActions);
	}

	initVisibilite() {
		//Initialisation de la liste des visibilités à cocher dans le multiselect
		if (!this.territoire.visibilite || this.territoire.visibilite === "") {
			//Si aucune visibilité renseignée : on considère que le territoire est visible pour tous les modules
			this.territoire.listeVisibilites = [{
				id: PorteeTerritoireCode.IND,
				libelle: this.translateService.instant('admin.bibliotheque.geographie.territoire.indemnite')
			},{
				id: PorteeTerritoireCode.OMP,
				libelle: this.translateService.instant('admin.bibliotheque.geographie.territoire.omp')
			},{
				id: PorteeTerritoireCode.TAX,
				libelle: this.translateService.instant('admin.bibliotheque.geographie.territoire.taxe')
			}]
		} else {
			this.territoire.listeVisibilites = this.territoire.visibilite.split(',').map(codeVisibilite => {
				let libelleVisibilite: string;
				switch (codeVisibilite) {
					case PorteeTerritoireCode.OMP:
						libelleVisibilite = this.translateService.instant('admin.bibliotheque.geographie.territoire.omp');
						break;
					case PorteeTerritoireCode.IND:
						libelleVisibilite = this.translateService.instant('admin.bibliotheque.geographie.territoire.indemnite');
						break;
					case PorteeTerritoireCode.TAX:
						libelleVisibilite = this.translateService.instant('admin.bibliotheque.geographie.territoire.taxe');
						break;
				}

				return {
					id: PorteeTerritoireCode[codeVisibilite],
					libelle: libelleVisibilite
				}
			});
		}
	}

	/**
	 * Changement d'onglet
	 *
	 * @param selectedItem Onglet sélectionné
	 */
	onSelectedItemChange(selectedItem: PageHeaderItem) {
		//Mise à jour de l'onglet sélectionné
		this.selectedItem = selectedItem;
	}

	/**
	 * Retour vers la liste des territoires
	 */
	onGoBack() {
		this.router.navigate([`Admin/Bibliotheque/Geographie/Territoires`]);
	}

	/**
	 * Enregistrement du territoire
	 */
	saveTerritoire() {
		//Enregistrement en cours
		this.isSaving = true;

		//Récupération de la visibilité
		if (!this.territoire.listeVisibilites || this.territoire.listeVisibilites.length === 0) {
			//Aucune visibilité sélectionnée : on considère le territoire visible pour tous les modules
			this.territoire.visibilite = "IND,OMP,TAX"
		} else {
			this.territoire.visibilite = this.territoire.listeVisibilites.map(visibilite => visibilite.id).join(',');
		}

		//Enregistrement du territoire
		this.geographieService.saveTerritoire(this.isCreation,this.territoire)
			.pipe(finalize(() => this.isSaving = false))
			.subscribe(data => {
			if (data.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Toast de succès
				this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

				if (this.isCreation) {
					//Récupération de l'identifiant du territoire (pour le fournir au composant périmètre)
					this.territoire.idTerritoire = data.data.idTerritoire;

					//On n'est plus en mode création
					this.isCreation = false;

					//Définition de la liste des actions possibles
					this.defineListeActionsPossibles();

					//Navigation avec le bon id
					this.router.navigate(['../' + data.data.idTerritoire],{relativeTo: this.route});

					//Ajout de l'onglet périmètres
					this.listeTabItems.push({
						code: Onglets.PERIMETRE,
						libelle: this.translateService.instant('admin.bibliotheque.geographie.territoire.onglets.perimetre')
					});

					//Sélection de l'onglet Périmètres
					this.selectedItem = this.listeTabItems.map(tab => {
						if (tab.code === Onglets.PERIMETRE) {
							tab.selected = true;
							return tab;
						}
					})[0];
				} else {
					//On réinitialise les visibilités afin de tout recocher si tout a été décoché
					this.initVisibilite();
				}
			} else if (data.codeErreur === TypeCodeErreur.ERROR_ALREADY_USED) {
				//Toast d'erreur : le code existe déjà pour un autre territoire
				this.toastrService.error(this.translateService.instant('admin.bibliotheque.geographie.territoire.erreurCode'))
			} else {
				//Toast d'erreur
				this.toastrService.error(this.translateService.instant('global.errors.enregistrement'))
			}
		});
	}

	/**
	 * Suppression du territoire
	 */
	deleteTerritoire() {
		//Popup de confirmation
		this.confirmService.showConfirm(this.translateService.instant('admin.bibliotheque.geographie.territoire.confirmSuppression'))
			.pipe(filter(isConfirmed => isConfirmed)).subscribe({ next: () => {
					//Suppression du territoire
					this.geographieService.deleteTerritoire(this.territoire.idTerritoire).subscribe(data => {
						if (data.codeErreur === TypeCodeErreur.NO_ERROR) {
							//Retour vers la liste des territoires
							this.onGoBack();

							//Toast de succès
							this.toastrService.success(this.translateService.instant('global.success.suppression'))
						} else {
							//Toast d'erreur
							this.toastrService.error(this.translateService.instant('global.errors.suppression'))
						}
					})
				}
			})
	}

}

/**
 * Enum des différents onglets d'un territoire
 */
export enum Onglets {
	GENERALITES = "Généralités",
	PERIMETRE = "Périmètre"
}
