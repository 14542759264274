import {Component,OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {PageHeaderItem} from "@share/component/page-header/page-header";
import {NavigationEnd,Router} from "@angular/router";
import {IComponentWithRoutedTabs} from "@domain/admin/recherche/component-with-routed-tabs";

/**
 * Enum pour les noms des différents onglets de la page Comptabilité/Exercices et Périodes
 */
export enum Onglets {
	EXERCICES = "Exercices"
}

/**
 * Page admin de Comptabilité/Exercices et Périodes
 *
 * @author Alexis BOUVARD
 * @date 19/04/2023
 */
@Component({
	host: {'data-test-id': 'comptabilite-exercices-periodes'},
	templateUrl: './comptabilite-exercices-periodes.component.html'
})
export class ComptabiliteExercicesPeriodesComponent implements OnInit, IComponentWithRoutedTabs {
	/** Liste statique des onglets */
	public static listeOnglets: Array<PageHeaderItem> = [
		{
			code: Onglets.EXERCICES,
			libelle: 'admin.comptabilite.exercicesPeriodes.onglets.exercices',
			url: 'Admin/Comptabilite/ExercicesPeriodes/Exercices'
		}
	];

	/** Liste des différents onglets disponibles dans le menu Comptabilité/Exercices et Périodes */
	listeTabItems: Array<PageHeaderItem> = ComptabiliteExercicesPeriodesComponent.listeOnglets;

	/** Index de l'onglet courant */
	selectedIndex: number;

	/** Indique si le header doit être affiché */
	isDisplayed: boolean = !this.router.url.includes('Exercice/');

	/**
	 * Constructeur
	 *
	 * @param translateService service de gestion de la traduction
	 * @param router
	 */
	constructor(
		private translateService: TranslateService,
		private router: Router
	) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//On s'abonne aux évènements du router
		this.router.events.subscribe((event) => {
			//On filtre les évènements de navigation
			if (event instanceof NavigationEnd) {
				//Si l'on est en consultation/modification d'un exercice, on masque le header
				this.isDisplayed = !event.urlAfterRedirects.includes('Exercice/');
			}
		});

	}
}
