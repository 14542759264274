import { Component } from "@angular/core";

/**
 * Onglet comptes verrouillés
 *
 * @author Guillaume TRAVOT
 * @date 26/04/2024
 */
@Component({
    host: {'data-test-id': 'authentification-comptes-verrouilles'},
    templateUrl: './authentification-comptes-verrouilles.component.html'
})
export class AuthentificationComptesVerrouillesComponent {}
