import {Component,OnDestroy,OnInit} from "@angular/core";
import {PageHeaderItem} from "@share/component/page-header/page-header";
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute,Router} from "@angular/router";
import {Alerte,NiveauAlerte} from "@domain/common/alerte/alerte";
import {ListeAlertes} from "@domain/common/alerte/listeAlertes";
import {DematService} from "@services/admin/parametre/demat/demat.service";
import {Store} from "@ngrx/store";
import {AppState} from "@domain/appstate";
import {Session} from "@domain/security/session";
import {isRouteAllowed} from "@core/security/role-admin-helpers";
import {filterFirstNotNull} from "@share/utils/rxjs-custom-operator";
import {IComponentWithRoutedTabs} from "@domain/admin/recherche/component-with-routed-tabs";
import {Subscription} from "rxjs";

/**
 * Enum pour les noms des différents onglets de la page Dématérialisation.
 */
export enum Onglets {
	MODULES = "Modules",
	PIECES_JOINTES = "PiecesJointes",
	EXTENSIONS = "Extensions",
	TYPES = "Types",
	OCR = "Ocr"
}

/**
 * Composant d'affichage de la page des paramètres Dématérialisation.
 *
 * @author François Turin
 * @date 27/06/2023
 *
 */
@Component({
	host: {'data-test-id': 'admin-demat'},
	templateUrl: './demat.component.html',
	providers: [DematService]
})
export class DematComponent implements OnInit, OnDestroy, IComponentWithRoutedTabs {
	/** Liste statique des onglets */
	public static listeOnglets: Array<PageHeaderItem> = [
		{
			code: Onglets.MODULES,
			libelle: 'admin.parametres.demat.modules.title',
			url: 'Admin/Parametre/Demat/Modules'
		}, {
			code: Onglets.PIECES_JOINTES,
			libelle: 'admin.parametres.demat.pieceJointes.title',
			url: 'Admin/Parametre/Demat/PiecesJointes'
		}, {
			code: Onglets.EXTENSIONS,
			libelle: 'admin.parametres.demat.extensions.title',
			url: 'Admin/Parametre/Demat/Extensions'
		}, {
			code: Onglets.TYPES,
			libelle: 'admin.parametres.demat.types.title',
			url: 'Admin/Parametre/Demat/Types'
		}, {
			code: Onglets.OCR,
			libelle: 'admin.parametres.demat.ocr.title',
			url: 'Admin/Parametre/Demat/Ocr'
		}
	];

	/* Accès aux enum dans la vue */
	readonly NiveauAlerte = NiveauAlerte;

	/** Liste des différents onglets disponibles dans la page */
	listeTabItems: Array<PageHeaderItem> = DematComponent.listeOnglets;

	/** Onglet courant */
	selectedItem: PageHeaderItem;

	/** Référence vers l'enum pour l'utiliser dans le template */
	Onglet = Onglets;

	/** Index de l'onglet courant */
	selectedIndex: number;

	/** Liste des alertes */
	listeAlertes: ListeAlertes = null;

	/** Session */
	private session: Session;

	/** Souscription */
	private subscription: Subscription;

	/**
	 * Constructeur
	 *
	 * @param translateService 	Service de traduction
	 * @param router 			Router de l'application
	 * @param store				Store
	 * @param activatedRoute	Route
	 */
	constructor(protected translateService: TranslateService,
				public router: Router,
				private store: Store<AppState>,
				private activatedRoute: ActivatedRoute
	) { }

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Récupération de la session
		this.store.select(state => state.session).pipe(filterFirstNotNull()).subscribe((session: Session) => {
			//Stockage de la session
			this.session = session;

			//Filtrage des onglets autorisés
			this.listeTabItems = this.listeTabItems.filter(i => isRouteAllowed(this.router, this.session, i.url));

			this.subscription = this.activatedRoute.url.subscribe(() => {
				//Récupération de la route
				const firstChild = this.activatedRoute.snapshot.firstChild;

				//Extraction du path
				const path = firstChild.url[0].path;

				//On vérifie si on trouve l'onglet demandé via l'url
				const matching = this.listeTabItems.find(i => i.code === path);

				//Si on trouve bien l'onglet correspondant
				if (!!matching) {
					//on met à jour le composant header
					setTimeout(() => {
						this.selectedItem = matching;
						this.selectedIndex = this.listeTabItems.findIndex(i => i.code === path);
					});
				} else {
					//Sinon, on redirige sur le premier onglet et on met à jour le composant header
					setTimeout(() => {
						this.selectedItem = this.listeTabItems[0];
						this.selectedIndex = 0;
					});
				}
			});
		});
	}

	/**
	 * Changement d'onglet
	 *
	 * @param selectedItem Onglet sélectionné
	 */
	onSelectedTabChange(selectedItem: PageHeaderItem): void {
		if (!this.selectedItem) {
			this.selectedItem = selectedItem;
		}
		if (selectedItem?.code !== this.selectedItem?.code) {
			this.selectedItem = selectedItem;
			this.router.navigateByUrl(selectedItem.url);

			//On met à jour les alertes qui dépendent de l'onglet
			this.updateAlertes();
		}
	}

	/**
	 * Mise à jour des alertes
	 */
	updateAlertes() {
		//Reset des alertes
		this.listeAlertes = new ListeAlertes();

		//Si on est sur l'onglet extension
		if (this.selectedItem.code === Onglets.EXTENSIONS) {
			//On ajoute l'alerte extension
			const alerte = new Alerte();
			alerte.niveau = NiveauAlerte.RGPD;
			alerte.message = this.translateService.instant('admin.parametres.demat.extensions.alerte');
			alerte.hasHtml = true;
			alerte.icon = 'policy';

			//Ajout de l'alerte
			this.listeAlertes.add(alerte);
			this.listeAlertes.niveau = NiveauAlerte.RGPD;
		}
	}

	/**
	 * Destruction du composant
	 */
	ngOnDestroy() {
		//Résiliation de l'abonnement
		this.subscription.unsubscribe();
	}
}
