<mat-form-field class="d-flex" [floatLabel]="floatLabel">
    <mat-label *ngIf="label">{{ label }}</mat-label>
    <mat-label class="float-label-extra" *ngIf="labelExtra != null"><ng-container [ngTemplateOutlet]="labelExtra"></ng-container></mat-label>
    <input [id]="inputId" type="text" [placeholder]="placeholder" matInput #input="matInput" [readonly]="disabled"
           [formControl]="autocompleteControl" [matAutocomplete]="autocomplete" [errorStateMatcher]="errorStateMatcher"
           (change)="onChange($event)" [required]="required" />
    <mat-icon class="clickable search-icon" matSuffix [hidden]="disabled" (click)="!disabled && showSearch(); $event.stopPropagation()">search</mat-icon>
    <mat-autocomplete #autocomplete="matAutocomplete" [displayWith]="displayItem" (optionSelected)="onOptionSelected($event.option.value)"
                      [panelWidth]="options.width">
        <mat-option *ngIf="isLoading">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </mat-option>
        <ng-container *ngIf="!isLoading">
            <mat-option *ngFor="let item of listeItems" [value]="item">
                <autocomplete-line [item]="item" [options]="options"></autocomplete-line>
            </mat-option>
        </ng-container>
    </mat-autocomplete>
    <mat-error *ngIf="!!matError" [ngClass]="matError?.css">{{ matError?.error }}</mat-error>
    <mat-hint *ngIf="!!matHint" [ngClass]="matHint?.css">{{ matHint?.libelle }}</mat-hint>
</mat-form-field>
