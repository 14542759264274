import {Component,OnDestroy,OnInit} from '@angular/core';
import {PageHeaderItem} from '@share/component/page-header/page-header';
import {GeographieService} from "@services/admin/geographie/geographie.service";
import {IComponentWithRoutedTabs} from "@domain/admin/recherche/component-with-routed-tabs";
import {ActivatedRoute,Router} from "@angular/router";
import {Subscription} from "rxjs";

/**
 * Enum des différents onglets de la page Géographie
 */
export enum Onglets {
	TERRITOIRES = "Territoires",
	ZONES = "Zones",
	PAYS = "Pays",
	REGIONS = "Regions",
	DEPARTEMENTS = "Departements",
	VILLES = 'Villes',
	ADRESSES = "Adresses",
	DISTANCES = "Distances",
	GEOLOCALISATION = "Geolocalisation",
	IATA = "Iata"
}

/**
 * Composant géographie
 *
 * @author Angeline Ha
 * @date 02/11/2023
 */
@Component({
	host: {'data-test-id': 'geographie'},
	selector: 'geographie',
	templateUrl: './geographie.component.html',
	providers: [GeographieService]
})
export class GeographieComponent implements OnInit, OnDestroy, IComponentWithRoutedTabs {
	/** Liste statique des onglets */
	public static listeOnglets: Array<PageHeaderItem> = [{
		code: Onglets.TERRITOIRES,
		libelle: 'admin.bibliotheque.geographie.onglets.territoires',
		url: 'Admin/Bibliotheque/Geographie/Territoires'
	}, {
		code: Onglets.ZONES,
		libelle: 'admin.bibliotheque.geographie.onglets.zones',
		url: 'Admin/Bibliotheque/Geographie/Zones'
	}, {
		code: Onglets.PAYS,
		libelle: 'admin.bibliotheque.geographie.onglets.pays',
		url: 'Admin/Bibliotheque/Geographie/Pays'
	}, {
		code: Onglets.REGIONS,
		libelle: 'admin.bibliotheque.geographie.onglets.regions',
		url: 'Admin/Bibliotheque/Geographie/Regions'
	}, {
		code: Onglets.DEPARTEMENTS,
		libelle: 'admin.bibliotheque.geographie.onglets.departements',
		url: 'Admin/Bibliotheque/Geographie/Departements'
	}, {
		code: Onglets.VILLES,
		libelle: 'admin.bibliotheque.geographie.onglets.villes',
		url: 'Admin/Bibliotheque/Geographie/Villes'
	}, {
		code: Onglets.ADRESSES,
		libelle: 'admin.bibliotheque.geographie.onglets.adresses',
		url: 'Admin/Bibliotheque/Geographie/Adresses'
	}, {
		code: Onglets.DISTANCES,
		libelle: 'admin.bibliotheque.geographie.onglets.distances',
		url: 'Admin/Bibliotheque/Geographie/Distances'
	}, {
		code: Onglets.IATA,
		libelle: 'admin.bibliotheque.geographie.onglets.iata',
		url: 'Admin/Bibliotheque/Geographie/Iata'
	}, {
		code: Onglets.GEOLOCALISATION,
		libelle: 'admin.bibliotheque.geographie.onglets.geolocalisation',
		url: 'Admin/Bibliotheque/Geographie/Geolocalisation'
	}];

	/** Liste des différents onglets disponibles dans le menu */
	listeTabItems: Array<PageHeaderItem> = GeographieComponent.listeOnglets;

	/** Onglet courant */
	selectedItem: PageHeaderItem;

	/** Référence vers l'enum des onglets pour utiliser dans le template */
	Onglet = Onglets;

	/** Index de l'onglet courant */
	selectedIndex: number;

	/** Indique si le header doit être affiché */
	isDisplayed: boolean;

	/** Souscription */
	private subscription: Subscription;

	/**
	 * Constructeur
	 *
	 * @param geographieService	Service geographie
	 * @param router Routeur
	 * @param activatedRoute Service de routage
	 */
	constructor(private geographieService: GeographieService,
				private router: Router,
				private activatedRoute: ActivatedRoute) {
	}

	/**
	 * Initialisation
	 */
	ngOnInit(): void {
		//Définition des onglets
		this.listeTabItems = GeographieComponent.listeOnglets;

		this.subscription = this.activatedRoute.url.subscribe(() => {
			//Récupération de la route
			const firstChild = this.activatedRoute.snapshot.firstChild;

			//Extraction du path
			const path = firstChild.url[0].path;

			//On vérifie si on trouve l'onglet demandé via l'url
			const matching = this.listeTabItems.find(i => i.code === path);

			//Si on trouve bien l'onglet correspondant
			if (!!matching) {
				//on met à jour le composant header
				setTimeout(() => {
					this.selectedItem = matching;
					this.selectedIndex = this.listeTabItems.findIndex(i => i.code === path);
					this.isDisplayed = (Object.values(firstChild.params).findIndex(v => v !== undefined) === -1);
				});
			} else {
				//Sinon, on redirige sur le premier onglet et on met à jour le composant header
				setTimeout(() => {
					this.selectedItem = this.listeTabItems[0];
					this.selectedIndex = 0;
					this.isDisplayed = (Object.values(firstChild.params).findIndex(v => v !== undefined) === -1);
				});
			}
		});
	}

	/**
	 * Changement d'onglet
	 *
	 * @param selectedItem Onglet sélectionné
	 */
	onSelectedItemChange(selectedItem: PageHeaderItem): void {
		if (!this.selectedItem) {
			this.selectedItem = selectedItem;
		}
		if (selectedItem?.code !== this.selectedItem?.code) {
			this.selectedItem = selectedItem;
			this.router.navigateByUrl(selectedItem.url);
		}
	}

	/**
	 * Indique si on est dans le détail d'un objet
	 */
	get isInDetail() {
		return this.geographieService.isShowDetail;
	}

	/**
	 * Destruction du composant
	 */
	ngOnDestroy() {
		//Résiliation de l'abonnement
		this.subscription.unsubscribe();
	}
}

/**
 * Enum des portées d'un territoire
 */
export enum PorteeTerritoireCode {
	IND = "IND",
	OMP = "OMP",
	TAX = "TAX"
}
