import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {PaysItem} from '@domain/admin/bibliotheque/geographie/paysItem';
import {ListViewItem} from '@domain/common/list-view/list-view-item';
import {GeographieService} from '@services/admin/geographie/geographie.service';

/**
 * Composant d'un pays dans la liste des pays
 *
 * @author Angeline Ha
 * @date 08/11/2023
 */
@Component({
	host: {'data-test-id': 'pays-item'},
	templateUrl: './pays-item.component.html'
})
export class PaysItemComponent extends ListViewItem<PaysItem> {

	/**
	 * Constructeur
	 *
	 * @param geographieService Service géographie
	 * @param router			Router de l'application
	 * @param route				Route d'accès à cette page
	 */
	constructor(private geographieService: GeographieService,
				private router: Router) {
		super();
	}

	/**
	 * Affichage du détail d'un pays
	 */
	goToDetailPays() {
		//Indique au service géographie qu'on affiche un détail
		this.geographieService.isShowDetail = true;

		//Navigue vers le détail
		this.router.navigate([`Admin/Bibliotheque/Geographie/Pays/${this.data.idPays}`]);
	}
}
