import {Injectable} from '@angular/core';
import {HttpClient,HttpParams} from '@angular/common/http';
import {Observable,of,Subject} from 'rxjs';
import {distinctUntilChanged,filter,finalize,first,map,switchMap,tap} from 'rxjs/operators';

import {Filter,ListView,TypeComparaison,TypeFilter} from '@domain/common/list-view';
import {environment} from '@environments/environment';
import {SearchSpec} from "@domain/common/list-view/searchSpec";
import {Result} from "@domain/common/http/result";
import {TranslateService} from '@ngx-translate/core';
import {emptyPage,Page} from '@domain/common/http/list-result';
import {DatePipe,DecimalPipe} from "@angular/common";
import {SearchType} from '@domain/common/list-view/sorting';
import {SessionStorageService} from "@domain/common/services/session-storage.service";
import {ListItem} from "@domain/common/list-view/list-item";
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {CountStatus,nbObjetsParPageDefaut} from "@domain/common/list-view/list-view";
import {TypeProfil} from "@domain/user/user";
import {FilterDTO} from "@domain/common/list-view/filterDTO";
import * as moment from "moment";

/**
 * Service de gestion des listes
 */
@Injectable()
export class ListViewService<T extends ListItem, K extends ListViewItem<T>> {
    /**
     * Constructeur
     */
    constructor(private http: HttpClient,
                private translateService: TranslateService,
                private sessionStorageService: SessionStorageService,
                private datePipe: DatePipe,
                private decimalPipe: DecimalPipe) {

    }

    /**
     * Chargement des données
     *
     * @param liste                 Liste de données
     * @param uri                   URL à appeler pour récupérer les données
     * @param numPage               Numéro de la page à afficher
     * @param nbObjetsParPage       Nombre d'objets à charger par page (forcé à 5 pour les listes du dashboard)
     * @param defaultOrder          Ordre par défaut à appliquer aux résultats de la requête
     * @param listeStaticFilters    Liste des filtres complémentaires pour le searchSpec
     * @param listeFilters          Liste des filtres pour le searchSpec
     * @param fonction              Fonction de l'utilisateur connecté
     * @return {Observable<Page<T>>}    Résultat de la requête
     */
    loadData(liste: ListView<T, K>, uri: string, numPage: number, nbObjetsParPage: number = nbObjetsParPageDefaut, defaultOrder: string, listeStaticFilters: Filter[], listeFilters: Filter[], fonction: TypeProfil): Observable<Page<T>> {
        const searchSpec: SearchSpec = this.buildSearchSpec(numPage, liste, nbObjetsParPage, defaultOrder, listeStaticFilters, listeFilters);

        //Si la recherche peut s'exécuter sans filtre, ou si au moins un filtre est renseigné
        if (!liste.isLocalData && (liste.isSearchWhenNoFilter || listeFilters && listeFilters.length > 0)) {
            //On lance la requête de chargement des données
            return this.doHttpPost(uri,liste,searchSpec,fonction).pipe(
                //On va switcher d'observable en fonction du résultat
                switchMap(result =>  {
                    //On stocke le résultat de la requête
                    const resultMappe = (liste.mapResult && liste.mapResult(result as Result) || result) as Page<T>;

                    //Si la liste n'a pas d'éléments et qu'elle est paramétrée pour ignorer les filtres si elle est vide
                    if (resultMappe && resultMappe.numPage == 0 && (!resultMappe.listeResultats || resultMappe.listeResultats.length == 0) && liste.removeFiltreOnceIfEmpty) {
                        //On supprime le flag
                        liste.removeFiltreOnceIfEmpty = false;

                        //On supprime les filtres
                        searchSpec.listeFilter.splice(0);
                        liste.listeSelectedFilters.splice(0);

                        //On relance la requête de liste sans filtre
                        return this.doHttpPost(uri,liste,searchSpec,fonction).pipe(map(result => (liste.mapResult && liste.mapResult(result as Result) || result) as Page<T>));
                    } else {
                        //Si on n'est pas dans le cas spécifique décrit ci-dessus, on renvoie simplement le résultat
                        return of(resultMappe);
                    }
                })
            );
        } else {
            //Sinon on renvoie une page vide
            return of(emptyPage<T>());
        }
    }


    /**
     * Exécution de la requête de la liste
     *
     * @param uri           URL à appeler
     * @param liste         Liste pour laquelle on cherche des résultats
     * @param searchSpec    Critères de tri et de filtre
     * @param fonction      Fonction de l'utilisateur connecté
     * @returns Observable de la page de résultat
     */
    private doHttpPost(uri: string,liste: ListView<T,K>,searchSpec: SearchSpec,fonction: TypeProfil): Observable<Result | Page<T>> {
        return this.http.post<Result | Page<T>>(environment.baseUrl + uri,liste.mapRequest ? liste.mapRequest(searchSpec) : searchSpec,{params: this.getRequestParams(uri,liste,fonction)});
    }

    /**
     * Récupère la liste des @RequestParam à envoyer
     *
     * @param uri       URI déjà crée pour appeler le controller
     * @param liste     Liste concernée
     * @param fonction  Fonction de l'utilisateur connecté
     * @returns {string} String contenant tous les params à ajouter à l'URL
     */
    getRequestParams(uri: string, liste: ListView<T, K>, fonction: TypeProfil): HttpParams {
        let params: HttpParams = new HttpParams();

        //Si la liste connait déjà son nombre d'objets total
        if (liste.countTotal >= 0) {
            //On envoie le count pour gagner du temps sur la requête (à condition que le controller java soit fait pour prendre le count en compte)
            params = params.append("count", String(liste.countTotal));
        }
        //Si l'utilisateur n'est pas sur un profil collaborateur (les collaborateurs n'ont pas le droit au count asynchrone), et qu'il y a une gestion du count
        else if (fonction !== TypeProfil.COLLABORATEUR && !!liste.loadCount) {
            //On indique qu'on ne veut pas que le count soit exécuté
            params = params.append("noCount", "");
        }

        return params;
    }

    /**
     * Préchargement des données suivantes de la liste
     *
     * @param liste                 Liste de données
     * @param uri                   URL à appeler pour récupérer les données
     * @param numPage               Numéro de la page à afficher
     * @param nbObjetsParPage       Nombre d'objets à charger par page (forcé à 5 pour les listes du dashboard)
     * @param defaultOrder          Ordre par défaut à appliquer aux résultats de la requête
     * @param listeStaticFilters    Liste des filtres complémentaires pour le searchSpec
     * @param listeFilters          Liste des filtres pour le searchSpec
     * @param fonction              Fonction de l'utilisateur connecté
     */
    preloadNextData(liste: ListView<T, K>, uri: string, numPage: number, nbObjetsParPage: number = nbObjetsParPageDefaut, defaultOrder: string, listeStaticFilters: Filter[], listeFilters: Filter[], fonction: TypeProfil): void {
        //S'il n'y a pas déjà de données préchargées et si la liste n'est pas déjà complètement chargée
        if (!liste.preloadedData.getValue() && !liste.isDashboardList && !liste.isPreloading && !liste.isTotalementChargee()) {
            //Indicateur de préchargement
            liste.isPreloading = true;

            //Chargement de la liste pour la page suivante
            this.loadData(liste, uri, numPage + 1, nbObjetsParPage, defaultOrder, listeStaticFilters, listeFilters, fonction).pipe(
                first(),
                finalize(() => {
                        //Indicateur de préchargement
                        liste.isPreloading = false;
                    }
                )).subscribe((data) => {
                //Préchargement des données
                liste.preloadedData.next(data);
            });
        }
    }

    /**
     * Sauvegarde des paramètres appliqués
     *
     * @param liste Liste de données
     * @param fonction Fonction active de l'utilisateur
     * @param force permet de forcer la sauvegarde des params de la liste
     */
    public backupListeParams(liste: ListView<T, K>, fonction: number, force?: boolean): void {
        //Détection du paramètre de persistence et protection contre l'absence de nom de classe
        if (liste?.isPersistFilters && liste?.className && !liste?.isDashboardList || force) {
            //Sauvegarde des filtres appliqués
            if (liste.listeFilters) {
                this.sessionStorageService.save(liste.className, 'listeFilters_' + fonction.toString(), liste.listeFilters);
            }
            if (liste.listeSelectedFilters) {
                this.sessionStorageService.save(liste.className, 'listeSelectedFilters_' + fonction.toString(), liste.listeSelectedFilters);
            }
            if (liste.sorting?.getFormattedSorting()) {
                this.sessionStorageService.save(liste.className, 'defaultOrder_' + fonction.toString(), liste.sorting.getFormattedSorting());
            }
            if (liste.sorting?.search) {
                this.sessionStorageService.save(liste.className, 'search_' + fonction.toString(), liste.sorting.search);
            }
        }
    }

    /**
     * Réinitialisation du LocalStorage de la liste
     *
     * @param liste Liste de données
     * @param fonction Fonction active de l'utilisateur
     */
    resetListeParams(liste: ListView<T, K>, fonction: number): void {
        //Purge de toutes les clés associées à la liste
        this.sessionStorageService.remove(liste.className, 'listeFilters_' + fonction.toString());
        this.sessionStorageService.remove(liste.className, 'listeSelectedFilters_' + fonction.toString());
        this.sessionStorageService.remove(liste.className, 'defaultOrder_' + fonction.toString());
        this.sessionStorageService.remove(liste.className, 'search_' + fonction.toString());
    }

    /**
     * Consommation des données préchargées ou chargement des données
     *
     * @param liste                 Liste de données
     * @param uri                   URL à appeler pour récupérer les données
     * @param numPage               Numéro de la page à afficher
     * @param nbObjetsParPage       Nombre d'objets à charger par page (forcé à 5 pour les listes du dashboard)
     * @param defaultOrder          Ordre par défaut à appliquer aux résultats de la requête
     * @param listeStaticFilters    Liste des filtres complémentaires pour le searchSpec
     * @param listeFilters          Liste des filtres pour le searchSpec
     * @param fonction              Fonction de l'utilisateur connecté
     *
     * @return {Observable<any>}    Résultat de la requête
     */
    consumePreloadedDataOrLoadIt(liste: ListView<T, K>, uri: string, numPage: number, nbObjetsParPage: number = nbObjetsParPageDefaut, defaultOrder: string, listeStaticFilters: Filter[], listeFilters: Filter[], fonction: TypeProfil): Observable<Page<T>> {
        //Si des données sont préchargées
        if (liste.preloadedData.getValue()) {
            //Création d'un Observable simple avec les données préchargées
            const preloadedData: Observable<Page<T>> = of(liste.preloadedData.getValue());

            //Purge des données préchargées après consommation
            liste.preloadedData.next(undefined);

            //Préchargement des données suivantes
            this.preloadNextData(liste, uri, numPage, nbObjetsParPage, defaultOrder, listeStaticFilters, listeFilters, fonction);

            //Retour des données préchargées sous forme d'Observable
            return preloadedData;
        }
        //Sinon si le préchargement a commencé
        else if (liste.isPreloading) {
            //Création d'un Subject dédié
            const preloadedData: Subject<Page<T>> = new Subject<Page<T>>();

            //Abonnement au préchargement
            liste.preloadedData.asObservable().pipe(filter(data => !!data), distinctUntilChanged(), first()).subscribe(data => {
                //Déclenchement de l'Observable dédié
                preloadedData.next(data);

                //Purge des données préchargées après consommation
                liste.preloadedData.next(undefined);
            });

            //Préchargement des données suivantes
            this.preloadNextData(liste, uri, numPage, nbObjetsParPage, defaultOrder, listeStaticFilters, listeFilters, fonction);

            //Retour de l'Observable de préchargement
            return preloadedData.asObservable().pipe(first());
        }
        //Sinon
        else {
            //Préchargement des données suivantes
            setTimeout(() => this.preloadNextData(liste, uri, numPage, nbObjetsParPage, defaultOrder, listeStaticFilters, listeFilters, fonction));

            //Retour de l'Observable de chargement de la liste
            return this.loadData(liste, uri, numPage, nbObjetsParPage, defaultOrder, listeStaticFilters, listeFilters, fonction).pipe(first());
        }
    }

    /**
     * Chargement des données annexes
     */
    loadAnnexData(liste: ListView<T, K>): Observable<void> {
        //Uniquement pour les listes concernées
        if (liste.annexData) {
            //Chargement des données annexes
            return this.http.get<Result>(environment.baseUrl + liste.annexData.uri).pipe(
                map(result => liste.annexData.onLoad(result))
            );
        }

        //Retour
        return;
    }

    /**
     * Mise à jour de la pagination
     * @param liste Liste affichée
     * @param fonction Fonction de l'utilisateur connecté
     * @returns{string} la valeur de pagination à afficher
     */
    getPagination(liste: ListView<T, K>, fonction: TypeProfil): string {
        //Avec des nombres le truthy/falsy n'est pas fiable, car 0 est une valeur pertinente
        if (!liste.data || liste.data.numPage == null || liste.data.nbObjetsParPage == null || liste.data.nbObjetsDansPage == null || (liste.data.nbObjetsTotal == null && liste.countTotal < 0)) {
            return "";
        }

        //Si on a une vraie pagination (pas le count forcé pour l'optimisation)
        if (fonction === TypeProfil.COLLABORATEUR || liste.loadCount?.loadStatus.getValue() === CountStatus.LOADED || liste.data.nbObjetsTotal !== 9000000000000000000 || liste.nbElementsCharges < liste.data.nbObjetsParPage) {
            //Pour le nombre d'éléments total, si le count est loadé, on prend sa valeur
            //Sinon, s'il y a moins d'éléments chargés que le max d'une page, on prend cette valeur
            //Sinon, on prend le nombre d'éléments total
            const nbTotal = liste.loadCount?.loadStatus.getValue() === CountStatus.LOADED ? liste.countTotal
                : liste.nbElementsCharges < liste.data.nbObjetsParPage ? liste.nbElementsCharges : liste.data.nbObjetsTotal;
            //Traduction de la pagination
            return this.translateService.instant('liste.pagination', {nbElements: liste.nbElementsCharges, nbTotal: nbTotal});
        } else {
            //Si on est ici, c'est qu'on n'a pas encore le nombre de pages, donc on met un message en conséquence
            return this.translateService.instant('liste.paginationSansCount', {nbElements: liste.nbElementsCharges});
        }
    }

    /**
     * Chargement d'une liste simple
     * @param liste définition de la liste
     * @param uri endpoint
     */
    loadSimpleList(liste: ListView<T, K>, uri: string): Observable<T[]> {
        //Chargement des données
        return this.http.post<Result>(environment.baseUrl + uri, null).pipe(
            map(result => (liste.mapResult && liste.mapResult(result) || result) as Array<T>)
        );
    }

    /**
     * Mise à jour de la liste des filtres sélectionnés
     *
     * @param liste La listeview concernée
     */
    refreshListeSelectedFilters(liste: ListView<T, K>) {
        //Création de la liste des filtres à partir de ceux sélectionnés
        let listeSelectedFilters = liste.listeFilters.map(filter => {
            if (filter.isSelected) {
                //Initialisation
                let displayedValeur = '';

                //Gestion des listes de choix
                if (filter.listeValues) {
                    //Application du libellé à la place de la valeur
                    if (filter.multiple) {
                        displayedValeur = this.formatFilterValuesMultiple(filter);
                    } else {
                        displayedValeur = filter.listeValues.find(v => v.code == filter.valeur).libelle;
                    }
                } else {
                    //Vérification du type de filtre
                    if (filter.displayedValeur) {
                        //Définition de la valeur à afficher
                        displayedValeur = filter.displayedValeur;
                    } else if (filter.valeur) {
                        //Définition de la valeur à afficher
                        if (filter.type == TypeFilter[TypeFilter.BOOLEAN] && filter.valeur != '') {
                            displayedValeur = this.translateService.instant(`filter.valeurOuiNon.${filter.valeur}`);
                        } else {
                            displayedValeur = filter.listeValues?.length > 0 ? filter.listeValues.find(value => value.code == filter.valeur)?.libelle : filter.valeur;
                        }
                    } else if (filter.type == TypeFilter[TypeFilter.DATE]) {
                        //Définition de la date de début
                        displayedValeur = this.translateService.instant(`filter.valeur.${filter.typeComparaison}`, {min: this.datePipe.transform(filter.dateDebut, 'shortDate'), max: this.datePipe.transform(filter.dateFin, 'shortDate')});
                    } else if (filter.type == TypeFilter[TypeFilter.DECIMAL]) {
                        //Définition de la valeur décimale
                        displayedValeur = this.translateService.instant(`filter.valeur.${filter.typeComparaison}`, {min: this.decimalPipe.transform(filter.min, '1.2-2'), max: this.decimalPipe.transform(filter.max, '1.2-2')});
                    } else if (filter.type == TypeFilter[TypeFilter.LONG]) {
                        //Définition de la valeur entière
                        displayedValeur = this.decimalPipe.transform(filter.min, '1.0-0');
                    }
                }

                //Si le filtre comporte une methode de sélection
                if (filter.selectMethod) {
                    //Appel de la methode de sélection
                    filter.selectMethod(filter);
                }

                //Retour
                return {
                    ...filter,
                    displayedValeur,
                    valeur: filter.valeur ? (filter.typeComparaison == TypeComparaison[TypeComparaison.LIKE] && !liste.isFrontendList ? `${filter.valeur}%` : `${filter.valeur}`) : null
                };
            } else {
                //Si le filtre comporte une methode de sélection
                if (filter.selectMethod) {
                    //Appel de la methode de sélection
                    filter.selectMethod(filter);
                }

                //Retour
                return filter;
            }
        }).filter(filter => filter.isSelected);

        //Ajout des filtres tapés manuellement dans la barre de recherche
        listeSelectedFilters.push(...liste.listeSelectedFilters.filter(f => f.isSearchbarFilter));

        //Ajout d'un timestamp aux filtres qui n'en ont pas le cas échéant
        listeSelectedFilters
            .filter(filter => !filter.timestamp)
            .forEach(filter => {
                filter.timestamp = liste.listeSelectedFilters.find(f => f.clef === filter.clef)?.timestamp ?? new Date().getTime();
            });

        //Tri sur le timestamp
        listeSelectedFilters.sort((f1,f2) => {
            return f1.timestamp == f2.timestamp ? 0
                : f1.timestamp < f2.timestamp ? -1
                : 1;
        });

        //Mise à jour de la liste des filtres
        liste.listeSelectedFilters = listeSelectedFilters;
    }

    /**
     * Formatage de la valeur à afficher pour un filtre à sélection multiple.<br />
     * Format de sortie : Valeur 1 ... valeur N
     *
     * @param filter Le filtre (doit être multiple)
     * @param max Le nombre maximum N de valeurs à afficher (2 par défaut)
     */
    formatFilterValuesMultiple(filter: Filter, max: number = 2): string {
        let strLibelles: string = '';
        let listeLibelle: Array<string>;
        let listeValeur: Array<any>;

        //Vérification que le filtre est multiple
        if (filter.multiple) {
            //Vérification de la présence de valeurs
            if (filter.listeObjects) {
                //Liste des n valeurs à afficher
                listeValeur = filter.listeObjects.slice(0, max);

                //Construction de la liste des libellés pour chaque valeur à sélectionner
                listeLibelle = filter.listeValues.filter(value => listeValeur.includes(value.code)).map(fv => fv.libelle);

                //Formatage
                strLibelles = listeLibelle.join(", ") + (listeLibelle.length < filter.listeObjects?.length ? ' (+' + (filter.listeObjects.length - listeLibelle.length) + ')' : '');
            }

            //Retour de la valeur formatée
            return strLibelles;
        } else {
            //Filtre non multiple : déclenchement d'une erreur qui, si elle n'est pas interceptée, affichera un message de type ERROR dans la console
            throw `Le filtre ${filter.clef} n'est pas multiple !`;
        }
    }

    /**
     * Charge le nombre total d'éléments de la liste
     *
     * @param liste                 Liste de données
     * @returns {Observable<number>} Observable qui va indiquer la valeur du count ou -1 si le count asynchrone n'est pas géré
     */
    loadCount(liste: ListView<T, K>): Observable<number> {
        //Si la liste a bien une gestion du count asynchrone et qu'on n'est pas sur une liste du dashboard
        if (!!liste.loadCount && !liste.isDashboardList) {
            liste.loadCount.loadStatus.next(CountStatus.LOADING);

            //Définition de la pagination
            const searchSpec: SearchSpec = this.buildSearchSpec(0, liste, liste.nbObjetsParPage ?? nbObjetsParPageDefaut, liste.defaultOrder, liste.listeStaticFilters, liste.listeSelectedFilters);

            //Chargement des données
            return this.http.post<Result>(environment.baseUrl + liste.loadCount.uri, liste.mapRequest ? liste.mapRequest(searchSpec) : searchSpec).pipe(
                first(),
                map(result => liste.loadCount.onLoad(result)),//On récupère la valeur du count depuis le result
                tap(count => {
                    //Si on a un count cohérent, on met à jour le nombre de pages de la liste
                    if (count >= 0) {
                        liste.countTotal = count;
                        liste.loadCount.loadStatus.next(CountStatus.LOADED)
                    }
                })
            );
        }

        //Si on est ici, c'est qu'on ne gère pas le count, on renvoie -1
        return of(-1);
    }


    /**
     * Construit le SearchSpec des requêtes
     *
     * @param liste                 Liste de données
     * @param numPage               Numéro de la page à afficher
     * @param nbObjetsParPage       Nombre d'objets à charger par page (forcé à 5 pour les listes du dashboard)
     * @param defaultOrder          Ordre par défaut à appliquer aux résultats de la requête
     * @param listeStaticFilters    Liste des filtres complémentaires pour le searchSpec
     * @param listeFilters          Liste des filtres pour le searchSpec
     * @return {SearchSpec}    Résultat de la requête
     */
    private buildSearchSpec(numPage: number, liste: ListView<T, K>, nbObjetsParPage: number, defaultOrder: string, listeStaticFilters: Filter[], listeFilters: Filter[]): SearchSpec {
        //On regroupe tous les filtres
        const listeMergedFilter: Filter[] = [...(listeStaticFilters || []),...listeFilters];

        //Paramétrage du filtre textuel
        if ((liste?.sorting || liste?.extraOptions?.searchType != null) && listeMergedFilter) {
            listeMergedFilter.forEach(f => {
                //Si le filtre est de type like
                if (f.typeComparaison == "LIKE") {
                    //Si le tri est paramétré en 'commence par' selon l'emplacement barre de recherche ou sous filtre
                    if ((f.isSearchbarFilter && liste?.sorting?.searchBar == SearchType.STARTS_WITH)
                        || (!f.isSearchbarFilter
                            && (liste?.sorting?.search == SearchType.STARTS_WITH || liste?.extraOptions?.searchType == SearchType.STARTS_WITH))) {
                        //Suppression du %
                        if (f.valeur?.startsWith('%')) {
                            f.valeur = f.valeur.substring(1);
                        }
                    }
                    //Sinon 'contient'
                    else {
                        //Ajout du %
                        if (!f.valeur?.startsWith('%')) {
                            f.valeur = '%' + f.valeur;
                        }
                    }
                }
            });
        }

        //Définition de la pagination
        const searchSpec: SearchSpec = {
            numPage: numPage,
            nbObjetsParPage: liste.isDashboardList ? 5 : nbObjetsParPage,
            defaultOrder: defaultOrder,
            listeFilter: listeMergedFilter.map(filter => new FilterDTO(filter))//on les convertit en DTO et les rajoute dans les searchspec
        };

        return searchSpec;
    }

    /**
     * Applique les filtres de recherche et le tri sur la liste de résultats d'une listview.<br/>
     * Si un élément ne correspond pas l'attribut isFiltre sera positionné à True, et à False dans le cas contraire.
     *
     * @param liste La listview sur laquelle appliquer les filtres et le tri
     */
    searchAndSortFrontendList(liste: ListView<T,K>): void {
        //Application des filtres
        liste.data.listeResultats.forEach(data => {
            data.isFiltre = !this.isObjectMatchSearch(data,liste);
        });

        //Tri si demandé via le paramètre et si au moins une colonne de tri a été spécifiée
        if (liste.sorting?.columns?.length > 0) {
            this.sortFrontendList(liste.data.listeResultats,liste.sorting.getFormattedSorting());
        }

        //Actualisation de l'affichage
        liste.onFiltrageFront$.next();
    }

    /**
     * Retourne True si un objet vérifie les filtres de recherche de la ListView, False sinon
     *
     * @param obj Objet à tester
     * @param liste La ListView contenant les filtres de recherche
     */
    isObjectMatchSearch(obj: unknown,liste: ListView<T,K>): boolean {
        //Concaténation des filtres static et des filtres actifs sauf ceux saisis manuellement dans la barre de recherche
        const listeFilter: Array<Filter> = [...(liste.listeStaticFilters || []), ...liste.listeSelectedFilters.filter(f => f.isSelected)];

        //Définition du mode de recherche
        const searchType: SearchType = liste?.sorting?.search == SearchType.STARTS_WITH || liste?.extraOptions?.searchType == SearchType.STARTS_WITH ? SearchType.STARTS_WITH : SearchType.CONTAINS;

        //ET entre tous les filtres "standards" sélectionnés
        return listeFilter.every(filter => this.isObjectMatchFilterDeep(obj,filter.clef,filter,searchType))
            //ET entre tous les filtres saisis dans la barre de recherche
            && liste.listeSelectedFilters
                .filter(f => !f.isSelected)
                //Découpage de la clef si elle porte sur plusieurs colonnes
                .every(f => f.clef
                        .split(',')
                        //Création d'un filtre pour chaque clef
                        .map(clef => Object.assign(new Filter(),f,{clef: clef}) as Filter)
                        //OU entre chaque filtre
                        .some(filter => this.isObjectMatchFilterDeep(obj,filter.clef,filter,searchType))
                );
    }

    /**
     * Vérifie si le champ ciblé par son path dans un objet vérifie un filtre donné.
     * Si la taille du path est supérieure à 1, le champ est recherché récursivement dans l'objet.
     *
     * @param obj Objet à tester
     * @param path Path du champ ciblé dans l'objet.<br />
     *             Peut être soit une chaine de la forme prop.sousProp.sousSousPro ou directement un tableau de la forme [prop,sousProp,sousSousPro]
     * @param filter Filtre de recherche
     * @param searchType Mode de recherche
     * @return True si l'objet vérifie le filtre, False sinon
     */
    private isObjectMatchFilterDeep(obj: unknown,path: Array<string>|string,filter: Filter,searchType: SearchType = SearchType.CONTAINS): boolean {
        let prop: string;
        let matchNull: boolean = false;
        let value: unknown;

        //Si le paramètre est au format chaine, on le découpe
        if (typeof path === 'string') {
            path = path.split('.');
        }

        //Récupération du champ correspondant au path
        prop = path[0];

        //Cas particulier où un sous-objet null est autorisé (équivalent du left join côté back)
        if (prop.startsWith("*")) {
        	matchNull = true;
        	//Suppression du flag '*'
            prop = prop.substring(1);
        }

        //Récupération de la valeur sur l'objet
        value = obj[prop];

        //Si on est sur le dernier niveau du path
        if (path.length == 1) {
            //Dans le cas d'un tableau
            if (Array.isArray(value)) {
				//Au moins une des valeurs de la liste correspond au filtre
                return (value as Array<unknown>).some(val => this.isValueMatchFilter(val,filter,searchType));
            } else {
            	//Cas nominal : la valeur correspond au filtre
                return this.isValueMatchFilter(value,filter,searchType);
            }
        } else if (path.length > 1) {
            //On passe au niveau suivant dans le path
            path.splice(0,1);

            if (Array.isArray(value)) {
            	//Cas d'un tableau : appel récursif pour tester si au moins l'un des objets du tableau correspond au filtre
                return (value as Array<unknown>).some(o => this.isObjectMatchFilterDeep(o,path,filter));
            } else {
                //Si la valeur est null, on s'arrête là
                if (value == null) {
                    //Mais on retourne True ou False en accord avec le flag * sur le path
                    return matchNull;
                } else {
                    //Sinon appel récursif avec la suite du path
                    return this.isObjectMatchFilterDeep(value,path,filter);
                }
            }
        }

		//Au cas où...
        return true;
    }

    /**
     * Vérifie si une valeur correspond à un filtre donné
     *
     * @param value Valeur à comparer à celle contenant dans le filtre
     * @param filter Filtre de recherche
     * @param searchType Mode de recherche (Utilisé uniquement dans le cas d'un filtre de type STRING)
     */
    private isValueMatchFilter(value: unknown,filter: Filter,searchType: SearchType): boolean {
        //Suivant le type de filtre
        switch (filter.type) {
            //Filtre de type numérique
            case TypeFilter[TypeFilter.DECIMAL]:
            case TypeFilter[TypeFilter.LONG]:
                //Récupération de la valeur numérique
                const valNumber: number = Number(value);

                switch (filter.typeComparaison) {
                    case TypeComparaison[TypeComparaison.IN]:
                    case TypeComparaison[TypeComparaison.NOT_IN]:
                        //Recherche de la valeur dans la liste des valeurs possibles
                        const isFound = filter.listeObjects.some(v => Number(v) == valNumber);
                        //Retour suivant le type de comparaison spécifié par le filtre
                        return filter.typeComparaison == TypeComparaison[TypeComparaison.NOT_IN] ? !isFound : isFound;
                    case TypeComparaison[TypeComparaison.BETWEEN]:
                        //Vérifie que la valeur numérique est comprise entre min et max
                        return valNumber >= filter.min && valNumber <= filter.max;
                    case TypeComparaison[TypeComparaison.GREATER_EQUAL]:
                        //Vérifie que la valeur numérique est supérieure ou égale à min
                        return valNumber >= filter.min;
                    case TypeComparaison[TypeComparaison.GREATER]:
                        //Vérifie que la valeur numérique est supérieure à min
                        return valNumber > filter.min;
                    case TypeComparaison[TypeComparaison.LESS_EQUAL]:
                        //Vérifie que la valeur numérique est inférieure ou égale à min
                        return valNumber <= filter.min;
                    case TypeComparaison[TypeComparaison.LESS]:
                        //Vérifie que la valeur numérique est inférieure à min
                        return valNumber < filter.min;
                    case TypeComparaison[TypeComparaison.EQUAL]:
                    case TypeComparaison[TypeComparaison.NOT_EQUAL]:
                    case null: //Cas par défaut si le type de comparaison n'a pas été défini sur le filtre
                        //Vérifie que la valeur numérique est égale à min
                        const isEquals = valNumber == (filter.min ?? Number(filter.valeur));
                        return filter.typeComparaison == TypeComparaison[TypeComparaison.NOT_EQUAL] ? !isEquals : isEquals;
                }
                break;
            //Filtre de type booléen
            case TypeFilter[TypeFilter.BOOLEAN]:
                //Fonction permettant de récupérer une valeur booléenne, que le champ soit un booléen, un nombre ou une chaine (la valeur du filtre est une chaine)
                const getBooleanValue = (bool: unknown) => bool === true || bool === 1 || bool === 'true';

                //Conversion de la valeur en booléen
                const valBoolean: boolean = getBooleanValue(value);

                switch (filter.typeComparaison) {
                    case TypeComparaison[TypeComparaison.EQUAL]:
                    case TypeComparaison[TypeComparaison.NOT_EQUAL]:
                    case null:
                        //Conversion de la valeur du filtre en booléen
                        const isEquals = valBoolean === getBooleanValue(filter.valeur);
                        //Retour suivant le type de comparaison spécifié par le filtre
                        return filter.typeComparaison == TypeComparaison[TypeComparaison.NOT_EQUAL] ? !isEquals : isEquals;
                }
                break;
            //Filtre de type Date
            case TypeFilter[TypeFilter.DATE]:
                //Date sans l'heure
                const valDate: moment.Moment = moment(value).startOf('day');

                switch (filter.typeComparaison) {
                    case TypeComparaison[TypeComparaison.IN]:
                    case TypeComparaison[TypeComparaison.NOT_IN]:
                        //Recherche de la valeur dans la liste des valeurs possibles
                        const isFound = filter.listeObjects.some(val => valDate.isSame(moment(val).startOf('day')));
                        //Retour suivant le type de comparaison spécifié par le filtre
                        return filter.typeComparaison == TypeComparaison[TypeComparaison.NOT_IN] ? !isFound : isFound;
                    case TypeComparaison[TypeComparaison.BETWEEN]:
                        //Date comprise entre dateDebut et dateFin
                        return valDate.isBetween(moment(filter.dateDebut).startOf('day'),moment(filter.dateFin).startOf('day'));
                    case TypeComparaison[TypeComparaison.GREATER_EQUAL]:
                        //Date postérieure ou égale à dateDebut
                        return valDate.isSameOrAfter(moment(filter.dateDebut).startOf('day'));
                    case TypeComparaison[TypeComparaison.GREATER]:
                        //Date postérieure à dateDebut
                        return valDate.isAfter(moment(filter.dateDebut).startOf('day'));
                    case TypeComparaison[TypeComparaison.LESS_EQUAL]:
                        //Date antérieure ou égale à dateDebut
                        return valDate.isSameOrBefore(moment(filter.dateFin).startOf('day'));
                    case TypeComparaison[TypeComparaison.LESS]:
                        //Date antérieure à dateDebut
                        return valDate.isBefore(moment(filter.dateFin).startOf('day'));
                    case TypeComparaison[TypeComparaison.EQUAL]:
                    case TypeComparaison[TypeComparaison.NOT_EQUAL]:
                    case null: //Cas par défaut si le type de comparaison n'a pas été défini sur le filtre
                        const isSame = valDate.isSame(moment(filter.dateDebut).startOf('day'));
                        //Retour suivant le type de comparaison spécifié par le filtre
                        return filter.typeComparaison == TypeComparaison[TypeComparaison.NOT_EQUAL] ? !isSame : isSame;
                }
                break;
            //Filtre de type Chaine
            case TypeFilter[TypeFilter.STRING]:
                const valString: string = String(value).toLowerCase();

                switch (filter.typeComparaison) {
                    case TypeComparaison[TypeComparaison.IN]:
                    case TypeComparaison[TypeComparaison.NOT_IN]:
                        //Recherche de la valeur dans la liste des valeurs possibles
                        const isFound = filter.listeObjects.some(v => String(v).toLowerCase() == valString);
                        //Retour suivant le type de comparaison spécifié par le filtre
                        return filter.typeComparaison == TypeComparaison[TypeComparaison.NOT_IN] ? !isFound : isFound;
                    case TypeComparaison[TypeComparaison.LIKE]:
                        //Recherche de l'index de la chaine recherchée dans la valeur suivant le type de recherche (Commence par / Contient)
                        return searchType === SearchType.STARTS_WITH
                            ? valString.indexOf(filter.valeur.toLowerCase()) == 0
                            : valString.indexOf(filter.valeur.toLowerCase()) >= 0;
                    case TypeComparaison[TypeComparaison.EQUAL]:
                    case TypeComparaison[TypeComparaison.NOT_EQUAL]:
                    case null: //Cas par défaut si le type de comparaison n'a pas été défini sur le filtre
                        //Comparaison des valeurs littérales
                        const isEquals = valString == filter.valeur.toLowerCase();
                        //Retour suivant le type de comparaison spécifié par le filtre
                        return filter.typeComparaison == TypeComparaison[TypeComparaison.NOT_EQUAL] ? !isEquals : isEquals;
                }
                break;
        }

        //Si on arrive jusque ici, c'est que l'on n'a pas su traiter le cas : info dans la console
        console.error(`Type de comparaison ${filter.typeComparaison} non supporté pour le type ${filter.type}`);
        return false;
    }

    /**
     * Tri de la liste des résultats
     *
     * @param listeResultats La liste des résultats à trier
     * @param sorting Ordre de tri de la liste
     */
    sortFrontendList(listeResultats: Array<any>,sorting: string | string[]): void {
        let keys: string[];

        //Initialisation des clés en fonction du type du paramètre
        if (typeof sorting === 'string') {
            keys = sorting?.split(',');
        } else {
            keys = sorting;
        }

        //S'il y a au moins une clé de tri
        if (keys?.length > 0) {
            //Parcours de tous les résultats
            listeResultats.sort((obj1,obj2): number => {
                let res: number = 0;
                let key: string;
                let val1: any;
                let val2: any;

                //Pour chaque colonne de tri tant que la comparaison est identique
                for (let iKey = 0; iKey < keys.length && res === 0; iKey++) {
                    //Récupération de la clef de tri
                    key = keys[iKey];

                    //Suppression de l'ordre de tri le cas échéant
                    if (key.startsWith('-')) {
                        key = key.substring(1);
                    }

                    //Récupération de la valeur sur chaque objet pour la colonne de tri en cours
                    val1 = Object.getOwnPropertyDescriptor(obj1,key)?.value;
                    val2 = Object.getOwnPropertyDescriptor(obj2,key)?.value;

                    //Comparaison
                    if (val1 === val2) {
                        //Identiques
                        res = 0;
                    } else if (val1 == null && val2 != null) {
                        //Remontée de l'objet 1 s'il a une valeur alors que l'objet 2 non
                        res = 1;
                    } else if (val1 != null && val2 == null) {
                        //Descente de l'objet 1 s'il n'a pas de valeur alors que l'objet 2 oui
                        res = -1;
                    } else {
                        if (typeof val1 === 'number' && typeof val2 === 'number') {
                            //Remontée de l'objet 1 qui a une valeur numérique supérieure à celle de l'objet 2
                            res = val1 > val2 ? 1 : -1;
                        } else if (typeof val1 === 'boolean' && typeof val2 === 'boolean') {
                            //Remontée de l'objet 1 qui a une valeur booléenne à True (et en toute logique celle de l'objet 2 à False)
                            res = val1 === true ? 1 : -1;
                        } else if (typeof val1 === 'string' && typeof val2 === 'string') {
                            //Remontée de l'objet 1 qui a une valeur litéral supérieure à celle de l'objet 2
                            res = val1.localeCompare(val2);
                        } else if (val1 instanceof Date && val2 instanceof Date) {
                            //Remontée de l'objet 1 qui a une date postérieure à celle de l'objet 2
                            res = val1.getTime() > val2.getTime() ? 1 : -1;
                        } else if (moment.isMoment(val1) || moment.isMoment(val2)) {
                            //Remontée de l'objet 1 qui a une date postérieure à celle de l'objet 2
                            res = moment(val1).valueOf() > moment(val2).valueOf() ? 1 : -1;
                        } else {
                            //Cas non géré : info dans la console
                            console.error(`Le type de champ n'est pas supporté pour le tri sur cette colonne`);
                        }
                    }

                    //Inversion du sens du tri le cas échéant
                    if (keys[iKey].startsWith('-')) {
                        res = -res;
                    }
                }

                //Retour de la valeur
                return res;
            });
        }
    }
}
