import {HttpClient,HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable,Subject} from 'rxjs';

import {Result} from '@domain/common/http/result';
import {ReleveFacture} from '@domain/facture/releve-facture';
import {environment} from '@environments/environment';
import {FactureErreurImport} from "@domain/facture/facture-erreur-import";
import {Fournisseur} from "@domain/facture/fournisseur";
import {Od} from '@domain/od/od';
import {UserDto} from "@domain/user/user-dto";

@Injectable()
export class ReleveFactureService {
    /** Indique s'il faut recharger la liste des factures */
    private _refreshListeFactures$: Subject<void> = new Subject<void>();

    /** Indique s'il faut recalculer la validité du relevé */
    private _refreshIsReleveValide$: Subject<void> = new Subject<void>();

    /** Indique s'il faut recharger la liste des lots comptables */
    private _refreshListeLots$: Subject<void> = new Subject<void>();

    /**
     * Contructeur
     */
    constructor(private http: HttpClient) {}

    /** permet de souscrire aux demandes de rafraichissement de la liste des factures */
    refreshListeFactures$ = this._refreshListeFactures$.asObservable();

    /** Indique aux souscripteurs qu'il faut recharger la liste des factures */
    refreshListeFactures() { this._refreshListeFactures$.next(); }

    /** Permet de souscrire aux demandes de rafraichissement de la validité du relevé */
    refreshIsReleveValide$ = this._refreshIsReleveValide$.asObservable();

    /** Indique aux souscripteurs qu'il faut recalculer la validité du relevé */
    refreshIsReleveValide() { this._refreshIsReleveValide$.next(); }

    /** Permet de souscrire aux demandes de rafraichissement de la liste des lots */
    refreshListeLots$ = this._refreshListeLots$.asObservable();

    /** Indique aux souscripteurs qu'il faut recharger la liste des lots */
    refreshListeLots() { this._refreshListeLots$.next(); }

    /**
     * Chargement d'un élément
     * @param id l'ID de l'élement à charger
     * @returns un Observable de l'élement
     */
    load(id: number): Observable<Result> {
        //Ajout des paramètres
        const params: URLSearchParams = new URLSearchParams();
        params.append('idFactureReleve',(id || 0).toString());

        //Appel
        return this.http.post<Result>(`${environment.baseUrl}/controller/Facture/consultReleve`,params.toString(),{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        });
    }

    /**
     * Enregistrement de l'élément
     * @param element l'élément à sauvegarder
     * @returns un Observable du résultat
     */
    save(element: ReleveFacture): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Facture/saveReleve`,element);
    }

    /**
     * Suppression de l'élément
     * @param id ID de l'élément à supprimer
     * @returns un Observable du résultat
     */
    delete(id: number): Observable<Result> {
        return this.http.delete<Result>(`${environment.baseUrl}/controller/Facture/deleteReleve/${id}`);
    }

    /**
     * Chargement d'une ligne de facture en erreur
     *
     * @param idErreurImport identifiant de l'erreur à charger
     */
    loadErreur(idErreurImport: number): Observable<{factureErreur: FactureErreurImport,fournisseur: Fournisseur,od: Od,collaborateur: UserDto}> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/ImportFacture/loadErreur/${idErreurImport}`,null)
            .map(result => result.data);
    }

    /**
     * Correction d'une ligne de facture en erreur
     *
     * @param factureAnomalie Erreur corrigée
     */
    correctError(factureAnomalie: FactureErreurImport): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/ImportFacture/correctError`,factureAnomalie);
    }

    /**
     * Marquage d'une ligne de facture en erreur comme masquée ou non
     *
     * @param masquer True: Masque de l'erreur. False : Démasquage de l'erreur.
     * @param factureAnomalie La ou les erreurs à (dé)masquer
     */
    hideError(masquer: boolean,factureAnomalie: Array<number>): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/ImportFacture/hideError`,factureAnomalie,{
            params: new HttpParams().append("masquer",masquer.toString())
        });
    }

    /**
     * Vérification de la présence de factures en anomalie non masquées
     */
    isFactureAnomalieVisible(idReleve: number): Observable<Result> {
        return this.http.get<Result>(`${environment.baseUrl}/controller/Facture/${idReleve}/isFactureAnomalieVisible`);
    }

}