import {Component} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {VilleItem} from "@domain/admin/bibliotheque/geographie/villeItem";
import {Router} from "@angular/router";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {TranslateService} from "@ngx-translate/core";
import {filter,finalize} from "rxjs/operators"
import {GeographieService} from "@services/admin/geographie/geographie.service";
import {TypeCodeErreur} from '@domain/common/http/result';
import {ToastrService} from "ngx-toastr";
import {PleaseWaitService} from "@share/component/please-wait/please-wait.service";

/**
 * Composant d'une ville dans la liste des villes
 *
 * @author Angeline Ha
 * @date 28/11/2023
 */
@Component({
    host: {'data-test-id': 'ville-item'},
    selector: 'ville-item',
    templateUrl: './ville-item.component.html'
})
export class VilleItemComponent extends ListViewItem<VilleItem> {

    /**
     * Constructeur
     *
     * @param router            Router
     * @param confirmService    Service de confirmation
     * @param translateService  Service des traductions
     * @param geographieService Service géographie
     * @param toastrService     Service des toasts
     * @param pleaseWaitService Service de chargement
     */
    constructor(private router: Router,
                private confirmService: ConfirmService,
                private translateService: TranslateService,
                private geographieService: GeographieService,
                private toastrService: ToastrService,
                private pleaseWaitService: PleaseWaitService) {
        super();
    }

    /**
     * Affichage du détail d'une ville
     */
    goToDetailVille() {
        this.router.navigate([`Admin/Bibliotheque/Geographie/Ville/${this.data.idVille}`]);
    }

    /**
     * Suppression de la ville
     */
    delete() {
        //Popup de confirmation
        this.confirmService.showConfirm(this.translateService.instant('admin.bibliotheque.geographie.ville.confirmSuppression'))
            .pipe(filter(isConfirmed => isConfirmed)).subscribe({
            next: () => {
                let waitDialogRef = this.pleaseWaitService.show();

                //Suppression de la ville
                this.geographieService.deleteVille(this.data.idVille)
                    .pipe(finalize(() => { waitDialogRef.close(); }))
                    .subscribe(data => {
                    if (data.codeErreur === TypeCodeErreur.NO_ERROR) {
                        //Indique au composant géographie de refresh la liste des villes
                        this.geographieService.listeVilles.refresh();

                        //Toast de succès
                        this.toastrService.success(this.translateService.instant('global.success.suppression'))
                    } else {
                        //Toast d'erreur
                        this.toastrService.error(this.translateService.instant('global.errors.suppression'))
                    }
                })
            }
        })
    }

}
