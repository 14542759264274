import {Component,OnDestroy,OnInit,ViewChild} from '@angular/core';
import {PageHeaderItem} from '@share/component/page-header/page-header';
import {RegionAdmin} from "@domain/geographie/regionAdmin";
import {NgForm} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';
import {FloatingButtonAction,TypeAction} from '@share/component/floating-button/floating-button';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {GeographieService} from '@services/admin/geographie/geographie.service';
import {ToastrService} from 'ngx-toastr';
import {ConfirmService} from '@share/component/confirmation/confirm.service';
import {TypeCodeErreur} from '@domain/common/http/result';
import {filter,finalize,first} from "rxjs/operators";

/**
 * Composant d'affichage du détail d'une région
 *
 * @author Angeline Ha
 * @date 09/11/2023
 */
@Component({
    host: {'data-test-id': 'region'},
    selector: 'region',
    templateUrl: './region.component.html'
})
export class RegionComponent implements OnInit, OnDestroy {
    /** Liste des différents onglets disponibles dans une Région */
    listeTabItems: Array<PageHeaderItem>;

    /** Onglet courant */
    selectedItem: PageHeaderItem;

    /** Référence vers l'enum pour l'utiliser dans le template */
    Onglets = Onglets;

    /** Région à afficher */
    region: RegionAdmin;

    /** Formulaire */
    @ViewChild('form') form: NgForm;

    /** Indique s'il faut afficher le formulaire en création ou en édition */
    isCreation: boolean;

    /** Indicateur d'enregistrement en cours */
    isSaving: boolean = false;

    /** Liste des actions possibles */
    listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

    /**
     * Constructeur
     *
     * @param translateService  Service de traduction
     * @param router            Router
     * @param route             Route d'accès à cette page
     * @param geographieService Service géographie
     * @param toastrService     Service de gestion des toasts
     * @param confirmService    Service de confirmation
     */
    constructor(private translateService: TranslateService,
                private router: Router,
                private route: ActivatedRoute,
                private geographieService: GeographieService,
                private toastrService: ToastrService,
                private confirmService: ConfirmService) {
    }

    /**
     * Initialisation
     */
    ngOnInit(): void {
        //Indique au composant géographie qu'on affiche le détail d'une région
        this.geographieService.isShowDetail = true;

        //Récupération de l'identifiant de la région
        this.route.params.pipe(first()).subscribe(params => {
            const idRegion = params['idRegion'];

            //Indique si on est en mode création
            this.isCreation = idRegion === '0';

            //Définition de la liste des actions possibles
            this.defineListeActionsPossibles();

            //Récupération de la région
            if (this.isCreation) {
                //Création d'une nouvelle région
                this.region = {
                    idRegion: null,
                    libelle: "",
                    libelleNettoye: "",
                    code: "",
                    idPays: null,
                    pays: null,
                    isActif: true
                }
            } else {
                //Récupération de la région existante
                this.geographieService.getRegionById(idRegion).subscribe(result => {
                    if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
                        this.region = result.data.region;
                        this.region.idPays = result.data.region.pays?.idPays;
                    } else {
                        TypeCodeErreur.showError(result.codeErreur, this.translateService, this.toastrService);
                    }
                });
            }
        });

        //Initialisation des onglets
        this.listeTabItems = [{
            code: Onglets.GENERALITES,
            libelle: this.translateService.instant('admin.bibliotheque.geographie.territoire.onglets.generalites')
        }];

        //Sélection du premier onglet par défaut
        this.selectedItem = this.listeTabItems[0];
    }

    /**
     * Destruction
     */
    ngOnDestroy() {
        //Indique au composant géographie qu'on n'affiche plus le détail d'une région
        this.geographieService.isShowDetail = false;
    }

    /**
     * Définition de la valeur de la liste des actions possibles
     */
    defineListeActionsPossibles() {
        //Liste des actions
        const listeActions: Array<FloatingButtonAction> = [{
            type: TypeAction.PRIMARY,
            icone: 'nio icon-sauvegarde',
            libelle: 'global.actions.enregistrer',
            doAction: () => this.saveRegion(),
            isDisabled: () => !this.form?.valid
        }];

        //Ajout de l'action de suppression lorsqu'on est en modification
        if (!this.isCreation) {
            listeActions.push({
                type: TypeAction.SECONDARY,
                icone: 'nio icon-suppression',
                libelle: 'global.actions.supprimer',
                doAction: () => this.deleteRegion()
            });
        }

        this.listeActions.next(listeActions);
    }

    /**
     * Changement d'onglet
     *
     * @param selectedItem Onglet sélectionné
     */
    onSelectedItemChange(selectedItem: PageHeaderItem) {
        //Mise à jour de l'onglet sélectionné
        this.selectedItem = selectedItem;
    }

    /**
     * Retour vers la liste des régions
     */
    onGoBack() {
        this.router.navigate([`Admin/Bibliotheque/Geographie/Regions`]);
    }

    /**
     * Enregistrement de la région
     */
    saveRegion() {
        //Enregistrement en cours
        this.isSaving = true;

        //Enregistrement de la région
        this.geographieService.saveRegion(this.isCreation, this.region)
            .pipe(finalize(() => this.isSaving = false))
            .subscribe(result => {
            if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
                //Toast de succès
                this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

                if (this.isCreation) {
                    //On n'est plus en mode création
                    this.isCreation = false;

                    //Récupération de l'identifiant
                    this.region.idRegion = result.data.idRegion;

                    //Définition de la liste des actions possibles
                    this.defineListeActionsPossibles();

                    //Navigation avec le bon id
                    this.router.navigate(['../' + result.data.idRegion], {relativeTo: this.route});
                }
            } else {
                //Toast d'erreur
                this.toastrService.error(this.translateService.instant('global.errors.enregistrement'))
            }
        })
    }

    /**
     * Suppression de la région
     */
    deleteRegion() {
        //Popup de confirmation
        this.confirmService.showConfirm(this.translateService.instant('admin.bibliotheque.geographie.region.confirmSuppression'))
            .pipe(filter(isConfirmed => isConfirmed)).subscribe({
            next: () => {
                //Suppression de la région
                this.geographieService.deleteRegion(this.region.idRegion).subscribe(result => {
                    if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
                        //Retour vers la liste des régions
                        this.onGoBack();

                        //Toast de succès
                        this.toastrService.success(this.translateService.instant('global.success.suppression'))
                    } else {
                        //Toast d'erreur
                        this.toastrService.error(this.translateService.instant('global.errors.suppression'))
                    }
                })
            }
        })
    }

}

/**
 * Enum des différents onglets d'une région
 */
export enum Onglets {
    GENERALITES = "Généralités"
}

