import {Component,OnDestroy,OnInit,ViewChild} from '@angular/core';
import {PageHeaderItem} from '@share/component/page-header/page-header';
import {Iata, TypeIata} from "@domain/geographie/iata";
import {NgForm} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';
import {FloatingButtonAction,TypeAction} from '@share/component/floating-button/floating-button';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {GeographieService} from '@services/admin/geographie/geographie.service';
import {ToastrService} from 'ngx-toastr';
import {TypeCodeErreur} from '@domain/common/http/result';
import {first} from "rxjs/operators";

/**
 * Composant du détail d'un code IATA
 *
 * @author Angeline Ha
 * @date 13/02/2024
 */
@Component({
    host: {'data-test-id': 'iata'},
    selector: 'iata',
    templateUrl: './iata.component.html'
})
export class IataComponent implements OnInit, OnDestroy {
    /** Accès à l'enum dans la vue */
    readonly TypeIata = TypeIata;

    /** Liste des différents onglets disponibles dans un iata */
    listeTabItems: Array<PageHeaderItem>;

    /** Onglet courant */
    selectedItem: PageHeaderItem;

    /** Référence vers l'enum pour l'utiliser dans le template */
    Onglets = Onglets;

    /** IATA à afficher */
    iata: Iata;

    /** Formulaire */
    @ViewChild('form') form: NgForm;

    /** Indique s'il faut afficher le formulaire en création ou en édition */
    isCreation: boolean;

    /** Liste des actions possibles */
    listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

    /**
     * Constructeur
     *
     * @param translateService  Service de traduction
     * @param router            Router
     * @param route             Route d'accès à cette page
     * @param geographieService Service géographie
     * @param toastrService     Service de gestion des toasts
     */
    constructor(private translateService: TranslateService,
                private router: Router,
                private route: ActivatedRoute,
                private geographieService: GeographieService,
                private toastrService: ToastrService) {
    }

    /**
     * Initialisation
     */
    ngOnInit(): void {
        //Indique au composant géographie qu'on affiche le détail d'un iata
        this.geographieService.isShowDetail = true;

        //Récupération de l'identifiant du iata
        this.route.params.pipe(first()).subscribe(params => {
            //Récupération de l'identifiant
            const idIata = params['idIataVille'];

            //Indique si on est en mode création
            this.isCreation = idIata === '0';

            //Récupération du iata
            if (this.isCreation) {
                //Création d'un nouveau iata
                this.iata = {
                    idIataVille: 0,
                    libelle: "",
                    codeIata: "",
                    codeGare: "",
                    codeKDS: "",
                    actif: true,
                    ville: null,
                    idVille: null,
                    type: TypeIata.AEROPORT,
                    latitude: 0,
                    longitude: 0
                };
            } else {
                //Récupération du iata existant
                this.geographieService.getIataById(idIata).subscribe(result => {
                    if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
                        this.iata = result.data.iata;
                    } else {
                        TypeCodeErreur.showError(result.codeErreur, this.translateService, this.toastrService);
                    }
                });
            }
        });

        //Initialisation des onglets
        this.listeTabItems = [{
            code: Onglets.GENERALITES,
            libelle: this.translateService.instant('admin.bibliotheque.geographie.iata.generalites')
        }];

        //Sélection du premier onglet par défaut
        this.selectedItem = this.listeTabItems[0];

        //Définition de la liste des actions possibles
        this.listeActions.next([{
            type: TypeAction.PRIMARY,
            icone: 'nio icon-sauvegarde',
            libelle: 'global.actions.enregistrer',
            doAction: () => this.saveIata(),
            isDisabled: () => !this.form?.valid
        }]);
    }

    /**
     * Destruction
     */
    ngOnDestroy() {
        //Indique au composant géographie qu'on n'affiche plus le détail d'un iata
        this.geographieService.isShowDetail = false;
    }

    /**
     * Changement d'onglet
     *
     * @param selectedItem Onglet sélectionné
     */
    onSelectedItemChange(selectedItem: PageHeaderItem) {
        //Mise à jour de l'onglet sélectionné
        this.selectedItem = selectedItem;
    }

    /**
     * Retour vers la liste des iata
     */
    onGoBack() {
        this.router.navigate([`Admin/Bibliotheque/Geographie/Iata`]);
    }

    /**
     * Enregistrement du iata
     */
    saveIata() {
        //Enregistrement du iata
        this.geographieService.saveIata(this.iata).subscribe(result => {
            if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
                //Toast de succès
                this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

                if (this.isCreation) {
                    //On n'est plus en mode création
                    this.isCreation = false;

                    //Récupération de l'identifiant
                    this.iata.idIataVille = result.data.idIataVille;

                    //Navigation avec le bon id
                    this.router.navigate(['../' + result.data.idIataVille], {relativeTo: this.route});
                }
            } else {
                //Toast d'erreur
                TypeCodeErreur.showError(result.codeErreur, this.translateService, this.toastrService);
            }
        });
    }
}

/**
 * Enum des différents onglets d'un iata
 */
export enum Onglets {
    GENERALITES = "Généralités"
}