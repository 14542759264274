<div class="with-floating-button">
    <div class="content">
        <page-header [title]="'admin.bibliotheque.reporting.onglets.reporting' | translate"
                     [listeItems]="listeTabItems"
                     [niveauAlerte]="alerte ? alerte.niveau : null">
            <preview-alerte *ngIf="alerte" [alerte]="alerte" [withIcon]="'info_outline'"></preview-alerte>
        </page-header>
        <list-view *ngIf="liste" [liste]="liste"></list-view>
    </div>
    <floating-button [listeActions]="listeActions"></floating-button>
</div>