import {Component,OnDestroy,OnInit,ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {FloatingButtonAction,TypeAction} from '@share/component/floating-button/floating-button';
import {BehaviorSubject, Subscription} from 'rxjs';
import {Jour,OptionCompte,OptionDateLotComptable,OptionSpeAvances,OptionsTransverses,TypeDateLotComptable} from "@domain/admin/comptabilite/optionsTransverses";
import {ComptabilitePlanComptableService} from "@components/admin/comptabilite/plan-comptable/comptabilite-plan-comptable.service";
import {TypeCodeErreur} from "@domain/common/http/result";
import {Compte,TypeCompte} from "@domain/admin/comptabilite/compte";
import {TranslateService} from "@ngx-translate/core";
import {MatDialog} from "@angular/material/dialog";
import {DatesFixesPopinComponent} from "@components/admin/comptabilite/plan-comptable/plan-comptable-parametres/dates-fixes-popin/dates-fixes-popin.component";
import {ToastrService} from "ngx-toastr";
import {finalize,first} from "rxjs/operators";

/**
 * Paramètres du plan comptable
 *
 * @author Angeline Ha
 * @date 29/02/2024
 */
@Component({
    host: {'data-test-id': 'plan-comptable-parametres'},
    selector: 'plan-comptable-parametres',
    templateUrl: './plan-comptable-parametres.component.html'
})
export class PlanComptableParametresComponent implements OnInit, OnDestroy {

    /** Formulaire */
    @ViewChild('form') form: NgForm;

    /** Configuration */
    config: OptionsTransverses;

    /** Liste des actions possibles */
    listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

    /** Liste des comptes tiers (collaborateur, fournisseurs et avances) */
    listeCompteTiers: OptionCompte[] = new Array<OptionCompte>();

    /** Liste des comptes taxe */
    listeCompteTaxe: OptionCompte[] = new Array<OptionCompte>();

    /** Liste des options de spécification pour les avances */
    listeSpecificationAvances: OptionSpeAvances[] = new Array<OptionSpeAvances>();

    /** Liste des comptes de gains */
    listeCompteGain: OptionCompte[] = new Array<OptionCompte>();

    /** Liste des comptes de pertes */
    listeComptePerte: OptionCompte[] = new Array<OptionCompte>();

    /** Liste des dates de lot comptable */
    listeDateLotComptable: OptionDateLotComptable[] = new Array<OptionDateLotComptable>();

    /** Liste des jours de la semaine */
    listeJours: Jour[];

    /** Liste des jours de la semaine initialisée au chargement et modifiée seulement à l'enregistrement global */
    listeJoursInit: string;

    /** Libellé indiquant le nombre de jours fériés */
    nombreJoursFeries: number;

    /** Liste des jours fériés fixes */
    listeJoursFeries: string[];

    /** Souscription à l'indicateur de refresh de la liste des comptes */
    refreshSubListeComptes: Subscription;

    /** Chargement en cours */
    isPending: boolean = false;

    /**
     * Constructeur
     *
     * @param planComptableService Service du plan comptable
     * @param translateService Service des traductions
     * @param toastrService Service des toasts
     * @param matDialog Boîte de dialogue
     */
    constructor(private planComptableService: ComptabilitePlanComptableService,
                private translateService: TranslateService,
                private toastrService: ToastrService,
                private matDialog: MatDialog,) {
    }

    /**
     * Initialisation
     */
    ngOnInit(): void {
        //Définition de la liste des actions du bouton en bas à droite de l'écran
        this.listeActions.next([{
                type: TypeAction.PRIMARY,
                icone: 'nio icon-sauvegarde',
                libelle: 'global.actions.enregistrer',
                doAction: () => this.saveConfig()
            }]
        );

        //Récupération de la configuration actuelle
        this.planComptableService.getOptionsTransverses().subscribe(result => {
            if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
                this.config = result.data.optionsTransverses;

                //Définition de la valeur par défaut de la date du lot
                if (!this.config.typeDateLotComptable) {
                    this.config.typeDateLotComptable = TypeDateLotComptable.DATE_JOUR;
                }

                //Définition des listes des jours de congé et jours fériés à partir de la configuration
                this.buildListesCongesFeriesFromString();
            } else {
                this.config = null;

                //Message d'erreur
                TypeCodeErreur.showError(TypeCodeErreur.ERROR_LOAD, this.translateService, this.toastrService);
            }
        })

        //Définition des listes des différents types de compte
        this.defineListesComptes();

        //Définition de la liste des spécifications sur les avances
        this.listeSpecificationAvances = [{
            id: 1,
            libelle: this.translateService.instant('admin.comptabilite.planComptable.parametres.collaborateur')
        }, {
            id: 2,
            libelle: this.translateService.instant('admin.comptabilite.planComptable.parametres.devise')
        }]

        //Définition de la liste des dates de lot comptable
        this.listeDateLotComptable = [{
            type: TypeDateLotComptable.DATE_JOUR,
            libelle: this.translateService.instant('admin.comptabilite.planComptable.parametres.date.jour')
        }, {
            type: TypeDateLotComptable.PREMIER_JOUR_PERIODE,
            libelle: this.translateService.instant('admin.comptabilite.planComptable.parametres.date.premier')
        }, {
            type: TypeDateLotComptable.DERNIER_JOUR_PERIODE,
            libelle: this.translateService.instant('admin.comptabilite.planComptable.parametres.date.dernier')
        }, {
            type: TypeDateLotComptable.DATE_A_SAISIR,
            libelle: this.translateService.instant('admin.comptabilite.planComptable.parametres.date.saisir')
        }]

        //Souscription à l'indicateur de refresh de la liste des comptes
        this.refreshSubListeComptes = this.planComptableService.refreshListeComptesObservable$.subscribe(() => {
            this.defineListesComptes();
        })
    }

    /**
     * Destruction du composant
     */
    ngOnDestroy() {
        this.refreshSubListeComptes.unsubscribe();
    }

    /**
     * Définition des listes des différents types de comptes
     */
    defineListesComptes() {
        //Remise à zéro des listes
        this.listeCompteTiers.length = 0;
        this.listeCompteTaxe.length = 0;
        this.listeComptePerte.length = 0;
        this.listeCompteGain.length = 0;

        //Ajout de l'option 'Aucun'
        this.listeCompteTiers.push({
            numero: null,
            libelle: this.translateService.instant('admin.comptabilite.planComptable.parametres.aucun')
        });
        this.listeCompteTaxe.push({
            numero: null,
            libelle: this.translateService.instant('admin.comptabilite.planComptable.parametres.aucun')
        });
        this.listeComptePerte.push({
            numero: null,
            libelle: this.translateService.instant('admin.comptabilite.planComptable.parametres.aucun')
        });
        this.listeCompteGain.push({
            numero: null,
            libelle: this.translateService.instant('admin.comptabilite.planComptable.parametres.aucun')
        });

        //Récupération de l'ensemble des comptes actifs
        this.planComptableService.getListeComptes().subscribe((listeCompte: Compte[]) => {
            listeCompte.forEach((compte: Compte) => {
                let newCompte = new Compte(compte);

                let optionCompte: OptionCompte = {
                    numero: newCompte.numero,
                    libelle: newCompte.libelleForDisplay
                }

                switch (newCompte.type) {
                    case TypeCompte.COMPTE_TIERS:
                        this.listeCompteTiers.push(optionCompte);
                        break;
                    case TypeCompte.COMPTE_TAXE:
                        this.listeCompteTaxe.push(optionCompte);
                        break;
                    case TypeCompte.PERTE_GAIN_CHANGE:
                        this.listeCompteGain.push(optionCompte);
                        this.listeComptePerte.push(optionCompte);
                        break;
                    case TypeCompte.NON_DEFINI:
                        this.listeCompteTiers.push(optionCompte);
                        this.listeCompteTaxe.push(optionCompte);
                        this.listeCompteGain.push(optionCompte);
                        this.listeComptePerte.push(optionCompte);
                        break;
                }
            });
        });
    }

    /**
     * Définition des jours de congé et jours fériés
     */
    buildListesCongesFeriesFromString() {
        //Séparation des congés et des jours fériés
        let listeCongesFeries: string[] = this.config.congesFeries.split(',');

        //Initialisation de la liste des jours de la semaine
        this.listeJours = new Array<Jour>({
            id: '1',
            isConge: false
        }, {
            id: '2',
            isConge: false
        }, {
            id: '3',
            isConge: false
        }, {
            id: '4',
            isConge: false
        }, {
            id: '5',
            isConge: false
        }, {
            id: '6',
            isConge: false
        }, {
            id: '7',
            isConge: false
        });

        //Définition des jours de congé
        this.listeJoursInit = listeCongesFeries[0];
        if (listeCongesFeries[0].trim() != '') {
            listeCongesFeries[0].trim().split(' ').forEach(jour => {
                this.listeJours[parseInt(jour) - 1].isConge = true;
            })
        }

        //Définition de la liste des jours fériés
        this.listeJoursFeries = listeCongesFeries[1] != '' ? listeCongesFeries[1].trim().split(' ') : [];

        //Définition du nombre de jours fériés pour le libellé
        this.nombreJoursFeries = this.listeJoursFeries.length;
    }


    /**
     * Ouvre la popin pour afficher/modifier les jours fériés fixes
     */
    openDatesFixes() {
        //Ouverture de la boîte de dialogue
        this.matDialog.open(DatesFixesPopinComponent, {
            data: {
                listeJoursFeries: this.listeJoursFeries,
                congesFeries: this.listeJoursInit
            }
        }).afterClosed().subscribe({
            next: (dialogResult: {listeJoursFeries: string[]}) => {
                //Si on doit mettre à jour la liste
                if (dialogResult?.listeJoursFeries) {
                    //Mise à jour de la liste
                    this.listeJoursFeries = dialogResult.listeJoursFeries;
                    this.nombreJoursFeries = this.listeJoursFeries.length;
                }
            }
        });
    }

    /**
     * Construit la string congesFeries à partir de la liste des jours de congé et la liste des jours fériés
     */
    buildCongesFeriesToString() {
        //Remise à zéro de congesFeries
        this.config.congesFeries = '';

        //Parcours des jours de congé
        this.listeJours.forEach(jour => {
            //Ajout des jours sélectionnés en tant que jour de congé
            if (jour.isConge) {
                this.config.congesFeries = this.config.congesFeries + ' ' + jour.id;
            }
        });

        //Séparation entre les congés et les jours fériés
        this.config.congesFeries = this.config.congesFeries + ',';

        //Parcours de la liste des jours fériés
        this.listeJoursFeries.forEach(date => {
            //Ajout de chaque jour férié
            this.config.congesFeries = this.config.congesFeries + ' ' + date;
        });
    }

    /**
     * Sauvegarde des paramètres
     */
    saveConfig() {
        //Début de l'enregistrement
        this.isPending = true;

        //Construction de la liste
        this.buildCongesFeriesToString();

        //Enregistrement des paramètres
        this.planComptableService.saveParametres(this.config)
            .pipe(first(), finalize(() => this.isPending = false))
            .subscribe(result => {
                if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
                    //Toast de succès
                    this.toastrService.success(this.translateService.instant('global.success.enregistrement'));
                } else if (result.codeErreur === TypeCodeErreur.ERROR_SAVE) {
                    //Toast d'erreur
                    this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
                }
            });
    }
}
