import { Component } from "@angular/core";

/**
 * Onglet abattements
 *
 * @author Guillaume TRAVOT
 * @date 26/04/2024
 */
@Component({
    host: {'data-test-id': 'indemnites-abattements'},
    templateUrl: './indemnites-abattements.component.html'
})
export class IndemnitesAbattementsComponent {}
