import {ListItem} from "../common/list-view/list-item";
import {UserDto} from "../user/user-dto";
import {Statut} from "../workflow/statut";
import {Devise} from "../settings/devise";
import {ListeAlertes} from "../common/alerte/listeAlertes";
import {TypeFact} from "../facture/type-fact";
import {TypeEntite} from "@domain/typeentite/typeEntite";
import {ReleveFacture} from "@domain/facture/releve-facture";
import {StatutArchivage} from "./statut-archivage";

/**
 * Représente un lot avec le minimum d'informations pour être affiché dans la liste des lots
 */
export class LotListItem implements ListItem {
    idLot: number;
    periode: Periode;
    libelle: string;
    date: Date;
    montant: number;
    nbNdf: number;
    nbFacture: number;
    nbAvance: number;
    dateArchivage: Date;
    statutArchivage: StatutArchivage;
    nbPJArchivees: number;
    nbPJTotal: number;

    /** Liste des alertes */
    listeAlertes: ListeAlertes;

    getKey(): number {
        return this.idLot;
    }
}

/**
 * Representation des lots comptables
 */
export class Lot extends LotListItem {
    montantNdf: number;
    montantOpeCarte: number;
    montantFactures: number;
    montantAvoirs: number;
    montantAvance: number;
    montantFacturesAvoirs: number;
    user: UserDto;
    listeNotes: number[];
    listeAvances: number[];
    listeFactures: number[];

    /**
     * Recalcule tous les montants du lot
     *
     * @param listeNdf      Liste des NDF du lot
     * @param listeAvances  Liste des Avances du lot
     * @param listeFactures Liste des Factures du lot
     */
    public computeMontants(listeNdf: LotNdfDTO[], listeAvances: LotAvanceDTO[], listeFactures: LotFactureDTO[]): void {
        this.montantNdf = listeNdf?.reduce((sum, ndf) => sum + ndf.montantRemboursable / ndf.cours, 0) || 0;
        this.montantFactures = listeFactures?.filter(facture => facture.type === TypeFact.FACTURE).reduce((sum, facture) => sum + facture.contrepartie, 0) || 0;
        this.montantAvoirs = listeFactures?.filter(facture => facture.type !== TypeFact.FACTURE).reduce((sum, avoir) => sum + avoir.contrepartie, 0) || 0;
        this.montantAvance = listeAvances?.reduce((sum, avance) => sum + (avance.montant + (avance.montantLettre ? avance.montantLettre : 0)) * avance.taux, 0) || 0;
        this.montantFacturesAvoirs = this.montantFactures - this.montantAvoirs;

        this.montant = this.montantNdf + this.montantAvance + this.montantFacturesAvoirs;
    }

    constructor() {
        super();

        this.date = new Date();
        this.dateArchivage = null;
        this.idLot = 0;
        this.libelle = null;
        this.listeAvances = [];
        this.listeFactures = [];
        this.listeNotes = [];
        this.montant = 0;
        this.montantAvance = 0;
        this.montantAvoirs = 0;
        this.montantFactures = 0;
        this.montantFacturesAvoirs = 0;
        this.montantNdf = 0;
        this.montantOpeCarte = 0;
        this.nbAvance = 0;
        this.nbFacture = 0;
        this.nbNdf = 0;
        this.periode = null;
        this.statutArchivage = null;
        this.user = null;
    }
}

/**
 * Classe parent des objets de type NDF / Facture / Avance contenus dans le lot
 */
export abstract class LotItemDTO implements ListItem {
    lot: Lot;

    abstract getKey(): number | string;

    abstract getIdObjetWF(): number;

    isSelected: boolean;

    /**
     * Constructeur
     * @param dto DTO (facultatif)
     */
    constructor(dto?: any) {
        //Vérification du passage d'un DTO
        if (dto) {
            //Copie des données dans l'objet
            Object.assign(this,dto);
        }
	}
}

/**
 * Classe représentant les NDF pour les lots comptables
 */
export class LotNdfDTO extends LotItemDTO {
    //Element de compat pour les anciennes listes wf
    idObjet?: number;
    idNDF: number;
    numeroPiece: string;
    user: UserDto;
    objet: string;
    od: LotOdDTO;
    devise: Devise;
    cours: number;
    montantRemboursable: number;
    montantARembourser: number;
    montantEntreprise: number;
    modeRemboursement: any;
    isSelected: boolean;
	statut: Statut;
	typeEntite: TypeEntite;
	dateMaj: Date;
	listeAlertes: ListeAlertes;
    statutArchivage: StatutArchivage;

    /**
     * Constructeur
     * @param dto DTO (facultatif)
     */
	constructor(dto?: any) {
		super(dto);
	}

    getKey(): number {
        return this.getIdObjetWF();
    }

    getIdObjetWF(): number {
        return this.idNDF;
    }
}

/**
 * Classe représentant les factures pour les lots comptables
 */
export class LotFactureDTO extends LotItemDTO {
    //Element de compat pour les anciennes listes wf
    idObjet?: number;
    idFacture: number;
    type: string;
    numero: string;
    devise: string;
    user: UserDto;
    montant: number;
    contrepartie: number;
    listeLiensFacOD: LienFacOdDTO[];
    isSelected: boolean;
    statut: Statut;
    listeAlertes: ListeAlertes;
    factureReleve: ReleveFacture;

    /**
     * Constructeur
     * @param dto DTO (facultatif)
     */
	constructor(dto?: any) {
		super(dto);
	}

    getKey(): number {
        return this.getIdObjetWF();
    }

    getIdObjetWF(): number {
        return this.idFacture;
    }
}

/**
 * Classe représentant les avances pour les lots comptables
 */
export class LotAvanceDTO extends LotItemDTO {
    //Element de compta pour les anciennes listes wf
    idObjet?: number;
    idAvance: number;
    libelleObjet: string;
    user: UserDto;
    od: LotOdDTO;
    montant: number;
    montantLettre: number;
    taux: number;
    devise: Devise;
    nature: string;
    isSelected: boolean;
    type: string;
    statut: Statut;
    listeAlertes: ListeAlertes;

    /**
     * Constructeur
     * @param dto DTO (facultatif)
     */
	constructor(dto?: any) {
		super(dto);
	}

    getKey(): number {
        return this.getIdObjetWF();
    }

    getIdObjetWF(): number {
        return this.idAvance;
    }
}

/**
 * Représentation des liens entre factures et od
 */
export interface LienFacOdDTO {
    idOd: number;
}

/**
 * Représentation des od pour les sous objet lot
 */
export interface LotOdDTO {
    idOd: number;
}

/**
 * Représentation des périodes pour les lots comptables
 */
export interface Periode {
    idPeriode: number; //Identifiant unique de la période
    exercice: Exercice;
    dateDebut: Date;
    dateFin: Date;
    libelle: string; //Période comptable
    isClosed: boolean;
    dateCloture: Date;
    nbObjetsOpened: number;
}

/**
 * Représentation des exercices pour les périodes pour les lots comptables
 */
export interface Exercice {
    idExercice: number;
    libelle: string;
    dateDebut: Date;
    dateFin: Date;
    isClosed: boolean;
    nbPeriodes: number;
    dateCloture: Date;
    prorataTaxe: number;
    listePeriode: Periode[];
    nbObjetsOpened: number;
    isLast: boolean;
}

/**
 * Enumeration pour les choix d'item dans la popup d'ajout
 */
 export enum LotItemType {
    NDF,
    FACTURE,
    AVANCE,
    RELEVE
}
