import {NgModule} from "@angular/core";
import {Routes} from "@angular/router";
import {ShareModule} from "@share/share.module";
import {AdminGuardProvider} from "@core/security/admin-guard.provider";
import {AdminParametreComponent} from "./admin-parametre.component";
import {DematModule,parametreDematRoutes} from "@components/admin/parametre/demat/demat.module";
import {DematComponent} from "@components/admin/parametre/demat/demat.component";
import {DroitAdmin} from "@core/security/droit-admin";
import {ParametreApiComponent} from "@components/admin/parametre/api/parametre-api.component";
import {ParametreApiModule,parametreApiRoutes} from "@components/admin/parametre/api/parametre-api.module";
import {PersonnalisationComponent} from "@components/admin/parametre/personnalisation/personnalisation.component";
import {ParametreAuthentificationComponent} from "@components/admin/parametre/authentification/parametre-authentification.component";
import {ParametreAuthentificationModule,parametreAuthentificationRoutes} from "@components/admin/parametre/authentification/parametre-authentification.module";
import {ParametreMobiliteModule,parametreMobiliteRoutes} from "@components/admin/parametre/mobilite/parametre-mobilite.module";
import {ParametreMobiliteComponent} from "@components/admin/parametre/mobilite/parametre-mobilite.component";
import {ParametreOrdonnanceurModule,parametreOrdonnanceurRoutes} from "@components/admin/parametre/ordonnanceur/parametre-ordonnanceur.module";
import {ParametreOrdonnanceurComponent} from "@components/admin/parametre/ordonnanceur/parametre-ordonnanceur.component";
import {ParametrePersonnalisationModule,parametrePersonnalisationRoutes} from "@components/admin/parametre/personnalisation/parametre-personnalisation.module";

export const adminParametreRoutes: Routes = [
	{
		path: 'API',
		component: ParametreApiComponent,
		canActivate: [AdminGuardProvider],
		children: parametreApiRoutes
	},{
		path: 'Authentification',
		component: ParametreAuthentificationComponent,
		canActivate: [AdminGuardProvider],
		children: parametreAuthentificationRoutes
	},{
		path: 'Demat',
		component: DematComponent,
		children: parametreDematRoutes,
		canActivate: [AdminGuardProvider],
		data: { sousAdminCredentials: [DroitAdmin.DROIT_DEMATERIALISATION] }
	},{
		path: 'Mobilite',
		component: ParametreMobiliteComponent,
		canActivate: [AdminGuardProvider],
		children: parametreMobiliteRoutes
	},{
		path: 'Ordonnanceur',
		component: ParametreOrdonnanceurComponent,
		canActivate: [AdminGuardProvider],
		children: parametreOrdonnanceurRoutes
	},{
		path: 'Personnalisation',
		component: PersonnalisationComponent,
		canActivate: [AdminGuardProvider],
		children:parametrePersonnalisationRoutes
	},{
		path: '',
		redirectTo: 'Demat',
		pathMatch: 'full'
	},{
		path: '**',
		redirectTo: 'Demat'
	}
];

/**
 * Module du menu d'administration des paramètres.
 */
@NgModule({
	imports: [
		ShareModule,
		DematModule,
		ParametreApiModule,
		ParametreAuthentificationModule,
		ParametreMobiliteModule,
		ParametreOrdonnanceurModule,
		ParametrePersonnalisationModule
	],
	declarations: [
		AdminParametreComponent,
		PersonnalisationComponent
	],
	entryComponents: [],
	exports: [
		AdminParametreComponent
	],
	providers: []
})
export class AdminParametreModule {
}
