import {Component,OnDestroy,OnInit} from '@angular/core';
import {PageHeaderItem} from "@share/component/page-header/page-header";
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute,Router,Routes} from "@angular/router";
import {EntrepriseUtilisateursService} from "@components/admin/entreprise/utilisateurs/entreprise-utilisateurs.service";
import {finalize,first} from "rxjs/operators";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {ToastrService} from "ngx-toastr";
import {TypeGenre,TypeProfil,User} from "@domain/user/user";
import * as moment from "moment";
import {DroitAdmin} from "@core/security/droit-admin";
import {AdminGuardProvider} from "@core/security/admin-guard.provider";
import {UserGeneralitesComponent} from "@components/admin/entreprise/utilisateurs/user-detail/tabs/user-generalites/user-generalites.component";
import {UserMetierComponent} from "@components/admin/entreprise/utilisateurs/user-detail/tabs/user-metier/user-metier.component";
import {UserProfilComponent} from "@components/admin/entreprise/utilisateurs/user-detail/tabs/user-profil/user-profil.component";
import {UserInfosBancairesComponent} from "@components/admin/entreprise/utilisateurs/user-detail/tabs/user-infos-bancaires/user-infos-bancaires.component";
import {UserVehiculesComponent} from "@components/admin/entreprise/utilisateurs/user-detail/tabs/user-vehicules/user-vehicules.component";
import {UserMobiliteComponent} from "@components/admin/entreprise/utilisateurs/user-detail/tabs/user-mobilite/user-mobilite.component";
import {UserFiltresComponent} from "@components/admin/entreprise/utilisateurs/user-detail/tabs/user-filtres/user-filtres.component";
import {Store} from "@ngrx/store";
import {AppState} from "@domain/appstate";
import {Session} from "@domain/security/session";
import {UserHistoriqueComponent} from "@components/admin/entreprise/utilisateurs/user-detail/tabs/user-historique/user-historique.component";
import {Subscription} from "rxjs";
import {filterFirstNotNull} from "@share/utils/rxjs-custom-operator";
import {isRouteAllowed} from "@core/security/role-admin-helpers";

export const userDetailRoutes: Routes = [
	{
		path: 'Generalites',
		component: UserGeneralitesComponent,
		canActivate: [AdminGuardProvider],
		//L'onglet "Généralités" est affiché dès que l'utilisateur possède au moins un des droits "utilisateur"
		data: {
			sousAdminCredentials: [
				DroitAdmin.DROIT_USER_GESTION,
				DroitAdmin.DROIT_USER_ANALYTIQUE,
				DroitAdmin.DROIT_HABILITATION_GESTION,
				DroitAdmin.DROIT_PROFIL_VOYAGEUR_GESTION,
				DroitAdmin.DROIT_PV_CONTACT_URGENCE,
				DroitAdmin.DROIT_PV_GESTION_DOCUMENT,
				DroitAdmin.DROIT_PV_CARTES_ABONNEMENT,
				DroitAdmin.DROIT_MOBILE_GESTION,
				DroitAdmin.DROIT_USER_INFOS_BANCAIRES,
				DroitAdmin.DROIT_VEHICULE_GESTION
			]
		}
	},{
		path: 'Metier',
		component: UserMetierComponent,
		canActivate: [AdminGuardProvider],
		data: {
			sousAdminCredentials: [
				DroitAdmin.DROIT_USER_GESTION,
				DroitAdmin.DROIT_USER_ANALYTIQUE
			]
		}
	},{
		path: 'Profil',
		component: UserProfilComponent,
		canActivate: [AdminGuardProvider],
		data: {
			sousAdminCredentials: [
				DroitAdmin.DROIT_PROFIL_VOYAGEUR_GESTION,
				DroitAdmin.DROIT_PV_CONTACT_URGENCE,
				DroitAdmin.DROIT_PV_GESTION_DOCUMENT,
				DroitAdmin.DROIT_PV_CARTES_ABONNEMENT
			]
		}
	},{
		path: 'InfosBancaires',
		component: UserInfosBancairesComponent,
		canActivate: [AdminGuardProvider],
		data: {
			sousAdminCredentials: [
				DroitAdmin.DROIT_USER_INFOS_BANCAIRES
			]
		}
	},{
		path: 'Vehicules',
		component: UserVehiculesComponent,
		canActivate: [AdminGuardProvider],
		data: {
			sousAdminCredentials: [
				DroitAdmin.DROIT_VEHICULE_GESTION
			]
		}
	},{
		path: 'Mobilite',
		component: UserMobiliteComponent,
		canActivate: [AdminGuardProvider],
		data: {
			sousAdminCredentials: [
				DroitAdmin.DROIT_MOBILE_GESTION
			]
		}
	},{
		path: 'Filtres',
		component: UserFiltresComponent,
		canActivate: [AdminGuardProvider],
		data: { sousAdminCredentials: [DroitAdmin.DROIT_USER_GESTION] }
	},{
		path: 'Historique',
		component: UserHistoriqueComponent,
		canActivate: [AdminGuardProvider],
		data: {
			sousAdminCredentials: [
				DroitAdmin.DROIT_USER_GESTION,
			]
		}
	},{
		path: '**',
		redirectTo: ''
	}
];

/**
 * Onglets de la page de consultation d'un utilisateur
 */
export enum Onglets {
	GENERALITES = 'GENERALITES',
	METIER = 'METIER',
	PROFIL = 'PROFIL',
	INFORMATIONS_BANCAIRES = 'INFORMATIONS_BANCAIRES',
	VEHICULES = 'VEHICULES',
	MOBILITE = 'MOBILITE',
	FILTRES = 'FILTRES',
	HISTORIQUE = 'HISTORIQUE'
}

/**
 * Détail d'un utilisateur
 */
@Component({
	host: {'data-test-id': 'user-detail'},
	selector: 'user-detail',
	templateUrl: './user-detail.component.html'
})
export class UserDetailComponent implements OnInit,OnDestroy {
	/** Liste des onglets */
	listeTabItems: Array<PageHeaderItem> = [
		{
			code: Onglets.GENERALITES,
			libelle: this.translateService.instant('admin.entreprise.utilisateurs.detail.tabs.generalites'),
			url: 'Admin/Entreprise/Utilisateurs/User/:idUser/Generalites'
		}
	];

	/** Onglet courant */
	_selectedItem: PageHeaderItem;

	/** Getter de l'onglet courant */
	get selectedItem(): PageHeaderItem {
		return this._selectedItem;
	}

	/** Setter de l'onglet courant (on le surcharge pour gérer le chargement des onglets) */
	set selectedItem(item: PageHeaderItem) {
		//Édition de la variable privée
		this._selectedItem = item;

		//Bascule du statut de chargement de l'onglet sélectionné
		switch (item.code) {
			case Onglets.GENERALITES:
				this.isGeneralitesLoaded = true;
				break;
			case Onglets.METIER:
				this.isMetierLoaded = true;
				break;
			case Onglets.PROFIL:
				this.isProfilLoaded = true;
				break;
			case Onglets.INFORMATIONS_BANCAIRES:
				this.isInfosBancairesLoaded = true;
				break;
			case Onglets.VEHICULES:
				this.isVehiculesLoaded = true;
				break;
			case Onglets.MOBILITE:
				this.isMobiliteLoaded = true;
				break;
			case Onglets.FILTRES:
				this.isFiltresLoaded = true;
				break;
			case Onglets.HISTORIQUE:
				this.isHistoriqueLoaded = true;
				break;
		}
	}

	/** Index de l'onglet courant */
	selectedIndex: number;

	/** Code de l'onglet à afficher au chargement de l'écran */
	onLoadTab: string;

	/** Utilisateur consulté (dont le profil est visualisé sur la page) */
	userConsulte: User;

	/** L'utilisateur a-t-il une ligne dans ns_collab */
	isCollabInit: boolean;

	/** Session */
	session: Session;

	/** Flag création / consultation */
	isCreation: boolean = true;

	/** Indicateur de chargement */
	isLoading: boolean = false;

	/** Onglets pour le DOM */
	Onglet = Onglets;

	/** Indicateur de chargement de l'onglet "GÉNÉRALITÉS" */
	isGeneralitesLoaded: boolean;

	/** Indicateur de chargement de l'onglet "MÉTIER" */
	isMetierLoaded: boolean;

	/** Indicateur de chargement de l'onglet "PROFIL" */
	isProfilLoaded: boolean;

	/** Indicateur de chargement de l'onglet "INFORMATIONS BANCAIRES" */
	isInfosBancairesLoaded: boolean;

	/** Indicateur de chargement de l'onglet "VÉHICULES" */
	isVehiculesLoaded: boolean;

	/** Indicateur de chargement de l'onglet "MOBILITÉ" */
	isMobiliteLoaded: boolean;

	/** Indicateur de chargement de l'onglet "FILTRES" */
	isFiltresLoaded: boolean;

	/** Indicateur de chargement de l'onglet "HISTORIQUE" */
	isHistoriqueLoaded: boolean;

	/** Rôles sous-admin */
	RoleAdmin = DroitAdmin;

	/** Souscription aux observables */
	listeSubscription: Array<Subscription> = new Array<Subscription>();

	/**
	 * Constructeur
	 *
	 * @param translateService le moteur de traduction
	 * @param activatedRoute la route actuelle
	 * @param userService le service de gestion de l'utilisateur
	 * @param toastrService le service du Toastr
	 * @param store le store
	 * @param router le routeur Angular
	 */
	constructor(
		private translateService: TranslateService,
		private activatedRoute: ActivatedRoute,
		private userService: EntrepriseUtilisateursService,
		private toastrService: ToastrService,
		private store: Store<AppState>,
		private router: Router
	) {
		//Récupération du state de la route courante
		const state = this.router.getCurrentNavigation().extras?.state;

		//Vérification de la présence d'un state sur la route
		if (state) {
			//Récupération de l'onglet à charger
			this.onLoadTab = state.tabToLoad;
		}
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Récupération de l'ID utilisateur dans la route
		this.listeSubscription.push(this.activatedRoute.params.subscribe(params => {
			//Initialisation de l'écran
			this.onInit(params['idUser']);
		}));
	}

	/**
	 * Initialisation de l'écran à partir de l'idUser
	 *
	 * @param idUser l'identifiant de l'utilisateur consulté
	 */
	onInit(idUser: number): void {
		//Récupération de la session
		this.store.select(state => state.session).pipe(filterFirstNotNull()).subscribe((session: Session) => {
			//Stockage de la session
			this.session = session;

			//On regarde si c'est une création ou une consultation
			if (idUser > 0) {
				//C'est une consultation
				this.isCreation = false;

				//Chargement de l'utilisateur
				this.refreshUser(idUser);
			} else {
				//On initialise un nouvel utilisateur
				this.userConsulte = new User();

				//Valeurs par défaut
				this.userConsulte.idUser = 0;
				this.userConsulte.genre = TypeGenre.MONSIEUR;
				this.userConsulte.actif = true;
			}
		});
	}

	/**
	 * Rafraichissement de l'utilisateur
	 */
	refreshUser(idUser?: number): void {
		//Si l'idUser n'est pas renseigné, c'est que c'est un rafraîchissement
		if (!idUser) {
			//Récupération de l'idUser
			idUser = this.userConsulte.idUser;
		}

		//Suppression de l'utilisateur courant
		this.userConsulte = null;

		//Début du chargement
		this.isLoading = true;

		//Appel au service
		this.userService.loadUser(idUser)
			.pipe(first(),finalize(() => this.isLoading = false))
			.subscribe((result: Result) => {
				//Vérification du résultat
				if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Récupération de l'utilisateur
					this.userConsulte = result.data.user;

					//On regarde si l'utilisateur a une ligne dans ns_collab
					this.isCollabInit = result.data.isCollabInit;

					if (this.userConsulte.passDate) {
						//Set de la date d'expiration du mdp
						this.userConsulte.passExpirationDate = moment(this.userConsulte.passDate).add(result.data.passPeremptionNbJour,'days').toDate();
					}

					//Filtrage des onglets autorisés (utilisateur consulté)
					this.refreshTabs();
				} else {
					TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
				}
			});
	}

	/**
	 * Rafraîchissement des onglets affichés (ATTENTION à la confusion entre utilisateur connecté et consulté)
	 */
	refreshTabs(): void {
		/**
		 * PREMIÈRE ÉTAPE : Ajout des onglets autorisés en fonction de l'utilisateur consulté
		 */

		//Stockage dans une variable temporaire des onglets à afficher
		const listeTabItems: Array<PageHeaderItem> = [
			{
				code: Onglets.GENERALITES,
				libelle: this.translateService.instant('admin.entreprise.utilisateurs.detail.tabs.generalites'),
				url: 'Admin/Entreprise/Utilisateurs/User/:idUser/Generalites'
			}
		];

		//Si l'utilisateur consulté a une habilitation de collaborateur
		if (this.userConsulte.listeLienRoleUsers
			.filter(lru => !lru.dateFin || moment(lru.dateFin).isSameOrAfter(moment(),'day'))
			.some(lru => lru.role.fonction === TypeProfil.COLLABORATEUR)) {

			//On ajoute tous les onglets visibles uniquement pour les users (consultés) collab
			listeTabItems.push(
				{
					code: Onglets.METIER,
					libelle: this.translateService.instant('admin.entreprise.utilisateurs.detail.tabs.metier'),
					url: 'Admin/Entreprise/Utilisateurs/User/:idUser/Metier'
				},{
					code: Onglets.PROFIL,
					libelle: this.translateService.instant('admin.entreprise.utilisateurs.detail.tabs.profil'),
					url: 'Admin/Entreprise/Utilisateurs/User/:idUser/Profil'
				},{
					code: Onglets.INFORMATIONS_BANCAIRES,
					libelle: this.translateService.instant('admin.entreprise.utilisateurs.detail.tabs.infosBancaires'),
					url: 'Admin/Entreprise/Utilisateurs/User/:idUser/InfosBancaires'
				},{
					code: Onglets.VEHICULES,
					libelle: this.translateService.instant('admin.entreprise.utilisateurs.detail.tabs.vehicules'),
					url: 'Admin/Entreprise/Utilisateurs/User/:idUser/Vehicules'
				},{
					code: Onglets.MOBILITE,
					libelle: this.translateService.instant('admin.entreprise.utilisateurs.detail.tabs.mobilite'),
					url: 'Admin/Entreprise/Utilisateurs/User/:idUser/Mobilite'
				}
			);
		}

		//Si l'utilisateur consulté a une habilitation de collaborateur, fournisseur ou responsable
		if (this.userConsulte.listeLienRoleUsers
			.filter(lru => !lru.dateFin || moment(lru.dateFin).isSameOrAfter(moment(),'day'))
			.some(lru => lru.role.fonction === TypeProfil.COLLABORATEUR || lru.role.fonction === TypeProfil.FOURNISSEUR || lru.role.fonction === TypeProfil.RESPONSABLE)) {

			//On ajoute l'onglet Filtres uniquement pour les users (consultés) collaborateur, fournisseur ou responsable
			listeTabItems.push(
				{
					code: Onglets.FILTRES,
					libelle: this.translateService.instant('admin.entreprise.utilisateurs.detail.tabs.filtres'),
					url: 'Admin/Entreprise/Utilisateurs/User/:idUser/Filtres'
				}
			);
		}

		//Ajout de l'onglet historique
		listeTabItems.push(
			{
				code: Onglets.HISTORIQUE,
				libelle: this.translateService.instant('admin.entreprise.utilisateurs.detail.tabs.historique'),
				url: 'Admin/Entreprise/Utilisateurs/User/:idUser/Historique'
			}
		);

		//Activation d'un onglet particulier au chargement de la page
		if (this.onLoadTab) {
			this.onSelectedItemChange(listeTabItems.find(value => value.code === this.onLoadTab));

			//Réinitialisation de onLoadTab
			delete this.onLoadTab;
		}

		/**
		 * DEUXIÈME ÉTAPE : Filtrage des onglets autorisés par les droits de l'utilisateur connecté (Admin ou Sous-Admin)
		 */

		//Transfert de la liste d'onglets temporaire vers la liste effective pour la page après filtrage basé sur les droits de l'utilisateur connecté
		this.listeTabItems = listeTabItems.filter(tab => isRouteAllowed(this.router,this.session,tab.url));

		//On initialise l'index des onglets
		this.selectedIndex = this.listeTabItems.findIndex(i => i.code === this.selectedItem.code);
	}

	/**
	 * Changement d'onglet
	 *
	 * @param item l'onglet sélectionné
	 */
	onSelectedItemChange(item: PageHeaderItem) {
		//Mise à jour de l'indicateur d'activation pour chaque onglet
		this.listeTabItems?.forEach(tab => {
			tab.selected = tab === item;
		});

		//Mise à jour de l'onglet sélectionnée
		item.selected = true;
		this.selectedItem = item;
		this.selectedIndex = this.listeTabItems.findIndex(i => i.code === item.code);
	}

	/**
	 * Retour à la liste
	 */
	onGoBack(): void {
		//Retour à la liste
		this.router.navigate(['Admin/Entreprise/Utilisateurs/Internes']);
	}

	/**
	 * Autorisation d'une carte spécifique
	 *
	 * @param roleAdmin rôle sous-admin à vérifier
	 */
	isCardAllowed(roleAdmin: DroitAdmin): boolean {
		//L'admin a tous les droits, sinon vérification du droit
		return this.session?.isAdmin || this.session?.user?.listeDroits?.includes(roleAdmin);
	}

	/**
	 * Destruction du composant
	 */
	ngOnDestroy(): void {
		//Désouscription des observables
		this.listeSubscription.forEach(s => s.unsubscribe());
	}
}
