import {Component,Input,OnInit} from '@angular/core';
import {MatDialog,MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {CguService} from '@components/cgu/cgu.service';
import {ODCreationComponent} from '@components/od/creation/od-creation.component';
import {OdListItemComponent} from '@components/od/od-list/od-list-item/od-list-item.component';
import {ODService} from '@components/od/od.service';
import {AbstractWorkflowListComponent} from '@components/workflow/abstract-workflow-list.component';
import {WorkflowService} from '@components/workflow/workflow.service';
import {AppState} from '@domain/appstate';
import {TypeComparaison,TypeFilter} from '@domain/common/list-view';
import {SessionStorageService} from '@domain/common/services/session-storage.service';
import {ListeOd} from '@domain/od/liste-od';
import {TypePortee} from '@domain/workflow/workflow';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {PleaseWaitDialogComponent} from '@share/component/please-wait/please-wait-dialog.component';
import {PleaseWaitService} from '@share/component/please-wait/please-wait.service';
import {ToastrService} from 'ngx-toastr';
import {UserDto} from "@domain/user/user-dto";
import {Result,TypeCodeErreur} from "@domain/common/http/result";

@Component({
	selector: 'od-list',
	host: {'data-test-id': 'od-list'},
	templateUrl: './od-list.component.html'
})
export class OdListComponent extends AbstractWorkflowListComponent<ListeOd,OdListItemComponent> implements OnInit {

	/** Gestion des actions dans le composant parent */
	@Input() remoteActions: boolean = false;

	/**
	 * Constructeur
	 */
	constructor(
		protected router: Router,
		protected matDialog: MatDialog,
		protected pleaseWaitService: PleaseWaitService,
		protected odService: ODService,
		protected translateService: TranslateService,
		protected store: Store<AppState>,
		protected workflowService: WorkflowService,
		protected sessionStorageService: SessionStorageService,
		protected cguService: CguService,
		protected toastrService: ToastrService
	) {
		//Constructeur parent
		super(
			translateService,
			store,
			workflowService,
			matDialog,
			TypePortee.OD,
			'/controller/OD',
			'od.liste.title',
			'-idOd',
			'OdListComponent',
			OdListItemComponent,
			sessionStorageService,
			cguService,
			toastrService
		);
	}

	/**
	 * Spécificités de la liste héritière
	 *
	 * @param initOnlyColumns True si on veut initialiser uniquement les colonnes de la liste (false par défaut)
	 */
	protected initListSpecific(initOnlyColumns?: boolean): void {
		//Définition des colonnes de tri
		this.liste.columns = [
			{key: 'idOd',title: 'od.liste.columns.idOd'},
			{key: 'objet',title: 'od.liste.columns.objet'},
			{key: 'idOmPermanent',title: 'od.liste.columns.idOmPermanent'},
			{key: 'user.matricule',title: 'od.liste.columns.user.matricule'},
			{key: 'typeEntite.idTypeEntite',title: 'od.liste.columns.typeEntite'},
			{key: 'statut.idStatut',title: 'od.liste.columns.statut'}
		];

		//Si on ne veut pas initialiser que les colonnes
		if (!initOnlyColumns) {
			//Ajout des filtres spécifiques
			this.liste.listeFilters = [
				...[
					{
						//Ajout du filtre sur l'objet
						clef: 'objet',
						title: this.translateService.instant('od.liste.filtres.objet'),
						isDefault: true,
						typeComparaison: TypeComparaison[TypeComparaison.LIKE]
					},{
						//Ajout du filtre sur le numéro
						clef: 'idOd',
						title: this.translateService.instant('od.liste.filtres.id'),
						isDefault: false,
						type: TypeFilter[TypeFilter.LONG],
						typeComparaison: TypeComparaison[TypeComparaison.EQUAL]
					},{
						//Ajout du filtre sur l'OMP
						clef: 'idOmPermanent',
						title: this.translateService.instant('od.liste.filtres.omp'),
						isDefault: false,
						type: TypeFilter[TypeFilter.LONG],
						typeComparaison: TypeComparaison[TypeComparaison.EQUAL]
					},{
						//Ajout du filtre sur la date de départ
						clef: 'depart_le',
						title: this.translateService.instant('od.liste.filtres.depart_le'),
						type: TypeFilter[TypeFilter.DATE]
					},{
						//Ajout du filtre sur la date de retour
						clef: 'retour_le',
						title: this.translateService.instant('od.liste.filtres.retour_le'),
						type: TypeFilter[TypeFilter.DATE]
					},{
						//Ajout du filtre sur le matricule
						clef: 'user.matricule',
						title: this.translateService.instant('od.liste.filtres.matricule'),
						isDefault: true,
						typeComparaison: TypeComparaison[TypeComparaison.LIKE]
					},{
						//Ajout du filtre sur la destination
						clef: 'pays.libelle,*ville.libelle',
						title: this.translateService.instant('od.liste.filtres.lieu'),
						isDefault: true,
						typeComparaison: TypeComparaison[TypeComparaison.LIKE]
					}
				],
				...this.liste.listeFilters
			];
		}
	}

	/**
	 * Création d'un objet
	 *
	 * @param collab Collab pour lequel on crée l'objet WF (cas d'un assistant/responsable/comptable)
	 */
	public create(collab?: UserDto): void {
		//Affichage de la popup de création
		this.matDialog.open(ODCreationComponent,{
			data: {
				idCollab: collab?.idUser
			}
		}).afterClosed().subscribe({
			next: (result: { od?: any,typeEntite: any }) => {
				if (result) {
					let matDialogRef: MatDialogRef<PleaseWaitDialogComponent>;

					//Affichage de la popup d'attente
					matDialogRef = this.pleaseWaitService.show();

					//Création de l'OD
					this.odService.create({
						idOMPermanent: result.od?.idOMPermanent,
						idTypeEntite: result.typeEntite.idTypeEntite,
						idCollab: collab?.idUser
					}).subscribe(idOD => {
						//Vérification de la création de l'OD
						if (idOD)
							//Redirection vers l'OD créé
							this.router.navigate(['OD',idOD]);

						//Fermeture de la popup
						matDialogRef.close();
					});
				}
			}
		});
	}

	/** @inheritdoc */
	protected hideDisplayItems(displayMode: boolean) {
		//Appel au service
		this.odService.hideDisplay(this.liste.listeObjetsSectionnes.map(od => od.idOd),displayMode)
			.subscribe((result: Result) => {
				//Vérification du result
				if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Toast succès
					this.toastrService.success(this.translateService.instant(`global.success.${displayMode ? 'demasquer' : 'masquer'}`,{nb: result.data.nbItems}));

					//Refresh liste
					this.liste.refresh();
				} else {
					//Gestion de l'erreur
					TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
				}
			});
	}
}
