import {Component,EventEmitter,Input} from '@angular/core';
import {TypeProfil} from "@domain/user/user";
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {MatDialog} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {LienRoleUser} from "@domain/workflow/lienRoleUser";
import {AddHabilitationPopinComponent} from "@components/admin/entreprise/utilisateurs/user-detail/tabs/user-generalites/add-habilitation-popin/add-habilitation-popin.component";

/**
 * Composant d'affichage de la page HabilitationAccordeListItem
 *
 * @author Lionel Gimenez
 * @date 25/09/2024
 */
@Component({
    host: {'data-test-id': 'habilitation-accorde-list-item'},
    templateUrl: './delegation-list-item.component.html'
})
export class DelegationListItemComponent extends ListViewItem<LienRoleUser> {
    readonly TypeProfil = TypeProfil;

    /** Options supplémentaires de la liste */
    @Input() extraOptions: {
        userChanged: EventEmitter<void>,
        refresh: () => void,
        canModifier: boolean,
        canShowDelegue: boolean,
        canShowDeleguant: boolean,
        canChangeEnveloppe: boolean,
        titleAdd: string,
    }

    /**
     * Constructeur
     *
     * @param translateService le moteur de traduction
     * @param matDialog le gestionnaire de popup
     */
    constructor(
        public translateService: TranslateService,
        private matDialog: MatDialog
    ) {
        //Héritage
        super();
    }

    /**
     * Ouverture d'une habilitation
     *
     * @param habilitation l'habilitation
     */
    openDelegation(habilitation: LienRoleUser): void {
        this.matDialog.open(AddHabilitationPopinComponent,{
            data: {
                isCreation: false,
                habilitation: habilitation,
                isCollab: false,
                isHistory: false,
                isDelegation: this.extraOptions.canModifier,
                canChangeEnveloppe: this.extraOptions.canChangeEnveloppe,
                title: this.extraOptions.titleAdd
            },
            width: '80%'
        }).afterClosed().subscribe((result: boolean) => {
            //Vérification du résultat
            if (result) {
                //Rechargement de l'utilisateur
                this.extraOptions.userChanged?.emit();
                //On contrôle que le refresh est nécessaire
                if (this.extraOptions.refresh != null) {
                    this.extraOptions.refresh();
                }
            }
        });
    }
}