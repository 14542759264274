import {Component,OnDestroy,OnInit} from '@angular/core';
import {ActivatedRoute,Router} from "@angular/router";
import {PageHeaderItem} from '@share/component/page-header/page-header';
import {Location} from "@angular/common";
import {IComponentWithRoutedTabs} from "@domain/admin/recherche/component-with-routed-tabs";
import {Subscription} from "rxjs";

/**
 * Enum des différents onglets disponibles sur la page
 */
export enum Tab {
	LISTEOD = 'LISTEOD',
	LISTEOMP = 'LISTEOMP',
}

/**
 * Composant d'affichage du menu missions
 */
@Component({
	host: {'data-test-id': 'admin-missions'},
	templateUrl: './voyages-missions.component.html',
})
export class VoyagesMissionsComponent implements OnInit, OnDestroy, IComponentWithRoutedTabs {
	/** Liste statique des onglets */
	public static listeOnglets: Array<PageHeaderItem> = [{
		code: Tab.LISTEOD,
		libelle: 'admin.voyages.missions.listeOD',
		url: 'Admin/Voyages/Missions?tabIndex=0'
	},{
		code: Tab.LISTEOMP,
		libelle: 'admin.voyages.missions.listeOMP',
		url: 'Admin/Voyages/Missions?tabIndex=1'
	}];

	/** Etat de chargement de l'onglet od */
	isOdListLoaded: boolean = false;

	/** Etat de chargement de l'onglet omp */
	isOmpListLoaded: boolean = false;

	/** Onglets disponibles sur la page Missions */
	protected readonly Tab = Tab;

	/** Index de la page sélectionnée */
	selectedIndex: number;

	/** Souscription */
	private souscription: Subscription;

	/** Constructeur */
	constructor(private activatedRoute: ActivatedRoute,
				private location: Location,
				private router: Router) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		// Affichage de l'onglet souhaité (retour depuis od ou omp)
		this.souscription = this.activatedRoute.queryParams.subscribe(queryParams => {
			const tabIndexParam = queryParams['tabIndex'];

			if (!!tabIndexParam) {
				const newTab = this.listeTabItems[tabIndexParam];

				if (!!newTab) {
					newTab.selected = true;
					this.onSelectedItemChange(newTab);
				}
			}
		});
	}

	/** Liste des onglets */
	listeTabItems: Array<PageHeaderItem> = VoyagesMissionsComponent.listeOnglets;

	/** Onglet sélectionné */
	private _selectedItem: PageHeaderItem;

	/** Getter de l'onglet sélectionné */
	get selectedItem(): PageHeaderItem {
		return this._selectedItem;
	}

	/**
	 * Setter de l'onglet sélectionné.
	 * Il peut être utile de le surcharger pour gérer le chargement des onglets<br>
	 * <strong>ATTENTION</strong> : en cas de surcharge, il faut également surcharger le getter (standard typescript)
	 */
	set selectedItem(item: PageHeaderItem) {
		//Edition de la variable privée
		this._selectedItem = item;
	}

	/**
	 * Changement d'onglet
	 * @param selectedItem nouvel onglet sélectionné
	 */
	onSelectedItemChange(selectedItem: PageHeaderItem): void {
		//index de l'onglet sélectionné
		const index: number = this.listeTabItems.indexOf(selectedItem);

		//Mise à jour de l'onglet sélectionné
		this.selectedItem = selectedItem;

		//Si on est sur l'onglet OD
		if (selectedItem.code === Tab.LISTEOD) {
			//Onglet OD chargé
			this.isOdListLoaded = true;

			//Sélection de l'onglet OD
			this.selectedIndex = 0;
		} else if (selectedItem.code === Tab.LISTEOMP) {
			//Sinon onglet OMP chargé
			this.isOmpListLoaded = true;

			//Sélection de l'onglet OMP
			this.selectedIndex = 1;
		}

		//Mise à jour de l'url avec le bon index
		this.location.replaceState(this.router.url.split("?")[0],`?tabIndex=${index}`);
	}

	/**
	 * Destruction du composant
	 */
	ngOnDestroy() {
		//Désabonnement
		this.souscription.unsubscribe();
	}
}
