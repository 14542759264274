import {Component,OnDestroy,OnInit,ViewChild} from '@angular/core';
import {ActivatedRoute,NavigationExtras,Router,UrlSegment} from '@angular/router';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {filter,finalize,first} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {Location} from '@angular/common';
import {AppState} from '@domain/appstate';
import {TypePortee} from '@domain/workflow/workflow';
import {FloatingButtonAction,TypeAction} from '@share/component/floating-button/floating-button';
import {PageHeaderItem} from '@share/component/page-header/page-header';
import {ReleveFactureService} from './releve-facture.service';
import * as settingsActions from '../../reducers/settings';
import {SettingsNFState} from '@domain/settings/settings';
import {Result,TypeCodeErreur} from '@domain/common/http/result';
import {TypeProfil,User} from '@domain/user/user';
import {ReleveFacture} from '@domain/facture/releve-facture';
import {FactureListComponent} from './facture-list/facture-list.component';
import {ConfirmService} from '@share/component/confirmation/confirm.service';
import {Field} from '@domain/settings/field';
import {ReleveComponent} from './releve/releve.component';
import {BehaviorSubject,Subscription} from "rxjs";
import {Session} from '@domain/security/session';
import {MontantPipe} from "@share/pipe/montant";
import {filterFirstNotNull} from "@share/utils/rxjs-custom-operator";
import {Alerte,NiveauAlerte} from "@domain/common/alerte/alerte";
import {EnumEtat} from "@domain/workflow/etat";
import {ListView} from "@domain/common/list-view";
import {LotItemType,LotListItem} from "@domain/comptabilite/lot";
import {LotListItemComponent} from "@components/comptabilite/list/lot-list-item.component";
import {ComptabiliteService} from "@components/comptabilite/comptabilite.service";

@Component({
	selector: 'releve-facture',
	templateUrl: './releve-facture.component.html'
})
export class ReleveFactureComponent implements OnInit,OnDestroy {
	/** Accès à l'enum dans la vue */
	readonly NiveauAlerte = NiveauAlerte;

	/** Liste des actions possibles */
	listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

	/** Liste des onglets */
	listeTabItems: Array<PageHeaderItem>;

	/** Onglet sélectionné */
	private _selectedItem: PageHeaderItem = null;
    get selectedItem(): PageHeaderItem { return this._selectedItem; }
	set selectedItem(item: PageHeaderItem) {
		//Edition de la variable privée
		this._selectedItem = item;

		//Bascule du statut de chargement de l'onglet pièces jointes
        if (item.code === Tab.PIECES_JOINTES) { this.piecesJointesLoaded = true; }

		//Gestion de la liste des actions
		this.updateListeActions();

        //Suppression de l'onglet défini dans les paramètres le cas échéant
        this.router.navigate([], {
            queryParams: {
                'tab': null,
            },
            queryParamsHandling: 'merge',
            replaceUrl: true
        });
	}

	/** Relevé facture */
	releveFacture: ReleveFacture = null;

	/** Liste des lots comptables liés */
	listeLots: ListView<LotListItem,LotListItemComponent>;

	/** Liste des champs customs */
	listeFieldParam: Field[];

	/** Indicateur de modification possible */
	canModifier: boolean = false;

	/** Utilisateur connecté */
	private user: User;

	/** Paramétrage de la note de frais */
	settings: SettingsNFState;

	/** Enregistrement en cours */
	isSaving: boolean = false;

	/** Intitulé pour l'affichage du relevé */
	bufferTitle: string;

	/** Etat de chargement de l'onglet pièces jointes */
	piecesJointesLoaded: boolean = false;

	/** Sous composant de liste des factures */
	private factureListComponent: FactureListComponent;

	/** Composant enfant Relevé */
	@ViewChild(ReleveComponent)
	private releveComponent: ReleveComponent;

	/** Indique la connexion en tant qu'admin **/
	public isAdminOuSSA: boolean;

	/** Souscriptions aux observables à désabonner lors de la destruction du composant */
	listeSubscriptions = new Array<Subscription>();

	/** Indique si le relevé est prêt à être comptabilisé */
	isReleveValide: boolean;

	/** Alerte à afficher lorsque le relevé est valide */
	alerteReleveValide: Alerte = {
		niveau: NiveauAlerte.SUCCESS,
		titre: this.translateService.instant('facture.releve.alerte.validePourCompta.title'),
		message: this.translateService.instant('facture.releve.alerte.validePourCompta.message')
	};

	/**
	 * Constructeur
	 */
	constructor(
		private store: Store<AppState>,
		private activatedRoute: ActivatedRoute,
		private translateService: TranslateService,
		private router: Router,
		private location: Location,
		private toastrService: ToastrService,
		private montantPipe: MontantPipe,
		private releveFactureService: ReleveFactureService,
		private lotService: ComptabiliteService,
		private confirmService: ConfirmService) {
	}

	/**
	 * Initialisation de la page
	 */
	ngOnInit(): void {
		//Chargement du paramétrage de la facture
		this.store.dispatch({
			type: settingsActions.LOAD_SETTINGS,
			payload: TypePortee.FC
		});

		//Sélection du paramétrage
		this.store.select(state => state.settings?.[TypePortee.FC]).pipe(filterFirstNotNull()).subscribe(settings => this.settings = settings);

		//Sélection de l'utilisateur connecté
		this.store.select(state => state.session?.user).pipe(filterFirstNotNull()).subscribe(user => this.user = user);

		//Récupération des paramètres de navigation
		this.activatedRoute.params.pipe(first()).subscribe(params => {
			//Chargement de l'élément
			this.load(params.id);
		});

		//Sélection de la session
		this.store.select<Session>(s => s.session).pipe(filterFirstNotNull()).subscribe(session => {
			//Mémorisation de la session
			this.isAdminOuSSA = session.isAdmin || session.user?.fonction === TypeProfil.SOUS_ADMINISTRATEUR;
		});

		this.listeSubscriptions.push(
			//Souscription à l'observable de rechargement du relevé
			this.releveFactureService.refreshListeFactures$.subscribe(() => {
				//Rechargement du relevé
				this.load(this.releveFacture.idFactureReleve);

				//Rechargement de la liste des factures
				this.factureListComponent?.refresh();
			})
		);

		this.listeSubscriptions.push(
			//Souscription à l'observable de rechargement de l'alerte du relevé
			this.releveFactureService.refreshIsReleveValide$.subscribe(() => {
				//Rechargement de la validité du relevé
				this.defineIsReleveValide(this.releveFacture.idFactureReleve);
			})
		);

		this.listeSubscriptions.push(
			//Souscription à l'observable de rechargement de la liste des lots
			this.releveFactureService.refreshListeLots$.subscribe(() => {
				//Rechargement de la liste des lots
				this.refreshListeLots();
			})
		);

	}

	/**
	 * Destruction du composant
	 */
	ngOnDestroy() {
		//Désabonnement
		this.listeSubscriptions?.forEach(sub => sub.unsubscribe());
	}

	/**
	 * Chargement de l'élément
	 * @param id ID de l'élément à charger
	 */
	load(id: number): void {
		//Chargement de l'élément
		this.releveFactureService.load(id)
			//Si à la fin du chargement le relevé est toujours undefined, on set à null pour faire disparaitre le loading
			.pipe(first(),finalize(() => this.releveFacture = this.releveFacture === undefined ? null : this.releveFacture))
			.subscribe({
				next: (result: Result) => {
					if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
						//Mise à jour de l'élément
						this.releveFacture = new ReleveFacture(result.data?.releveDto);
						this.bufferTitle = this.releveFacture?.numero;
						this.canModifier = result.data?.isFCATraiter as boolean;

						//Initialisation des éléments prédéfinis
						this.listeFieldParam = result.data?.listeFieldParam;

						//Transmission du CurrencyPipe pour le formatage des alertes
						this.releveFacture.montantPipeForAlerte = this.montantPipe;

						//Peuplement de la liste des actions possibles
						this.updateListeActions();

						//Chargement des onglets
						this.updateTabItems();

						//Activation d'un onglet spécifique le cas échéant
						this.activatedRoute.queryParams.pipe(first()).subscribe(queryParams => {
							//Recherche de l'onglet à activer
                            const tab = queryParams['tab'] != null ? this.listeTabItems?.find(tab => tab.code === queryParams['tab']) : null;

							//Si l'onglet a été trouvé
							if (tab) {
								//Changement d'onget
								tab.selected = true;
								this.onSelectedItemChange(tab);
							}
						});
					} else {
						//Message d'erreur
						TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
					}
				},
				error: () => {
					//Message d'erreur
					TypeCodeErreur.showError(TypeCodeErreur.ERROR_LOAD,this.translateService,this.toastrService);
				}
			});
	}

	/**
	 * Vérifie si le relevé est valide pour comptabilisation
	 *
	 * @param id Identifiant du relevé
	 */
	defineIsReleveValide(id: number) {
		//Vérification de la présence d'une facture, de l'absence de factures masquées (filtres, habilitations) & de factures dont le statut n'est pas lié à l'état VALIDE
		if (!!this.factureListComponent.liste.data.listeResultats.length
			&& this.factureListComponent.liste.data.nbObjetsTotal == this.releveFacture.quantite
			&& !this.factureListComponent.liste.data.listeResultats.some(facture => facture.statut.idEtat !== EnumEtat.VALIDE)) {
			//Vérification de la présence de factures en anomalies non masquées
			this.releveFactureService.isFactureAnomalieVisible(id).pipe(first())
				.subscribe((resultFactureAnomalie: Result) => {
					//Relevé valide si pas de factures en anomalie masquées
					this.isReleveValide = !resultFactureAnomalie?.data.isFactureAnomalieVisible;

					//Mise à jour des actions disponibles
					this.updateListeActions();
				});
		} else {
			//Factures masquées ou non valides : relevé non valide
			this.isReleveValide = false;

			//Mise à jour des actions disponibles
			this.updateListeActions();
		}
	}

	/**
	 * Définition/rafraîchissement de la liste des lots comptables liés au relevé
	 */
	refreshListeLots() {
		if (!this.listeLots) {
			//Définition de la liste des lots
			this.listeLots = new ListView<LotListItem,LotListItemComponent>({
				uri: `/controller/Lot/listeLotsforReleve?idFactureReleve=` + this.releveFacture.idFactureReleve,
				title: this.translateService.instant('lot.liste.title'),
				component: LotListItemComponent,
				isSimple: true,
				mapResult: (result: Result) => {
					return result?.data?.listeLots;
				},
				extraOptions: {
					user: this.user,
					isClickable: false
				}
			});
		} else {
			//Refresh de la liste des lots
			this.listeLots.refresh();
		}
	}


	/**
	 * Mise à jour de la liste des onglets
	 */
	updateTabItems(): void {
		//Définition des onglets
		this.listeTabItems = [{
			code: Tab.RELEVE,
			libelle: this.translateService.instant('facture.releve.navigation.releve.title')
		}];

		//Uniquement si l'objet existe en base
		if (this.releveFacture?.idFactureReleve) {
			this.listeTabItems = this.listeTabItems.concat([{
				code: Tab.FACTURES,
				libelle: this.translateService.instant('facture.releve.navigation.factures.title')
			}]);

			//Uniquement si l'utilisateur connecté est admin ou SSA
			if (this.isAdminOuSSA) {
				//Ajout de l'onglet permettant de traiter les rejets d'import
				this.listeTabItems = this.listeTabItems.concat([{
					code: Tab.FACTURES_ANOMALIE,
					libelle: this.translateService.instant('facture.releve.navigation.anomalie.title')
				}]);
			}

			this.listeTabItems = this.listeTabItems.concat([{
				code: Tab.PIECES_JOINTES,
				libelle: this.translateService.instant('facture.releve.navigation.pj.title')
			}]);
		}
	}

	/**
	 * Mise à jour de la liste des actions
	 */
	updateListeActions(): void {
		//Suivant l'onglet affiché
		switch (this.selectedItem?.code) {
			//Actions pour le relevé (par défaut)
			case Tab.RELEVE:
			case null:
			case undefined:
				//Définition de la liste des actions
				this.listeActions.next([this.canModifier && {
					type: TypeAction.SECONDARY,
					icone: 'nio icon-sauvegarde',
					libelle: 'global.actions.enregistrer',
					doAction: () => this.save(),
					isVisible: () => true
				},this.canModifier && {
					type: TypeAction.SECONDARY,
					icone: 'nio icon-suppression',
					libelle: 'global.actions.supprimer',
					doAction: () => this.delete(),
					isVisible: () => true
				}].filter(a => !!a));
				break;
			//Actions pour les factures
			case Tab.FACTURES:
				//Si le sous-composant n'est pas encore chargé, la liste des actions
				//sera récupérée une fois l'initialisation de celui-ci achevée
				//(donc pas ici, voir onFactureListeLoaded())
				if (this.factureListComponent) {
					//Définition de la liste des actions
					this.listeActions.next(this.factureListComponent.listeActions.getValue());
				} else {
					//Forçage à null
					this.listeActions.next(null);
				}
				break;
			//Actions pour les documents
			default:
				//Pas actions
				this.listeActions.next(null);
		}

		//Ajout du bouton de comptabilisation globale seulement pour le comptable
		if (!this.isAdminOuSSA) {
			this.listeActions.next(
				[...(this.listeActions.getValue() ?? []),
					{
						type: TypeAction.PRIMARY,
						icone: 'nio icon-comptabilisation',
						libelle: 'global.actions.comptabiliser',
						doAction: () => this.comptabiliser(),
						isVisible: () => this.isReleveValide && !this.factureListComponent.liste?.data?.listeResultats?.some(i => i.isSelected)
					}
				]);
		}
	}

	/** Titre de la page */
	get title(): string {
		//Lorsque le relevé a été enregistré
		if (this.releveFacture?.idFactureReleve) {
			return this.translateService.instant('facture.releve.title',{numero: this.bufferTitle});
		}
		//Lors d'une création
		else {
			return this.translateService.instant('facture.releve.defaultTitle');
		}
	}

	/**
	 * Enregistrement de l'élément
	 */
	save(): void {
		//Si le formulaire n'est pas valide
		if (this.selectedItem.code === Tab.RELEVE && !this.releveComponent.isValid()) {
			//Message d'erreur
			this.toastrService.error(this.translateService.instant('global.errors.formInvalid'));
			return;
		}

		//Enregistrement en cours
		this.isSaving = true;

		//Enregistrement de l'élément
		this.releveFactureService.save(this.releveFacture).pipe(first(),finalize(() => this.isSaving = false)).subscribe({
			next: result => {
				//Vérification de l'enregistrement
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

					//Mise à jour de l'ID dans l'URL
					if (!this.releveFacture?.idFactureReleve && result.data?.idFactureReleve) {
						this.location.replaceState(`/ReleveFacture/${result.data?.idFactureReleve}`);
					}

					//Rechargement de l'élément
					this.load(result.data?.idFactureReleve);
				} else {
					//Message d'erreur
					TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
				}
			},
			error: () => {
				//Message d'erreur
				TypeCodeErreur.showError(TypeCodeErreur.ERROR_SAVE,this.translateService,this.toastrService);
			}
		});
	}

	/**
	 * Suppression de l'élément
	 */
	delete(): void {
		//Si le relevé est importé ou s'il comporte au moins une facture : impossible de supprimer
		if (this.releveFacture.importe || this.releveFacture.quantite != 0) {
			let errors = [];

			//Si le relevé n'est pas vide
			if (this.releveFacture.quantite != 0) {
				errors.push(this.translateService.instant("facture.releve.errors.suppression.nonVide"));
			}

			//Si le relevé a été importé (pas créé manuellement)
			if (this.releveFacture.importe) {
				errors.push(this.translateService.instant("facture.releve.errors.suppression.importe"));
			}

			//Affiche le message d'erreur
			this.confirmService.showConfirm(errors.join('\n'),{
				type: 'ok',
				title: 'facture.releve.errors.suppression.title'
			});
		} else {
			//Message de confirmation
			this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation')).pipe(filter(isConfirmed => isConfirmed)).subscribe({
				next: () => {
					//Suppression de l'élément
					this.releveFactureService.delete(this.releveFacture.idFactureReleve).pipe(first()).subscribe({
						next: result => {
							//Vérification de la suppression
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('global.success.suppression'));

								//Retour à la liste
								this.onGoBack();
							} else {
								//Message d'erreur
								TypeCodeErreur.showError(TypeCodeErreur.ERROR_DELETE,this.translateService,this.toastrService);
							}
						},
						error: () => {
							//Message d'erreur
							TypeCodeErreur.showError(TypeCodeErreur.ERROR_DELETE,this.translateService,this.toastrService);
						}
					});
				}
			});
		}
	}

	/**
	 * Changement d'onglet
	 * @param selectedItem nouvel onglet sélectionné
	 */
	onSelectedItemChange(selectedItem: PageHeaderItem): void {
		//Mise à jour de l'onglet sélectionné
		this.selectedItem = selectedItem;
		this.releveFacture.selectedItemForAlerte = selectedItem;
	}

	/**
	 * Retour arrière
	 */
	onGoBack(): void {
        //Navigation vers la liste
        this.router.navigate([this.isAdminOuSSA ? 'Admin/Frais/Factures/Releves' : 'ListeReleve']);
	}

	/**
	 * Récupération du pointeur vers le sous-composant une fois son initialisation terminée
	 * @param factureListComponent le sous-composant de liste de factures
	 */
	onFactureListeLoaded(factureListComponent: FactureListComponent): void {
		//Sauvegarde du pointeur vers le sous-composant
		this.factureListComponent = factureListComponent;
	}

	/**
	 * Rechargement du relevé à la suppression d'une facture depuis la liste
	 */
	onFactureDeleted(): void {
		//Rechargement du relevé pour mettre à jour le nombre total de factures liées pour permettre la suppression du relevé s'il n'y en a plus aucune
		this.load(this.releveFacture.idFactureReleve);
	}

	/**
	 * Comptabilisation en masse du relevé
	 */
	comptabiliser() {
		this.confirmService.showConfirm(this.translateService.instant("facture.releve.confirmComptabilisation"))
			.pipe(filter(isConfirmed => isConfirmed))
			.subscribe({ next: () => {
				//Mise en session de l'identifiant du relevé
				this.lotService.setIdsForLot(LotItemType.RELEVE,[this.releveFacture.idFactureReleve]);

				//On souscrit à la route
				this.listeSubscriptions.push(this.activatedRoute.url.subscribe((listeUrlSegment: UrlSegment[]) => {
					//Définition des extras
					const extras: NavigationExtras = {
						state: {
							path: listeUrlSegment[0].path,
							idReleve: this.releveFacture.idFactureReleve
						}
					}

					//Création d'un nouveau lot
					this.router.navigate(['Lot', 0],extras);
				}));
			}});
	}
}

/**
 * Enum des différents onglets disponibles sur la page
 */
export enum Tab {
	RELEVE = 'RELEVE',
	FACTURES = 'FACTURES',
	FACTURES_ANOMALIE = 'FACTURES_ANOMALIE',
	PIECES_JOINTES = 'PIECES_JOINTES',
}