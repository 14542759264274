import {Injectable} from "@angular/core";
import {Motif} from "@domain/workflow/motif";
import {HttpClient} from "@angular/common/http";
import {environment} from "@environments/environment";
import {Result} from "@domain/common/http/result";

/**
 * Service de gestion des Motifs du workflow
 */
@Injectable()
export class WorkflowMotifsService {
    /**
     * Constructeur
     *
     * @param http Client http
     */
    constructor(private http: HttpClient) {
    }

    /**
     * Récupère un motif par son identifiant
     *
     * @param idMotif Identifiant du motif
     * @return Result contenant le motif le cas échéant
     */
    public getMotif(idMotif: number) {
        return this.http.get<Result>(`${environment.baseUrl}/controller/WorkflowMotif/loadMotif/${idMotif}`);
    }

    /**
     * Enregistrement d'un motif
     *
     * @param motif
     * @return Result résultat de l'enregistrement
     */
    public saveMotif(motif: Motif) {
        return this.http.post<Result>(`${environment.baseUrl}/controller/WorkflowMotif/saveMotif`,motif);
    }

    /**
     * Suppression d'un motif
     *
     * @param motif
     * @return Result résultat de l'enregistrement
     */
    public deleteMotif(motif: Motif) {
        return this.http.post<Result>(`${environment.baseUrl}/controller/WorkflowMotif/deleteMotif`,motif);
    }
}
