import {NgModule} from '@angular/core';
import {EntrepriseUtilisateursComponent} from "@components/admin/entreprise/utilisateurs/entreprise-utilisateurs.component";
import {EntrepriseUtilisateursService} from "@components/admin/entreprise/utilisateurs/entreprise-utilisateurs.service";
import {ShareModule} from "@share/share.module";
import {UtilisateursInternesListComponent} from './utilisateurs-internes-list/utilisateurs-internes-list.component';
import {UtilisateursInternesListItemComponent} from './utilisateurs-internes-list/utilisateurs-internes-list-item/utilisateurs-internes-list-item.component';
import {Routes} from "@angular/router";
import {UserDetailComponent,userDetailRoutes} from './user-detail/user-detail.component';
import {AdminGuardProvider} from "@core/security/admin-guard.provider";
import {UtilisateursExternesComponent} from '@components/admin/entreprise/utilisateurs/utilisateurs-externes-list/utilisateurs-externes.component';
import {UtilisateursExternesListItemComponent} from './utilisateurs-externes-list/utilisateurs-externes-list-item/utilisateurs-externes-list-item.component';
import {UtilisateursParametresComponent} from './utilisateurs-parametres/utilisateurs-parametres.component';
import {UserGeneralitesComponent} from './user-detail/tabs/user-generalites/user-generalites.component';
import {UserMetierComponent} from './user-detail/tabs/user-metier/user-metier.component';
import {UserProfilComponent} from './user-detail/tabs/user-profil/user-profil.component';
import {UserInfosBancairesComponent} from './user-detail/tabs/user-infos-bancaires/user-infos-bancaires.component';
import {UserVehiculesComponent} from './user-detail/tabs/user-vehicules/user-vehicules.component';
import {UserMobiliteComponent} from './user-detail/tabs/user-mobilite/user-mobilite.component';
import {UserFiltresComponent} from './user-detail/tabs/user-filtres/user-filtres.component';
import {PasswordPopinComponent} from 'app/components/admin/entreprise/utilisateurs/user-detail/tabs/user-generalites/password-popin/password-popin.component';
import {HabilitationListItemComponent} from '@components/admin/entreprise/utilisateurs/user-detail/tabs/user-generalites/habilitation-list-item/habilitation-list-item.component';
import {AddHabilitationPopinComponent} from 'app/components/admin/entreprise/utilisateurs/user-detail/tabs/user-generalites/add-habilitation-popin/add-habilitation-popin.component';
import {UserEntreeSortiePopinComponent} from './user-detail/tabs/user-generalites/user-entree-sortie-popin/user-entree-sortie-popin.component';
import {HabilitationHistoryPopinComponent} from './user-detail/tabs/user-generalites/habilitation-history-popin/habilitation-history-popin.component';
import {SelectEnveloppesPopinComponent} from './user-detail/tabs/user-generalites/select-enveloppes-popin/select-enveloppes-popin.component';
import {EnveloppeListItemComponent} from './user-detail/tabs/user-generalites/select-enveloppes-popin/enveloppe-list-item/enveloppe-list-item.component';
import {SelectModeRembPopinComponent} from './user-detail/tabs/user-metier/select-mode-remb-popin/select-mode-remb-popin.component';
import {ModeRembListItemComponent} from './user-detail/tabs/user-metier/select-mode-remb-popin/mode-remb-list-item/mode-remb-list-item.component';
import {FranchisePopinComponent} from './user-detail/tabs/user-metier/franchise-popin/franchise-popin.component';
import {UserContratListItemComponent} from '@components/admin/entreprise/utilisateurs/user-detail/tabs/user-metier/franchise-popin/user-contrat-list-item/user-contrat-list-item.component';
import {UserContratDetailPopinComponent} from '@components/admin/entreprise/utilisateurs/user-detail/tabs/user-metier/franchise-popin/user-contrat-detail-popin/user-contrat-detail-popin.component';
import {CompteurFiscalPopinComponent} from 'app/components/admin/entreprise/utilisateurs/user-detail/tabs/user-metier/compteur-fiscal-popin/compteur-fiscal-popin.component';
import {CompteurListItemComponent} from './user-detail/tabs/user-metier/compteur-fiscal-popin/compteur-list-item/compteur-list-item.component';
import {
	CompteurDetailListItemComponent
} from '@components/admin/entreprise/utilisateurs/user-detail/tabs/user-metier/compteur-fiscal-popin/compteur-detail-list-item/compteur-detail-list-item.component';
import {CompteurDetailPopinComponent} from '@components/admin/entreprise/utilisateurs/user-detail/tabs/user-metier/compteur-fiscal-popin/compteur-detail-popin/compteur-detail-popin.component';
import {LieuModule} from "@components/lieu/lieu.module";
import {PreviewAlerteModule} from "@share/component/alerte/preview-alerte.module";
import {VehiculeComponent} from "@components/vehicule/vehicule.component";
import {DeviceListItemComponent} from './user-detail/tabs/user-mobilite/device-list-item/device-list-item.component';
import {QRCodeModule} from "angularx-qrcode";
import {FiltreListItemComponent} from './user-detail/tabs/user-filtres/filtre-list-item/filtre-list-item.component';
import {CreateFiltrePopinComponent} from './user-detail/tabs/user-filtres/create-filtre-popin/create-filtre-popin.component';
import {PopulationListItemComponent} from './user-detail/tabs/user-filtres/population-list-item/population-list-item.component';
import {PrestationsAutoriseesPopinComponent} from './user-detail/tabs/user-filtres/prestations-autorisees-popin/prestations-autorisees-popin.component';
import {PrestationListItemComponent} from './user-detail/tabs/user-filtres/prestations-autorisees-popin/prestation-list-item/prestation-list-item.component';
import {DroitAdmin} from "@core/security/droit-admin";
import {EditExternePopinComponent} from './edit-externe-popin/edit-externe-popin.component';
import {UserHistoriqueComponent} from './user-detail/tabs/user-historique/user-historique.component';
import {UserHistoriqueListItemComponent} from './user-detail/tabs/user-historique/user-historique-list-item/user-historique-list-item.component';
import {UtilisateursParametresService} from "@services/admin/entreprise/utilisateurs/utilisateurs-parametres.service";
import {ChampsComplementairesComponent} from './utilisateurs-parametres/champs-complementaires/champs-complementaires.component';
import {ChampComplementairePopinComponent} from './utilisateurs-parametres/champs-complementaires/champ-complementaire-popin/champ-complementaire-popin.component';
import {ProfilAlertesConfigComponent} from '@components/admin/entreprise/utilisateurs/utilisateurs-parametres/alertes-profil-config/profil-alertes-config.component';
import {ExternesParamsComponent} from './utilisateurs-parametres/externes-params/externes-params.component';
import {UserHabilitationService} from "@components/admin/entreprise/utilisateurs/user-detail/tabs/user-generalites/user-habilitation.service";
import {DelegationModule} from "@share/component/delegation/delegation.module";

/**
 * Routes du menu Entreprise > Utilisateurs
 */
export const entrepriseUtilisateursRoutes: Routes = [
	{
		path: 'Internes',
		component: UtilisateursInternesListComponent,
		canActivate: [AdminGuardProvider],
		data: {
			sousAdminCredentials: [
				DroitAdmin.DROIT_USER_GESTION,
				DroitAdmin.DROIT_USER_ANALYTIQUE,
				DroitAdmin.DROIT_HABILITATION_GESTION,
				DroitAdmin.DROIT_PROFIL_VOYAGEUR_GESTION,
				DroitAdmin.DROIT_PV_CONTACT_URGENCE,
				DroitAdmin.DROIT_PV_GESTION_DOCUMENT,
				DroitAdmin.DROIT_PV_CARTES_ABONNEMENT,
				DroitAdmin.DROIT_MOBILE_GESTION,
				DroitAdmin.DROIT_USER_INFOS_BANCAIRES,
				DroitAdmin.DROIT_VEHICULE_GESTION
			]
		}
	},{
		path: 'Externes',
		component: UtilisateursExternesComponent,
		canActivate: [AdminGuardProvider],
		data: {sousAdminCredentials: [DroitAdmin.DROIT_GESTION_EXTERNE]}
	},{
		path: 'Parametres',
		component: UtilisateursParametresComponent,
		canActivate: [AdminGuardProvider],
		data: { sousAdminCredentials: [DroitAdmin.DROIT_USER_ALERTE_PROFIL] }
	},{
		path: 'User/:idUser/Vehicule/:idPa',
		component: VehiculeComponent,
		canActivate: [AdminGuardProvider],
		data: {sousAdminCredentials: [DroitAdmin.DROIT_VEHICULE_GESTION]}
	},{
		path: 'User',
		canActivate: [AdminGuardProvider],
		children: [{
			path: ":idUser",
			component: UserDetailComponent,
			canActivate: [AdminGuardProvider],
			children: userDetailRoutes
		}]
	},{
		path: '',
		pathMatch: 'full',
		redirectTo: 'Internes'
	},{
		path: '**',
		redirectTo: 'Internes'
	}
];

/**
 * Module du menu Entreprise > Utilisateurs
 */
@NgModule({
	declarations: [
		EntrepriseUtilisateursComponent,
		UtilisateursInternesListComponent,
		UtilisateursInternesListItemComponent,
		UserDetailComponent,
		UtilisateursExternesComponent,
		UtilisateursExternesListItemComponent,
		UtilisateursParametresComponent,
		UserGeneralitesComponent,
		UserMetierComponent,
		UserProfilComponent,
		UserInfosBancairesComponent,
		UserVehiculesComponent,
		UserMobiliteComponent,
		UserFiltresComponent,
		PasswordPopinComponent,
		HabilitationListItemComponent,
		AddHabilitationPopinComponent,
		UserEntreeSortiePopinComponent,
		HabilitationHistoryPopinComponent,
		SelectEnveloppesPopinComponent,
		EnveloppeListItemComponent,
		SelectModeRembPopinComponent,
		ModeRembListItemComponent,
		FranchisePopinComponent,
		UserContratListItemComponent,
		UserContratDetailPopinComponent,
		CompteurFiscalPopinComponent,
		CompteurListItemComponent,
		CompteurDetailListItemComponent,
		CompteurDetailPopinComponent,
		DeviceListItemComponent,
		FiltreListItemComponent,
		CreateFiltrePopinComponent,
		PopulationListItemComponent,
		PrestationsAutoriseesPopinComponent,
		PrestationListItemComponent,
		CompteurDetailPopinComponent,
		EditExternePopinComponent,
		UserHistoriqueComponent,
		UserHistoriqueListItemComponent,
		EditExternePopinComponent,
		ChampsComplementairesComponent,
		ChampComplementairePopinComponent,
		ProfilAlertesConfigComponent,
		ExternesParamsComponent,
	],
	imports: [
		ShareModule,
		LieuModule,
		PreviewAlerteModule,
		QRCodeModule,
		DelegationModule,
	],
	providers: [
		EntrepriseUtilisateursService,
		UtilisateursParametresService,
		UserHabilitationService,
	]
})
export class EntrepriseUtilisateursModule {
}
