import {Component} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {AdresseItem} from "@domain/admin/bibliotheque/geographie/adresseItem";
import {Router} from "@angular/router";
import {GeographieService} from '@services/admin/geographie/geographie.service';
import {ConfirmService} from '@share/component/confirmation/confirm.service';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {filter} from "rxjs/operators"
import {TypeCodeErreur} from '@domain/common/http/result';
import {Origine} from "@domain/geolocalisation/localisation";

/**
 * Composant d'une adresse dans la liste des adresses
 *
 * @author Angeline Ha
 * @date 07/12/2023
 */
@Component({
    host: {'data-test-id': 'adresse-item'},
    selector: 'adresse-item',
    templateUrl: './adresse-item.component.html'
})
export class AdresseItemComponent extends ListViewItem<AdresseItem> {
    /** Accès à l'enum dans la vue */
    readonly Origine = Origine;

    /**
     * Constructeur
     * @param geographieService Service géographie
     * @param router            Router de l'application
     * @param confirmService    Service de confirmation
     * @param translateService  Service de traduction
     * @param toastrService     Service de gestion des toasts
     */
    constructor(private geographieService: GeographieService,
                private router: Router,
                private confirmService: ConfirmService,
                private translateService: TranslateService,
                private toastrService: ToastrService) {
        super();
    }

    /**
     * Affichage du détail d'une adresse
     */
    goToDetailAdresse() {
        this.router.navigate([`Admin/Bibliotheque/Geographie/Adresse/${this.data.idAdresse}`]);
    }

    /**
     * Suppression de l'adresse
     */
    deleteAdresse() {
        //Popup de confirmation
        this.confirmService.showConfirm(this.translateService.instant('admin.bibliotheque.geographie.adresse.confirmSuppression'))
            .pipe(filter(isConfirmed => isConfirmed)).subscribe({
            next: () => {
                //Suppression de l'adresse
                this.geographieService.deleteAdresse(this.data.idAdresse).subscribe(result => {
                    if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
                        //Refresh la liste des adresses
                        this.geographieService.listeAdresses.refresh();

                        //Toast de succès
                        this.toastrService.success(this.translateService.instant('global.success.suppression'));
                    } else {
                        //Toast d'erreur
                        this.toastrService.error(this.translateService.instant('global.errors.suppression'));
                    }
                })
            }
        });
    }
}
