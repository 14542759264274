import {NgModule} from "@angular/core";
import {Routes} from "@angular/router";
import {ShareModule} from "@share/share.module";
import {AdminFraisComponent} from "./admin-frais.component";
import {ReferentielsService} from "@services/admin/referentiels/referentiels.service";
import {FraisReferentielInfosComponent} from "@components/admin/frais/referentiel/referentiel-infos/frais-referentiel-infos.component";
import {FraisReferentielComponent} from "@components/admin/frais/referentiel/frais-referentiel.component";
import {FraisReferentielModule,fraisReferentielRoutes} from '@components/admin/frais/referentiel/frais-referentiel.module';
import {FraisFacturesComponent} from "@components/admin/frais/factures/frais-factures.component";
import {FraisFacturesModule,fraisFacturesRoutes} from "@components/admin/frais/factures/frais-factures.module";
import {FraisNdfComponent} from "@components/admin/frais/ndf/frais-ndf.component";
import {FraisNdfModule,fraisNdfRoutes} from "@components/admin/frais/ndf/frais-ndf.module";
import {DroitAdmin} from "@core/security/droit-admin";
import {AvanceListComponent} from "@components/avance/avance-list/avance-list.component";
import {TypePortee} from "@domain/workflow/workflow";
import {AdminGuardProvider} from "@core/security/admin-guard.provider";
import {fraisIndemnitesRoutes} from "@components/admin/frais/indemnites/frais-indemnites.module";
import {FraisIndemnitesComponent} from "@components/admin/frais/indemnites/frais-indemnites.component";
import {FraisPrestationsModule,fraisPrestationsRoutes} from "@components/admin/frais/prestations/frais-prestations.module";
import {FraisPrestationsComponent} from "@components/admin/frais/prestations/frais-prestations.component";
import {FraisTaxesModule,fraisTaxesRoutes} from "@components/admin/frais/taxes/frais-taxes.module";
import {FraisTaxesComponent} from "@components/admin/frais/taxes/frais-taxes.component";

export const adminFraisRoutes: Routes = [
	{
		path: 'Avances',
		component: AvanceListComponent,
		canActivate: [AdminGuardProvider],
		data: {
			typePortee: TypePortee.AV,
			sousAdminCredentials: [DroitAdmin.DROIT_VISUALISATION_AVANCES]
		}
	},{
		path: 'Factures',
		component: FraisFacturesComponent,
		children: fraisFacturesRoutes,
		canActivate: [AdminGuardProvider]
	},{
		path: 'Indemnites',
		component: FraisIndemnitesComponent,
		canActivate: [AdminGuardProvider],
		children: fraisIndemnitesRoutes
	},{
		path: 'Ndf',
		component: FraisNdfComponent,
		children: fraisNdfRoutes,
		canActivate: [AdminGuardProvider]
	},{
		path: 'Prestations',
		component: FraisPrestationsComponent,
		canActivate: [AdminGuardProvider],
		children: fraisPrestationsRoutes
	},{
		path: 'Referentiels',
		component: FraisReferentielComponent,
		canActivate: [AdminGuardProvider],
		children: fraisReferentielRoutes
	},{
		path: 'Referentiel/:idReferentiel/:type',
		component: FraisReferentielInfosComponent,
		canActivate: [AdminGuardProvider],
		data: { sousAdminCredentials: [DroitAdmin.DROIT_FRAIS_PRESTATION] }
	},{
		path: 'Taxes',
		component: FraisTaxesComponent,
		canActivate: [AdminGuardProvider],
		children: fraisTaxesRoutes
	},{
		path: '',
		redirectTo: 'Factures',
		pathMatch: 'full'
	},{
		path: '**',
		redirectTo: 'Factures'
	}
];

/**
 * Module de gestion des frais
 */
@NgModule({
	imports: [
		ShareModule,
		FraisReferentielModule,
		FraisFacturesModule,
		FraisNdfModule,
		FraisPrestationsModule,
		FraisTaxesModule
	],
	declarations: [
		AdminFraisComponent
	],
	exports: [
		AdminFraisComponent
	],
	providers: [ReferentielsService]
})
export class AdminFraisModule {
}
