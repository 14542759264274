import {NgModule} from "@angular/core";
import {Routes} from "@angular/router";
import {AdminGuardProvider} from "@core/security/admin-guard.provider";
import {ShareModule} from "@share/share.module";
import {ParametreAuthentificationComponent} from "./parametre-authentification.component";
import {AuthentificationGeneralitesComponent} from "./authentification-generalites/authentification-generalites.component";
import {AuthentificationSamlComponent} from "./authentification-saml/authentification-saml.component";
import {AuthentificationListeNoireComponent} from "./authentification-liste-noire/authentification-liste-noire.component";
import {AuthentificationComptesVerrouillesComponent} from "./authentification-comptes-verrouilles/authentification-comptes-verrouilles.component";
import {DroitAdmin} from "@core/security/droit-admin";

export const parametreAuthentificationRoutes : Routes = [
    {
        path: 'Generalites',
        component: AuthentificationGeneralitesComponent,
        canActivate: [AdminGuardProvider]
    }, {
        path: 'SAML',
        component: AuthentificationSamlComponent,
        canActivate: [AdminGuardProvider]
    }, {
        path: 'ListeNoire',
        component: AuthentificationListeNoireComponent,
        canActivate: [AdminGuardProvider]
    }, {
        path: 'ComptesVerrouilles',
        component: AuthentificationComptesVerrouillesComponent,
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_COMPTES_VERROUILLES] }
    }, {
        path: '',
        pathMatch: 'full',
        redirectTo: 'Generalites'
    }, {
        path: '**',
        redirectTo: 'Generalites'
    }
];

@NgModule({
    imports: [ShareModule],
    declarations: [
        ParametreAuthentificationComponent,
        AuthentificationGeneralitesComponent,
        AuthentificationSamlComponent,
        AuthentificationListeNoireComponent,
        AuthentificationComptesVerrouillesComponent
    ],
    entryComponents: [],
    exports: [
        AuthentificationGeneralitesComponent,
        AuthentificationSamlComponent,
        AuthentificationListeNoireComponent,
        AuthentificationComptesVerrouillesComponent
    ],
    providers: []
})
export class ParametreAuthentificationModule {}
