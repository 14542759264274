import {Component} from "@angular/core";
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {ResultatRecherche} from "@domain/admin/recherche/resultat-recherche";
import {Router} from "@angular/router";

/**
 * Composant pour afficher un résultat de recherche de page
 */
@Component({
    host: {'data-test-id': 'recherche-item'},
    templateUrl: './recherche-item.component.html',
    styleUrls: ['./recherche-item.component.scss']
})
export class RechercheItemComponent extends ListViewItem<ResultatRecherche> {

    /**
     * Constructeur
     */
    constructor(private router: Router) { super(); }

    /**
     * Navigation vers la page proposée
     */
    navigate(): void {
        //Fermeture de la popup
        this.liste.extraOptions.matDialogRef.close();

        //Navigation vers la route
        this.router.navigateByUrl(this.data.route);
    }
}
