import {TranslateService} from '@ngx-translate/core';
import {Filter,TypeComparaison} from '@domain/common/list-view';

import {AutocompleteOptions} from '../autocomplete';
import {SearchType} from "@domain/common/list-view/sorting";
import {PrestationFraisLineItemComponent} from "./prestation-frais-line-item.component";
import {PrestationFraisSearchItemComponent} from "@share/component/autocomplete/options/prestation-frais-search-item";
import {TypePrestation} from "@domain/prestation/prestation";

/**
 * Options des prestations pour le frais
 */
export default class PrestationFraisOptions implements AutocompleteOptions {
	/**
	 * Récupération de l'URL
	 */
	url(filter: any) {
		//URL
		let url: string = `/controller/Settings/filtrePrestations/${filter?.idPays || 0}/${filter?.idTypeEntite || 0}/${filter?.typeObjet || ''}/${filter?.idObjet || 0}`
			+ `?idPA=${filter?.idPA || '0'}`;

		return url;
	}

	/**
	 * Composant d'affichage d'une ligne dans la popup de recherche
	 */
	searchItemComponent() { return PrestationFraisSearchItemComponent; }

	/**
	 * Affichage d'un élément
	 */
	displayItem(item: any) { return (item?.libelle) || ''; }

	/**
	 * Composant d'affichage d'une ligne de l'autocomplete
	 */
	lineItemComponent() { return PrestationFraisLineItemComponent; }

	/**
	 * Filtres disponibles
	 */
	listeFilters(translateService: TranslateService): Array<Filter> {
		//Retour de la liste des filtres
		return [{
			title: translateService.instant('filter.libelle'),
			clef: 'libelleNettoye',
			typeComparaison: TypeComparaison[TypeComparaison.LIKE],
			isDefault: true
		},{
			title: translateService.instant('ndf.prestation.famille'),
			clef: 'famille.libelle',
		},{
			title: translateService.instant('ndf.prestation.unite'),
			clef: 'unite.libelle',
		},{
			title: translateService.instant('ndf.prestation.remboursement'),
			clef: 'typeRemboursement',
			listeValues: [{
				code: TypePrestation.FORFAIT.toString(),
				libelle: translateService.instant('ndf.prestation.typeRemboursement.REEL')
			},{
				code: TypePrestation.REEL.toString(),
				libelle: translateService.instant('ndf.prestation.typeRemboursement.forfaitShort')
			},{
				code: TypePrestation.PLAFOND.toString(),
				libelle: translateService.instant('ndf.prestation.typeRemboursement.plafondShort')
			}],
		}]
	}

	/**
	 * Mode de recherche
	 */
	searchType = SearchType.CONTAINS;
}