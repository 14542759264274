import {Component,Input,OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';

import {AppState} from '@domain/appstate';
import {TypeComparaison,TypeFilter} from '@domain/common/list-view';
import {OmpListItemComponent} from './omp-list-item/omp-list-item.component';
import {ListeOmp} from '@domain/omp/liste-omp';
import {WorkflowService} from '../../workflow/workflow.service';
import {TypePortee} from '@domain/workflow/workflow';
import {AutocompleteService} from '@share/component/autocomplete/autocomplete.service';
import {MatDialog,MatDialogRef} from '@angular/material/dialog';
import {PleaseWaitDialogComponent} from '@share/component/please-wait/please-wait-dialog.component';
import {PleaseWaitService} from '@share/component/please-wait/please-wait.service';
import {OMPService} from '../omp.service';
import {AbstractWorkflowListComponent} from '../../workflow/abstract-workflow-list.component';
import {SessionStorageService} from '@domain/common/services/session-storage.service';
import {CguService} from '@components/cgu/cgu.service';
import {ToastrService} from 'ngx-toastr';
import {UserDto} from "@domain/user/user-dto";
import {Result,TypeCodeErreur} from "@domain/common/http/result";

@Component({
    selector: 'omp-list',
    templateUrl: './omp-list.component.html'
})
export class OmpListComponent extends AbstractWorkflowListComponent<ListeOmp,OmpListItemComponent> implements OnInit {

    /** Gestion des actions dans le composant parent */
    @Input() remoteActions: boolean = false;

    /**
     * Constructeur
     */
    constructor(
        protected autocompleteService: AutocompleteService,
        protected pleaseWaitService: PleaseWaitService,
        protected ompService: OMPService,
        protected translateService: TranslateService,
        protected store: Store<AppState>,
        protected workflowService: WorkflowService,
        protected matDialog: MatDialog,
        protected sessionStorageService: SessionStorageService,
        protected cguService: CguService,
        protected toastrService: ToastrService
    ) {
        //Constructeur parent
        super(
            translateService,
            store,
            workflowService,
            matDialog,
            TypePortee.OT,
            '/controller/OMPermanent',
            'omp.liste.title',
            '-idOMPermanent',
            'OmpListComponent',
            OmpListItemComponent,
            sessionStorageService,
            cguService,
            toastrService
        );
    }

    /**
     * Spécificités de la liste héritière
     *
     * @param initOnlyColumns True si on veut initialiser uniquement les colonnes de la liste (false par défaut)
     */
    protected initListSpecific(initOnlyColumns?: boolean): void {
        //Définition des colonnes de tri
        this.liste.columns = [
            { key: 'idOMPermanent', title: 'omp.liste.columns.idOMPermanent' },
            { key: 'objet', title: 'omp.liste.columns.objet' },
            { key: 'user.matricule', title: 'omp.liste.columns.user.matricule' },
            { key: 'idTypeEntite', title: 'omp.liste.columns.typeEntite' },
            { key: 'dateDebut', title: 'omp.liste.columns.dateDebut' },
            { key: 'dateFin', title: 'omp.liste.columns.dateFin' },
            { key: 'statut.idStatut', title: 'omp.liste.columns.statut' }
        ];

        //Si on ne veut pas initialiser que les colonnes
        if (!initOnlyColumns) {
            //Ajout des filtres spécifiques
            this.liste.listeFilters = [
                ...[
                    {
                        //Ajout du filtre sur l'objet
                        clef: 'objet',
                        title: this.translateService.instant('omp.liste.filtres.objet'),
                        isDefault: true,
                        typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                    }, {
                        //Ajout du filtre sur le numéro
                        clef: 'idOMPermanent',
                        title: this.translateService.instant('omp.liste.filtres.id'),
                        isDefault: false,
                        type: TypeFilter[TypeFilter.LONG],
                        typeComparaison: TypeComparaison[TypeComparaison.EQUAL]
                    }, {
                        //Ajout du filtre sur la date de début
                        clef: 'dateDebut',
                        title: this.translateService.instant('omp.liste.filtres.dateDebut'),
                        type: TypeFilter[TypeFilter.DATE]
                    }, {
                        //Ajout du filtre sur la date de fin
                        clef: 'dateFin',
                        title: this.translateService.instant('omp.liste.filtres.dateFin'),
                        type: TypeFilter[TypeFilter.DATE]
                    }, {
                        //Ajout du filtre sur le matricule
                        clef: 'user.matricule',
                        title: this.translateService.instant('omp.liste.filtres.matricule'),
                        isDefault: true,
                        typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                    }
                ],
                ...this.liste.listeFilters
            ];
        }
    }

    /**
     * Création d'un objet
     *
     * @param collab Collab pour lequel on crée l'objet WF (cas d'un assistant/responsable/comptable)
     */
    public create(collab?: UserDto): void {
        //Affichage de la popup de sélection du type entité
        this.autocompleteService.showSearch({
            type: 'typeEntite',
            filter: {
                idPortee: TypePortee.OT,
                idCollab: collab?.idUser
            }
        }).subscribe({
            next: (typeEntite) => {
                if (typeEntite) {
                    let matDialogRef: MatDialogRef<PleaseWaitDialogComponent>;

                    //Affichage de la popup d'attente
                    matDialogRef = this.pleaseWaitService.show();

                    //Création de la mission permanente
                    this.ompService.create(typeEntite.idTypeEntite,collab?.idUser).subscribe(idOMPermanent => {
                        //Vérification de la création de la mission permanente
                        if (idOMPermanent) {
                            //Redirection vers la mission permanente créée
                            this.ompService.navigateToOMP(idOMPermanent);
                        }

                        //Fermeture de la popup
                        matDialogRef.close();
                    });
                }
            }
        });
    }

    /** @inheritdoc */
    protected hideDisplayItems(displayMode: boolean) {
        //Appel au service
        this.ompService.hideDisplay(this.liste.listeObjetsSectionnes.map(omp => omp.idOMPermanent),displayMode)
            .subscribe((result: Result) => {
                //Vérification du result
                if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
                    //Toast succès
                    this.toastrService.success(this.translateService.instant(`global.success.${displayMode ? 'demasquer' : 'masquer'}`,{nb: result.data.nbItems}));

                    //Refresh liste
                    this.liste.refresh();
                } else {
                    //Gestion de l'erreur
                    TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
                }
            });
    }
}
