import {Component,OnDestroy,OnInit} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {ListView,TypeComparaison,TypeFilter} from "@domain/common/list-view";
import {FloatingButtonAction,TypeAction} from "@share/component/floating-button/floating-button";
import {Sorting} from "@domain/common/list-view/sorting";
import {Router} from "@angular/router";
import {BehaviorSubject,Subscription} from "rxjs";
import {Report} from "@domain/reporting/report";
import {ReportingItemComponent} from "@components/admin/bibliotheque/reporting/reporting-item/reporting-item.component";
import {IComponentWithRoutedTabs} from "@domain/admin/recherche/component-with-routed-tabs";
import {PageHeaderItem} from "@share/component/page-header/page-header";
import {ReportingService} from "@components/reporting/reporting.service";
import {Alerte,NiveauAlerte} from "@domain/common/alerte/alerte";

/**
 * Enum pour les noms des différents onglets
 */
export enum Onglets {
    REPORTING = "Reporting"
}
/**
 * Composant concernant le reporting
 */
@Component({
    host: {'data-test-id': 'reporting'},
    templateUrl: './reporting.component.html'
})
export class ReportingComponent implements OnInit, OnDestroy, IComponentWithRoutedTabs {
    /** Liste statique des onglets */
    public static listeOnglets: Array<PageHeaderItem> = [
        {
            code: Onglets.REPORTING,
            libelle: 'admin.bibliotheque.reporting.onglets.reporting',
            url: 'Admin/Bibliotheque/Reporting/Reporting'
        }
    ];

    /** Liste des actions possibles */
    listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

    /** Liste des rapports, pour utiliser le composant ListViewComponent */
    liste: ListView<Report, ReportingItemComponent>;

    /** Nom du rapport en cours de génération (sinon null) */
    pendingReportName: string;

    /** Souscription */
    private subscription: Subscription;

    /** Liste des différents onglets disponibles */
    listeTabItems: Array<PageHeaderItem> = ReportingComponent.listeOnglets;

    /** Alerte à afficher lorsqu'un rapport est en cours de génération */
    alerte: Alerte;

    /**
     * Constructeur
     */
    constructor(protected translateService: TranslateService, public router: Router, private reportService: ReportingService) { }

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        //Abonnement aux mises à jour de génération
        this.subscription = this.reportService.pendingReportName$.subscribe(r => {
            //Sauvegarde du libellé du rapport en cours de génération
            this.pendingReportName = r;

            //Si le message comporte un libellé
            if (!!r) {
                //Création d'une alerte dédiée
                this.alerte = new Alerte({
                    niveau: NiveauAlerte.NO_CONTROL,
                    message: this.translateService.instant("admin.bibliotheque.reporting.generationEnCours", { libelle: r })
                });
            } else {
                //Sinon réinitialisation de l'alerte
                this.alerte = undefined;
            }
        });

        //Vérification du statut de la génération
        this.reportService.checkGenerationStatus();

        //Définition de la liste des actions du bouton en bas à droite de l'écran
        this.listeActions.next([{
            type: TypeAction.PRIMARY,
            icone: 'nio icon-ajouter',
            libelle: 'global.actions.creer',
            doAction: () => this.createNewReport(),
        }]);

        //Création de la liste des rapports
        this.liste = new ListView<Report, ReportingItemComponent>({
            uri: `/controller/Report/listeAllReport`,
            title: this.translateService.instant('admin.bibliotheque.reporting.title'),
            component: ReportingItemComponent,
            extraOptions: {
                onSelect: this.onSelect.bind(this)
            },
            isFilter: true,
            defaultOrder: 'groupeDefini,libelle',
            listeFilters: [
                {
                    clef: 'libelle',
                    title: this.translateService.instant('admin.bibliotheque.reporting.reportingFiltres.libelle'),
                    isDefault: true,
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                },{
                    clef: 'userFile',
                    title: this.translateService.instant('admin.bibliotheque.reporting.reportingFiltres.fichier'),
                    isDefault: true,
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                },{
                    clef: 'isActif',
                    title: this.translateService.instant('admin.bibliotheque.reporting.reportingFiltres.actif'),
                    isDefault: false,
                    typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
                    type: TypeFilter[TypeFilter.BOOLEAN]
                },{
                    clef: 'isStandard',
                    title: this.translateService.instant('admin.bibliotheque.reporting.reportingFiltres.standard'),
                    isDefault: false,
                    typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
                    type: TypeFilter[TypeFilter.BOOLEAN]
                },{
                    clef: 'groupeDefini',
                    title: this.translateService.instant('admin.bibliotheque.reporting.reportingFiltres.groupe'),
                    isDefault: false,
                    listeValues: [{
                        code: "1",
                        libelle: this.translateService.instant("admin.bibliotheque.reporting.groupe.1")
                    },{
                        code: "2",
                        libelle: this.translateService.instant("admin.bibliotheque.reporting.groupe.2")
                    },{
                        code: "3",
                        libelle: this.translateService.instant("admin.bibliotheque.reporting.groupe.3")
                    },{
                        code: "4",
                        libelle: this.translateService.instant("admin.bibliotheque.reporting.groupe.4")
                    },{
                        code: "5",
                        libelle: this.translateService.instant("admin.bibliotheque.reporting.groupe.5")
                    }]
                },{
                    clef: 'groupeCustom',
                    title: this.translateService.instant('admin.bibliotheque.reporting.reportingFiltres.groupeCustom'),
                    isDefault: false,
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                }
            ]
        });

        //Activation de la persistance des filtres
        this.liste.isPersistFilters = true;

        //Définition du nom de la classe pour la persistance des filtres dans le LocalStorage
        this.liste.className = 'ReportingComponent';

        //Définition des colonnes de tri
        this.liste.columns = [
            { key: 'groupeDefini', title: 'admin.bibliotheque.reporting.reportingFiltres.groupe' },
            { key: 'libelle', title: 'admin.bibliotheque.reporting.reportingFiltres.libelle' },
            { key: 'fileName', title: 'admin.bibliotheque.reporting.reportingFiltres.fichier' }
        ];

        //Ajout du tri de la liste selon l'ordre voulu
        this.liste.sorting = new Sorting(this.liste.columns, "groupeDefini,libelle");
    }

    /**
     * Méthode appelée lors de la sélection d'un rapport
     *
     * @param reportSelected Rapport sélectionné
     */
    onSelect(reportSelected: Report) {
        //Navigation vers les informations du rapport sélectionné
        this.router.navigate(['Admin/Bibliotheque/Report/'+reportSelected.idReport]);
    }

    /**
     * Méthode appelée lors de la création d'un nouveau rapport
     */
    createNewReport() {
        //Navigation vers le formulaire de création d'un rapport
        this.router.navigate(['Admin/Bibliotheque/Report/0']);
    }

    /**
     * Destruction du composant
     */
    ngOnDestroy() {
        //Résiliation
        this.subscription.unsubscribe();
    }
}
