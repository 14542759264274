import {Component,OnDestroy,OnInit} from '@angular/core';
import {PageHeaderItem} from "@share/component/page-header/page-header";
import {ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs";
import {IComponentWithRoutedTabs} from "@domain/admin/recherche/component-with-routed-tabs";

/**
 * Énum des onglets du menu Workflow - Motifs
 */
enum Onglets {
  MOTIFS = "Motifs"
}

/**
 * Composant du menu admin Workflow - Motifs
 *
 * @author Angeline Ha
 * @date 27/08/2024
 */
@Component({
  host: {'data-test-id': 'workflow-motifs'},
  selector: 'workflow-motifs',
  templateUrl: './workflow-motifs.component.html'
})
export class WorkflowMotifsComponent implements OnInit, OnDestroy, IComponentWithRoutedTabs {
  /** Liste statique des onglets */
  public static listeOnglets: Array<PageHeaderItem> = [{
    code: Onglets.MOTIFS,
    libelle: 'workflow.motifs.title',
    url: 'Admin/Workflow/Motifs/Motifs'
  }];

  /** Liste des onglets disponibles */
  listeTabItems: Array<PageHeaderItem> = WorkflowMotifsComponent.listeOnglets;

  /** Onglet courant */
  selectedItem: PageHeaderItem = this.listeTabItems[0];

  /** Indique si le header doit être affiché */
  isDisplayed: boolean;

  /** Subscription */
  subscription: Subscription;

  /**
   * Constructeur
   *
   * @param activatedRoute Route active
   */
  constructor(private activatedRoute: ActivatedRoute) {}

  /**
   * Initialisation
   */
  ngOnInit() {
    //Abonnement aux changements de route
    this.subscription = this.activatedRoute.url.subscribe(() => {
      //Récupération de la route
      const firstChild = this.activatedRoute.snapshot.firstChild;

      //Indique s'il faut afficher le header
      this.isDisplayed = (Object.values(firstChild.params).findIndex(v => v !== undefined) === -1);
    });
  }

  /**
   * Destruction
   */
  ngOnDestroy() {
    //Résiliation de l'abonnement
    this.subscription.unsubscribe();
  }
}
