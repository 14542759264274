import {NgModule} from "@angular/core";
import {Routes} from "@angular/router";
import {AdminGuardProvider} from "@core/security/admin-guard.provider";
import {ShareModule} from "@share/share.module";
import {WfConstructionComponent} from "./wf-construction.component";
import {ListeWfComponent} from "./liste-wf/liste-wf.component";

export const workflowConstructionRoutes : Routes = [
    {
        path: 'ListeWf',
        component: ListeWfComponent,
        canActivate: [AdminGuardProvider]
    }, {
        path: '',
        pathMatch: 'full',
        redirectTo: 'ListeWf'
    }, {
        path: '**',
        redirectTo: 'ListeWf'
    }
];

@NgModule({
    imports: [ShareModule],
    declarations: [
        WfConstructionComponent,
        ListeWfComponent
    ],
    entryComponents: [],
    exports: [
        ListeWfComponent
    ],
    providers: []
})
export class WfConstructionModule {}
