import {NgModule} from "@angular/core";
import {Routes} from "@angular/router";
import {AdminGuardProvider} from "@core/security/admin-guard.provider";
import {ShareModule} from "@share/share.module";
import {ParametreOrdonnanceurComponent} from "./parametre-ordonnanceur.component";
import {OrdonnanceurParametresComponent} from "./ordonnanceur-parametres/ordonnanceur-parametres.component";
import {DroitAdmin} from "@core/security/droit-admin";

export const parametreOrdonnanceurRoutes : Routes = [
    {
        path: 'Planifications',
        component: OrdonnanceurParametresComponent,
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_CONFIG_MOBILE] }
    }, {
        path: '',
        pathMatch: 'full',
        redirectTo: 'Planifications'
    }, {
        path: '**',
        redirectTo: 'Planifications'
    }
];

@NgModule({
    imports: [ShareModule],
    declarations: [
        ParametreOrdonnanceurComponent,
        OrdonnanceurParametresComponent
    ],
    entryComponents: [],
    exports: [
        OrdonnanceurParametresComponent
    ],
    providers: []
})
export class ParametreOrdonnanceurModule {}
