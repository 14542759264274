import { Component } from "@angular/core";

/**
 * Onglet prestations
 *
 * @author Guillaume TRAVOT
 * @date 26/04/2024
 */
@Component({
    host: {'data-test-id': 'prestations-liste'},
    templateUrl: './prestations-liste.component.html'
})
export class PrestationsListeComponent {}
