import {AfterViewInit,Component,OnDestroy,OnInit} from "@angular/core";
import {ActivatedRoute,NavigationExtras,Router} from "@angular/router";
import {FraisReferentielItemComponent} from "@components/admin/frais/referentiel/frais-referentiel-item/frais-referentiel-item.component";
import {Referentiel} from "@domain/admin/referentiels/referentiel";
import {ListView,TypeComparaison} from "@domain/common/list-view";
import {TranslateService} from "@ngx-translate/core";
import {FloatingButtonAction,TypeAction} from "@share/component/floating-button/floating-button";
import {PageHeaderItem} from "@share/component/page-header/page-header";
import {BehaviorSubject,Subscription} from "rxjs";
import {TypeReferentiel} from "@domain/admin/referentiels/type-referentiel";
import {ReferentielDto} from "@domain/admin/referentiels/referentielDto";
import {Onglets} from "@components/admin/frais/referentiel/onglets";
import {IComponentWithRoutedTabs} from "@domain/admin/recherche/component-with-routed-tabs";

/**
 * Composant d'affichage de la liste des référentiels de la gestion des frais
 */
@Component({
	host: {'data-test-id': 'frais-referentiel'},
	templateUrl: './frais-referentiel.component.html'
})
export class FraisReferentielComponent implements OnInit, OnDestroy,AfterViewInit,IComponentWithRoutedTabs {
	/** Liste statique des onglets */
	public static listeOnglets: Array<PageHeaderItem> = [
		{
			code: Onglets.FAMILLE,
			libelle: 'admin.frais.referentiels.types.famille',
			url: 'Admin/Frais/Referentiels/Famille'
		},{
			code: Onglets.INDEMNITE,
			libelle: 'admin.frais.referentiels.types.indemnite',
			url: 'Admin/Frais/Referentiels/Indemnite'
		},{
			code: Onglets.LIASSE,
			libelle: 'admin.frais.referentiels.types.liasse',
			url: 'Admin/Frais/Referentiels/Liasse'
		},{
			code: Onglets.TYPE_PRESTATION,
			libelle: 'admin.frais.referentiels.types.typePrestation',
			url: 'Admin/Frais/Referentiels/TypePrestation'
		},{
			code: Onglets.RUBRIQUE,
			libelle: 'admin.frais.referentiels.types.rubrique',
			url: 'Admin/Frais/Referentiels/Rubrique'
		},{
			code: Onglets.UNITE,
			libelle: 'admin.frais.referentiels.types.unite',
			url: 'Admin/Frais/Referentiels/Unite'
		}
	];

	/** Liste des actions possibles */
	listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

	/** Liste des différents onglets disponibles dans le menu "Référentiels" de l'entreprise */
	listeTabItems: Array<PageHeaderItem> = FraisReferentielComponent.listeOnglets;

	/** Onglet courant */
	selectedItem$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

	/** Liste des référentiels à afficher */
	listeReferentiels: ListView<Referentiel,FraisReferentielItemComponent>;

	/** Onglet cible lors du retour sur cet écran */
	targetTab: PageHeaderItem = null;

	/** Souscription */
	private subscription: Subscription;

	/**
	 * Constructeur
	 *
	 * @param translateService Service de traduction
	 * @param activatedRoute Service de routage
	 * @param router Router de l'application
	 */
	constructor(
		protected translateService: TranslateService,
		private activatedRoute: ActivatedRoute,
		private router: Router
	) {
		if (this.router.getCurrentNavigation()?.extras?.state?.referentiel) {
			//S'il y a un référentiel dans le state, c'est que l'on vient de le créer
			const referentiel: ReferentielDto = this.router.getCurrentNavigation()?.extras?.state?.referentiel;

			//Suivant le type de ce référentiel, on définit le targetTab
			switch (referentiel.type) {
				case TypeReferentiel.FAMILLE:
					this.targetTab = this.listeTabItems.find(tab => tab.code === Onglets.FAMILLE);
					break;
				case TypeReferentiel.INDEMNITE:
					this.targetTab = this.listeTabItems.find(tab => tab.code === Onglets.INDEMNITE);
					break;
				case TypeReferentiel.LIASSE:
					this.targetTab = this.listeTabItems.find(tab => tab.code === Onglets.LIASSE);
					break;
				case TypeReferentiel.TYPE_PRESTATION:
					this.targetTab = this.listeTabItems.find(tab => tab.code === Onglets.TYPE_PRESTATION);
					break;
				case TypeReferentiel.RUBRIQUE:
					this.targetTab = this.listeTabItems.find(tab => tab.code === Onglets.RUBRIQUE);
					break;
				case TypeReferentiel.NS_UNITE:
				case TypeReferentiel.NS_UNITE_DISTANCE:
					this.targetTab = this.listeTabItems.find(tab => tab.code === Onglets.UNITE);
					break;
			}
		} else {
			//On récupère un onglet à rejoindre s'il y en a un
			this.targetTab = this.router.getCurrentNavigation()?.extras?.state?.tab;
		}
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		this.subscription = this.activatedRoute.url.subscribe(() => {
			//Récupération de la route
			const firstChild = this.activatedRoute.snapshot.firstChild;

			//Extraction du path
			const path = firstChild.url[0].path;

			//On vérifie si on trouve l'onglet demandé via l'url
			const matching = this.listeTabItems.find(i => i.code === path);

			//Si on trouve bien l'onglet correspondant
			if (!!matching) {
				//on met à jour le composant header
				setTimeout(() => {
					this.targetTab = matching;
					this.selectedItem$.next((this.listeTabItems.findIndex(item => item.code === this.targetTab.code)));
				});
			} else {
				//Sinon, on redirige sur le premier onglet et on met à jour le composant header
				setTimeout(() => {
					this.targetTab = this.listeTabItems[0];
					this.selectedItem$.next((this.listeTabItems.findIndex(item => item.code === this.targetTab.code)));
				});
			}
		});

		//Initialisation de la liste des référentiels
		this.listeReferentiels = new ListView<Referentiel,FraisReferentielItemComponent>({
			uri: '/controller/Referentiel/loadFraisReferentiel',
			component: FraisReferentielItemComponent,
			isFilter: true,
			extraOptions: {
				listeTabItems: this.listeTabItems
			},
			listeFilters: [
				{
					clef: 'libelle',
					title: this.translateService.instant('admin.entreprise.referentiels.referentielFiltres.libelle'),
					isDefault: true,
					typeComparaison: TypeComparaison[TypeComparaison.LIKE]
				},{
					clef: 'code',
					title: this.translateService.instant('admin.entreprise.referentiels.referentielFiltres.code'),
					isDefault: true,
					typeComparaison: TypeComparaison[TypeComparaison.LIKE]
				},{
					clef: 'ordre',
					title: this.translateService.instant('admin.entreprise.referentiels.referentielFiltres.ordre'),
					isDefault: true,
					typeComparaison: TypeComparaison[TypeComparaison.LIKE]
				},
			]
		});

		//Définition de la liste des actions du bouton en bas à droite de l'écran
		this.listeActions.next([{
			type: TypeAction.PRIMARY,
			icone: 'nio icon-ajouter',
			libelle: 'global.actions.creer',
			doAction: () => this.createNewReferentiel()
		}]);
	}

	/**
	 * Après l'initialisation de la vue
	 */
	ngAfterViewInit(): void {
		//Si un onglet cible a été récupéré
		if (this.targetTab) {
			//On switch sur celui-ci
			this.selectedItem$.next((this.listeTabItems.findIndex(item => item.code === this.targetTab.code)));
		}
	}

	/**
	 * Méthode appelée lors de la création d'un nouveau référentiel
	 */
	createNewReferentiel() {
		//Définition des extras
		const extras: NavigationExtras = {
			state: {
				tab: this.listeTabItems[this.selectedItem$.value]
			}
		}

		//Navigation vers la page d'informations du référentiel
		this.router.navigate(['Admin/Frais/Referentiel/-1/false'],extras);
	}

	/**
	 * Changement d'onglet
	 *
	 * @param selectedItem Onglet sélectionné
	 */
	onSelectedItemChange(selectedItem: PageHeaderItem): void {
		//Mise à jour de l'onglet sélectionné
		this.selectedItem$.next(this.listeTabItems.findIndex(item => item.code === selectedItem.code))

		//Changement du titre de la liste
		this.listeReferentiels.title = this.translateService.instant(FraisReferentielComponent.getTitleByOnglet(selectedItem.code));

		//Suppression des filtres
		this.listeReferentiels.listeSelectedFilters = [];

		//Filtre de la liste par le référentiel sélectionné
		this.listeReferentiels.listeSelectedFilters.push({
			clef: 'type',
			listeObjects: FraisReferentielComponent.getTypeReferentielByOnglet(selectedItem.code),
			typeComparaison: TypeComparaison[TypeComparaison.IN],
			hiddenChip: true
		});

		//Rafraîchissement de la liste
		this.listeReferentiels.refresh && this.listeReferentiels.refresh();

		//Si un onglet est sélectionné
		if (!this.targetTab) {
			//Application de l'onglet
			this.targetTab = selectedItem;
		}

		//Si l'onglet a changé
		if (selectedItem?.code !== this.targetTab?.code) {
			//Application de l'onglet
			this.targetTab = selectedItem;
			this.router.navigateByUrl(selectedItem.url);
		}
	}

	/**
	 * Destruction du composant
	 */
	ngOnDestroy() {
		//Résiliation de l'abonnement
		this.subscription.unsubscribe();
	}

	/**
	 * Renvoie le titre à donner à la liste pour un onglet donné
	 *
	 * @param onglet le code d'un onglet
	 */
	static getTitleByOnglet(onglet: string): string {
		switch (onglet) {
			case Onglets.FAMILLE:
				return 'admin.frais.referentiels.types.famille';
			case Onglets.INDEMNITE:
				return 'admin.frais.referentiels.types.indemnite';
			case Onglets.LIASSE:
				return 'admin.frais.referentiels.types.liasse';
			case Onglets.TYPE_PRESTATION:
				return 'admin.frais.referentiels.types.typePrestation'
			case Onglets.RUBRIQUE:
				return 'admin.frais.referentiels.types.rubrique';
			case Onglets.UNITE:
				return 'admin.frais.referentiels.types.unite';
		}
	};

	/**
	 * Renvoie le type de référentiel associé à un onglet donné
	 *
	 * @param onglet le code d'un onglet
	 */
	static getTypeReferentielByOnglet(onglet: string): TypeReferentiel[] {
		switch (onglet) {
			case Onglets.FAMILLE:
				return [TypeReferentiel.FAMILLE];
			case Onglets.INDEMNITE:
				return [TypeReferentiel.INDEMNITE];
			case Onglets.LIASSE:
				return [TypeReferentiel.LIASSE];
			case Onglets.TYPE_PRESTATION:
				return [TypeReferentiel.TYPE_PRESTATION];
			case Onglets.RUBRIQUE:
				return [TypeReferentiel.RUBRIQUE];
			case Onglets.UNITE:
				return [TypeReferentiel.NS_UNITE,TypeReferentiel.NS_UNITE_DISTANCE];
		}
	};
}
