/**
 * Mapping des routes admin temporaires en attendant la refonte des pages admin en V10.
 * La map permet de limiter un peu les bidouilles via l'URL tout en autorisant la compréhension de la route.
 * L'URL conserve une signification et les utilisateurs peuvent copier-coller en conservant la page si besoin.
 * TODO: A nettoyer pour chaque nouvelle page admin V10 et à supprimer une fois toutes les pages portées en V10
 * */
export const FRAME_ROUTES: Map<string,{ path: string, idParam?: boolean}> = new Map([
    ["/Admin/Entreprise/Populations", { path: "controller/Population?action=listePopulation" }],
    ["enveloppes", { path: "controller/Analytique?action=consult" }],
    ["externes", { path: "servlet/NDFServlet#/Externes/consult?tab=0" }],
    ["parametres1", { path: "servlet/NDFServlet#/Externes/consult?tab=1" }],
    ["parametres2", { path: "controller/Notification?action=liste&tab=id_bloc_profil_config" }],
    ["motifs", { path: "servlet/NDFServlet#/WorkflowMotif" }],
    ["accueil", { path: "controller/Notification?action=liste&tab=id_bloc_messages_accueil" }],
    ["balises", { path: "controller/Notification?action=liste&tab=id_bloc_liste_balises" }],
    ["mails", { path: "servlet/NDFServlet#/Mail/configSMTP" }],
    ["messages", { path: "controller/Notification?action=liste&tab=id_bloc_liste_notifications" }],
    ["relances", { path: "controller/Notification?action=liste&tab=id_bloc_relances" }],
    ["typeEntite1", { path: "controller/TypeEntite?action=liste" }],
    ["typeEntite2", { path: "controller/Portee" }],
    ["zonesPredefinies", { path: "controller/ZoneUtilisateur?tab=id_zones_predefinies" }],
    ["zonesParametrables", { path: "controller/ZoneUtilisateur?tab=id_zones_parametrables" }],
    ["rest", { path: "servlet/NDFServlet#/API/comptes" }],
    ["soap", { path: "servlet/NDFServlet#/Interfaces/Configuration?tab=0" }],
    ["clefs-api", { path: "servlet/NDFServlet#/Interfaces/Referentiels?tab=0" }],
    ["authGeneralites", { path: "controller/AuthAdmin?tab=id_bloc_options" }],
    ["authSaml", { path: "controller/AuthAdmin?tab=id_bloc_saml" }],
    ["authListeNoire", { path: "controller/AuthAdmin?tab=id_bloc_blacklist" }],
    ["authComptesVerrouilles", { path: "controller/AuthAdmin?tab=id_bloc_comptes_verrouilles" }],
    ["mobilite", { path: "servlet/NDFServlet#/MobileConfig/loadConfig" }],
    ["ordonnanceur", { path: "servlet/NDFServlet#/Ordonnanceur/listeOrdo" }],
    ["liens", { path: "servlet/NDFServlet#/BoiteLien/listeLien" }],
    ["fournisseurs", { path: "servlet/NDFServlet#/Contrat/listeFournisseur" }],
    ["typesCharges", { path: "servlet/NDFServlet#/Interfaces/Referentiels?tab=2" }],
    ["tolerances", { path: "servlet/NDFServlet#/Interfaces/Configuration?tab=2" }],
    ["fraisMissions", { path: "servlet/NDFServlet#/IndemnitesJournalieres?tab=0" }],
    ["fraisAttribution", { path: "servlet/NDFServlet#/IndemnitesJournalieres?tab=1" }],
    ["fraisAbattements", { path: "servlet/NDFServlet#/IndemnitesJournalieres?tab=2" }],
    ["fraisParametres", { path: "servlet/NDFServlet#/IndemnitesJournalieres?tab=3" }],
    ["fraisCartes1", { path: "servlet/NDFServlet#/Interfaces/Configuration?tab=1" }],
    ["fraisCartes2", { path: "controller/SuiviImportFacture?tab=id_bloc_suivi_ca" }],
    ["prestations", { path: "servlet/NDFServlet#/Prestation/listePrestation" }],
    ["taxes", { path: "servlet/NDFServlet#/Taxe/param?tab=0" }],
    ["domaines", { path: "servlet/NDFServlet#/Taxe/param?tab=1" }],
    ["/Admin/Voyages/FraisAgence", { path: "servlet/NDFServlet#/FraisAgence" }],
    ["alertes", { path: "servlet/NDFServlet#/Alerte/liste" }],
    ["conditions", { path: "servlet/NDFServlet#/Condition/liste" }],
    ["wfConstruction", { path: "servlet/NDFServlet#/Workflow/liste" }],
    ["wfControle", { path: "servlet/NDFServlet#/Workflow/controle" }],
    ["wfMotifsListe", { path: "servlet/NDFServlet#/WorkflowMotif?tab=0" }],
    ["wfMotifsHistorique", { path: "servlet/NDFServlet#/WorkflowMotif?tab=1" }],
    ["statuts", { path: "servlet/NDFServlet#/Statut/liste/0" }],
    ["etats", { path: "servlet/NDFServlet#/Statut/liste/1" }],
    ["distances", { path: "controller/Geographie?tab_index=7"}],
    ["traductionMessages", { path: "controller/I18n?tab=id_bloc_liste_messages"}],
    ["traductionReferentiels", { path: "controller/I18n?tab=id_bloc_liste_referentiels"}],
    ["personnalisation1", { path: "servlet/NDFServlet#/Personnalisation"}],
    ["personnalisation2", { path: "servlet/NDFServlet#/BoiteLien/listeLien" }],
]);
