<list-view *ngIf="listeHabilitations && !listeProfilUserHabilitations" [liste]="listeHabilitations"></list-view>

<mat-card class="p-0" *ngIf="listeProfilUserHabilitations">
    <mat-card-content>
        <div class="listview">
            <div class="listview-search">
                <div class="search-container">
                    <div class="title">
                        <i class="nio icon-transfere clickable m-r-10" style="font-size: 1.75em; align-self: center" (click)="onGoBack()"></i>
                        <span [translate]="'admin.entreprise.utilisateurs.detail.generalites.habilitations.historiqueHabilitationsProfil' | translate: { profil: getLibelleProfil() }"></span>
                    </div>
                    <div class="search-spaceholder"></div>
                    <button mat-icon-button (click)="matDialogRef.close()">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
            </div>
            <div class="listview-body popin-list">
                <div class="listview-item" *ngFor="let habilitation of listeProfilUserHabilitations">
                    <div class="lvi-content">
                        <div class="avatar">
                            <i *ngIf="habilitation.role.fonction === TypeProfil.COMPTABLE" class="nio icon-comptable"></i>
                            <i *ngIf="habilitation.role.fonction === TypeProfil.RESPONSABLE" class="nio icon-responsable"></i>
                            <i *ngIf="habilitation.role.fonction === TypeProfil.COLLABORATEUR" class="nio icon-collaborateurs"></i>
                            <i *ngIf="habilitation.role.fonction === TypeProfil.ASSISTANT" class="nio icon-assistant"></i>
                            <i *ngIf="habilitation.role.fonction === TypeProfil.SOUS_ADMINISTRATEUR" class="nio icon-sous_administrateur"></i>
                            <i *ngIf="habilitation.role.fonction === TypeProfil.FOURNISSEUR" class="nio icon-fournisseur"></i>
                        </div>
                        <div class="lvi-body">
                            <div class="title">
                                <a (click)="openHabilitation(habilitation)">{{ habilitation.role.libelle }}</a>
                            </div>
                            <ul class="lvi-attrs">
                                <li *ngIf="habilitation.userDelegant">
                                    <strong [translate]="'admin.entreprise.utilisateurs.detail.generalites.habilitations.delegant'"></strong>
                                    <span>{{ habilitation.userDelegant | user }}</span>
                                </li>
                                <li *ngIf="habilitation.type === TypeLienRoleUser.DELEGATION && habilitation.user">
                                    <strong [translate]="'admin.entreprise.utilisateurs.detail.generalites.habilitations.delegue'"></strong>
                                    <span>{{ habilitation.user | user }}</span>
                                </li>
                                <li *ngIf="habilitation.dateDebut">
                                    <strong [translate]="'admin.entreprise.utilisateurs.detail.generalites.habilitations.dateDebut'"></strong>
                                    <span>{{ habilitation.dateDebut | date:'shortDate' }}</span>
                                </li>
                                <li *ngIf="habilitation.dateFin">
                                    <strong [translate]="'admin.entreprise.utilisateurs.detail.generalites.habilitations.dateFin'"></strong>
                                    <span>{{ habilitation.dateFin | date:'shortDate' }}</span>
                                </li>
                                <li *ngIf="habilitation.role.fonction !== TypeProfil.COLLABORATEUR">
                                    <strong [translate]="'admin.entreprise.utilisateurs.detail.generalites.habilitations.population'"></strong>
                                    <span>{{ habilitation.population ? habilitation.population.libelle : ('admin.entreprise.utilisateurs.detail.generalites.habilitations.tousLesCollabs' | translate) }}</span>
                                </li>
                                <li *ngIf="habilitation.listeEnveloppes.length > 0">
                                    <strong [translate]="'admin.entreprise.utilisateurs.detail.generalites.habilitations.enveloppes'"></strong>
                                    <span>{{ habilitation.listeEnveloppes.length }}</span>
                                </li>
                                <li>
                                    <strong [translate]="'admin.entreprise.utilisateurs.detail.generalites.habilitations.priorite'"></strong>
                                    <span [translate]="'admin.entreprise.utilisateurs.detail.generalites.habilitations.' + (habilitation.priorite === TypePriorite.TITULAIRE ? 'titulaire' : 'suppleant')"></span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>