import { NgModule } from "@angular/core";
import { FraisReferentielItemComponent } from "@components/admin/frais/referentiel/frais-referentiel-item/frais-referentiel-item.component";
import { FraisReferentielComponent } from "@components/admin/frais/referentiel/frais-referentiel.component";
import { FraisReferentielInfosIconesComponent } from "@components/admin/frais/referentiel/referentiel-infos/frais-referentiel-infos-icones.component";
import { FraisReferentielInfosComponent } from "@components/admin/frais/referentiel/referentiel-infos/frais-referentiel-infos.component";
import { ShareModule } from "@share/share.module";
import { Routes } from "@angular/router";
import { AdminGuardProvider } from "@core/security/admin-guard.provider";
import { DroitAdmin } from "@core/security/droit-admin";

export const fraisReferentielRoutes : Routes = [
    {
        path: 'Famille',
        component: FraisReferentielComponent,
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_FRAIS_PRESTATION] }
    }, {
        path: 'Indemnite',
        component: FraisReferentielComponent,
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_FRAIS_PRESTATION] }
    }, {
        path: 'Liasse',
        component: FraisReferentielComponent,
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_FRAIS_PRESTATION] }
    }, {
        path: 'TypePrestation',
        component: FraisReferentielComponent,
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_FRAIS_PRESTATION] }
    }, {
        path: 'Rubrique',
        component: FraisReferentielComponent,
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_FRAIS_PRESTATION] }
    }, {
        path: 'Unite',
        component: FraisReferentielComponent,
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_FRAIS_PRESTATION] }
    }, {
        path: '',
        pathMatch: 'full',
        redirectTo: 'Famille'
    }, {
        path: '**',
        redirectTo: 'Famille'
    }
];

/**
 * Module des référentiels de la gestion des frais
 */
@NgModule({
    imports: [ShareModule],
    declarations: [
        FraisReferentielComponent,
        FraisReferentielItemComponent,
        FraisReferentielInfosComponent,
        FraisReferentielInfosIconesComponent
    ],
    entryComponents: [FraisReferentielItemComponent],
    exports: [
        FraisReferentielComponent,
        FraisReferentielItemComponent,
        FraisReferentielInfosComponent,
        FraisReferentielInfosIconesComponent
    ],
    providers: []
})
export class FraisReferentielModule {
}
