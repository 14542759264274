import {NgModule} from '@angular/core';
import {CoreComponentsModule} from "../core-components.module";
import {CommonModule} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {AutocompleteModule} from "../autocomplete/autocomplete.module";
import {MatSelectModule} from "@angular/material/select";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {DelegationListItemComponent} from "./delegation-list-item/delegation-list-item.component";
import {DelegationHistoryPopinComponent} from "./delegation-history-popin/delegation-history-popin.component";
import {DelegationHistoryListItemComponent} from "./delegation-history-list-item/delegation-history-list-item.component";
import {ListViewModule} from "@share/component/list-view/list-view.module";
import {ShareModule} from "@share/share.module";


@NgModule({
    imports: [
        CoreComponentsModule,
        CommonModule,
        TranslateModule,
        AutocompleteModule,
        MatSelectModule,
        MatDatepickerModule,
        ListViewModule,
        ShareModule,
    ],
    declarations: [
        DelegationListItemComponent,
        DelegationHistoryPopinComponent,
        DelegationHistoryListItemComponent,
    ],
    exports: [
        DelegationListItemComponent,
        DelegationHistoryPopinComponent,
        DelegationHistoryListItemComponent,
    ]
})
export class DelegationModule {

}
