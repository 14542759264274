import {Component,OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {ListView,TypeComparaison,TypeFilter} from "@domain/common/list-view";
import {FloatingButtonAction,TypeAction} from "@share/component/floating-button/floating-button";
import {BehaviorSubject} from "rxjs";
import {PopulationListItemComponent} from "./population-list-item/population-list-item.component";
import {TranslateService} from "@ngx-translate/core";
import {PageHeaderItem} from "@share/component/page-header/page-header";
import {Population} from "@domain/workflow/population";
import {Origine,TypeCreation} from "@domain/admin/entreprise/population/population";


/**
 * Liste des populations
 *
 * @author Tom JEAMMET
 */
@Component({
    host: {'data-test-id': 'population-list'},
    templateUrl: './population-list.component.html'
})
export class PopulationListComponent implements OnInit {
    /** Liste des différents onglets disponibles dans le menu entreprise */
    listeTabItems: PageHeaderItem[] = [{
        code: Onglets.GENERALITES,
        libelle: this.translateService.instant('admin.entreprise.populations.onglets.generalites'),
        url: 'Admin/Entreprise/Populations/Liste'
    }];

    /** Onglet courant */
    selectedItem: PageHeaderItem = this.listeTabItems[0];

    /** Index de l'onglet courant */
    selectedIndex: number = 0;

    /** Liste des populations */
    listePopulations: ListView<Population,PopulationListItemComponent>;

    /** Liste des actions possibles */
    listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

    /**
     * Constructeur de la classe.
     *
     * @param translateService Le service de traduction utilisé dans la classe.
     * @param router Le routeur utilisé dans la classe.
     */
    constructor(
        private translateService: TranslateService,
        private router: Router
    ) { }

    /**
     * Hook initialisation
     */
    ngOnInit(): void {
        //Initialisation de la liste des populations
        this.listePopulations = new ListView<Population,PopulationListItemComponent>({
            uri: `/controller/Population/filtrePopulation`,
            title: this.translateService.instant('admin.entreprise.populations.liste.title'),
            component: PopulationListItemComponent,
            isFilter: true,
            defaultOrder: 'libelle',
            listeFilters: [
                {
                    clef: 'libelle',
                    title: this.translateService.instant('admin.entreprise.populations.liste.libelle'),
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE],
                    isDefault: true
                },{
                    clef: 'reference',
                    title: this.translateService.instant('admin.entreprise.populations.liste.reference'),
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE],
                    isDefault: true
                },{
                    clef: 'origine',
                    title: this.translateService.instant('admin.entreprise.populations.liste.origine'),
                    typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
                    isDefault: true,
                    multiple: true,
                    listeValues: [
                        {
                            code: '' + Origine.ORIGINE_ADMINISTRATEUR,
                            libelle: this.translateService.instant('admin.entreprise.populations.liste.admin')
                        },{
                            code: '' + Origine.ORIGINE_IMPORT,
                            libelle: this.translateService.instant('admin.entreprise.populations.liste.import')
                        },{
                            code: '' + Origine.ORIGINE_USER,
                            libelle: this.translateService.instant('admin.entreprise.populations.liste.user')
                        }
                    ]
                },{
                    clef: 'typeCreation',
                    title: this.translateService.instant('admin.entreprise.populations.liste.typeCreation'),
                    typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
                    isDefault: true,
                    listeValues: [
                        {
                            code: '' + TypeCreation.TYPE_CREATION_STANDARD,
                            libelle: this.translateService.instant('admin.entreprise.populations.liste.standard')
                        },{
                            code: '' + TypeCreation.TYPE_CREATION_VOLEE,
                            libelle: this.translateService.instant('admin.entreprise.populations.liste.volee')
                        }
                    ]
                },{
                    clef: 'isActif',
                    title: this.translateService.instant('admin.entreprise.populations.liste.actif'),
                    type: TypeFilter[TypeFilter.BOOLEAN],
                    typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
                    isDefault: false,
                    onloadValue: 'true'
                }
            ]
        });

        //Définition des colonnes de tri
        this.listePopulations.columns = [
            { key: 'libelle', title: 'admin.entreprise.populations.liste.libelle' },
            { key: 'reference', title: 'admin.entreprise.populations.liste.reference' },
            { key: 'origine', title: 'admin.entreprise.populations.liste.origine' },
            { key: 'typeCreation', title: 'admin.entreprise.populations.liste.typeCreation' },
            { key: 'isActif', title: 'admin.entreprise.populations.liste.actif' }
        ];

        //Persistence des filtres
        this.listePopulations.isPersistFilters = true;
        this.listePopulations.className = 'PopulationListComponent';

        //Initialisation des actions possibles
        this.listeActions.next([{
            type: TypeAction.PRIMARY,
            icone: 'nio icon-ajouter',
            libelle: 'global.actions.creer',
            doAction: () => this.create()
        }]);
    }

    /**
     * Méthode appelée lors de la création d'un nouveau service
     */
    create() {
        this.router.navigate(['Admin/Entreprise/Populations/Population/-1']);
    }

    /**
     * Changement d'onglet
     *
     * @param selectedItem Onglet sélectionné
     */
    onSelectedItemChange(selectedItem: PageHeaderItem): void {
        //Si on n'a pas encore initialisé un selectItem
        if (!this.selectedItem) {
            this.selectedItem = selectedItem;
        }
        if (selectedItem.code !== this.selectedItem.code) {
            //Si le code n'est pas le code courant, on change
            this.selectedItem = selectedItem;
            this.selectedIndex = this.listeTabItems.findIndex(item => selectedItem.code === item.code);
        }
    }
}

/**
 * Enum pour les noms des différents onglets de la page Entreprise/population
 */
export enum Onglets {
    GENERALITES = "Généralités"
}
