import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot,CanActivate,Router} from '@angular/router';

import {LoginService} from '@share/login/login.service';
import {Session} from "@domain/security/session";
import {TypeProfil} from "@domain/user/user";
import {getMinimumCredentials,isAllowed} from "@core/security/role-admin-helpers";
import {DroitAdmin} from "@core/security/droit-admin";

/**
 * Gardien pour l'accès à la partie administration réservée à l'administrateur et aux sous administrateurs
 */
@Injectable()
export class AdminGuardProvider implements CanActivate {
	/**
	 * Constructeur
	 */
	constructor(private loginService: LoginService,private router: Router) {}

	/**
	 * Activation du composant.
	 *
	 * @param route route qu'on veut atteindre, dont on vérifie le droit d'activation
	 */
	async canActivate(route: ActivatedRouteSnapshot) {
		//Initialisation
		let canActivate: boolean = false;
		let session: Session;

		//Récupération de la session
		session = this.loginService.getSession();

		//Si l'utilisateur est légitime
		if (session?.isLogged && (session?.isAdmin || session?.user?.fonction === TypeProfil.SOUS_ADMINISTRATEUR)) {
			//Vérification des droits
			canActivate = isAllowed(session, getMinimumCredentials([route.routeConfig]));

			//Si l'accès est refusé à priori
			if (!canActivate) {
				//Les droits étant variables, on n'est jamais certain que la route par défaut est autorisée, donc on essaye les sœurs à partir du parent.
				for (const childRoute of route.parent.routeConfig.children) {
					//Récupération des règles de la route sœur
					const credentialsChild = childRoute.data?.sousAdminCredentials as DroitAdmin[];

					//Si la route est autorisée et sans paramètre à fournir
					if (!childRoute.path.includes(':') && childRoute.path != '' && childRoute.path != '**' && isAllowed(session, credentialsChild)) {
						//Récupération de la racine
						const path: string = (route['_routerState']['url'] as string).replace(new RegExp(route.url.toString() + '.*$'), '');

						//Redirection
						await this.router.navigate([(path + childRoute.path)]);

						//Fin du débat
						return false;
					}
				}
			}
		}

		//Vérification de l'accès
		if (!canActivate) {
			//Attente du chargement du mode d'authentification (si on tente d'accéder à une route de la partie admin sans être passé par le login il n'a pas encore été chargé)
			await this.loginService.loadAuth().toPromise();

			//Si la session est encore active
			if (session.isLogged) {
				//On a pas le droit d'être là : Logout qui renverra à l'accueil
				this.loginService.logout();
			} else {
				//Sinon redirection vers la page de Login
				this.router.navigate(['Login']);
			}
		}

		return canActivate;
	}
}
