import {ActionReducer} from '@ngrx/store';
import {localStorageSync} from 'ngrx-store-localstorage';

import {sessionReducer} from './session';
import {listviewReducer} from './listview';
import {layoutReducer} from './layout';
import {settingsReducer} from './settings';
import {saisieReducer} from './saisie';
import {typeEntiteReducer} from './type-entite';
import {navigationReducer} from './navigation';
import {authReducer} from "@reducers/auth";
import {searchReducer} from "@reducers/search";

/**
 * Export des reducers
 */
export const reducers = {
    session: sessionReducer,
    listview: listviewReducer,
    layout: layoutReducer,
    settings: settingsReducer,
    saisie: saisieReducer,
    typeEntite: typeEntiteReducer,
    navigation: navigationReducer,
    auth: authReducer,
    search: searchReducer
};

/**
 * Export de la configuration
 */
export function metaReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    //Configuration de la persistance
    return localStorageSync({ keys: ['session','navigation','auth'],rehydrate: true,storage: sessionStorage })(reducer);
}
