import {NgModule} from '@angular/core';
import {Routes} from "@angular/router";
import {ShareModule} from "@share/share.module";
import {WorkflowMotifsComponent} from "./workflow-motifs.component";
import {MotifListeComponent} from "@components/admin/workflow/motifs/motif-liste/motif-liste.component";
import {MotifListeItemComponent} from "@components/admin/workflow/motifs/motif-liste/motif-liste-item.component";
import {WorkflowMotifsService} from "@services/admin/workflow/workflow-motifs.service";
import {MotifDetailComponent} from "@components/admin/workflow/motifs/detail/motif-detail/motif-detail.component";
import {MotifGeneralitesComponent} from "@components/admin/workflow/motifs/detail/motif-generalites/motif-generalites.component";

/** Routes du menu Workflow - Motifs */
export const workflowMotifsRoutes: Routes = [
    {
        path: 'Motifs',
        component: MotifListeComponent
    }, {
        path: 'Motifs/:idMotif',
        component: MotifDetailComponent
    }, {
        path: '',
        pathMatch: 'full',
        redirectTo: 'Motifs'
    },{
        path: '**',
        redirectTo: 'Motifs'
    }
]

/**
 * Module du menu Workflow - Motifs
 */
@NgModule({
    declarations: [
        WorkflowMotifsComponent,
        MotifListeComponent,
        MotifListeItemComponent,
        MotifDetailComponent,
        MotifGeneralitesComponent
    ],
    providers: [
        WorkflowMotifsService
    ],
    imports: [
        ShareModule
    ]
})
export class WorkflowMotifsModule {
}
