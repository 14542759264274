import {Component, OnInit} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {FloatingButtonAction, TypeAction} from "@share/component/floating-button/floating-button";
import {GeographieService} from "@services/admin/geographie/geographie.service";
import {ActivatedRoute, Router} from "@angular/router";

/**
 * Liste des IATA
 *
 * @author Angeline Ha
 * @date 13/02/2024
 */
@Component({
    host: {'data-test-id': 'iata-list'},
    selector: 'iata-list',
    templateUrl: './iata-list.component.html'
})
export class IataListComponent implements OnInit {

    /** Liste des actions possibles */
    listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

    /**
     * Constructeur
     *
     * @param geographieService Service géographie
     * @param router            Router de l'application
     * @param route             Route active
     */
    constructor(public geographieService: GeographieService,
                private router: Router,
                private route: ActivatedRoute) {
    }

    /**
     * Initialisation
     */
    ngOnInit(): void {
        //Définition de la liste des actions du bouton en bas à droite de l'écran
        this.listeActions.next([{
                type: TypeAction.PRIMARY,
                icone: 'nio icon-ajouter',
                libelle: 'global.actions.creer',
                doAction: () => this.createNewIata()
            }]
        );
    }

    /**
     * Création d'un nouveau iata
     */
    createNewIata() {
        this.router.navigate(['Admin/Bibliotheque/Geographie/Iata/0']);
    }

}
