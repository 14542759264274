import {NgModule} from "@angular/core";
import {Routes} from "@angular/router";
import {AdminGuardProvider} from "@core/security/admin-guard.provider";
import {ShareModule} from "@share/share.module";
import {ParametrePersonnalisationComponent} from "./parametre-personnalisation.component";
import {PersonnalisationLiensComponent} from "@components/admin/parametre/personnalisation/personnalisation-liens/personnalisation-liens.component";

export const parametrePersonnalisationRoutes : Routes = [
    {
        path: 'Liens',
        component: PersonnalisationLiensComponent,
        canActivate: [AdminGuardProvider]
    }, {
        path: '',
        pathMatch: 'full',
        redirectTo: 'Liens'
    }, {
        path: '**',
        redirectTo: 'Liens'
    }
];

@NgModule({
    imports: [ShareModule],
    declarations: [
        ParametrePersonnalisationComponent,
        PersonnalisationLiensComponent
    ],
    entryComponents: [],
    exports: [
        PersonnalisationLiensComponent
    ],
    providers: []
})
export class ParametrePersonnalisationModule {}
