import { Component } from "@angular/core";

/**
 * Onglet règles d'attribution
 *
 * @author Guillaume TRAVOT
 * @date 26/04/2024
 */
@Component({
    host: {'data-test-id': 'indemnites-regles-attribution'},
    templateUrl: './indemnites-regles-attribution.component.html'
})
export class IndemnitesReglesAttributionComponent {}
