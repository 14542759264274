import {Component,OnInit} from "@angular/core";
import {ListView} from "@domain/common/list-view";
import {Langue} from "@domain/admin/bibliotheque/internationalisation/langue";
import {LangueItemComponent} from "../langue-item/langue-item.component";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";
import {Result} from "@domain/common/http/result";

/**
 * Onglet des langues
 *
 * @author Guillaume TRAVOT
 * @date 29/10/2024
 */
@Component({
    host: {'data-test-id': 'internationalisation-langues'},
    templateUrl: './internationalisation-langues.component.html'
})
export class InternationalisationLanguesComponent implements OnInit {
    /** Liste des langues, pour utiliser le composant ListViewComponent */
    liste: ListView<Langue, LangueItemComponent>;

    /**
     * Constructeur
     */
    constructor(protected translateService: TranslateService,public router: Router) {}

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        //Cr�ation de la liste des langues
        this.liste = new ListView<Langue, LangueItemComponent>({
            uri: `/controller/Traduction/listeLangue`,
            title: this.translateService.instant('admin.bibliotheque.internationalisation.onglets.langues'),
            component: LangueItemComponent,
            extraOptions: {
                onSelect: this.onSelect.bind(this)
            },
            isSimple: true,
            mapResult: (result: Result) => {
                return result?.data?.listeLangue
            },
        });
    }

    /**
     * M�thode appel�e lors de la s�lection d'une langue
     *
     * @param langueSelected Langue s�lectionn�e
     */
    onSelect(langueSelected) {
        //Navigation vers les informations de la langue s�lectionn�e
        this.router.navigate(['Admin/Bibliotheque/Internationalisation/Langue/'+langueSelected.code]);
    }
}
