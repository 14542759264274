import {Component} from '@angular/core';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';

import {AppState} from '@domain/appstate';
import {TypeComparaison,TypeFilter} from '@domain/common/list-view';
import {AvanceListItemComponent} from './avance-list-item/avance-list-item.component';
import {ListeAvance} from '@domain/avance/liste-avance';
import {WorkflowService} from '../../workflow/workflow.service';
import {TypePortee} from '@domain/workflow/workflow';
import {NavigationExtras,Router} from '@angular/router';
import {AbstractWorkflowListComponent} from '../../workflow/abstract-workflow-list.component';
import {MatDialog} from "@angular/material/dialog";
import {SessionStorageService} from '@domain/common/services/session-storage.service';
import {CguService} from '@components/cgu/cgu.service';
import {ToastrService} from 'ngx-toastr';
import {LotItemType} from "@domain/comptabilite/lot";
import {ComptabiliteService} from "@components/comptabilite/comptabilite.service";
import {UserDto} from "@domain/user/user-dto";
import {AvanceService} from "@components/avance/avance.service";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {IComponentWithRoutedTabs} from "@domain/admin/recherche/component-with-routed-tabs";
import {PageHeaderItem} from "@share/component/page-header/page-header";

@Component({
    host: {'data-test-id': 'avance-list'},
    templateUrl: './avance-list.component.html'
})
export class AvanceListComponent extends AbstractWorkflowListComponent<ListeAvance,AvanceListItemComponent> implements IComponentWithRoutedTabs {
    /** Liste statique des onglets */
    public static listeOnglets: Array<PageHeaderItem> = [{
        code: undefined,
        libelle: 'avance.liste.title',
        url: 'Admin/Frais/Avances'
    }];

    /**
     * Constructeur
     */
    constructor(
        protected router: Router,
        protected translateService: TranslateService,
        protected store: Store<AppState>,
        protected workflowService: WorkflowService,
        protected matDialog: MatDialog,
        protected sessionStorageService: SessionStorageService,
        protected cguService: CguService,
        protected toastrService: ToastrService,
        protected comptabiliteService: ComptabiliteService,
        protected avanceService: AvanceService
    ) {
        //Constructeur parent
        super(
            translateService,
            store,
            workflowService,
            matDialog,
            TypePortee.AV,
            '/controller/Avance',
            'avance.liste.title',
            '-idAvance',
            'AvanceListComponent',
            AvanceListItemComponent,
            sessionStorageService,
            cguService,
            toastrService
        );
    }

    /**
     * Spécificités de la liste héritière
     *
     * @param initOnlyColumns True si on veut initialiser uniquement les colonnes de la liste (false par défaut)
     */
    protected initListSpecific(initOnlyColumns?: boolean): void {
        //Définition des colonnes de tri
        this.liste.columns = [
            { key: 'idAvance', title: 'avance.liste.columns.idAvance' },
            { key: 'libelleObjet', title: 'avance.liste.columns.libelleObjet' },
            { key: 'idOd', title: 'avance.liste.columns.idOd' },
            { key: 'nature', title: 'avance.liste.columns.nature' },
            { key: 'dateStatut', title: 'avance.liste.columns.dateStatut' },
            { key: 'user.matricule', title: 'avance.liste.columns.user.matricule' },
            { key: 'statut.idStatut', title: 'avance.liste.columns.statut' }
        ];

        //Si on ne veut pas initialiser que les colonnes
        if (!initOnlyColumns) {
            //Ajout des filtres spécifiques
            this.liste.listeFilters = [
                ...[
                    {
                        //Ajout du filtre sur l'objet
                        clef: 'libelleObjet',
                        title: this.translateService.instant('avance.liste.filtres.libelleObjet'),
                        isDefault: true,
                        typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                    }, {
                        //Ajout du filtre sur le numéro
                        clef: 'idAvance',
                        title: this.translateService.instant('avance.liste.filtres.numero'),
                        isDefault: false,
                        type: TypeFilter[TypeFilter.LONG],
                        typeComparaison: TypeComparaison[TypeComparaison.EQUAL]
                    }, {
                        //Ajout du filtre sur l'OM
                        clef: 'idOd',
                        title: this.translateService.instant('avance.liste.filtres.om'),
                        isDefault: false,
                        type: TypeFilter[TypeFilter.LONG],
                        typeComparaison: TypeComparaison[TypeComparaison.EQUAL]
                    }, {
                        //Ajout du filtre sur la date du statut
                        clef: 'dateStatut',
                        title: this.translateService.instant('avance.liste.filtres.dateStatut'),
                        type: TypeFilter[TypeFilter.DATE]
                    }, {
                        //Ajout du filtre sur le type
                        clef: 'nature',
                        title: this.translateService.instant('avance.liste.filtres.type'),
                        listeValues: [{
                            code: 'ESP',
                            libelle: this.translateService.instant('avance.liste.filtres.typeESP')
                        }, {
                            code: 'VIR',
                            libelle: this.translateService.instant('avance.liste.filtres.typeVIR')
                        }]
                    }, {
                        //Ajout du filtre sur le matricule
                        clef: 'user.matricule',
                        title: this.translateService.instant('avance.liste.filtres.matricule'),
                        isDefault: true,
                        typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                    }
                ],
                ...this.liste.listeFilters
            ];
        }
    }

    /**
     * Création d'une avance.
     *
     * @param collab Collab pour lequel on crée l'objet WF (cas d'un assistant/responsable/comptable)
     */
    protected create(collab?: UserDto): void {
        //Définition d'un objet contenant l'id du collaborateur à passer au composant des avances
        const extras: NavigationExtras = {
            state: {
                //On ne passe que les informations nécessaires au composant <propietaire.component.ts> car l'objet est trop gros pour le state
                collab: collab ? {
                    idUser: collab.idUser,
                    nom: collab.nom,
                    prenom: collab.prenom,
                } as UserDto : null
            }
        };

        this.router.navigate(['Avance', 0], extras);
    }

    /**
     * Permet la création d'un lot comptable depuis la sélection
     */
    protected comptabiliser() {
        //Mise en session des items à ajouter au lot
        this.comptabiliteService.setItemsForLot(LotItemType.AVANCE,this.liste.data.listeResultats);

        //Création d'un nouveau lot
        this.router.navigate(['Lot', 0]);
    }

    /** @inheritdoc */
    protected hideDisplayItems(displayMode: boolean) {
        //Appel au service
        this.avanceService.hideDisplay(this.liste.listeObjetsSectionnes.map(a => a.idAvance),displayMode)
            .subscribe((result: Result) => {
                //Vérification du result
                if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
                    //Toast succès
                    this.toastrService.success(this.translateService.instant(`global.success.${displayMode ? 'demasquer' : 'masquer'}`,{nb: result.data.nbItems}));

                    //Refresh liste
                    this.liste.refresh();
                } else {
                    //Gestion de l'erreur
                    TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
                }
            });
    }
}
