import {TypeUsage} from "@components/admin/comptabilite/plan-comptable/comptes-comptables/detail-compte-comptable/compte-comptable-usage/compte-comptable-usage.component";
import {BehaviorSubject,Observable} from "rxjs";

/**
 * Compteur pour un type d'usage
 *
 * @author Laurent Convert
 * @date 07/10/2024
 */
export class CompteUsageCompteur {
	/** Type d'usage associé au compteur */
	typeUsage: TypeUsage;
	/** Libellé du compteur */
	libelle: string;
	/** Compteur (public) */
	count: Observable<number>;
	/** Compteur (privé) */
	private _count: BehaviorSubject<number>;

	/**
	 * Constructeur
	 *
	 * @param typeUsage Type d'usage
	 */
	constructor(typeUsage:TypeUsage) {
		//Initialisation
		this.typeUsage = typeUsage;
		this.libelle = `admin.comptabilite.planComptable.compteComptable.usage.typeUsage.${typeUsage}`;

		//Initialisation du compteur
		this._count = new BehaviorSubject(null);
		this.count = this._count.asObservable();
	}

	/**
	 * Met à jour le compteur
	 *
	 * @param count Nombre d'usages
	 */
	setCount(count: number) {
		this._count.next(count);
	}
}