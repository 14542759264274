import {Component,Inject,OnInit} from '@angular/core';
import {ListView,TypeComparaison,TypeFilter} from "@domain/common/list-view";
import {LienRoleUser} from "@domain/workflow/lienRoleUser";
import {TranslateService} from "@ngx-translate/core";
import {MAT_DIALOG_DATA,MatDialogRef} from "@angular/material/dialog";
import {DelegationHistoryListItemComponent} from "@share/component/delegation/delegation-history-list-item/delegation-history-list-item.component";
import {Sorting} from "@domain/common/list-view/sorting";

/**
 * Composant d'affichage de la page délégation history popin
 *
 * @author Lionel Gimenez
 * @date 26/09/2024
 */
@Component({
    host: {'data-test-id': 'delegation-history-popin'},
    templateUrl: './delegation-history-popin.component.html',
    styles: [
        '::ng-deep .mat-dialog-without-margin .mat-dialog-container { padding: 0 }',
        '::ng-deep .mat-dialog-without-margin .mat-card { margin-bottom: 0 }'
    ]
})
export class DelegationHistoryPopinComponent implements OnInit {
    /** ListeView de l'historique de la délégation */
    listeViewDelegationHistory: ListView<LienRoleUser,DelegationHistoryListItemComponent>;

    /** Liste de l'historique de la délégation */
    listeDelegationHistory: LienRoleUser[];

    /**
     * Constructeur
     */
    constructor(
        public translateService: TranslateService,
        public matDialogRef: MatDialogRef<DelegationHistoryPopinComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            uriHistory: string,
            canShowDelegue: boolean,
            canShowDeleguant: boolean,
        },
    ) {
    }

    /**
     * Hook initialisation
     */
    ngOnInit(): void {
        this.initDelegation();
    }

    /**
     * Initialisation de la ListView délégation
     */
    initDelegation(): void {
        //Initialisation de la liste des habilitations
        this.listeViewDelegationHistory = new ListView<LienRoleUser,DelegationHistoryListItemComponent>({
            title: this.data.canShowDelegue
                ? this.translateService.instant('admin.entreprise.utilisateurs.detail.generalites.habilitations.titleHistoriqueAccordee')
                : this.translateService.instant('admin.entreprise.utilisateurs.detail.generalites.habilitations.titleHistoriqueRecue'),
            component: DelegationHistoryListItemComponent,
            uri: this.data.uriHistory,
            listeActions: [
                {
                    icon: 'close',
                    onPress: () => this.matDialogRef.close()
                }
            ],
            extraOptions: {
                canShowDelegue: this.data.canShowDelegue,
                canShowDeleguant: this.data.canShowDeleguant,
            },
            defaultOrder: 'dateDebut',
            listeFilters: [
                {
                    clef: 'role.fonction',
                    title: this.translateService.instant('admin.entreprise.utilisateurs.detail.generalites.habilitations.fonction'),
                    listeValues: [
                        {
                            code: '1',
                            libelle: this.translateService.instant('profil.comptable')
                        },{
                            code: '2',
                            libelle: this.translateService.instant('profil.responsable')
                        },{
                            code: '3',
                            libelle: this.translateService.instant('profil.collaborateur')
                        },{
                            code: '4',
                            libelle: this.translateService.instant('profil.assistant')
                        },{
                            code: '5',
                            libelle: this.translateService.instant('profil.sousAdministrateur')
                        },{
                            code: '6',
                            libelle: this.translateService.instant('profil.fournisseur')
                        }
                    ]
                },{
                    clef: 'role.libelle',
                    title: this.translateService.instant('admin.entreprise.utilisateurs.detail.generalites.habilitations.role'),
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE],
                    isDefault: true
                },{
                    clef: 'dateDebut',
                    title: this.translateService.instant('admin.entreprise.utilisateurs.detail.generalites.habilitations.dateDebut'),
                    type: TypeFilter[TypeFilter.DATE],
                    typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
                    isDefault: true,
                },{
                    clef: 'dateFin',
                    title: this.translateService.instant('admin.entreprise.utilisateurs.detail.generalites.habilitations.dateFin'),
                    type: TypeFilter[TypeFilter.DATE],
                    typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
                    isDefault: true,
                },{
                    clef: '*population.libelle',
                    title: this.translateService.instant('admin.entreprise.utilisateurs.detail.generalites.habilitations.population'),
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE],
                    isDefault: true
                },
            ]
        });

        //Définition des colonnes de tri
        this.listeViewDelegationHistory.columns = [
            {key: 'role.fonction',title: 'admin.entreprise.utilisateurs.detail.generalites.habilitations.fonction'},
            {key: 'role.libelle',title: 'admin.entreprise.utilisateurs.detail.generalites.habilitations.role'},
            {key: 'dateDebut',title: 'admin.entreprise.utilisateurs.detail.generalites.habilitations.dateDebut'},
            {key: 'dateFin',title: 'admin.entreprise.utilisateurs.detail.generalites.habilitations.dateFin'},
            {key: 'population.libelle',title: 'admin.entreprise.utilisateurs.detail.generalites.habilitations.population'}
        ];

        if (this.data.canShowDeleguant) {
            this.listeViewDelegationHistory.listeFilters.push({
                clef: 'userDelegant.matricule,userDelegant.nom,userDelegant.prenom',
                title: this.translateService.instant('admin.entreprise.utilisateurs.detail.generalites.habilitations.delegant'),
                typeComparaison: TypeComparaison[TypeComparaison.LIKE],
                isDefault: true
            });

            this.listeViewDelegationHistory.columns.push(
                {key: 'userDelegant.nom',title: 'admin.entreprise.utilisateurs.detail.generalites.habilitations.delegant'});

        }

        if (this.data.canShowDelegue) {
            this.listeViewDelegationHistory.listeFilters.push({
                clef: 'user.matricule,user.nom,user.prenom',
                title: this.translateService.instant('admin.entreprise.utilisateurs.detail.generalites.habilitations.delegue'),
                typeComparaison: TypeComparaison[TypeComparaison.LIKE],
                isDefault: true
            });

            this.listeViewDelegationHistory.columns.push(
                {key: 'userDelegue.nom',title: 'admin.entreprise.utilisateurs.detail.generalites.habilitations.delegue'});

        }

        //Ajout du tri de la liste selon l'ordre voulu
        this.listeViewDelegationHistory.sorting = new Sorting(this.listeViewDelegationHistory.columns,"dateDebut");

    }
}