import {Component,Input,OnInit} from '@angular/core';
import {ListView,TypeComparaison,TypeFilter} from "@domain/common/list-view";
import {TranslateService} from "@ngx-translate/core";
import {CompteComptableHistoriqueListItemComponent} from "./compte-comptable-historique-list-item.component";
import {Clef,HistoriqueCompteComptable} from "@domain/admin/comptabilite/compte-historique/historique-compte-comptable";
import {SourceLog,TypeLog} from "@domain/admin/log/log";

/**
 * Onglet "Historique" de l'écran de consultation d'un compte comptable
 */
@Component({
	host: {'data-test-id': 'compte-comptable-historique'},
	selector: 'compte-comptable-historique',
	templateUrl: './compte-comptable-historique.component.html'
})
export class CompteComptableHistoriqueComponent implements OnInit {
	/** Numéro du compte comptable courant */
	@Input() numero: string;

	/** Liste de l'historique de l'utilisateur */
	listeHistorique: ListView<HistoriqueCompteComptable,CompteComptableHistoriqueListItemComponent>;

	/**
	 * Constructeur
	 *
	 * @param translateService le moteur de traduction
	 */
	constructor(private translateService: TranslateService) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Initialisation de la liste
		this.listeHistorique = new ListView<HistoriqueCompteComptable,CompteComptableHistoriqueListItemComponent>({
			uri: `/controller/Compte/${this.numero}/historique`,
			title: this.translateService.instant('admin.historique.title'),
			component: CompteComptableHistoriqueListItemComponent,
			defaultOrder: '-dateLog',
			listeFilters: [
				{
					title: this.translateService.instant('admin.historique.origine'),
					clef: 'sourceLog',
					typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
					listeValues: [
						{
							code: '' + SourceLog.ADMINISTRATEUR,
							libelle: this.translateService.instant(`admin.historique.typeSourceLog.${SourceLog.ADMINISTRATEUR}`)
						},{
							code: '' + SourceLog.IMPORT,
							libelle: this.translateService.instant(`admin.historique.typeSourceLog.${SourceLog.IMPORT}`)
						},{
							code: '' + SourceLog.UTILISATEUR,
							libelle: this.translateService.instant(`admin.historique.typeSourceLog.${SourceLog.UTILISATEUR}`)
						}
					]
				},{
					title: this.translateService.instant('admin.historique.date'),
					clef: 'dateLog',
					type: TypeFilter[TypeFilter.DATE],
					typeComparaison: TypeComparaison[TypeComparaison.EQUAL]
				},{
					title: this.translateService.instant('admin.historique.acteur'),
					clef: 'acteur.matricule,acteur.nom,acteur.prenom',
					typeComparaison: TypeComparaison[TypeComparaison.LIKE],
					isDefault: true
				},{
					title: this.translateService.instant('admin.historique.typeModif'),
					clef: 'typeLog',
					typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
					listeValues: (Object.keys(TypeLog) as Array<keyof typeof TypeLog>)
						.filter(val => !isNaN(Number(val)))
						.map(val => {
							return {
								code: val,
								libelle: this.translateService.instant(`admin.historique.typeLog.${val}`)
							}
						})
				},{
					title: this.translateService.instant('admin.historique.modification'),
					clef: 'listeChanges.clef',
					typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
					listeValues: Object.values(Clef).map(clef => {
							return {
								code: clef,
								libelle: this.translateService.instant(`admin.comptabilite.planComptable.compteComptable.historique.clef.${clef}`)
							}
						})
				}
			]
		});

		//Définition des colonnes de tri
		this.listeHistorique.columns = [
			{key: 'sourceLog',title: 'admin.historique.origine'},
			{key: 'dateLog',title: 'admin.historique.date'},
			{key: 'acteur',title: 'admin.historique.acteur'},
			{key: 'typeLog',title: 'admin.historique.typeModif'}
		];

		//Persistence des filtres
		this.listeHistorique.isPersistFilters = true;
		this.listeHistorique.className = 'CompteComptableHistoriqueComponent';
	}
}
