import { Component } from "@angular/core";

/**
 * Onglet liste des taxes
 *
 * @author Guillaume TRAVOT
 * @date 26/04/2024
 */
@Component({
    host: {'data-test-id': 'liste-taxes'},
    templateUrl: './liste-taxes.component.html'
})
export class ListeTaxesComponent {}
