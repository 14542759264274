import {Component} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {LienRoleUser} from "@domain/workflow/lienRoleUser";
import {TypeProfil} from '@domain/user/user';
import {TranslateService} from "@ngx-translate/core";

/**
 * Composant d'affichage de la page délégation history list item
 *
 * @author Lionel Gimenez
 * @date 26/09/2024
 */
@Component({
    host: {'data-test-id': 'delegation-history-list-item'},
    templateUrl: './delegation-history-list-item.component.html'
})
export class DelegationHistoryListItemComponent extends ListViewItem<LienRoleUser> {
    readonly TypeProfil = TypeProfil;

    /**
     * Constructeur
     */
    constructor(
        public translateService: TranslateService,
    ) {
        super();
    }
}