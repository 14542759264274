import {Component,OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {Motif} from "@domain/workflow/motif";
import {TranslateService} from '@ngx-translate/core';
import {TypeAction} from "@domain/workflow/workflow";
import {TypeProfil} from "@domain/user/user";
import {filter,first} from "rxjs/operators";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {ToastrService} from "ngx-toastr";
import {WorkflowMotifsService} from "@services/admin/workflow/workflow-motifs.service";

/**
 * Motif dans la liste des motifs
 *
 * @author Angeline Ha
 * @date 27/08/2024
 */
@Component({
  host: {'data-test-id': 'motif-liste-item'},
  templateUrl: './motif-liste-item.component.html'})
export class MotifListeItemComponent extends ListViewItem<Motif> implements OnInit{
  /** Traduction du nombre de modules */
  nombreModulesTrad: string;

  /** Traduction du nombre de profils */
  nombreProfilsTrad: string;

  /** Traduction du nombre d'actions */
  nombreActionsTrad: string;

  /** Liste des profils traduits */
  profilsTrad: string = "";

  /** Liste des actions traduites */
  actionsTrad: string = "";

  /**
   * Constructeur
   *
   * @param router Routeur Angular
   * @param translateService Service des traductions
   * @param confirmService Service de confirmation
   * @param toastrService Service des toasts
   * @param motifService Service des motifs
   */
  constructor(private router: Router,
              private translateService: TranslateService,
              private confirmService: ConfirmService,
              private toastrService: ToastrService,
              private motifService: WorkflowMotifsService) {
    super();
  }

  /**
   * Initialisation
   */
  ngOnInit() {
    //Définition des traductions à afficher
    this.defineTrad();
  }

  /**
   * Définitions des traductions du nombre d'éléments à afficher à partir des listes (modules,profils,actions)
   */
  defineTrad() {
    //Récupération du nombre d'éléments des différentes listes (modules,profils,actions)
    const countModules = this.data.lstPortee.split(",").filter(s => s !== '').length;
    const countProfils = this.data.lstProfil.split(",").filter(s => s !== '').length;
    const countActions = this.data.lstAction.split(",").filter(s => s !== '').length;

    //Traduction du nombre de modules
    if (countModules > 1) {
      this.nombreModulesTrad = this.translateService.instant("workflow.motifs.nbModules.plusieursModules", {count: countModules});
    } else if (countModules == 1) {
      this.nombreModulesTrad = this.translateService.instant("workflow.motifs.nbModules.unModule", {count: 1});
    } else {
      this.nombreModulesTrad = this.translateService.instant("workflow.motifs.nbModules.aucunModule");
    }

    //Traduction du nombre de profils
    if (countProfils > 1) {
      this.nombreProfilsTrad = this.translateService.instant("workflow.motifs.nbProfils.plusieursProfils", {count: countProfils});
    } else if (countProfils == 1) {
      this.nombreProfilsTrad = this.translateService.instant("workflow.motifs.nbProfils.unProfil", {count: 1});
    } else {
      this.nombreProfilsTrad = this.translateService.instant("workflow.motifs.nbProfils.aucunProfil");
    }

    //Traduction du nombre d'actions
    if (countActions > 1) {
      this.nombreActionsTrad = this.translateService.instant("workflow.motifs.nbActions.plusieursActions", {count: countActions});
    } else if (countActions == 1) {
      this.nombreActionsTrad = this.translateService.instant("workflow.motifs.nbActions.uneAction", {count: 1});
    } else {
      this.nombreActionsTrad = this.translateService.instant("workflow.motifs.nbActions.aucuneAction");
    }

    //Traduction des actions pour le tooltip
    const listeActions: string[] = []

    for (const action of this.data.lstAction) {
      switch (Number(action)) {
        case TypeAction.VALIDER:
          listeActions.push(this.translateService.instant('global.actions.valider'));
          break;
        case TypeAction.REJETER:
          listeActions.push(this.translateService.instant('global.actions.rejeter'));
          break;
        case TypeAction.INVALIDER:
          listeActions.push(this.translateService.instant('global.actions.invalider'));
          break;
        case TypeAction.ANNULER:
          listeActions.push(this.translateService.instant('global.actions.annuler'));
          break;
        case TypeAction.EMETTRE:
          listeActions.push(this.translateService.instant('global.actions.emettre'));
          break;
        case TypeAction.ARCHIVER:
          listeActions.push(this.translateService.instant('global.actions.archiver'));
          break;
        case TypeAction.CLOTURER:
          listeActions.push(this.translateService.instant('global.actions.cloturer'));
          break;
      }
    }

    this.actionsTrad = listeActions.join(',');

    //Traduction des profils pour le tooltip
    const listeProfils: string[] = []

    for (const profil of this.data.lstProfil) {
      switch (Number(profil)) {
        case TypeProfil.COMPTABLE:
          listeProfils.push(this.translateService.instant('profil.comptable'));
          break;
        case TypeProfil.RESPONSABLE:
          listeProfils.push(this.translateService.instant('profil.responsable'));
          break;
        case TypeProfil.COLLABORATEUR:
          listeProfils.push(this.translateService.instant('profil.collaborateur'));
          break;
        case TypeProfil.ASSISTANT:
          listeProfils.push(this.translateService.instant('profil.assistant'));
          break;
        case TypeProfil.FOURNISSEUR:
          listeProfils.push(this.translateService.instant('profil.fournisseur'));
          break;
      }
    }

    this.profilsTrad = listeProfils.join(',');
  }

  /**
   * Suppression du motif
   */
  deleteMotif() {
    //Confirmation de suppression
    this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation'))
        .pipe(filter(isConfirmed => isConfirmed))
        .subscribe(() => {
          //Suppression du motif
          this.motifService.deleteMotif(this.data).pipe(first()).subscribe((result: Result)=> {
            if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
              //Rafraichissement de la liste
              this.liste.refresh();

              //Toast succès
              this.toastrService.success(this.translateService.instant('global.success.suppression'));
            } else {
              //Toast d'erreur
              TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
            }
          })
        });
  }

  /**
   * Ouverture du détail du motif
   */
  goToDetail() {
    this.router.navigate(['/Admin/Workflow/Motifs/Motifs/' + this.data.idMotif]);
  }

}
