import {Component} from "@angular/core";


/**
 * Onglet des messages
 *
 * @author Guillaume TRAVOT
 * @date 29/10/2024
 */
@Component({
    host: {'data-test-id': 'internationalisation-messages'},
    templateUrl: './internationalisation-messages.component.html'
})
export class InternationalisationMessagesComponent {}
