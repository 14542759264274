import {Component,EventEmitter,Input,OnInit} from '@angular/core';
import {ListViewItem} from '@domain/common/list-view/list-view-item';
import {UserHabilitations} from '@domain/user/user-habilitations';
import {TranslateService} from "@ngx-translate/core";
import {LienRoleUser,TypeLienRoleUser,TypePriorite} from '@domain/workflow/lienRoleUser';
import {TypeProfil} from '@domain/user/user';
import {AddHabilitationPopinComponent} from "@components/admin/entreprise/utilisateurs/user-detail/tabs/user-generalites/add-habilitation-popin/add-habilitation-popin.component";
import {MatDialog} from "@angular/material/dialog";
import {Role} from "@domain/workflow/role";
import {DelegationCreationComponent} from "@components/delegations/liste/creation/delegation-creation.component";
import * as moment from "moment";

/**
 * Item de la liste des habilitations d'un utilisateur
 */
@Component({
	host: {'data-test-id': 'habilitation-list-item'},
	templateUrl: './habilitation-list-item.component.html'
})
export class HabilitationListItemComponent extends ListViewItem<UserHabilitations> implements OnInit {
	/** Options supplémentaires de la liste */
	@Input() extraOptions: {
		userChanged: EventEmitter<void>,
		isCollab: boolean,
		isHistory: boolean,
		showHistory: (fonction: TypeProfil) => void,
		titleAdd: string,
	}

	/** Liste des rôles distincts de l'habilitation */
	listeDistinctRoles: Role[];

	/** L'habilitation est-elle dépliée */
	isUnfolded: boolean = false;

	//Import des énumérations pour le DOM
	TypeProfil = TypeProfil;
	TypeLienRoleUser = TypeLienRoleUser;
	TypePriorite = TypePriorite;

	/**
	 * Constructeur
	 *
	 * @param translateService le moteur de traduction
	 * @param matDialog le gestionnaire de popup
	 */
	constructor(
		public translateService: TranslateService,
		private matDialog: MatDialog
	) {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Initialisation de la liste des rôles de l'habilitation
		this.listeDistinctRoles = this.data.liensRoleUser
			.filter((object,index,array) => index === array.findIndex(element => element.role.libelle === object.role.libelle))
			.map((lru => lru.role));
	}

	/**
	 * Ouverture d'une habilitation
	 *
	 * @param habilitation l'habilitation
	 */
	openHabilitation(habilitation: LienRoleUser): void {
		this.matDialog.open(AddHabilitationPopinComponent,{
			data: {
				isCreation: false,
				habilitation: habilitation,
				isCollab: this.extraOptions.isCollab,
				isHistory: this.extraOptions.isHistory,
				isDelegation: habilitation.type === TypeLienRoleUser.DELEGATION,
				canChangeEnveloppe: true,
				title: this.extraOptions.titleAdd,
			},
			width: '80%'
		}).afterClosed().subscribe((result: boolean) => {
			//Vérification du résultat
			if (result) {
				//Rechargement de l'utilisateur
				this.extraOptions.userChanged.emit();
			}
		});
	}

	/**
	 * Ouverture d'une délégation
	 *
	 * @param delegation la délégation
	 */
	openDelegation(delegation: LienRoleUser): void {
		//Ouverture de la popin de création/modification d'une délégation
		this.matDialog.open<DelegationCreationComponent,any,boolean>(DelegationCreationComponent,{
			data: {
				deleg: delegation,
				isFromAdmin: true
			},
			width: '80%'
		}).afterClosed().subscribe((refresh: boolean) => {
			//Vérification de la nécessité de refresh
			if (refresh) {
				//Rechargement de l'utilisateur
				this.extraOptions.userChanged.emit();
			}
		});
	}

	/**
	 * Ouverture de la popin de création d'une délégation pour ce rôle
	 */
	createDelegation(role: Role): void {
		//Initialisation d'une délégation
		let deleg: LienRoleUser = new LienRoleUser();

		//Définition des valeurs par défaut
		deleg.idUserDelegant = this.data.liensRoleUser[0].user.idUser;
		deleg.dateDebut = moment().startOf('day').toDate();
		deleg.role = role;
		deleg.idRole = role.idRole;

		//Ouverture de la popin
		this.matDialog.open<DelegationCreationComponent,any,boolean>(DelegationCreationComponent,{
			data: {
				deleg: deleg,
				isFromAdmin: true
			},
			width: '80%'
		}).afterClosed().subscribe((refresh: boolean) => {
			//Vérification de la nécessité de recharger
			if (refresh) {
				//Rechargement de l'utilisateur
				this.extraOptions.userChanged.emit();
			}
		});
	}

	/**
	 * Affiche l'historique d'une fonction donnée
	 *
	 * @param fonction la fonction
	 */
	showHistory(fonction: TypeProfil): void {
		//Appel de la fonction des options
		this.extraOptions.showHistory(fonction);
	}
}
