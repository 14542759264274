import { Component } from "@angular/core";

/**
 * Onglet �tats
 *
 * @author Guillaume TRAVOT
 * @date 26/04/2024
 */
@Component({
    host: {'data-test-id': 'liste-etats'},
    templateUrl: './liste-etats.component.html'
})
export class ListeEtatsComponent {}
