import {Component,EventEmitter,Input,OnChanges,Output,SimpleChanges,ViewChild} from '@angular/core';
import {NgForm} from "@angular/forms";
import {FloatingButtonAction,TypeAction} from "@share/component/floating-button/floating-button";
import {Origine,Population} from "@domain/admin/entreprise/population/population";
import {filter,finalize} from "rxjs/operators";
import {TypeCodeErreur} from "@domain/common/http/result";
import {BehaviorSubject} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {PopulationsService} from "@services/admin/entreprise/population.service";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {ToastrService} from "ngx-toastr";
import {Router} from "@angular/router";

/**
 * Composant d'affichage de la page PopulationGeneralite
 *
 * @author Lionel Gimenez
 * @date 07/12/2023
 */
@Component({
    host: {'data-test-id': 'population-generalite'},
    selector: 'population-generalite',
    templateUrl: './population-generalite.component.html'
})
export class PopulationGeneraliteComponent implements OnChanges {
    /** Id de la population */
    @Input() idPopulation: number;

    /** Interprétation du champ number 'origine' da la population pour un affichage textuel */
    origine: string = '';

    /** Indique si on est en création */
    isCreation: boolean;

    /** Formulaire de la page */
    @ViewChild('form') form: NgForm;

    /** Liste des actions possibles */
    listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

    /** Pour permettre le pending sur le floating button */
    isSaving: boolean;

    /** La population */
    population: Population;

    /** Emetteur lors d'un changement de l'id population */
    @Output() idPopulationChange = new EventEmitter<number>();

    /**
     * Constructeur de la classe.
     *
     * @param populationsService Le service PopulationsService utilisé dans la classe pour gérer les populations.
     * @param translateService Le service de traduction utilisé dans la classe.
     * @param toastrService Le service ToastrService utilisé dans la classe pour afficher des messages de notification.
     * @param confirmService Le service ConfirmService utilisé dans la classe pour afficher des confirmations.
     * @param router Le router utilisé dans la classe.
     */
    constructor(
        private populationsService: PopulationsService,
        private translateService: TranslateService,
        private toastrService: ToastrService,
        private confirmService: ConfirmService,
        private router: Router
    ) {
    }

    /**
     * Hook onChanges
     * @param changes changement en cours
     */
    ngOnChanges(changes: SimpleChanges) {
        //En cas de modification de idPopulation
        if (changes.idPopulation.currentValue != null) {
            //Si on idPopulation qui change on (ré)initialise
            this.onInit(changes.idPopulation.currentValue);
        }
    }

    /**
     * Initialisation des éléments de la page
     *
     * @param idPopulation de la population
     */
    onInit(idPopulation: string) {
        this.isCreation = idPopulation === '-1';

        //Initialisation des actions possibles
        const listeActions: Array<FloatingButtonAction> = [{
            type: TypeAction.SECONDARY,
            icone: 'nio icon-sauvegarde',
            libelle: 'global.actions.enregistrer',
            doAction: () => this.save()
        }];

        //Si on est en mode création, on initialise un nouveau service
        if (this.isCreation) {
            //Initialisation du nouveau service population
            this.population = new Population();

            this.population.origine = 0;
            this.population.actif = true;
            this.population.reference = '';
            this.population.libelle = '';
            this.population.remarque = '';
        } else {
            //Si on est en mode modification, on ajoute la possibilité de supprimer le service
            listeActions.push({
                type: TypeAction.SECONDARY,
                icone: 'nio icon-suppression',
                libelle: 'global.actions.supprimer',
                doAction: () => this.delete(),
            });

            //Récupération de l'organisation à modifier
            this.populationsService.getPopulation(Number(idPopulation)).subscribe(data => {
                this.population = data.data.population;
                if (this.population.origine === Origine.ORIGINE_ADMINISTRATEUR) {
                    this.origine = this.translateService.instant('admin.entreprise.populations.liste.admin');
                } else if (this.population.origine === Origine.ORIGINE_IMPORT) {
                    this.origine = this.translateService.instant('admin.entreprise.populations.liste.import');
                } else if (this.population.origine === Origine.ORIGINE_USER) {
                    this.origine = this.translateService.instant('admin.entreprise.populations.liste.user');
                }
            })
        }

        this.listeActions.next([...listeActions]);
    }

    /**
     * Méthode appelée lors de la sauvegarde d'un service en base
     */
    save() {
        //Si le formulaire est valide, on enregistre le service
        if (this.form.valid) {
            this.isSaving = true;
            //Enregistrement du service en base
            this.populationsService.savePopulation(this.population)
                .pipe(finalize(() => this.isSaving = false))
                .subscribe(result => {
                    if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
                        //Dans le cas d'une création
                        if (this.isCreation) {
                            //On affecte l'id
                            this.population.idPopulation = result.data.idPopulation;

                            //On indique au composant parent que l'id population a changé pour mettre à jour les onglets
                            this.idPopulationChange.emit(this.population.idPopulation)

                            //On intègre la bonne route
                            this.router.navigate(['Admin/Entreprise/Populations/Population/' + result.data.idPopulation]);
                        }

                        //Si aucune erreur n'est renvoyée, on affiche un message de succès
                        this.toastrService.success(this.translateService.instant('global.success.enregistrement'));
                    } else {
                        if (result.codeErreur == 900) {
                            //Désactivation impossible
                            this.toastrService.error(this.translateService.instant('admin.entreprise.populations.errors.desactivationImpossible'));
                        } else if (result.codeErreur == 902) {
                            //Erreur doublon
                            this.toastrService.error(this.translateService.instant('admin.entreprise.populations.errors.referenceDejaAjoute'));
                        } else {
                            //Si une erreur est renvoyée, on affiche un message d'erreur
                            TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
                        }
                    }
                });
        } else {
            //Si, au contraire, le formulaire n'est pas valide alors affichage d'un message disant que le formulaire n'est pas valide
            this.toastrService.error(this.translateService.instant('global.errors.formInvalid'));
        }
    }

    /**
     * Méthode appelée lors de la suppression de la population
     */
    delete() {
        //Affichage de la popin de confirmation de suppression du service
        this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation')).pipe(filter(isConfirmed => isConfirmed)).subscribe({
            next: () => {
                //Si l'utilisateur a appuyé sur valider, on supprime le service en base
                this.populationsService.deletePopulation(this.population.idPopulation).subscribe(data => {
                    if (data.codeErreur === TypeCodeErreur.NO_ERROR) {
                        //Si aucune erreur n'est renvoyée, on affiche un message de succès
                        this.toastrService.success(this.translateService.instant('global.success.suppression'));
                        //Navigation vers l'organigramme
                        this.router.navigate(['Admin/Entreprise/Populations']);
                    } else {
                        //Si une erreur renvoyée, on affiche un message d'erreur
                        TypeCodeErreur.showError(data.codeErreur,this.translateService,this.toastrService);
                    }
                })
            }
        });
    }
}