import {Component} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {LienRoleUser} from "@domain/workflow/lienRoleUser";

/**
 * Composant d'un item de la liste role usage
 *
 * @author Alexis Bouvard
 * @date 09/09/2024
 */
@Component({
	templateUrl: './role-usage-list-item.component.html'
})
export class RoleUsageListItemComponent extends ListViewItem<LienRoleUser> {
}
