import {NgModule} from "@angular/core";
import {Routes} from "@angular/router";
import {AdminGuardProvider} from "@core/security/admin-guard.provider";
import {ShareModule} from "@share/share.module";
import {WfStatutsComponent} from "./wf-statuts.component";
import {ListeStatutsComponent} from "./liste-statuts/liste-statuts.component";
import {ListeEtatsComponent} from "@components/admin/workflow/statuts/liste-etats/liste-etats.component";

export const workflowStatutsRoutes : Routes = [
    {
        path: 'ListeStatuts',
        component: ListeStatutsComponent,
        canActivate: [AdminGuardProvider]
    }, {
        path: 'ListeEtats',
        component: ListeEtatsComponent,
        canActivate: [AdminGuardProvider]
    }, {
        path: '',
        pathMatch: 'full',
        redirectTo: 'ListeStatuts'
    }, {
        path: '**',
        redirectTo: 'ListeStatuts'
    }
];

@NgModule({
    imports: [ShareModule],
    declarations: [
        WfStatutsComponent,
        ListeStatutsComponent,
        ListeEtatsComponent
    ],
    entryComponents: [],
    exports: [
        ListeStatutsComponent,
        ListeEtatsComponent
    ],
    providers: []
})
export class WfStatutsModule {}
