import {CompteUsage} from "./compte-usage";
import {EntityAttribute} from "@share/component/autocomplete/options/entity-attribute";
import {DatePipe,DecimalPipe} from "@angular/common";

/**
 * Usages d'un compte comptable
 * Taxe associée à un compte comptable
 *
 * @author Laurent Convert
 * @date 24/09/2024
 */
export class CompteUsageTaxe implements CompteUsage {
	taux: number;
	date: Date;
	libelleDomaine: string;
	libelleZone: string;

	/**
	 * Constructeur
	 *
	 * @param dto Source
	 * @param decimalPipe Formatage des nombres
	 * @param datePipe Formatage des dates
	 */
	constructor(dto: any,public decimalPipe: DecimalPipe,public datePipe:DatePipe) {
		Object.assign(this,dto);
	}

	/**
	 * Récupération de l'identifiant de l'objet
	 */
	getKey(): string {
		return this.libelleDomaine;
	}

	/**
	 * Affichage d'un élément
	 */
	displayItem(): string {
		return this.libelleDomaine;
	}

	/**
	 * Liste les propriétés de l'objet pour lesquelles il faut afficher un badge 'clé:valeur'
	 */
	listDisplayedAttributes(): Array<EntityAttribute> {
		return [{
			clef: 'admin.comptabilite.planComptable.compteComptable.usage.zone',
			valeur: this.libelleZone
		},{
			clef: 'admin.comptabilite.planComptable.compteComptable.usage.taux',
			valeur: this.taux != null ? this.decimalPipe.transform(this.taux,'1.2-2') : null
		},{
			clef: 'admin.comptabilite.planComptable.compteComptable.usage.date',
			valeur: this.datePipe.transform(this.date,'shortDate')
		}];
	}
}