<div class="lvi-content" (click)="navigate()">
    <div class="avatar">
        <i class="{{ data.icone.class }}">{{ data.icone.icone }}</i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a class="label"><span>{{ data.niveau1 | translate }} > {{ data.niveau2 | translate }} > {{ data.niveau3 | translate }}</span></a>
        </div>
        <ul class="lvi-attrs">
            <li *ngFor="let tag of data.listeTag">
                <span [class.bold]="tag.matchingQuery">{{ tag.tag }}</span>
            </li>
        </ul>
    </div>
    <div>
        <mat-icon class="material-icons-outlined">explore</mat-icon>
    </div>
</div>
