import {Injectable} from '@angular/core';
import {UserHabilitations} from "@domain/user/user-habilitations";
import {LienRoleUser,TypeLienRoleUser} from "@domain/workflow/lienRoleUser";
import * as moment from "moment";

/**
 * Service des habilitations de l'utilisateur
 *
 * @author Lionel Gimenez
 * @date 25/09/2024
 */
@Injectable({
    providedIn: "root",
})
export class UserHabilitationService {
    /**
     * Construction de la liste des habilitations de l'utilisateur regroupées par profil
     *
     * @param user l'utilisateur
     * @param isHistory est-ce qu'on veut les habilitations échues ?
     */
    buildListeUserHabilitationGenerale(listeLienRoleUsers: LienRoleUser[],isHistory?: boolean): UserHabilitations[] {
        let listeLRU: LienRoleUser[];

        if (isHistory) {
            //Habilitations échues
            listeLRU = listeLienRoleUsers
                .filter(lienRoleUser =>
                    lienRoleUser.type === TypeLienRoleUser.HABILITATION
                    && (moment(lienRoleUser.dateFin).isBefore(moment(),'day')));
        } else {
            //Habilitations en cours
            listeLRU = listeLienRoleUsers
                .filter(lienRoleUser =>
                    lienRoleUser.type === TypeLienRoleUser.HABILITATION
                    && (!lienRoleUser.dateFin || moment(lienRoleUser.dateFin).isSameOrAfter(moment(),'day')));
        }

        return this.buildListeUserHabilitation(listeLRU);
    }

    /**
     * Construction de la liste des habilitations de l'utilisateur
     * @param listeLienRoleUser
     * @private
     */
    private buildListeUserHabilitation(listeLienRoleUser: LienRoleUser[]): UserHabilitations[] {
        const listeUserHabilitation: UserHabilitations[] = [];

        //Parcours des habilitations de l'utilisateur
        listeLienRoleUser.forEach(hab => {
            //On cherche si le profil du rôle de cette habilitation existe dans la liste
            const idx: number = listeUserHabilitation.findIndex(uh => uh.fonction === hab.role.fonction);

            //Si oui
            if (idx !== -1) {
                //On ajoute l'habilitation
                listeUserHabilitation[idx].liensRoleUser.push(hab);
            } else {
                //Sinon, on crée une nouvelle habilitation
                listeUserHabilitation.push(new UserHabilitations(hab.role.fonction,[hab]));
            }
        });

        return listeUserHabilitation;
    }
}