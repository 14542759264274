import {CompteUsage} from "./compte-usage";
import {EntityAttribute} from "@share/component/autocomplete/options/entity-attribute";
import {TranslateService} from "@ngx-translate/core";
import {TypePrestation} from "../../../prestation/prestation";

/**
 * Usages d'un compte comptable
 * Prestation associée à un compte comptable
 *
 * @author Laurent Convert
 * @date 24/09/2024
 */
export class CompteUsagePrestation implements CompteUsage {
	libelle: string;
	type: TypePrestation;
	actif: boolean;

	/**
	 * Constructeur
	 *
	 * @param dto Source
	 */
	constructor(dto: any) {
		Object.assign(this,dto);
	}

	/**
	 * Récupération de l'identifiant de l'objet
	 */
	getKey(): string {
		return this.libelle;
	}

	/**
	 * Affichage d'un élément
	 */
	displayItem(): string {
		return this.libelle;
	}

	/**
	 * Liste les propriétés de l'objet pour lesquelles il faut afficher un badge 'clé:valeur'
	 */
	listDisplayedAttributes(translateService:TranslateService): Array<EntityAttribute> {
		return [{
			clef: 'admin.comptabilite.planComptable.compteComptable.usage.type',
			valeur: translateService.instant('admin.comptabilite.planComptable.compteComptable.usage.typePrestation.' + TypePrestation[this.type])
		},{
			clef: 'admin.comptabilite.planComptable.compteComptable.usage.actif',
			valeur: translateService.instant(this.actif ? 'global.oui' : 'global.non')
		}];
	}
}
