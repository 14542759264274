import {Component,OnDestroy,OnInit} from "@angular/core";
import {ActivatedRoute,Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {PageHeaderItem} from "@share/component/page-header/page-header";
import {Store} from "@ngrx/store";
import {AppState} from "@domain/appstate";
import {Session} from "@domain/security/session";
import {isRouteAllowed} from "@core/security/role-admin-helpers";
import {filterFirstNotNull} from "@share/utils/rxjs-custom-operator";
import {IComponentWithRoutedTabs} from "@domain/admin/recherche/component-with-routed-tabs";
import {Subscription} from "rxjs";

/**
 * Enum pour les noms des différents onglets de la page Entreprise
 */
export enum Onglets {
    ORGANIGRAMME = "Services",
    PROJETS = "Projets",
    AXE5 = "Axe5",
    AXE6 = "Axe6",
    ENVELOPPES = "Enveloppes",
    PARAMETRES = "Parametres"
}

/**
 * Sous-menu Description du menu Entreprise
 *
 * @author Tom JEAMMET
 * @date 21/02/2023
 */
@Component({
    host: {'data-test-id': 'entreprise-analytique'},
    templateUrl: './entreprise-analytique.component.html'
})
export class EntrepriseAnalytiqueComponent implements OnInit, OnDestroy, IComponentWithRoutedTabs, OnDestroy {

    /** Liste statique des onglets */
    public static listeOnglets: Array<PageHeaderItem> = [
        {
            code: Onglets.ORGANIGRAMME,
            libelle: 'admin.entreprise.analytique.onglets.organigramme',
            url: 'Admin/Entreprise/Analytique/Services'
        }, {
            code: Onglets.PROJETS,
            libelle: 'admin.entreprise.analytique.onglets.projets',
            url: 'Admin/Entreprise/Analytique/Projets'
        }, {
            code: Onglets.AXE5,
            libelle: 'admin.entreprise.analytique.onglets.axe5',
            url: 'Admin/Entreprise/Analytique/Axe5'
        }, {
            code: Onglets.AXE6,
            libelle: 'admin.entreprise.analytique.onglets.axe6',
            url: 'Admin/Entreprise/Analytique/Axe6'
        }, {
            code: Onglets.ENVELOPPES,
            libelle: 'admin.entreprise.analytique.onglets.enveloppes',
            url: 'Admin/Entreprise/Analytique/Enveloppes'
        }, {
            code: Onglets.PARAMETRES,
            libelle: 'admin.entreprise.analytique.onglets.parametres',
            url: 'Admin/Entreprise/Analytique/Parametres'
        }
    ];

    /** Liste des différents onglets disponibles dans le menu entreprise */
    listeTabItems: Array<PageHeaderItem> = EntrepriseAnalytiqueComponent.listeOnglets;

    /** Onglet courant */
    selectedItem: PageHeaderItem;

    /** Url de retour */
    goBackUrl: string;

    /** Référence vers l'enum des onglets entreprise pour l'utiliser dans le template */
    Onglet = Onglets;

    /** Titre affiché dans le header */
    title: string;

    /** Index de l'onglet courant */
    selectedIndex: number;

    /** Indique si le header doit être affiché */
    isDisplayed: boolean;

    /** Session */
    private session: Session;

    /** Souscription */
    private subscription: Subscription;

    constructor(
        protected translateService: TranslateService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private store: Store<AppState>
    ) { }

    /**
     * Initialisation du composant
     */
    ngOnInit(): void {
        //Récupération de la session
        this.store.select(state => state.session).pipe(filterFirstNotNull()).subscribe((session: Session) => {
            //Stockage de la session
            this.session = session;

            //Filtrage des onglets autorisés
            this.listeTabItems = this.listeTabItems.filter(i => isRouteAllowed(this.router, this.session, i.url));

            this.subscription = this.activatedRoute.url.subscribe(() => {
                const firstChild = this.activatedRoute.snapshot.firstChild;

                //Récupération des paramètres additionnels des routes enfants
                this.isDisplayed = (Object.values(firstChild.params).findIndex(v => v !== undefined) === -1);

                this.title = this.translateService.instant('admin.entreprise.analytique.title');
                this.goBackUrl = null;
                const segment = firstChild.url[0].path;
                //On vérifie si on trouve l'onglet demandé via l'url
                const matching = Object.values(Onglets).find(v => v === segment);
                if (!!matching) {
                    //Si on trouve bien l'onglet correspondant, on met à jour le composant header
                    this.selectedItem = this.listeTabItems.find(i => i.code === segment);
                    this.selectedIndex = this.listeTabItems.findIndex(i => i.code === segment);
                } else {
                    //Sinon, on redirige sur le premier onglet et on met à jour le composant header
                    this.selectedItem = this.listeTabItems[0];
                    this.selectedIndex = 0;
                }
            });
        });
    }

    /**
     * Changement d'onglet
     *
     * @param selectedItem Onglet sélectionné
     */
    onSelectedItemChange(selectedItem: PageHeaderItem): void {
        if (!this.selectedItem) {
            this.selectedItem = selectedItem;
        }
        if (selectedItem?.code !== this.selectedItem?.code) {
            this.selectedItem = selectedItem;
            this.router.navigateByUrl(selectedItem.url);
        }
    }

    /**
     * Destruction du composant
     */
    ngOnDestroy() {
        //Résiliation de l'abonnement
        this.subscription.unsubscribe();
    }
}
