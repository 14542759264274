import {Component,OnDestroy,OnInit} from '@angular/core';
import {PageHeaderItem} from "@share/component/page-header/page-header";
import {ActivatedRoute,Router} from "@angular/router";
import {BehaviorSubject,Subscription} from 'rxjs';
import {IComponentWithRoutedTabs} from "@domain/admin/recherche/component-with-routed-tabs";

/**
 * Énum des onglets du menu Workflow - Conditions
 */
enum Onglets {
	ALERTES = "Alertes",
	CONDITIONS_VISIBILITE = "ConditionsVisibilite"
}

/**
 * Composant du menu admin Workflow - Conditions
 */
@Component({
	host: {'data-test-id': 'workflow-conditions'},
	selector: 'workflow-conditions',
	templateUrl: './workflow-conditions.component.html'
})
export class WorkflowConditionsComponent implements OnInit, OnDestroy, IComponentWithRoutedTabs {
    /** Liste statique des onglets */
    public static listeOnglets: Array<PageHeaderItem> = [{
        code: Onglets.ALERTES,
        libelle: 'workflow.conditions.alertes.title',
        url: 'Admin/Workflow/Conditions/Alertes'
    },{
        code: Onglets.CONDITIONS_VISIBILITE,
        libelle: 'workflow.conditions.conditionsVisibilite.title',
        url: 'Admin/Workflow/Conditions/ConditionsVisibilite'
    }];

	/** Possibilité de masquer le header quand un enfant en a un également */
	isHeaderHidden: boolean = false;

	/** Liste des onglets */
	listeTabItems: Array<PageHeaderItem> = WorkflowConditionsComponent.listeOnglets;

	/** Index de l'onglet courant */
	selectedItem$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

	/** Import de l'énum pour le DOM */
	protected readonly Onglets = Onglets;

	/** Souscription */
	private subscription: Subscription;

	/**
	 * Constructeur
	 *
	 * @param router le routeur Angular
	 * @param activatedRoute la route actuelle
	 */
	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute
	) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//On souscrit à la route
		this.subscription = this.activatedRoute.url.subscribe(() => {
			//Récupération du path courant
			const path: string = this.activatedRoute.snapshot.firstChild.url[0].path;

			//Si un onglet correspond au path
			if (Object.values(Onglets).find(value => value === path)) {
				//On navigue vers celui-ci
				this.onSelectedItemChange(this.listeTabItems.find(item => item.code === path));

				//On affiche le header
				this.isHeaderHidden = false;
			} else {
				//Sinon, on masque le header
				this.isHeaderHidden = true;
			}
		})
	}

	/**
	 * Changement d'onglet
	 *
	 * @param item l'onglet sélectionné
	 */
	onSelectedItemChange(item: PageHeaderItem) {
		//Récupération de l'index de l'onglet sélectionné
		const index = this.listeTabItems.findIndex(i => i.code === item.code);

		//S'il est différent de l'index courant
		if (index !== this.selectedItem$.value) {
			//On met à jour l'index courant
			this.selectedItem$.next(this.listeTabItems.findIndex(i => i.code === item.code));

			//Et on navigue vers l'URL correspondante
			this.router.navigateByUrl(item.url);
		}
	}

	/**
	 * Destruction du composant
	 */
	ngOnDestroy() {
		//Résiliation de l'abonnement
		this.subscription.unsubscribe();
	}
}
