<div class="with-floating-button">
    <div class="content">
        <page-header
                [hidden]="isInDetail"
                [title]="'admin.comptabilite.planComptable.title' | translate"
                [listeItems]="listeTabItems"
                [selectedIndex]="selectedIndex"
                (onSelectedItemChange)="onSelectedItemChange($event)">
        </page-header>

        <router-outlet></router-outlet>

    </div>
</div>
