import {NgModule} from "@angular/core";
import {Routes} from "@angular/router";
import {AdminGuardProvider} from "@core/security/admin-guard.provider";
import {ShareModule} from "@share/share.module";
import {FraisPrestationsComponent} from "./frais-prestations.component";
import {PrestationsListeComponent} from "@components/admin/frais/prestations/prestations-liste/prestations-liste.component";
import {DroitAdmin} from "@core/security/droit-admin";

export const fraisPrestationsRoutes : Routes = [
    {
        path: 'Liste',
        component: PrestationsListeComponent,
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_PRESTATION_NDF] }
    }, {
        path: '',
        pathMatch: 'full',
        redirectTo: 'Liste'
    }, {
        path: '**',
        redirectTo: 'Liste'
    }
];

@NgModule({
    imports: [ShareModule],
    declarations: [
        FraisPrestationsComponent,
        PrestationsListeComponent
    ],
    entryComponents: [],
    exports: [
        PrestationsListeComponent
    ],
    providers: []
})
export class FraisPrestationsModule {}
