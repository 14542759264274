import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { PageHeaderItem } from "@share/component/page-header/page-header";
import { IComponentWithRoutedTabs } from "@domain/admin/recherche/component-with-routed-tabs";

/**
 * Sous-menu compte-admin
 *
 * @author Guillaume TRAVOT
 * @date 01/02/2024
 */
@Component({
    host: {'data-test-id': 'compte-admin'},
    templateUrl: './compte-admin.component.html'
})
export class CompteAdminComponent implements IComponentWithRoutedTabs {
    /** Liste statique des onglets */
    public static listeOnglets: Array<PageHeaderItem> = [
        {
            code: null,
            libelle: 'admin.maintenance.compteAdmin.onglets.compteAdmin',
            url: 'Admin/Maintenance/CompteAdmin'
        }
    ];

    /** Onglet unique */
    listeTabItems: Array<PageHeaderItem> = CompteAdminComponent.listeOnglets;

    /** Onglet courant */
    selectedItem: PageHeaderItem = this.listeTabItems[0];

    constructor(protected translateService: TranslateService) {}
}
