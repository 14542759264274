import {Component,OnInit,ViewChild} from "@angular/core";
import {NgForm} from "@angular/forms";
import {ActivatedRoute,Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {FloatingButtonAction,TypeAction} from "@share/component/floating-button/floating-button";
import {PageHeaderItem} from "@share/component/page-header/page-header";
import {ToastrService} from "ngx-toastr";
import {BehaviorSubject} from "rxjs";
import {Langue} from "@domain/admin/bibliotheque/internationalisation/langue";
import {LangueService} from "@services/admin/langue/langue.service";
import {TypeCodeErreur} from "@domain/common/http/result";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {filter,first} from "rxjs/operators";

/**
 * Composant d'affichage des informations d'une langue et de création d'une langue
 */
@Component({
    host: {'data-test-id': 'langue-infos'},
    templateUrl: './langue-infos.component.html'
})
export class LangueInfosComponent implements OnInit {
    /** Boolean pour savoir s'il faut afficher le formulaire en création ou en édition */
    isCreation: boolean;

    /** Langue à afficher sur la page */
    langue: Langue;

    /** Fichier sélectionné */
    selectedFile: File = null;

    @ViewChild('form') form: NgForm;

    /** Liste des différents onglets disponibles dans le menu "Langues" de la bibliothèque */
    listeTabItems: Array<PageHeaderItem>;

    /** Onglet courant */
    selectedItem: PageHeaderItem;

    /** Référence vers l'enum pour l'utiliser dans le template */
    Onglet = OngletsInfos;

    /** Liste des actions possibles pour le floatingButton en bas à droite */
    listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

    /** true si custom, false sinon */
    custom: { isCustom: boolean } = { isCustom: true };

    /** Validité du formulaire */
    isFormValid: boolean = false;

    /** Flag action en cours */
    isPending: boolean = false;

    /** Liste des variants possibles */
    listeVariant: string[];

    /**
     * Constructeur
     *
     * @param translateService  Service de traduction
     * @param langueService     Service de gestion des langues
     * @param activatedRoute    Route d'accès à cette page
     * @param confirmService    Service de confirmation
     * @param toastrService     Service pour afficher les messages de succès, d'erreurs, ...
     * @param router            Router de l'application pour naviguer entre les pages
     */
    constructor(private translateService: TranslateService,
                private langueService: LangueService,
                private activatedRoute: ActivatedRoute,
                private confirmService: ConfirmService,
                private toastrService: ToastrService,
                private router: Router) {
    }

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        //Récupération du code de la langue
        let codeLangue: string = this.activatedRoute.snapshot.params.idLangue;

        //Permet de générer la liste des différents onglets disponibles
        this.listeTabItems = [{
            code: OngletsInfos.CONFIGURATION,
            libelle: this.translateService.instant('admin.bibliotheque.internationalisation.onglets.configuration')
        }];

        //Sélection de l'onglet Généralités par défaut
        this.selectedItem = this.listeTabItems[0];
        this.selectedItem.selected = true;

        //Définition de la liste des actions possibles
        this.listeActions.next([{
            type: TypeAction.SECONDARY,
            icone: 'nio icon-suppression',
            libelle: 'global.actions.supprimer',
            doAction: () => this.delete(),
            isVisible: () => this.langue?.standard === false,
            isDisabled: () => this.langue?.useCount > 0
        },{
            type: TypeAction.PRIMARY,
            icone: 'nio icon-sauvegarde',
            libelle: 'global.actions.enregistrer',
            doAction: () => this.save(),
            isDisabled: () => !this.isSaveValid(),
        }]);

        //Récupération de la liste des variants autorisés
        this.langueService.getListeVariant().subscribe(result => {
            this.listeVariant = result.data.listeVariant;
        });

        //Si le code de la langue est "0" alors on est en création
        if (codeLangue === "0") {
            this.isCreation = true;
            //Initialisation d'une nouvelle langue (celle qui va être créée)
            this.langue = new Langue(null);

            //Application du variant par défaut
            this.langue.codeVariant = 'fr';

            //Définition comme une langue secondaire
            this.langue.parDefaut = false;
        } else {
            //Si le code de la langue n'est pas "0" alors on est en modification
            this.isCreation = false;

            //Récupération de la langue à modifier à l'aide du code
            this.langueService.getLangueByCode(codeLangue).subscribe(data => {
                //Initialisation de la langue
                this.langue = new Langue(data.data.langue);
            });

            //Comme on est en modification, on ajoute un onglet "Libellés" pour afficher les traductions
            this.listeTabItems.push({
                code: OngletsInfos.LIBELLES,
                libelle: this.translateService.instant('admin.bibliotheque.internationalisation.onglets.libelles')
            });
        }
    }
    /**
     * Changement d'onglet
     *
     * @param selectedItem Onglet sélectionné
     */
    onSelectedItemChange(selectedItem: PageHeaderItem) {
        //Mise à jour de l'onglet sélectionné
        this.selectedItem = selectedItem;
    }

    /**
     * Mise à jour du fichier sélectionné
     *
     * @param file fichier concerné
     */
    onFileSelected(file: File): void {
        //Mise à jour du fichier
        this.selectedFile = file;
    }

    /**
     * Sauvegarde des changements apportés à la langue
     */
    save() {
        //Si le formulaire est valide
        if (this.isSaveValid()) {
            //Flag d'action
            this.isPending = true;

            //Sauvegarde des changements en base
            this.langueService.saveLangue(this.langue,this.selectedFile,this.isCreation ? false : this.custom.isCustom).subscribe(data => {
                //Si le code erreur concerne un doublon
                if (data.codeErreur == TypeCodeErreur.ERROR_DOUBLON) {
                    //Affichage d'un message d'erreur indiquant que le code est déjà pris
                    this.toastrService.error(this.translateService.instant('admin.bibliotheque.internationalisation.infosLangue.langueCard.erreurDoublon'));
                } else if (data.codeErreur !== TypeCodeErreur.NO_ERROR) { //Sinon si le code d'erreur du retour est différent de NO_ERROR alors c'est que la langue ne s'est pas enregistrée correctement dans la base de données.
                    //Affichage d'un message d'erreur quand l'enregistrement de la langue en base n'a pas fonctionné correctement
                    this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
                } else {
                    //Affichage d'un message de succès si l'enregistrement de la langue en base s'est passé correctement
                    this.toastrService.success(this.translateService.instant('global.success.enregistrement'));
                    //Si on est en création alors on redirige vers la liste après avoir créé la langue, sinon on reste sur la page de la langue
                    if (this.isCreation) {
                        this.router.navigate(['Admin/Bibliotheque/Internationalisation']);
                    }
                    //Reset du fichier
                    this.selectedFile = null;
                    this.custom.isCustom = true;
                }

                //Flag d'action
                this.isPending = false;
            });
        } else {
            //Affichage d'un message d'erreur indiquant que le formulaire n'est pas valide
            this.toastrService.error(this.translateService.instant('global.errors.formInvalid'));
        }
    }

    /**
     * Suppression de la langue
     */
    delete(): void {
        //Message de confirmation
        this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation')).pipe(filter(isConfirmed => isConfirmed)).subscribe({
            next: () => {
                //Suppression
                this.langueService.deleteLangue(this.langue.code)
                    .pipe(first())
                    .subscribe({
                        next: isSuccess => {
                            //Vérification de l'enregistrement
                            if (isSuccess) {
                                //Message de succès
                                this.toastrService.success(this.translateService.instant('global.success.suppression'));

                                //Retour à la liste
                                this.onGoBack()
                            } else {
                                //Message d'erreur
                                this.toastrService.error(this.translateService.instant('global.errors.suppression'));
                            }
                        },
                        error: () => {
                            //Message d'erreur
                            this.toastrService.error(this.translateService.instant('global.errors.suppression'));
                        }
                    });
            }
        });
    }

    /**
     * Vérification de la validité du choix de fichier
     */
    isFileValid(): boolean {
        //Si langue n'existe pas encore et qu'aucun fichier n'est fourni
        if (!this.langue.idLangue && !this.selectedFile) {
            //Interdit
            return false;
        }

        //Sinon on peut mettre à jour la langue avec ou sans fichier
        return true;
    }

    /**
     * Vérification de la validité du formulaire
     */
    private isSaveValid(): boolean {
        return this.isFormValid && this.isFileValid();
    }

    /**
     * Méthode appelée lors du clic sur le bouton de retour en arrière pour retourner vers la liste des langues
     */
    onGoBack() {
        //Navigation vers la liste
        this.router.navigate(['Admin/Bibliotheque/Internationalisation']);
    }
}

/**
 * Enum pour les noms des différents onglets de la page Langue
 */
export enum OngletsInfos {
    CONFIGURATION = "Configuration",
    LIBELLES = "Libellés"
}
