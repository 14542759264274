import { Component } from "@angular/core";

/**
 * Onglet liste des domaines
 *
 * @author Guillaume TRAVOT
 * @date 26/04/2024
 */
@Component({
    host: {'data-test-id': 'liste-domaines'},
    templateUrl: './liste-domaines.component.html'
})
export class ListeDomainesComponent {}
