import {Component,OnDestroy,OnInit,ViewChild} from '@angular/core';
import {PageHeaderItem} from '@share/component/page-header/page-header';
import {DepartementAdmin} from "@domain/geographie/departementAdmin";
import {NgForm} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';
import {FloatingButtonAction,TypeAction} from '@share/component/floating-button/floating-button';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute,Router} from '@angular/router';
import {GeographieService} from '@services/admin/geographie/geographie.service';
import {ToastrService} from 'ngx-toastr';
import {ConfirmService} from '@share/component/confirmation/confirm.service';
import {TypeCodeErreur} from '@domain/common/http/result';
import {filter,first} from 'rxjs/operators'

/**
 * Composant d'affichage du détail d'un département
 *
 * @author Angeline Ha
 * @date 10/11/2023
 */
@Component({
    host: {'data-test-id': 'departement'},
    selector: 'departement',
    templateUrl: './departement.component.html'
})
export class DepartementComponent implements OnInit, OnDestroy {

    /** Liste des différents onglets disponibles dans un département */
    listeTabItems: Array<PageHeaderItem>;

    /** Onglet courant */
    selectedItem: PageHeaderItem;

    /** Référence vers l'enum pour l'utiliser dans le template */
    Onglets = Onglets;

    /** Département à afficher */
    departement: DepartementAdmin;

    /** Formulaire */
    @ViewChild('form') form: NgForm;

    /** Indique s'il faut afficher le formulaire en création ou en édition */
    isCreation: boolean;

    /** Liste des actions possibles */
    listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

    /**
     * Constructeur
     *
     * @param translateService  Service de traduction
     * @param router            Router
     * @param route             Route d'accès à cette page
     * @param geographieService Service géographie
     * @param toastrService     Service de gestion des toasts
     * @param confirmService    Service de confirmation
     */
    constructor(private translateService: TranslateService,
                private router: Router,
                private route: ActivatedRoute,
                private geographieService: GeographieService,
                private toastrService: ToastrService,
                private confirmService: ConfirmService) {
    }

    /**
     * Initialisation
     */
    ngOnInit(): void {
        //Indique au composant géographie qu'on affiche le détail d'un département
        this.geographieService.isShowDetail = true;

        //Récupération de l'identifiant du département
        this.route.params.pipe(first()).subscribe(params => {
            const idDepartement = params['idDepartement'];

            //Indique si on est en mode création
            this.isCreation = idDepartement === '0';

            //Définition de la liste des actions possibles
            this.defineListeActionsPossibles();

            //Récupération du département
            if (this.isCreation) {
                //Création d'un nouveau département
                this.departement = {
                    idDepartement: null,
                    libelle: "",
                    libelleNettoye: "",
                    code: "",
                    idRegion: null,
                    regionAdmin: null,
                    isActif: true
                }
            } else {
                //Récupération du département existant
                this.geographieService.getDepartementById(idDepartement).subscribe(data => {
                    if (data.codeErreur === TypeCodeErreur.NO_ERROR) {
                        this.departement = data.data.departement;
                        this.departement.idRegion = data.data.departement.regionAdmin.idRegion;

                    } else {
                        TypeCodeErreur.showError(data.codeErreur, this.translateService, this.toastrService);
                    }
                });
            }
        });

        //Initialisation des onglets
        this.listeTabItems = [{
            code: Onglets.GENERALITES,
            libelle: this.translateService.instant('admin.bibliotheque.geographie.territoire.onglets.generalites')
        }];

        //Sélection du premier onglet par défaut
        this.selectedItem = this.listeTabItems[0];
    }

    /**
     * Destruction
     */
    ngOnDestroy() {
        //Indique au composant géographie qu'on n'affiche plus le détail d'un département
        this.geographieService.isShowDetail = false;
    }

    /**
     * Définition de la valeur de la liste des actions possibles
     */
    defineListeActionsPossibles() {
        //Liste des actions
        const listeActions: Array<FloatingButtonAction> = [{
            type: TypeAction.PRIMARY,
            icone: 'nio icon-sauvegarde',
            libelle: 'global.actions.enregistrer',
            doAction: () => this.saveDepartement(),
            isDisabled: () => !this.form?.valid
        }];

        //Ajout de l'action de suppression lorsqu'on est en modification
        if (!this.isCreation) {
            listeActions.push({
                type: TypeAction.SECONDARY,
                icone: 'nio icon-suppression',
                libelle: 'global.actions.supprimer',
                doAction: () => this.deleteDepartement()
            });
        }

        this.listeActions.next(listeActions);
    }

    /**
     * Changement d'onglet
     *
     * @param selectedItem Onglet sélectionné
     */
    onSelectedItemChange(selectedItem: PageHeaderItem) {
        //Mise à jour de l'onglet sélectionné
        this.selectedItem = selectedItem;
    }

    /**
     * Retour vers la liste des départements
     */
    onGoBack() {
        this.router.navigate([`Admin/Bibliotheque/Geographie/Departements`]);
    }

    /**
     * Enregistrement du département
     */
    saveDepartement() {
        //Enregistrement du département
        this.geographieService.saveDepartement(this.departement).subscribe(data => {
            if (data.codeErreur === TypeCodeErreur.NO_ERROR) {
                //Toast de succès
                this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

                if (this.isCreation) {
                    //On n'est plus en mode création
                    this.isCreation = false;

                    //Récupération de l'identifiant
                    this.departement.idDepartement = data.data.idDepartement;

                    //Définition de la liste des actions possibles
                    this.defineListeActionsPossibles();

                    //Navigation avec le bon id
                    this.router.navigate(['../' + data.data.idDepartement], {relativeTo: this.route});
                }
            } else {
                //Toast d'erreur
                TypeCodeErreur.showError(TypeCodeErreur.ERROR_SAVE, this.translateService, this.toastrService);
            }
        });
    }

    /**
     * Suppression du département
     */
    deleteDepartement() {
        //Popup de confirmation
        this.confirmService.showConfirm(this.translateService.instant('admin.bibliotheque.geographie.territoire.confirmSuppression'))
            .pipe(filter(isConfirmed => isConfirmed)).subscribe({
            next: () => {
                //Suppression du département
                this.geographieService.deleteDepartement(this.departement.idDepartement).subscribe(data => {
                    if (data.codeErreur === TypeCodeErreur.NO_ERROR) {
                        //Retour vers la liste des départements
                        this.onGoBack();

                        //Toast de succès
                        this.toastrService.success(this.translateService.instant('global.success.suppression'))
                    } else {
                        //Toast d'erreur
                        TypeCodeErreur.showError(TypeCodeErreur.ERROR_DELETE, this.translateService, this.toastrService);
                    }
                })
            }
        })
    }

}

/**
 * Enum des différents onglets d'un département
 */
export enum Onglets {
    GENERALITES = "Généralités"
}
