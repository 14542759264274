import {Component} from "@angular/core";


/**
 * Onglet des r�f�rentiels
 *
 * @author Guillaume TRAVOT
 * @date 29/10/2024
 */
@Component({
    host: {'data-test-id': 'internationalisation-referentiels'},
    templateUrl: './internationalisation-referentiels.component.html'
})
export class InternationalisationReferentielsComponent {}
