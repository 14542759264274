<h1 mat-dialog-title>
    <span [translate]="'vehicule.formulaire.documents.autorisation'"></span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <please-wait class="content" *ngIf="!autorisation"></please-wait>
    <form autocomplete="off" #form="ngForm">
        <fieldset *ngIf="autorisation" [disabled]="!canModifier">
            <div [ngClass]="{ 'has-error': dateDeb?.invalid }" class="row">
                <label class="col-md-4 text-right required">
                    <span [translate]="'vehicule.formulaire.documents.dateDeb'"></span>
                </label>
                <div class="col-md-8">
                    <mat-form-field class="d-flex">
                        <input #dateDeb="ngModel" [(ngModel)]="autorisation.dateDebut" [matDatepicker]="$any(dateDebPicker)" matInput name="dateDeb" required>
                        <mat-datepicker-toggle [for]="dateDebPicker" matSuffix></mat-datepicker-toggle>
                        <mat-datepicker #dateDebPicker [disabled]="!canModifier"></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
            <div [ngClass]="{ 'has-error': dateFin.invalid }" class="row">
                <label class="col-md-4 text-right required">
                    <span [translate]="'vehicule.formulaire.documents.dateFin'"></span>
                </label>
                <div class="col-md-8">
                    <mat-form-field class="d-flex">
                        <input #dateFin="ngModel" [(ngModel)]="autorisation.dateFin" [matDatepicker]="$any(dateFinPicker)" [min]="autorisation.dateDebut || aujourdhui" matInput name="dateFin" required>
                        <mat-datepicker-toggle [for]="dateFinPicker" matSuffix></mat-datepicker-toggle>
                        <mat-datepicker #dateFinPicker [disabled]="!canModifier"></mat-datepicker>
                        <mat-error *ngIf="autorisation.dateDebut && autorisation.dateFin && DateUtilsService.compareDates(autorisation.dateDebut,autorisation.dateFin) > 0"><span [translate]="'global.errors.dateFinAndDateDeb'"></span>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <label class="col-md-4"><span [translate]="'ndf.frais.remarque'"></span></label>
                <div class="col-md-8">
                    <mat-form-field class="d-flex">
                        <textarea [(ngModel)]="autorisation.remarque" matInput name="remarque" rows="3"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="autorisation.user" class="row">
                <label class="col-md-4 text-right"><span [translate]="'vehicule.formulaire.documents.accorde'"></span></label>
                <div class="col-md-8">
                    <div class="d-flex">
                        <span>{{autorisation.user | user}}  ({{autorisation.dateApplication| date: 'short' }})</span>
                    </div>
                </div>
            </div>

        </fieldset>
    </form>
    <document-list #listeDocument *ngIf="autorisation && settings" [idObjet]="autorisation.idVehiculeAutorisation" [idObjetForPJ]="vehicule.idCollab" [context]="'PROFIL_VEHICULE_AUTORISATION'" [settings]="settings"
                   [isSimpleDocumentList]="true" [canAddDocument]="canModifier" [canDeleteDocument]="canModifier"></document-list>
</div>
<div mat-dialog-actions align="end" *ngIf="autorisation && (canModifier || canDelete)">
    <button (click)="deleteAutorisation()" *ngIf="autorisation.idVehiculeAutorisation && vehicule.statut == 0" [disabled]="isSaving" color="warn" mat-stroked-button>
        <span [translate]="'global.actions.supprimer'"></span>
    </button>
    <button (click)="saveAutorisation()" [disabled]="form?.invalid || isSaving || !canModifier" color="primary" mat-flat-button>
        <span *ngIf="!isSaving" [translate]="'global.actions.enregistrer'"></span>
        <mat-spinner *ngIf="isSaving" class="m-5" diameter="28"></mat-spinner>
    </button>
</div>
