import {NgModule} from "@angular/core";
import {Routes} from "@angular/router";
import {ShareModule} from "@share/share.module";
import {AdminVoyagesComponent} from "./admin-voyages.component";
import {VoyagesReferentielsModule,voyagesReferentielsRoutes} from './referentiels/voyages-referentiels.module';
import {VoyagesReferentielsComponent} from "@components/admin/voyages/referentiels/voyages-referentiels.component";
import {AdminGuardProvider} from "@core/security/admin-guard.provider";
import {VoyagesMissionsComponent} from './missions/voyages-missions.component';
import {VoyagesMissionsModule} from "@components/admin/voyages/missions/voyages-missions.module";
import {AdminTravelhubComponent} from "@components/admin/voyages/travelhub/admin-travelhub.component";
import {adminTravelhubRoutes} from "@components/admin/voyages/travelhub/admin-travelhub.module";
import {DroitAdmin} from "@core/security/droit-admin";
import {VoyagesFraisAgenceModule} from "@components/admin/voyages/frais-agence/voyages-frais-agence.module";
import {VoyagesFraisAgenceComponent} from "@components/admin/voyages/frais-agence/voyages-frais-agence.component";

export const adminVoyagesRoutes: Routes = [
    {
        path: 'FraisAgence',
        component: VoyagesFraisAgenceComponent,
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_GESTION_FRAIS_AGENCE] }
    },{
        path: 'Missions',
        component: VoyagesMissionsComponent,
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_VISUALISATION_MISSIONS] }
    },{
        path: 'Referentiels',
        component: VoyagesReferentielsComponent,
        canActivate: [AdminGuardProvider],
        children: voyagesReferentielsRoutes
    },{
        path: 'TravelHub',
        component: AdminTravelhubComponent,
        children: adminTravelhubRoutes,
    },{
        path: '',
        redirectTo: 'Referentiels',
        pathMatch: 'full'
    },{
        path: '**',
        redirectTo: 'Referentiels'
    }
];

/**
 * Module de gestion des voyages
 */
@NgModule({
    imports: [
        ShareModule,
        VoyagesReferentielsModule,
        VoyagesMissionsModule,
        VoyagesFraisAgenceModule
    ],
    declarations: [
        AdminVoyagesComponent
    ],
    exports: [
        AdminVoyagesComponent
    ],
    providers: []
})
export class AdminVoyagesModule {
}
