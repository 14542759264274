import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Result} from "@domain/common/http/result";
import {environment} from '@environments/environment';
import {Lot,LotAvanceDTO,LotFactureDTO,LotItemType,LotListItem,LotNdfDTO} from "@domain/comptabilite/lot";
import {Observable} from "rxjs/Rx";
import {Filter} from "@domain/common/list-view";
import {SearchSpec} from "@domain/common/list-view/searchSpec";
import 'rxjs/add/operator/map';
import {Page} from '@domain/common/http/list-result';
import {FilterDTO} from "@domain/common/list-view/filterDTO";
import {AbstractObjetWorkflow} from "@domain/workflow/abstract-objet-workflow";
import {ListItem} from "@domain/common/list-view/list-item";
import {TypeProfil,User} from "@domain/user/user";
import {DroitAdmin} from "@core/security/droit-admin";

/**
 * Service de gestion des lots comptables
 */
@Injectable()
export class ComptabiliteService {

    constructor(private http: HttpClient) {
    }

    /**
     * Création du Lot comptable
     * @param lot comptable
     */
    createLot(lot: Lot) {
        return this.http.put<Result>(`${environment.baseUrl}/controller/Lot/saveLot`, lot);
    }

    /**
     * Chargement du Lot comptable
     * @param idLot identifiant du lot comptable
     */
    loadLot(idLot: number): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Lot/loadLot/${idLot}`, null);
    }

    /**
     * Chargement du header d'un lot comptable, qui correspond également à un item de la liste des lots comptables
     * @param idLot identifiant du lot comptable
     */
    loadLotListItem(idLot: number) {
        return this.http.post<Result<{lot: LotListItem}>>(`${environment.baseUrl}/controller/Lot/loadListLot/${idLot}`, null);
    }

    /**
     * Suppression du Lot comptable
     * @param idLot identifiant du lot comptable
     */
    deleteLot(idLot: number): Observable<Result> {
        return this.http.delete<Result>(`${environment.baseUrl}/controller/Lot/deleteLot/${idLot}`);
    }

    /**
     * Charge la liste des NDF à comptabiliser correspondant au filtre en paramètre.
     *
     * @param filter Filtre sur les NDF
     * @return {Observable<LotNdfDTO[]>} Liste des NDF à comptabiliser
     */
    loadListeNdf(filter: Filter): Observable<Page<LotNdfDTO>> {
        const searchSpec: SearchSpec = {
            numPage: 0,
            nbObjetsParPage: 1000,
            listeFilter: [new FilterDTO(filter)],
            defaultOrder: "-idNDF"
        }
        return this.http.post<Result>(`${environment.baseUrl}/controller/NDF/listeNDFComptabiliser`, searchSpec).map(result => {
            return result?.data?.result as Page<LotNdfDTO>;
        });
    }

    /**
     * Charge la liste des factures à comptabiliser correspondant au filtre en paramètre.
     *
     * @param filter Filtre sur les factures
     * @return {Observable<LotFactureDTO[]>} Liste des factures à comptabiliser
     */
    loadListeFactures(filter: Filter): Observable<Page<LotFactureDTO>> {
        const searchSpec: SearchSpec = {
            numPage: 0,
            nbObjetsParPage: 1000,
            listeFilter: [new FilterDTO(filter)],
            defaultOrder: "-idFacture"
        }
        return this.http.post<Result>(`${environment.baseUrl}/controller/Facture/listeFactureComptabiliser`, searchSpec).map(result => {
            return result?.data?.result as Page<LotFactureDTO>;
        });
    }

    /**
     * Charge la liste des avances à comptabiliser correspondant au filtre en paramètre.
     *
     * @param filter Filtre sur les avances
     * @return {Observable<LotAvanceDTO[]>} Liste des avances à comptabiliser
     */
    loadListeAvances(filter: Filter): Observable<Page<LotAvanceDTO>> {
        const searchSpec: SearchSpec = {
            numPage: 0,
            nbObjetsParPage: 1000,
            listeFilter: [new FilterDTO(filter)],
            defaultOrder: "-idAvance"
        }
        return this.http.post<Result>(`${environment.baseUrl}/controller/Avance/listeAvanceComptabiliser`, searchSpec).map(result => {
            return result?.data?.result as Page<LotAvanceDTO>;
        });
    }

    /**
     * Enregistre les items à ajouter au lot dans le local storage
     *
     * @param type Type des items
     * @param listeItems Liste des items
     */
    setItemsForLot<U extends AbstractObjetWorkflow & ListItem>(type: LotItemType,listeItems: Array<U>) {
        this.setIdsForLot(type,listeItems.filter(value => value.isSelected && value.getMapAction()?.canComptabiliser).map(item => item.getId()));
    }

    /**
     * Enregistre une liste d'identifiants dans le local storage
     *
     * @param type Type des items
     * @param listeIds Liste des identifiants
     */
    setIdsForLot(type: LotItemType,listeIds: Array<number>) {
        sessionStorage.setItem(this.getSessionStorageKeyForType(type),JSON.stringify(listeIds));
    }

    /**
     * Récupère les identifiants depuis le local storage
     *
     * @param type Type des items
     */
    getListeIdsForLot(type: LotItemType): Array<number> {
        return JSON.parse(sessionStorage.getItem(this.getSessionStorageKeyForType(type)));
    }

    /**
     * Supprime les items du local storage
     *
     * @param type Type des items
     */
    clearItems(type: LotItemType) {
        sessionStorage.removeItem(this.getSessionStorageKeyForType(type));
    }

    /**
     * Retourne la clef utilisée dans le local storage pour la gestion des items à ajouter à un lot
     *
     * @param type Type des items
     */
    private getSessionStorageKeyForType(type: LotItemType): string {
        switch (type) {
            case LotItemType.NDF: return 'lot_ndf';
            case LotItemType.AVANCE: return 'lot_avances';
            case LotItemType.FACTURE: return 'lot_factures';
            case LotItemType.RELEVE: return 'lot_releve';
            default: throw 'Type inconnu !?';
        }
    }

    /**
     * Récupère la liste des factures comptabilisables d'un relevé pour les ajouter à un lot
     *
     * @param idReleve Identifiant du relevé
     */
    listeFacturesLotFromReleve(idReleve: number): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Facture/listeFactureLot/${idReleve}`,null);
    }

    /**
     * Vérifie si l'utilisateur passé en paramètre peut voir le détail du lot (généralités et listes d'objets contenus dans le lot)
     *
     * @param user Utilisateur
     */
    canSeeDetailLot(user: User): boolean {
        let canSeeDetail: boolean = false;

        //Vérification de la fonction
        if (user?.fonction === TypeProfil.ADMINISTRATEUR || user?.fonction === TypeProfil.COMPTABLE) {
            //Admin et Comptable : OK
            canSeeDetail = true;
        } else if (user?.fonction === TypeProfil.SOUS_ADMINISTRATEUR) {
            //Sous admin : doit avoir le droit spécifique
            canSeeDetail = user.listeDroits?.some(da => da === DroitAdmin.DROIT_LOTS_COMPTABLES);
        }

        //Retour du droit
        return canSeeDetail;
    }

    /**
     * Vérifie si l'utilisateur passé en paramètre a accès à l'archive du lot (demat)
     *
     * @param user Utilisateur
     */
    canSeeArchiveLot(user: User): boolean {
        let canSeeArchive: boolean = false;

        //Vérification de la fonction
        if (user?.fonction === TypeProfil.ADMINISTRATEUR) {
            //Admin : OK
            canSeeArchive = true;
        } else if (user?.fonction === TypeProfil.SOUS_ADMINISTRATEUR) {
            //Sous admin : doit avoir le droit spécifique
            canSeeArchive = user.listeDroits?.some(da => da === DroitAdmin.DROIT_DEMATERIALISATION);
        }

        //Retour du droit
        return canSeeArchive;
    }

}