import {CompteUsage} from "./compte-usage";
import {EntityAttribute} from "@share/component/autocomplete/options/entity-attribute";

/**
 * Usages d'un compte comptable
 * Fournisseur associé à un compte comptable
 *
 * @author Laurent Convert
 * @date 24/09/2024
 */
export class CompteUsageFournisseur implements CompteUsage {
	raisonSociale: string;
	codeFournisseur: string;
	serviceFacture: string;

	/**
	 * Constructeur
	 *
	 * @param dto Source
	 */
	constructor(dto: any) {
		Object.assign(this,dto);
	}

	/**
	 * Récupération de l'identifiant de l'objet
	 */
	getKey(): string {
		return this.raisonSociale;
	}

	/**
	 * Affichage d'un élément
	 */
	displayItem(): string {
		return this.raisonSociale;
	}

	/**
	 * Liste les propriétés de l'objet pour lesquelles il faut afficher un badge 'clé:valeur'
	 */
	listDisplayedAttributes(): Array<EntityAttribute> {
		return [{
			clef: 'admin.comptabilite.planComptable.compteComptable.usage.codeFournisseur',
			valeur: this.codeFournisseur
		},{
			clef: 'admin.comptabilite.planComptable.compteComptable.usage.serviceFacture',
			valeur: this.serviceFacture
		}];
	}
}