import {Action} from '@domain/action';

/**
 * Actions
 */
export const SEARCH_FROM_MENU = 'SEARCH_FROM_MENU';

/**
 * Interface
 */
export interface SearchState {
    /** Clic sur le bouton de recherche dans le menu **/
    searchData?: any
}

/**
 * Reducer pour l'écoute de l'évènement de clic sur le bouton recherche dans le menu latéral
 */
export function searchReducer(state: SearchState = {},action: Action<any>): SearchState {
    //Vérification du type d'action
    if (action.type == SEARCH_FROM_MENU) {
        //Mise à jour de l'état
        state = {
            ...state,
            searchData: action.payload
        };
    }

    //Retour de l'état
    return state;
}
