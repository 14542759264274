import {Component} from '@angular/core';
import {PageHeaderItem} from '@share/component/page-header/page-header';
import {IComponentWithRoutedTabs} from "@domain/admin/recherche/component-with-routed-tabs";

/**
 * Onglets de l'écran du menu admin : Voyages > Frais d'agence
 */
export enum Onglets {
	FRAIS_AGENCE = "FRAIS_AGENCE"
}

/**
 * Composant du menu admin : Voyages > Frais d'agence
 */
@Component({
	host: {'data-test-id': 'voyages-frais-agence'},
	selector: 'voyages-frais-agence',
	templateUrl: './voyages-frais-agence.component.html'
})
export class VoyagesFraisAgenceComponent implements IComponentWithRoutedTabs {
	/** Liste statique des onglets */
	public static listeOnglets: Array<PageHeaderItem> = [{
		code: Onglets.FRAIS_AGENCE,
		libelle: 'admin.voyages.fraisAgence.tabs.fraisAgence',
		url: 'Admin/Voyages/FraisAgence'
	}];

	/** Liste des différents onglets */
	listeTabItems: Array<PageHeaderItem> = VoyagesFraisAgenceComponent.listeOnglets;

	/** Onglet courant */
	selectedItem: PageHeaderItem = this.listeTabItems[0];

	//Import de l'énumération pour le DOM
	readonly Onglets = Onglets;

	/**
	 * Changement d'onglet
	 *
	 * @param selectedItem Onglet sélectionné
	 */
	onSelectedItemChange(selectedItem: PageHeaderItem) {
		//Mise à jour de l'onglet sélectionné
		this.selectedItem = selectedItem;
	}
}
