import {Component,OnDestroy,OnInit} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {PageHeaderItem} from "@share/component/page-header/page-header";
import {ActivatedRoute,Router} from "@angular/router";
import {KeyStatutApplication,KeyStatutWorkflow} from "@domain/admin/statut-application/statut-application";
import {filter} from "rxjs/operators";
import {MigrationsService} from "@services/admin/maintenance/migrations.service";
import {Subscription} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {MigrationBddPopupComponent} from "@components/admin/maintenance/migrations/migration-bdd-popup.component";
import {StatutWorkflowService} from "@services/admin/statut-workflow/statut-workflow.service";
import {IComponentWithRoutedTabs} from "@domain/admin/recherche/component-with-routed-tabs";

/**
 * Enum pour les noms des différents onglets de la page migrations
 */
export enum Onglets {
    HISTORIQUE = "Historique",
    MAINTENANCE = "Maintenance",
    PARAMETRES = "Parametres"
}

/**
 * Sous-menu migrations
 *
 * @author Guillaume TRAVOT
 * @date 01/02/2024
 */
@Component({
    host: {'data-test-id': 'migrations'},
    templateUrl: './migrations.component.html'
})
export class MigrationsComponent implements OnInit, OnDestroy, IComponentWithRoutedTabs {
    /** Liste statique des onglets */
    public static listeOnglets: Array<PageHeaderItem> = [
        {
            code: Onglets.HISTORIQUE,
            libelle: 'admin.maintenance.migrations.onglets.historique.title',
            url: 'Admin/Maintenance/Migrations/Historique'
        }, {
            code: Onglets.MAINTENANCE,
            libelle: 'admin.maintenance.migrations.onglets.maintenance.title',
            url: 'Admin/Maintenance/Migrations/Maintenance'
        }, {
            code: Onglets.PARAMETRES,
            libelle: 'admin.maintenance.migrations.onglets.parametres.title',
            url: 'Admin/Maintenance/Migrations/Parametres'
        }
    ];

    /** Liste des différents onglets disponibles dans le menu migrations */
    listeTabItems: Array<PageHeaderItem> = MigrationsComponent.listeOnglets;

    /** Onglet courant */
    selectedItem: PageHeaderItem;

    /** Référence vers l'enum des onglets pour utiliser dans le template */
    Onglet = Onglets;

    /** Index de l'onglet courant */
    selectedIndex: number;

    /** Indique si le header doit être affiché */
    isDisplayed: boolean;

    /** Statut de l'application */
    applicationStatut: string;

    /** Souscriptions */
    souscriptions: Subscription[] = [];

    /** Popup ouverte */
    isPopupOpen: boolean = false;

    /** Maintenance en cours */
    isMaintenanceEnCours: boolean = false;

    /** Statut du Workflow */
    synchroWfStatut: KeyStatutWorkflow;

    /**
     * Constructeur
     */
    constructor(
        protected translateService: TranslateService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private migrationsService: MigrationsService,
        private statutWorkflowService: StatutWorkflowService,
        protected matDialog: MatDialog
    ) { }

    /**
     * Initialisation du composant
     */
    ngOnInit(): void {
        this.souscriptions.push(this.activatedRoute.url.subscribe(() => {
            //Récupération de la route
            const firstChild = this.activatedRoute.snapshot.firstChild;

            //Extraction du path
            const path = firstChild.url[0].path;

            //On vérifie si on trouve l'onglet demandé via l'url
            const matching = this.listeTabItems.find(i => i.code === path);

            //Si on trouve bien l'onglet correspondant
            if (!!matching) {
                //on met à jour le composant header
                setTimeout(() => {
                    this.selectedItem = matching;
                    this.selectedIndex = this.listeTabItems.findIndex(i => i.code === path);
                    this.isDisplayed = (Object.values(firstChild.params).findIndex(v => v !== undefined) === -1);
                });
            } else {
                //Sinon, on redirige sur le premier onglet et on met à jour le composant header
                setTimeout(() => {
                    this.selectedItem = this.listeTabItems[0];
                    this.selectedIndex = 0;
                    this.isDisplayed = (Object.values(firstChild.params).findIndex(v => v !== undefined) === -1);
                });
            }
        }));

        //Abonnement à la mise à jour du statut du Workflow
        this.souscriptions.push(this.statutWorkflowService.synchroWFStatut$.subscribe(statut => this.synchroWfStatut = statut?.statut as KeyStatutWorkflow));

        //Abonnement à la mise à jour de la maintenance en cours
        this.souscriptions.push(this.migrationsService.isMaintenanceEnCours$.subscribe((isMaintenanceEnCours) => this.isMaintenanceEnCours = isMaintenanceEnCours));

        //Demande de vérification du statut de l'appli
        this.migrationsService.checkAppliStatut();

        //Abonnement à la mise à jour du statut de l'application
        this.souscriptions.push(this.migrationsService.appStatut$.pipe(filter(statut => !!statut)).subscribe((appStatut) => {
            //Affichage du statut
            this.applicationStatut = 'admin.maintenance.migrations.statutApplication.' + KeyStatutApplication[appStatut?.applicationStatut];

            //Si la BDD doit être mise à jour
            if (!this.isPopupOpen && !appStatut?.isMigrationAuto && !appStatut?.isBddUpToDate && !appStatut.isMaintenanceEnCours && this.synchroWfStatut == KeyStatutWorkflow.HISTO_WF_NONE && appStatut?.applicationStatutAdmin?.statut != KeyStatutApplication.HORS_LIGNE && appStatut?.applicationStatutAdmin?.statut != KeyStatutApplication.HORS_LIGNE_TEMPO) {
                //Flag de popup ouverte
                this.isPopupOpen = true;

                //Ouverture de la popup de migration
                this.matDialog.open(MigrationBddPopupComponent, {
                    minWidth: 600,
                    maxWidth: 600,
                    minHeight: 200
                });
            }
        }));
    }

    /**
     * Changement d'onglet
     *
     * @param selectedItem Onglet sélectionné
     */
    onSelectedItemChange(selectedItem: PageHeaderItem): void {
        if (!this.selectedItem) {
            this.selectedItem = selectedItem;
        }
        if (selectedItem?.code !== this.selectedItem?.code) {
            this.selectedItem = selectedItem;
            this.router.navigateByUrl(selectedItem.url);
        }
    }

    /** Indique si on se trouve sur l'onglet de maintenance */
    isOngletMaintenance(): boolean {
        return this.selectedItem.code == Onglets.MAINTENANCE;
    }

    /**
     * Destruction du composant
     */
    ngOnDestroy() {
        //Parcours des souscriptions
        for (const sub of this.souscriptions) {
            //Désabonnement
            sub.unsubscribe();
        }
    }
}