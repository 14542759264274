import {Component,OnDestroy,OnInit} from '@angular/core';
import {PageHeaderItem} from "@share/component/page-header/page-header";
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute,Router} from "@angular/router";
import {Session} from "@domain/security/session";
import {Store} from "@ngrx/store";
import {AppState} from "@domain/appstate";
import {isRouteAllowed} from "@core/security/role-admin-helpers";
import {filterFirstNotNull} from "@share/utils/rxjs-custom-operator";
import {IComponentWithRoutedTabs} from "@domain/admin/recherche/component-with-routed-tabs";
import {Subscription} from "rxjs";

/**
 * Onglets de la page Utilisateurs
 */
export enum Onglets {
	INTERNES = 'Internes',
	EXTERNES = 'Externes',
	PARAMETRES = 'Parametres'
}

/**
 * Composant du menu Entreprise > Utilisateurs
 */
@Component({
	host: {'data-test-id': 'entreprise-utilisateurs'},
	selector: 'entreprise-utilisateurs',
	templateUrl: './entreprise-utilisateurs.component.html'
})
export class EntrepriseUtilisateursComponent implements OnInit, OnDestroy, IComponentWithRoutedTabs {
	/** Liste statique des onglets */
	public static listeOnglets: Array<PageHeaderItem> = [
		{
			code: Onglets.INTERNES,
			libelle: 'admin.entreprise.utilisateurs.tabs.internes',
			url: 'Admin/Entreprise/Utilisateurs/Internes'
		},{
			code: Onglets.EXTERNES,
			libelle: 'admin.entreprise.utilisateurs.tabs.externes',
			url: 'Admin/Entreprise/Utilisateurs/Externes'
		},{
			code: Onglets.PARAMETRES,
			libelle: 'admin.entreprise.utilisateurs.tabs.parametres',
			url: 'Admin/Entreprise/Utilisateurs/Parametres'
		}
	];

	/** Liste des onglets */
	listeTabItems: Array<PageHeaderItem> = EntrepriseUtilisateursComponent.listeOnglets;

	/** Onglet courant */
	selectedItem: PageHeaderItem;

	/** Référence vers l'enum des onglets entreprise pour l'utiliser dans le template */
	Onglet = Onglets;

	/** Index de l'onglet courant */
	selectedIndex: number;

	/** Indique si le header doit être affiché */
	isDisplayed: boolean;

	/** Session */
	private session: Session;

	/** Souscription */
	private subscription: Subscription;

	/**
	 * Constructeur
	 *
	 * @param translateService le moteur de traduction
	 * @param router le routeur Angular
	 * @param store le store de l'appli
	 * @param activatedRoute la route actuelle
	 */
	constructor(
		protected translateService: TranslateService,
		private router: Router,
		private store: Store<AppState>,
		private activatedRoute: ActivatedRoute,
	) { }

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Récupération de la session
		this.store.select(state => state.session).pipe(filterFirstNotNull()).subscribe((session: Session) => {
			//Stockage de la session
			this.session = session;

			//Filtrage des onglets autorisés
			this.listeTabItems = this.listeTabItems.filter(i => isRouteAllowed(this.router, this.session, i.url));

			this.subscription = this.activatedRoute.url.subscribe(() => {
				//Récupération de la route
				const firstChild = this.activatedRoute.snapshot.firstChild;

				//Extraction du path
				const path = firstChild.url[0].path;

				//On vérifie si on trouve l'onglet demandé via l'url
				const matching = this.listeTabItems.find(i => i.code === path);

				//Si on trouve bien l'onglet correspondant
				if (!!matching) {
					//on met à jour le composant header
					setTimeout(() => {
						this.selectedItem = matching;
						this.selectedIndex = this.listeTabItems.findIndex(i => i.code === path);
						this.isDisplayed = true;
					});
				} else {
					//Sinon, on redirige sur le premier onglet et on met à jour le composant header
					setTimeout(() => {
						this.selectedItem = this.listeTabItems[0];
						this.selectedIndex = 0;
						this.isDisplayed = path != 'User';
					});
				}
			});
		});
	}

	/**
	 * Changement d'onglet
	 *
	 * @param selectedItem Onglet sélectionné
	 */
	onSelectedItemChange(selectedItem: PageHeaderItem): void {
		if (!this.selectedItem) {
			this.selectedItem = selectedItem;
		}
		if (selectedItem?.code !== this.selectedItem?.code) {
			this.selectedItem = selectedItem;
			this.router.navigateByUrl(selectedItem.url);
		}
	}

	/**
	 * Destruction du composant
	 */
	ngOnDestroy() {
		//Résiliation de l'abonnement
		this.subscription.unsubscribe();
	}
}
