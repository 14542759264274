import {Component,Input,OnInit} from '@angular/core';
import {ListView,TypeComparaison,TypeFilter} from "@domain/common/list-view";
import {LienRoleUser} from "@domain/workflow/lienRoleUser";
import {RoleUsageListItemComponent} from "@components/admin/workflow/roles/roles/detail/usage/role-usage-list-item.component";
import {TranslateService} from "@ngx-translate/core";
import {RoleLong} from "@domain/workflow/roleLong";
import {Sorting} from "@domain/common/list-view/sorting";
import {ListViewService} from "@share/component/list-view/list-view.service";

/**
 * Composant d'affichage de l'usage des rôles
 *
 * @author Laurent SCIMIA
 * @date 09/10/2023
 */
@Component({
	host: {'data-test-id': 'role-usage'},
	selector: 'role-usage',
	templateUrl: './role-usage.component.html'
})
export class RoleUsageComponent implements OnInit {
	/** Rôle */
	@Input() role: RoleLong;

	/** Liste des usages */
	listeUsages: ListView<LienRoleUser,RoleUsageListItemComponent>;

	/**
	 * Constructeur
	 *
	 * @param translateService le moteur de traduction
	 * @param listViewService service des listes
	 */
	constructor(
		private translateService: TranslateService,
		private listViewService: ListViewService<LienRoleUser, RoleUsageListItemComponent>
	) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Initialisation de la liste
		this.listeUsages = new ListView<LienRoleUser,RoleUsageListItemComponent>({
			title: this.translateService.instant('workflow.roles.usage.title'),
			uri: `/controller/Role/getUsages/${this.role.idRole}`,
			component: RoleUsageListItemComponent,
			isFilter: true,
			defaultOrder: 'user.nom,user.prenom',
			listeFilters: [
				{
					clef: 'user.nom,user.prenom',
					title: this.translateService.instant('filter.utilisateur'),
					typeComparaison: TypeComparaison[TypeComparaison.LIKE],
					isDefault: true
				},{
					clef: 'user.matricule',
					title: this.translateService.instant('filter.matricule'),
					typeComparaison: TypeComparaison[TypeComparaison.LIKE],
					isDefault: true
				},{
					clef: 'user.orga.numService,user.orga.libelle',
					title: this.translateService.instant('filter.service'),
					typeComparaison: TypeComparaison[TypeComparaison.LIKE],
					isDefault: true
				},{
					clef: 'dateDebut',
					title: this.translateService.instant('filter.dateDebut'),
					type: TypeFilter[TypeFilter.DATE]
				},{
					clef: 'dateFin',
					title: this.translateService.instant('filter.dateFin'),
					type: TypeFilter[TypeFilter.DATE]
				},{
					clef: 'isActif',
					isSelected: true,
					valeur: "true",
					title: this.translateService.instant('filter.isActif'),
					typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
					type: TypeFilter[TypeFilter.BOOLEAN]
				}
			]
		});

		//Rafraichissement des filtres sélectionnés par défaut pour forcer le filtre isActif
		this.listViewService.refreshListeSelectedFilters(this.listeUsages);

		//Définition des colonnes de tri
		this.listeUsages.columns = [
			{key: 'user.nom',title: 'filter.nom'},
			{key: 'user.prenom',title: 'filter.prenom'},
			{key: 'user.matricule',title: 'filter.matricule'},
			{key: 'user.orga.numService,user.orga.libelle',title: 'filter.service'},
			{key: 'dateDebut',title: 'filter.dateDebut'},
			{key: 'dateFin',title: 'filter.dateFin'}
		];

		//Ajout du tri de la liste selon l'ordre voulu
		this.listeUsages.sorting = new Sorting(this.listeUsages.columns,'user.nom,user.prenom');
	}
}