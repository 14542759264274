import {Component,ViewChild} from '@angular/core';
import {Store} from "@ngrx/store";
import {AppState} from "@domain/appstate";
import {AvanceListComponent} from "../../../avance/avance-list/avance-list.component";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {WorkflowService} from "../../../workflow/workflow.service";
import {DashboardAvancesListItemComponent} from "./dashboard-avances-list-item.component";
import {IDashboardComponent} from "../../IDashboardComponent";
import {ListeAvance} from "@domain/avance/liste-avance";
import {AvanceListItemComponent} from "../../../avance/avance-list/avance-list-item/avance-list-item.component";
import {ListViewComponent} from "@share/component/list-view/list-view.component";
import {MatDialog} from "@angular/material/dialog";
import {TypeProfil} from "@domain/user/user";
import {SessionStorageService} from '@domain/common/services/session-storage.service';
import {CguService} from '@components/cgu/cgu.service';
import {ToastrService} from 'ngx-toastr';
import {ComptabiliteService} from "@components/comptabilite/comptabilite.service";
import {AvanceService} from "@components/avance/avance.service";

/**
 * Liste des Avances du Dashboard
 */
@Component({
	host: {'data-test-id': 'dashboard-avances-list'},
	selector: 'dashboard-avances-list',
	templateUrl: './dashboard-avances-list.component.html'
})
export class DashboardAvancesListComponent extends AvanceListComponent implements IDashboardComponent<ListeAvance,AvanceListItemComponent> {
	/** C'est une liste de dashboard */
	isDashboardList = true;

	/** Référence au composant de la liste (pour mise à jour au changement de profil) */
	@ViewChild('listViewComponent') listViewComponent: ListViewComponent<ListeAvance,AvanceListItemComponent>;

	/**
	 * Constructeur
	 *
	 * @param router le routeur
	 * @param translateService le service de traduction
	 * @param store le store
	 * @param workflowService le service de gestion du workflow
	 * @param matDialog le composant des popups
	 * @param sessionStorageService Service de gestion du Session Storage
	 * @param cguService Service des CGU
	 * @param toastrService Service de messages au format toast
	 * @param comptabiliteService Service de gestion des lots comptables
	 * @param avanceService le service de gestion des avances
	 */
	constructor(protected router: Router,
				protected translateService: TranslateService,
				protected store: Store<AppState>,
				protected workflowService: WorkflowService,
				protected matDialog: MatDialog,
				protected sessionStorageService: SessionStorageService,
				protected cguService: CguService,
				protected toastrService: ToastrService,
				protected comptabiliteService: ComptabiliteService,
				protected avanceService: AvanceService
	) {
		super(router,translateService,store,workflowService,matDialog,sessionStorageService,cguService,toastrService,comptabiliteService,avanceService);
	}

	/**
	 * Spécificités de la liste
	 */
	protected initListCore() {
		//Il s'agit d'une liste du dashboard
		this.liste.isDashboardList = true;

		//Définition du list item du dashboard
		this.liste.component = DashboardAvancesListItemComponent;

		//Adresse de redirection au clic sur le titre
		this.liste.redirect = "/ListeAvance";
	}

	/**
	 * Spécificités de la liste
	 */
	protected initListSpecific() {
		//Ajout du bouton de création d'une avance
		this.liste.listeActions = [{
			icon: 'add',
			onPress: () => this.fonction == TypeProfil.COLLABORATEUR ? this.create() : this.createWithCollab(),
			isVisible: () => this.creationsPossibles.avancePossible
		},{
			icon: 'search',
			onPress: () => this.router.navigate([this.liste.redirect]),
			isVisible: () => !this.creationsPossibles.avancePossible
		}];
	}
}
