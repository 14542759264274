import {Component,Input,OnInit} from '@angular/core';
import {Motif} from "@domain/workflow/motif";
import {TypeAction,TypePortee} from "@domain/workflow/workflow";
import {TypeProfil} from "@domain/user/user";
import {TranslateService} from "@ngx-translate/core";

/**
 * Composant de l'onglet généralité d'un motif
 *
 * @author Angeline Ha
 * @date 27/08/2024
 */
@Component({
  host: {'data-test-id': 'motif-generalites'},
  selector: 'motif-generalites',
  templateUrl: './motif-generalites.component.html'
})
export class MotifGeneralitesComponent implements OnInit {
  /** Motif */
  @Input() motif: Motif;

  /** Liste des modules disponibles */
  listeModules: Array<MultiselectOptionModule> = [{
    id: TypePortee.OT,
    libelle: this.translateService.instant('portee.OT')
  }, {
    id: TypePortee.OD,
    libelle: this.translateService.instant('portee.OD')
  }, {
    id: TypePortee.NF,
    libelle: this.translateService.instant('portee.NF')
  }, {
    id: TypePortee.DV,
    libelle: this.translateService.instant('portee.DV')
  }, {
    id: TypePortee.AV,
    libelle: this.translateService.instant('portee.AV')
  }];

  /** Liste des modules sélectionnés */
  listeModulesSelected: Array<MultiselectOptionModule> = [];

  /** Liste des profils disponibles */
  listeProfils: Array<MultiselectOptionNumber> = [{
    id: TypeProfil.COMPTABLE,
    libelle: this.translateService.instant('profil.comptable')
  }, {
    id: TypeProfil.RESPONSABLE,
    libelle: this.translateService.instant('profil.responsable')
  }, {
    id: TypeProfil.COLLABORATEUR,
    libelle: this.translateService.instant('profil.collaborateur')
  }, {
    id: TypeProfil.ASSISTANT,
    libelle: this.translateService.instant('profil.assistant')
  }, {
    id: TypeProfil.FOURNISSEUR,
    libelle: this.translateService.instant('profil.fournisseur')
  }];

  /** Liste des profils sélectionnés */
  listeProfilsSelected: Array<MultiselectOptionNumber> = [];

  /** Liste des actions disponibles */
  listeActions: Array<MultiselectOptionNumber> = [{
    id: TypeAction.VALIDER,
    libelle: this.translateService.instant('global.actions.valider')
  }, {
    id: TypeAction.REJETER,
    libelle: this.translateService.instant('global.actions.rejeter')
  }, {
    id: TypeAction.INVALIDER,
    libelle: this.translateService.instant('global.actions.invalider')
  }, {
    id: TypeAction.ANNULER,
    libelle: this.translateService.instant('global.actions.annuler')
  }, {
    id: TypeAction.EMETTRE,
    libelle: this.translateService.instant('global.actions.emettre')
  }, {
    id: TypeAction.ARCHIVER,
    libelle: this.translateService.instant('global.actions.archiver')
  }, {
    id: TypeAction.CLOTURER,
    libelle: this.translateService.instant('global.actions.cloturer')
  }];

  /** Liste des actions sélectionnées */
  listeActionsSelected: Array<MultiselectOptionNumber> = [];

  /**
   * Constructeur
   *
   * @param translateService Service des traductions
   */
  constructor(private translateService: TranslateService) {
  }

  /**
   * Initialisation
   */
  ngOnInit(): void {
    //Initialisation des valeurs du formulaire
    this.initMultiSelect();
  }

  /**
   * Initialisation des valeurs sélectionnées dans le formulaire
   */
  initMultiSelect() {
    //Portées (modules)
    this.motif.lstPortee.split(',').forEach(portee => {
      switch (portee) {
        case TypePortee.OT:
          this.listeModulesSelected.push({
            id: TypePortee.OT,
            libelle: this.translateService.instant('portee.OT')
          });
          break;
        case TypePortee.OD:
          this.listeModulesSelected.push({
            id: TypePortee.OD,
            libelle: this.translateService.instant('portee.OD')
          });
          break;
        case TypePortee.NF:
          this.listeModulesSelected.push({
            id: TypePortee.NF,
            libelle: this.translateService.instant('portee.NF')
          });
          break;
        case TypePortee.DV:
          this.listeModulesSelected.push({
            id: TypePortee.DV,
            libelle: this.translateService.instant('portee.DV')
          });
          break;
        case TypePortee.AV:
          this.listeModulesSelected.push({
            id: TypePortee.AV,
            libelle: this.translateService.instant('portee.AV')
          });
          break;
      }
    })

    //Profils
    this.motif.lstProfil.split(',').forEach(profil => {
      switch (Number(profil)) {
        case TypeProfil.COMPTABLE:
          this.listeProfilsSelected.push({
            id: TypeProfil.COMPTABLE,
            libelle: this.translateService.instant('profil.comptable')
          });
          break;
        case TypeProfil.RESPONSABLE:
          this.listeProfilsSelected.push({
            id: TypeProfil.RESPONSABLE,
            libelle: this.translateService.instant('profil.responsable')
          })
          break;
        case TypeProfil.COLLABORATEUR:
          this.listeProfilsSelected.push({
            id: TypeProfil.COLLABORATEUR,
            libelle: this.translateService.instant('profil.collaborateur')
          })
          break;
        case TypeProfil.ASSISTANT:
          this.listeProfilsSelected.push({
            id: TypeProfil.ASSISTANT,
            libelle: this.translateService.instant('profil.assistant')
          })
          break;
        case TypeProfil.FOURNISSEUR:
          this.listeProfilsSelected.push({
            id: TypeProfil.FOURNISSEUR,
            libelle: this.translateService.instant('profil.fournisseur')
          })
          break;
      }
    })

    //Actions
    this.motif.lstAction.split(',').forEach(action => {
      switch (Number(action)) {
        case TypeAction.VALIDER:
          this.listeActionsSelected.push({
            id: TypeAction.VALIDER,
            libelle: this.translateService.instant('global.actions.valider')
          });
          break;
        case TypeAction.REJETER:
          this.listeActionsSelected.push({
            id: TypeAction.REJETER,
            libelle: this.translateService.instant('global.actions.rejeter')
          });
          break;
        case TypeAction.INVALIDER:
          this.listeActionsSelected.push({
            id: TypeAction.INVALIDER,
            libelle: this.translateService.instant('global.actions.invalider')
          });
          break;
        case TypeAction.ANNULER:
          this.listeActionsSelected.push({
            id: TypeAction.ANNULER,
            libelle: this.translateService.instant('global.actions.annuler')
          });
          break;
        case TypeAction.EMETTRE:
          this.listeActionsSelected.push({
            id: TypeAction.EMETTRE,
            libelle: this.translateService.instant('global.actions.emettre')
          });
          break;
        case TypeAction.ARCHIVER:
          this.listeActionsSelected.push({
            id: TypeAction.ARCHIVER,
            libelle: this.translateService.instant('global.actions.archiver')
          });
          break;
        case TypeAction.CLOTURER:
          this.listeActionsSelected.push({
            id: TypeAction.CLOTURER,
            libelle: this.translateService.instant('global.actions.cloturer')
          });
          break;
      }
    })
  }

  /**
   * Lors de la modification de la sélection des modules
   */
  onModuleChange() {
    this.motif.lstPortee = this.listeModulesSelected.map(module => module.id).join(',');
  }

  /**
   * Lors de la modification de la sélection des profils
   */
  onProfilChange() {
    this.motif.lstProfil = this.listeProfilsSelected.map(profil => profil.id).join(',');
  }

  /**
   * Lors de la modification de la sélection des actions
   */
  onActionChange() {
    this.motif.lstAction = this.listeActionsSelected.map(action => action.id).join(',');
  }
}

/**
 * Option du multiselect (profil,action)
 */
type MultiselectOptionNumber = {
  id: number,
  libelle: string
}

/**
 * Option du multiselect module
 */
type MultiselectOptionModule = {
  id: string,
  libelle: string
}