import {LogChange} from "./log-change";

/**
 * Classe générique des logs de modification des objets de l'application
 *
 * @author Laurent Convert
 * @date 02/10/2024
 */
export abstract class Log {
	/** Identifiant du log */
	idLog: number;
	/** Identifiant de l'acteur à l'origine de la modification */
	idActeur: number;
	/** Type de log (création / modification / suppression) */
	typeLog: TypeLog;
	/** Date du log */
	dateLog: Date;
	/** Origine de la modification (Admin / import / utilisateur) */
	sourceLog: SourceLog;
	/** Acteur à l'origine de la modification */
	acteur: string;

	/** Liste des valeurs modifiées */
	listeChanges: Array<LogChange>;
}

/**
 * Type de log
 */
export enum TypeLog {
	AJOUT = 1,
	MODIFICATION = 2,
	SUPPRESSION = 3
}

/**
 * Type de source de log
 */
export enum SourceLog {
	ADMINISTRATEUR = 1,
	IMPORT = 2,
	UTILISATEUR = 3
}