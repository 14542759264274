<fieldset>
    <mat-card>
        <mat-card-title>
            <span [translate]="'workflow.motif.detail.motif'"></span>
        </mat-card-title>
        <mat-card-content>
            <fieldset>
                <div class="row">
                    <custom-input ngDefaultControl
                                  id="libelle"
                                  name="libelle"
                                  customType="input"
                                  libelle="workflow.motif.detail.libelle"
                                  [(ngModel)]="motif.libelle"
                                  required="true">
                    </custom-input>
                    <custom-input ngDefaultControl
                                  id="modules"
                                  name="modules"
                                  customType="multiselect"
                                  libelle="workflow.motif.detail.modules"
                                  [(ngModel)]="listeModulesSelected"
                                  (onChange)="onModuleChange()"
                                  optionValue="id"
                                  optionDisplay="libelle"
                                  [selectOptions]="listeModules">
                    </custom-input>
                </div>
                <div class="row">
                    <custom-input ngDefaultControl
                                  id="profils"
                                  name="profils"
                                  customType="multiselect"
                                  libelle="workflow.motif.detail.profils"
                                  [(ngModel)]="listeProfilsSelected"
                                  (onChange)="onProfilChange()"
                                  optionValue="id"
                                  optionDisplay="libelle"
                                  [selectOptions]="listeProfils">
                    </custom-input>
                    <custom-input ngDefaultControl
                                  id="actions"
                                  name="actions"
                                  customType="multiselect"
                                  libelle="workflow.motif.detail.actions"
                                  [(ngModel)]="listeActionsSelected"
                                  (onChange)="onActionChange()"
                                  optionValue="id"
                                  optionDisplay="libelle"
                                  [selectOptions]="listeActions">
                    </custom-input>
                </div>
                <div class="row">
                    <custom-input ngDefaultControl
                                  rCol="10"
                                  id="motif"
                                  name="motif"
                                  customType="textarea"
                                  libelle="workflow.motif.detail.motif"
                                  [(ngModel)]="motif.motif"
                                  required="true"
                                  maxlength="2000">
                    </custom-input>
                </div>
            </fieldset>
        </mat-card-content>
    </mat-card>
</fieldset>
