import {Component,OnInit} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {TerritoireItem} from "@domain/admin/bibliotheque/geographie/territoireItem";
import {PorteeTerritoireCode} from "../../../geographie.component";
import {GeographieService} from "@services/admin/geographie/geographie.service";
import {Router} from "@angular/router";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {TranslateService} from "@ngx-translate/core";
import {TypeCodeErreur} from '@domain/common/http/result';
import {filter} from 'rxjs/operators'
import {ToastrService} from "ngx-toastr";

/**
 * Composant d'un territoire dans la liste des territoires
 */
@Component({
	host: {'data-test-id': 'territoire-item'},
	templateUrl: './territoire-item.component.html'
})
export class TerritoireItemComponent extends ListViewItem<TerritoireItem> implements OnInit{

	/** Liste des codes des portées du territoires */
	porteesCodes: String[];

	/** Liste des portées du territoire */
	portees: String[];

	/**
	 * Constructeur
	 * @param geographieService Service géographie
	 * @param router            Router de l'application
	 * @param confirmService	Service de confirmation
	 * @param translateService	Service de traduction
	 * @param toastrService		Service de gestion des toasts
	 */
	constructor(private geographieService: GeographieService,
				private router: Router,
				private confirmService: ConfirmService,
				private translateService: TranslateService,
				private toastrService: ToastrService) {
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Récupération des portées (visibilité) du territoire
		this.porteesCodes = this.data.visibilite.split(',')
		this.portees = [];

		for (let portee of this.porteesCodes) {
			switch (portee) {
				case (PorteeTerritoireCode.IND):
					this.portees.push('admin.bibliotheque.geographie.territoire.indemnite');
					break;
				case (PorteeTerritoireCode.OMP):
					this.portees.push('admin.bibliotheque.geographie.territoire.omp');
					break;
				case (PorteeTerritoireCode.TAX):
					this.portees.push('admin.bibliotheque.geographie.territoire.taxe');
					break;
			}
		}

	}

	/**
	 * Affichage du détail d'un territoire
	 */
	goToDetailTerritoire() {
		//Indique au service géographie qu'on affiche un détail
		this.geographieService.isShowDetail = true;

		//Navigue vers le détail
		this.router.navigate([`Admin/Bibliotheque/Geographie/Territoire/${this.data.idTerritoire}`]);
	}

	/**
	 * Suppression du territoire
	 */
	deleteTerritoire() {
		//Popup de confirmation
		this.confirmService.showConfirm(this.translateService.instant('admin.bibliotheque.geographie.territoire.confirmSuppression'))
			.pipe(filter(isConfirmed => isConfirmed)).subscribe({ next: () => {
				//Suppression du territoire
				this.geographieService.deleteTerritoire(this.data.idTerritoire).subscribe(data => {
					if (data.codeErreur === TypeCodeErreur.NO_ERROR) {
						//Refresh la liste des territoires
						this.geographieService.listeTerritoires.refresh();

						//Toast de succès
						this.toastrService.success(this.translateService.instant('global.success.suppression'))
					} else {
						//Toast d'erreur
						this.toastrService.error(this.translateService.instant('global.errors.suppression'))
					}
				})
			}
		})
	}
}
