import {ListItem} from "../common/list-view/list-item";

/**
 * Classe représentant un motif workflow
 */
export class Motif implements ListItem {
	idMotif: number;
	libelle: string;
	motif: string;
	lstPortee: string;
	lstProfil: string;
	lstAction: string;

	/**
	 * Constructeur
	 */
	constructor() {
		//Intialisation des listes (modules,profils,actions)
		this.lstPortee = "OT,OD,NF,DV,AV";
		this.lstProfil = "1,2,3,4,6";
		this.lstAction = "1,2,3,4,7,8,20";
	}

	getKey(): number {
		return this.idMotif;
	}
}