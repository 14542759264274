import {Component,Input} from '@angular/core';
import {ActivatedRoute,Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {AppState} from "@domain/appstate";
import {TypePortee} from "@domain/workflow/workflow";
import {SettingsGlobalState} from "@domain/settings/settings";
import {TypeProfil,User} from "@domain/user/user";
import {LotListItem} from "@domain/comptabilite/lot";
import {NiveauAlerte} from "@domain/common/alerte/alerte";
import {DematService} from "@services/admin/parametre/demat/demat.service";
import {ComptabiliteService} from "../comptabilite.service";

/**
 * Composant d'affichage du détail d'un comptable
 */
@Component({
	templateUrl: './lot-list-item.component.html'
})
export class LotListItemComponent extends ListViewItem<LotListItem> {
	/** Déclaration pour accès depuis le template */
	readonly NiveauAlerte = NiveauAlerte;

	/** Paramètres/options supplémentaires */
	@Input() extraOptions: {
		user: User
		isClickable?: boolean
	}

	/** Paramétrage */
	settings: SettingsGlobalState;

	/**
	 * Constructeur
	 */
	constructor(private router: Router,private route:ActivatedRoute,private store: Store<AppState>,private dematService: DematService,private comptabiliteService: ComptabiliteService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Sélection du paramétrage
		this.store.select(state => state.settings?.[TypePortee.CPT]).subscribe(settings => this.settings = settings);
	}

	/**
	 * Navigation vers le lot comptable
	 * @param idLot Identifiant du lot
	 */
	navigateToLot(idLot: number) {
		//Vérification du profil
		if ([TypeProfil.ADMINISTRATEUR,TypeProfil.SOUS_ADMINISTRATEUR].includes(this.extraOptions.user.fonction as TypeProfil)) {
			//Navigation vers le lot (admin)
			this.router.navigate([`Admin/Comptabilite/LotsComptables/Lot/${idLot}`]);
		} else {
			//Navigation vers le lot (Comptable)
			this.router.navigate(['Lot',idLot]);
		}
	}

	/**
	 * Indique si le lot peut être archivé et si l'utilisateur courant a le droit de le faire.
	 */
	isArchivageAutorise(): boolean {
		return this.comptabiliteService.canSeeArchiveLot(this.extraOptions.user)
			&& this.data.nbNdf > 0
			&& this.dematService.isArchivageAutorise(this.data);
	}
}
