import {Component,Inject,OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {ProfilService} from "../../profil.service";
import {ToastrService} from "ngx-toastr";
import {MAT_DIALOG_DATA,MatDialogRef} from "@angular/material/dialog";
import {SettingsGlobalState} from "@domain/settings/settings";
import {CompteBancaire} from "@domain/profil/compteBancaire";
import {filter,first} from "rxjs/operators";
import {TypeCodeErreur} from "@domain/common/http/result";
import {TypeCompteBancaire} from '@domain/comptabilite/compte-bancaire';
import {Observable,of} from "rxjs";
import {MatCheckboxChange} from "@angular/material/checkbox";
import {Devise} from "@domain/settings/devise";
import {GeographieView} from "@domain/geographie/geographieView";

/**
 * Composant d'ajout/modification de documents pour le profil utilisateur
 */
@Component({
	selector: 'profil-donnees-add-compte-bancaire',
	host: {'data-test-id': 'profil-donnees-add-compte-bancaire'},
	templateUrl: './profil-donnees-add-compte-bancaire.component.html'
})
export class ProfilDonneesAddCompteBancaireComponent implements OnInit {
	/** Indicateur de sauvegarde */
	isSaving: boolean = false;

	/** Indicateur de suppression */
	isDeleting: boolean = false;

	/** Indicateur pour refresh la liste à la sortie de la popup */
	willRefreshList: boolean = false;

	/** Liste des types de document pour le select */
	listeTypeCompte: Array<any> = [];

	/** Pays de l'autocomplete */
	pays: GeographieView;

	/** Liste des devises avec un libellé formaté */
	listeDeviseFormated: Array<Devise>;

	/**
	 * Constructeur
	 *
	 * @param translateService le moteur de traduction
	 * @param confirmService le service de confirmation
	 * @param profilService le service de gestion du profil
	 * @param toastrService le toaster
	 * @param matDialogRef la ref vers cette popin
	 * @param data les données en entrée de la popin (idUser est transmis uniquement quand cette popin est ouverte depuis l'admin)
	 */
	constructor(
		private translateService: TranslateService,
		private confirmService: ConfirmService,
		private profilService: ProfilService,
		private toastrService: ToastrService,
		private matDialogRef: MatDialogRef<ProfilDonneesAddCompteBancaireComponent>,
		@Inject(MAT_DIALOG_DATA) public data: { compte: CompteBancaire,compteParDefaut: CompteBancaire,settings: SettingsGlobalState,idUser?: number }
	) {
	}

	/**
	 * Initialisation (permet de renseigner le pays)
	 */
	ngOnInit(): void {
		if (this.data.compte.idPays) {
			this.profilService.getEntiteGeoForIdPays(this.data.compte.idPays).pipe(first()).subscribe(
				result => this.pays = result.data?.geographie
			);
		}

		//Initialisation du select selon les types autorisés
		const configCompte = this.data.settings.configCompte;
		if (configCompte.iban) {
			this.listeTypeCompte.push(
				{type: TypeCompteBancaire.IBAN,libelle: this.translateService.instant("profilUser.compteBancaire.IBAN")})
		}
		if (configCompte.autre) {
			this.listeTypeCompte.push(
				{type: TypeCompteBancaire.AUTRE,libelle: this.translateService.instant("profilUser.compteBancaire.AUTRE")})
		}

		//Initialisation de la liste des devises affichées
		this.listeDeviseFormated = this.data.settings.listeDevises.map((d: Devise) => {
			const devise: Devise = {
				code: d.code,
				libelle: d.code + " - " + d.libelle
			}
			return devise;
		});
	}

	/**
	 * Appelé au changement de pays
	 */
	onPaysChange(): void {
		//Mise à jour du pays et de l'ID pays dans le compte
		this.data.compte.pays = this.pays;
		this.data.compte.idPays = this.pays.id;
	}

	/**
	 * Suppression d'un compte bancaire
	 */
	deleteCompte() {
		//Confirmation auprès de l'utilisateur sur la suppression d'un compte bancaire
		this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation'))
			.pipe(filter(isConfirmed => isConfirmed))
			.subscribe({
				next: () => {
					this.isDeleting = true;
                    this.profilService.deleteCompteBancaire(this.data.compte,!!this.data.idUser).pipe(first()).subscribe({
						next: result => {
							//Message d'information
							if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
								this.toastrService.success(this.translateService.instant('global.success.suppression'));
							} else if (result.codeErreur == TypeCodeErreur.ERROR_DELETE) {
								this.toastrService.error(this.translateService.instant('profilUser.compteBancaire.add.deleteUtiliseError'));
							} else {
								this.toastrService.error(this.translateService.instant('global.errors.suppression'));
							}

							//Fermeture de l'écran
							this.matDialogRef.close(result.codeErreur == TypeCodeErreur.NO_ERROR);
						}
					});
				}
			});
	}

	/**
	 * Sauvegarde d'un compte bancaire
	 */
	saveCompte() {
		let obsConfirm: Observable<boolean | null>;

		//Indique que l'enregistrement est en cours
		this.isSaving = true;

		//Vérification de la présence d'un compte par défaut qui n'est pas celui en cours
		if (this.data.compte.defaut && this.data.compteParDefaut && this.data.compteParDefaut.idCompte != this.data.compte.idCompte) {
			//Récupération de l'observable qui contiendra la confirmation (ou non) du flag "par défaut"
			obsConfirm = this.confirmService.showConfirm(this.translateService.instant('profilUser.compteBancaire.add.messageConfirmCompteDefaut'),{type: 'oui-non'});
		} else {
			//Pas de confirmation nécessaire
			obsConfirm = of(null);
		}

		//Souscription sur la confirmation
		obsConfirm.pipe(first()).subscribe(isConfirm => {
			//On vérifie si l'utilisateur confirme qu'il ne veut finalement pas remplacer le compte par défaut
			if (isConfirm !== null) {
				//Suppression de l'indicateur de compte par défaut
				this.data.compte.defaut = !!isConfirm;
			}

			//Lancement de l'enregistrement
			this.profilService.saveCompteBancaire(this.data.compte,this.data.idUser).pipe(first()).subscribe({
				next: result => {
					if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
						//Message de succès
						this.toastrService.success(this.translateService.instant('global.success.enregistrement'));
					} else {
						//Message d'erreur
						TypeCodeErreur.showError(result?.codeErreur,this.translateService,this.toastrService);
					}

					//Fermeture de l'écran
					this.matDialogRef.close(result?.codeErreur === TypeCodeErreur.NO_ERROR);
				}
			});
		});
	}

	onActifChanged($event: MatCheckboxChange) {
		if ($event.checked === false && this.data.compte.defaut) {
			this.data.compte.defaut = false;
		}
	}

}
