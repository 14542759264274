import {Component,OnDestroy,OnInit} from '@angular/core';
import {PageHeaderItem} from "@share/component/page-header/page-header";
import {ActivatedRoute,Router} from "@angular/router";
import {Subscription} from "rxjs";
import {AdminTravelhubService} from "@components/admin/voyages/travelhub/admin-travelhub.service";
import {IComponentWithRoutedTabs} from "@domain/admin/recherche/component-with-routed-tabs";

/**
 * Enum pour les noms des différents onglets du module
 */
export enum Onglets {
    TRAVELHUB = 'TRAVELHUB',
    CONFIGURATION_SBT = 'CONFIGURATION_SBT',
    PROFIL_CONNEXION = 'PROFIL_CONNEXION'
}

/**
 * Index du module d'administration Voyages/TravelHub
 *
 * @author Laurent Convert
 * @date 02/11/2023
 */
@Component({
    host: {'data-test-id': 'admin-travelhub'},
    templateUrl: './admin-travelhub.component.html'
})
export class AdminTravelhubComponent implements OnInit, OnDestroy, IComponentWithRoutedTabs {
    /** Liste statique des onglets */
    public static listeOnglets: Array<PageHeaderItem> = [
        {
            code: Onglets.TRAVELHUB,
            libelle: 'admin.voyages.travelhub.onglets.travelhub',
            url: AdminTravelhubService.URL_TH_CONFIG
        },{
            code: Onglets.CONFIGURATION_SBT,
            libelle: 'admin.voyages.travelhub.onglets.configSbt',
            url: AdminTravelhubService.URL_SBT_CONFIG
        },{
            code: Onglets.PROFIL_CONNEXION,
            libelle: 'admin.voyages.travelhub.onglets.profilConnexion',
            url: AdminTravelhubService.URL_PROFIL_CONNEXION
        }
    ];

    /** Liste des différents onglets disponibles dans le menu Voyages/TravelHub */
    listeTabItems: Array<PageHeaderItem> = AdminTravelhubComponent.listeOnglets;

    /** Index de l'onglet courant */
    selectedIndex: number = 0;

    /** Possibilité de masquer le header quand un enfant en a un également */
    isHeaderHidden: boolean = false;

    /** On stocke la souscription pour pouvoir unsub */
    souscription: Subscription;

    /**
     * Constructeur
     *
     * @param activatedRoute la route actuelle
     * @param router le routeur Angular
     */
    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router
    ) {}

    /**
     * Initialisation du composant
     */
    ngOnInit(): void {
        //On souscrit aux changements de route
        this.souscription = this.activatedRoute.url.subscribe(() => {
            //Récupération de l'onglet correspondant au path courant
            let pageHeaderIndex = this.listeTabItems.findIndex(tab => tab.url === this.router.url.substr(1));

            //Si un onglet correspond au path
            if (pageHeaderIndex >= 0) {
                //On affiche le header
                this.isHeaderHidden = false;

                //On active l'onglet
                this.selectedIndex = pageHeaderIndex;
            } else {
                //Sinon, on masque le header
                this.isHeaderHidden = true;
            }
        });
    }

    /**
     * Destruction du composant
     */
    ngOnDestroy(): void {
        //On désouscrit aux changements de route
        this.souscription?.unsubscribe();
    }

    /**
     * Changement d'onglet
     *
     * @param item l'onglet sélectionné
     */
    onSelectedItemChange(item: PageHeaderItem): void {
        //Récupération de l'index de l'onglet sélectionné
        const index = this.listeTabItems.findIndex(i => i.code === item.code);

        //S'il est différent de l'index courant
        if (index !== this.selectedIndex) {
            //On met à jour l'index courant
            this.selectedIndex = index;

            //Et on navigue vers l'URL correspondante
            this.router.navigateByUrl(item.url);
        }
    }

    /**
     * Méthode appelée lors du clic sur le bouton de retour en arrière pour retourner vers la liste des configurations TravelHub
     */
    onGoBack(): void {
        //Navigation vers la liste
        this.router.navigate([this.listeTabItems[0].url]);
    }

    protected readonly Date = Date;
}
