<div class="with-floating-button">
    <div class="content" *ngIf="motif">
        <page-header [title]="isCreation ? ('workflow.motif.detail.titleCreation' | translate) : ('workflow.motif.detail.title' | translate : {libelle: motif.libelle})"
                     (onSelectedItemChange)="onSelectedItemChange($event)" (onGoBack)="onGoBack()" [listeItems]="listeTabItems">
        </page-header>

        <div [hidden]="selectedItem?.code !== Onglets.GENERALITES">
            <form #form="ngForm" *ngIf="motif">
                <motif-generalites [motif]="motif"></motif-generalites>
            </form>
        </div>
        <floating-button [listeActions]="listeActions" [isPending]="isSaving"></floating-button>
    </div>
    <please-wait class="content" *ngIf="!motif"></please-wait>
</div>