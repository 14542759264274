import {Component,Inject,OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Orga} from "@domain/admin/entreprise/analytique/orga";
import {LienPopulationOrga} from "@domain/admin/entreprise/population/lienPopulationOrga";
import {TypeLien} from "@domain/admin/entreprise/population/lienPopulationUser";

/**
 * Composant d'affichage du formulaire d'ajout de service à une population
 */
@Component({
    host: {'data-test-id': 'population-add-service'},
    templateUrl: './population-add-service.component.html'
})
export class PopulationAddServiceComponent {

    /** Id de la population */
    idPopulation: number;

    /** Organisation */
    orga: Orga;

    /** Indique si on doit exclure l'organisme */
    isExcludeOrga: boolean = false;

    /** Indique si on doit exclure le sous organisme */
    isExcludeSousOrga: boolean = false;

    /** Flag permettant de signaler la sauvegarde */
    isSaving: boolean = false;

    /** Constructeur */
    constructor(
        private matDialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) private data: { idPopulation: number}
    ) { }

    /**
     * Ajout du service sélectionné à la population
     */
    addOrga() {
        if (this.orga) {
            const lienPopulationOrga: LienPopulationOrga = new LienPopulationOrga();
            lienPopulationOrga.idPopulation = this.data.idPopulation;
            lienPopulationOrga.idOrga = this.orga.idOrga;
            lienPopulationOrga.typeLien = this.isExcludeOrga ? TypeLien.EXCLUS : TypeLien.INCLUS;
            lienPopulationOrga.dependance = !this.isExcludeSousOrga;

            //Fermeture de la popup
            this.matDialogRef.close(lienPopulationOrga);
        }
    }
}