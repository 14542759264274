import {ListViewModule} from "@share/component/list-view/list-view.module";
import {FloatingButtonModule} from "@share/component/floating-button/floating-button.module";
import {NgModule} from "@angular/core";
import {WorkflowRolesComponent} from "./workflow-roles.component";
import {RolesListeComponent} from "./roles/liste/roles-liste.component";
import {PageHeaderModule} from "@share/component/page-header/page-header.module";
import {RouterModule,Routes} from "@angular/router";
import {TranslateModule} from "@ngx-translate/core";
import {RolesDetailComponent} from "@components/admin/workflow/roles/roles/detail/roles-detail.component";
import {RolesListeItemComponent} from "@components/admin/workflow/roles/roles/liste/roles-liste-item.component";
import {CommonModule} from "@angular/common";
import {PreviewAlerteModule} from "@share/component/alerte/preview-alerte.module";
import {RoleUsageComponent} from "@components/admin/workflow/roles/roles/detail/usage/role-usage.component";
import {RoleDroitsComponent} from "@components/admin/workflow/roles/roles/detail/droits/role-droits.component";
import {ShareModule} from "@share/share.module";
import {WorkflowRolesService} from "@services/admin/workflow/workflow-roles.service";
import {RoleRoleComponent} from "@components/admin/workflow/roles/roles/detail/generalites/role/role-role.component";
import {RoleOptionsComponent} from "@components/admin/workflow/roles/roles/detail/generalites/role-options/role-options.component";
import {MatTableModule} from "@angular/material/table";
import {RoleUsageListItemComponent} from './roles/detail/usage/role-usage-list-item.component';

export const workflowRolesRoutes: Routes = [
	{
		path: 'Roles',
		children: [
			{
				path: ':idDetail',
				component: RolesDetailComponent,
			}
		]
	},{
		path: '',
		pathMatch: 'full',
		redirectTo: 'Roles'
	},{
		path: '**',
		redirectTo: 'Roles'
	}
]

/**
 * Module des référentiels de la gestion des frais
 */
@NgModule({
	imports: [
		ListViewModule,
		FloatingButtonModule,
		PageHeaderModule,
		RouterModule,
		TranslateModule,
		CommonModule,
		PreviewAlerteModule,
		ShareModule,
		MatTableModule
	],
	declarations: [
		WorkflowRolesComponent,
		RolesListeComponent,
		RolesDetailComponent,
		RolesListeItemComponent,
		RoleDroitsComponent,
		RoleRoleComponent,
		RoleOptionsComponent,
		RoleUsageComponent,
		RoleUsageListItemComponent
	],
	entryComponents: [],
	exports: [],
	providers: [WorkflowRolesService]
})
export class WorkflowRolesModule {
}