<form #form="ngForm">
    <mat-card>
        <mat-card-title>
            <span [translate]="'admin.entreprise.utilisateurs.detail.generalites.infosGenerales.title'"></span>
        </mat-card-title>
        <mat-card-content>
            <div class="row">
                <custom-input
                        ngDefaultControl
                        id="civilite"
                        name="civilite"
                        customType="select"
                        [(ngModel)]="user.genre"
                        [libelle]="'admin.entreprise.utilisateurs.detail.generalites.infosGenerales.civilite'"
                        [selectOptions]="listeTypeGenre"
                        optionDisplay="libelle"
                        optionValue="code"
                        [readonly]="isReadOnly">
                </custom-input>
                <label class="col-md-2">
                    <span [translate]="'admin.entreprise.utilisateurs.detail.generalites.infosGenerales.actif'"></span>
                </label>
                <button mat-button color="primary" (click)="onClickActif()" [disabled]="isReadOnly" style="margin-bottom: 0.5rem">
                    <span *ngIf="!user.actif" [translate]="'confirmation.non'"></span>
                    <span *ngIf="user.actif && user.dateIn && user.dateOut && moment().isBetween(user.dateIn,user.dateOut,'day','[]')"
                          [translate]="'admin.entreprise.utilisateurs.detail.generalites.infosGenerales.actifOuiDuAu'"
                          [translateParams]="{ dateIn: user.dateIn | date:'shortDate',dateOut: user.dateOut | date:'shortDate' }"></span>
                    <span *ngIf="user.actif && user.dateIn && !user.dateOut && moment().isSameOrAfter(user.dateIn,'day')"
                          [translate]="'admin.entreprise.utilisateurs.detail.generalites.infosGenerales.actifOuiDepuis'" [translateParams]="{ dateIn: user.dateIn | date:'shortDate' }"></span>
                    <span *ngIf="user.actif && !user.dateIn && user.dateOut && moment().isSameOrBefore(user.dateOut,'day')"
                          [translate]="'admin.entreprise.utilisateurs.detail.generalites.infosGenerales.actifOuiJusquAu'" [translateParams]="{ dateOut: user.dateOut | date:'shortDate' }"></span>
                    <span *ngIf="user.actif && (!user.dateIn && !user.dateOut || moment().isBefore(user.dateIn,'day') || moment().isAfter(user.dateOut,'day'))"
                          [translate]="'confirmation.oui'"></span>
                    <mat-icon color="primary">search</mat-icon>
                </button>
            </div>
            <div class="row">
                <custom-input
                        ngDefaultControl
                        id="nom"
                        name="nom"
                        customType="input"
                        [(ngModel)]="user.nom"
                        libelle="admin.entreprise.utilisateurs.detail.generalites.infosGenerales.nom"
                        [maxlength]="100"
                        [required]="true"
                        [readonly]="isReadOnly">
                </custom-input>
                <custom-input
                        ngDefaultControl
                        id="matricule"
                        name="matricule"
                        customType="input"
                        [(ngModel)]="user.matricule"
                        libelle="admin.entreprise.utilisateurs.detail.generalites.infosGenerales.matricule"
                        [maxlength]="10"
                        [required]="true"
                        [readonly]="isReadOnly">
                </custom-input>
            </div>
            <div class="row">
                <custom-input
                        ngDefaultControl
                        id="prenom"
                        name="prenom"
                        customType="input"
                        [(ngModel)]="user.prenom"
                        libelle="admin.entreprise.utilisateurs.detail.generalites.infosGenerales.prenom"
                        [maxlength]="100"
                        [required]="true"
                        [readonly]="isReadOnly">
                </custom-input>
                <custom-input
                        ngDefaultControl
                        id="email"
                        name="email"
                        customType="input"
                        [(ngModel)]="user.email"
                        libelle="admin.entreprise.utilisateurs.detail.generalites.infosGenerales.email"
                        [maxlength]="100"
                        [required]="true"
                        [readonly]="isReadOnly"
                        email>
                </custom-input>
            </div>
            <div class="row">
                <custom-input
                        ngDefaultControl
                        id="nomAcces"
                        name="nomAcces"
                        customType="input"
                        [(ngModel)]="user.nomAcces"
                        libelle="admin.entreprise.utilisateurs.detail.generalites.infosGenerales.nomAcces"
                        [maxlength]="500"
                        [required]="true"
                        [readonly]="isReadOnly">
                </custom-input>
                <custom-input
                        ngDefaultControl
                        id="affectation"
                        name="affectation"
                        customType="autocomplete"
                        [autocompleteFilter]="{ isAffectable: true }"
                        [(ngModel)]="user.orga"
                        libelle="admin.entreprise.utilisateurs.detail.generalites.infosGenerales.affectation"
                        autocompleteType="service"
                        optionValue="idOrga"
                        optionDisplay="libelle"
                        [idName]="'idOrga'"
                        (onChange)="onOrgaChange()"
                        [required]="true"
                        [readonly]="isReadOnly">
                </custom-input>
            </div>
            <div class="row" *ngIf="user.idUser">
                <label class="col-md-2">
                    <span [translate]="'admin.entreprise.utilisateurs.detail.generalites.infosGenerales.motDePasse'"></span>
                </label>
                <button mat-button color="primary" (click)="onClickPassword()" [disabled]="isReadOnly" style="margin-bottom: 0.5rem">
                    <span [translate]="user.passExpirationDate ? 'admin.entreprise.utilisateurs.detail.generalites.infosGenerales.expiration' : 'admin.entreprise.utilisateurs.detail.generalites.infosGenerales.activer'"
                          [translateParams]="{ date: user.passExpirationDate | date: 'shortDate' }"></span>
                    <mat-icon color="primary">search</mat-icon>
                </button>
            </div>
        </mat-card-content>
    </mat-card>
</form>

<list-view *ngIf="listeHabilitations && isDroitHabilitations" [liste]="listeHabilitations"></list-view>

<list-view *ngIf="listeDelegationRecue && isDroitHabilitations" [liste]="listeDelegationRecue"></list-view>

<list-view *ngIf="listeDelegationAccordee && isDroitHabilitations" [liste]="listeDelegationAccordee"></list-view>

<floating-button *ngIf="listeActions" [listeActions]="listeActions" [isPending]="isPending"></floating-button>
