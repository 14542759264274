import {Component,Input} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {Enveloppe} from "@domain/budget/enveloppe";

/**
 * Item de la liste des enveloppes
 */
@Component({
	host: {'data-test-id': 'enveloppe-list-item'},
	templateUrl: './enveloppe-list-item.component.html'
})
export class EnveloppeListItemComponent extends ListViewItem<Enveloppe> {
	/** Options supplémentaires */
	@Input() extraOptions: {
		listeIdEnveloppe: number[],
		canChangeEnveloppe: boolean
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Initialisation de isSelected
		if (this.extraOptions?.listeIdEnveloppe && this.extraOptions.listeIdEnveloppe.includes(this.data.idEnveloppe)) {
			this.data.isSelected = true;
		}
	}
}