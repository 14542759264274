import {Component,OnInit} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {TranslateService} from "@ngx-translate/core";
import {HistoriqueCompteComptable} from "@domain/admin/comptabilite/compte-historique/historique-compte-comptable";

/**
 * Composant d'un item de la liste de l'historique d'un compte comptable
 */
@Component({
	host: {'data-test-id': 'compte-comptable-historique-list-item'},
	selector: 'compte-comptable-historique-list-item',
	templateUrl: './compte-comptable-historique-list-item.component.html'
})
export class CompteComptableHistoriqueListItemComponent extends ListViewItem<HistoriqueCompteComptable> implements OnInit {
	/** Indicateur de l'état de l'affichage (plié/déplié) */
	isUnfolded: boolean = false;

	/**
	 * Constructeur
	 *
	 * @param translateService Service de traduction
	 */
	constructor(private translateService: TranslateService) {
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Remplacement des valeurs vides par des "(Vide)"
		this.data.listeChanges?.forEach(change => {
			change.oldValue = change.oldValue ?? `(${this.translateService.instant('admin.historique.vide')})`;
			change.newValue = change.newValue ?? `(${this.translateService.instant('admin.historique.vide')})`;
		});
	}
}