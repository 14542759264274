<div class="lvi-content">
    <div class="avatar">
        <i class="material-icons-outlined">lan</i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a class="label cursor-default">{{ data.orga.numService }} - {{ data.orga.libelle }}</a>
        </div>
        <ul class="lvi-attrs">
            <li>
                <strong [translate]="'admin.entreprise.populations.liste.statut'"></strong>
                <span [translate]="data.typeLien === TypeLien.INCLUS ? 'admin.entreprise.populations.liste.inclus' : 'admin.entreprise.populations.liste.exclus'"></span>
            </li>
            <li *ngIf="data.dependance != null">
                <strong [translate]="'admin.entreprise.populations.liste.sousServices'"></strong>
                <span [translate]="data.dependance ? 'admin.entreprise.populations.liste.inclus' : 'admin.entreprise.populations.liste.exclus'"></span>
            </li>
        </ul>
    </div>
    <div class="lvi-actions">
        <button mat-icon-button (click)="deleteOrga()" [matTooltip]="'global.actions.supprimer' | translate" [matTooltipPosition]="'above'">
            <i class="nio icon-suppression icone"></i>
        </button>
    </div>
</div>