import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {MatIconModule} from "@angular/material/icon";
import {RechercheComponent} from "./recherche.component";
import {RechercheDirective} from "../../directive/recherche/recherche.directive";
import {RechercheItemComponent} from "@share/layout/recherche/recherche-item.component";
import {AutoFocusModule} from "@share/directive/autofocus/autofocus.module";
import {ListViewModule} from "@share/component/list-view/list-view.module";
import {RechercheService} from "@services/admin/recherche/recherche.service";

@NgModule({
    declarations: [RechercheDirective,RechercheComponent,RechercheItemComponent],
    imports: [CommonModule,TranslateModule,MatIconModule,AutoFocusModule,ListViewModule],
    exports: [RechercheDirective,RechercheComponent,RechercheItemComponent],
    providers: [RechercheService]
})
export class RechercheModule {}
