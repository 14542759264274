import {ListItem} from "../../common/list-view/list-item";

/**
 * Classe représentant un résultat de recherche
 */
export class ResultatRecherche implements ListItem {
    /** Identifiant */
    key: number;

    /** Icône à afficher dans la liste */
    icone: { icone: string, class: string };

    /** Différents niveaux du fil d'ariane */
    niveau1: string;
    niveau2: string;
    niveau3: string;

    /** Liste des tags concernés par le résultat */
    listeTag: Tag[];

    /** Route du résultat */
    route: string;

    /** Mise en valeur au survol du clavier */
    isKeyboardSelected: boolean = false;

    /**
     * Constructeur
     */
    constructor(dto?: any) {
        //Pseudo clé
        this.key = Math.random() * 10000;

        //Construction
        if (dto) {
            this.icone = ResultatRechercheIcone.get(dto.niveau1.replace("menu.admin.",""));
            this.niveau1 = dto.niveau1;
            this.niveau2 = dto.niveau2;
            this.niveau3 = dto.niveau3;
            this.listeTag = dto.listeTag as Tag[] || [];
            this.route = dto.route;
        }
    }

    /**
     * Obtention de l'identifiant standard de l'objet
     */
    getKey(): number { return this.key; }
}

/**
 * Interface représentant un Tag
 */
export interface Tag {
    tag: string;
    matchingQuery: boolean;
}

/**
 * Map représentant les icônes du menu
 */
const ResultatRechercheIcone: Map<string, { icone: string, class: string }> = new Map<string, { icone: string, class: string }>([
    ["entreprise", { icone: "business", class: "material-icons-outlined" }],
    ["comptabilite", { icone: undefined, class: "nio icon-comptabilisation" }],
    ["bibliotheque", { icone: "topic", class: "material-icons-outlined" }],
    ["parametres", { icone: "build", class: "material-icons-outlined" }],
    ["frais", { icone: undefined, class: "nio icon-frais" }],
    ["voyage", { icone: "map", class: "material-icons-outlined" }],
    ["workflow", { icone: "account_tree", class: "material-icons-outlined" }],
    ["maintenance", { icone: "settings_suggest", class: "material-icons-outlined" }],
]);
