import {Component,ViewChild} from '@angular/core';
import {Store} from "@ngrx/store";
import {AppState} from "@domain/appstate";
import {OdListComponent} from "../../../od/od-list/od-list.component";
import {Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {PleaseWaitService} from "@share/component/please-wait/please-wait.service";
import {ODService} from "../../../od/od.service";
import {TranslateService} from "@ngx-translate/core";
import {WorkflowService} from "../../../workflow/workflow.service";
import {DashboardOdListItemComponent} from "./dashboard-od-list-item.component";
import {ListViewComponent} from "@share/component/list-view/list-view.component";
import {IDashboardComponent} from "../../IDashboardComponent";
import {OdListItemComponent} from "../../../od/od-list/od-list-item/od-list-item.component";
import {ListeOd} from "@domain/od/liste-od";
import {TypeProfil} from "@domain/user/user";
import {SessionStorageService} from '@domain/common/services/session-storage.service';
import {CguService} from '@components/cgu/cgu.service';
import {ToastrService} from 'ngx-toastr';

/**
 * Liste des OD du Dashboard
 */
@Component({
    host: {'data-test-id': 'dashboard-od-list'},
    selector: 'dashboard-od-list',
    templateUrl: './dashboard-od-list.component.html'
})
export class DashboardOdListComponent extends OdListComponent implements IDashboardComponent<ListeOd,OdListItemComponent> {
    /** C'est une liste de dashboard */
    isDashboardList = true;

    /** Référence au composant de la liste (pour mise à jour au changement de profil) */
    @ViewChild('listViewComponent') listViewComponent: ListViewComponent<ListeOd,OdListItemComponent>;

    /**
     * Constructeur
     *
     * @param router le routeur
     * @param matDialog le composant des popups
     * @param pleaseWaitService le service de chargement
     * @param odService le service des OD
     * @param translateService le service de traduction
     * @param store le store
     * @param workflowService le service de gestion du workflow
     * @param sessionStorageService Service de gestion du Session Storage
     * @param cguService Service des CGU
     * @param toastrService Service de messages au format toast
     */
    constructor(protected router: Router,
                protected matDialog: MatDialog,
                protected pleaseWaitService: PleaseWaitService,
                protected odService: ODService,
                protected translateService: TranslateService,
                protected store: Store<AppState>,
                protected workflowService: WorkflowService,
                protected sessionStorageService: SessionStorageService,
                protected cguService: CguService,
                protected toastrService: ToastrService) {
        super(router,matDialog,pleaseWaitService,odService,translateService,store,workflowService,sessionStorageService,cguService,toastrService);
    }

    /**
     * Spécificités de la liste
     */
    protected initListCore() {
        //Il s'agit d'une liste du dashboard
        this.liste.isDashboardList = true;

        //On définit le list item du dashboard
        this.liste.component = DashboardOdListItemComponent;

        //Adresse de redirection au clic sur le titre
        this.liste.redirect = "/ListeOD";
    }

    /**
     * Spécificités de la liste
     */
    protected initListSpecific() {
        //Ajout du bouton de création d'un OD
        this.liste.listeActions = [{
            icon: 'add',
            onPress: () => this.fonction == TypeProfil.COLLABORATEUR ? this.create() : this.createWithCollab(),
            isVisible: () => this.creationsPossibles.odPossible
        },{
            icon: 'search',
            onPress: () => this.router.navigate([this.liste.redirect]),
            isVisible: () => !this.creationsPossibles.odPossible
        }];
    }
}
