import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ResultatRecherche,Tag} from "@domain/admin/recherche/resultat-recherche";
import {TranslateService,TranslateStore} from "@ngx-translate/core";
import {MenuItem,Scope} from "@domain/common/menu/menu-item";
import {menu,RECHERCHE_MENU} from "../../../menu";
import {Session} from "@domain/security/session";
import {Store} from "@ngrx/store";
import {AppState} from "@domain/appstate";
import {isRouteAllowed} from "@core/security/role-admin-helpers";
import {Route,Router,Routes} from "@angular/router";
import {PageHeaderItem} from "@share/component/page-header/page-header";
import {IComponentWithRoutedTabs,IComponentWithRoutedTabsBuilder} from "@domain/admin/recherche/component-with-routed-tabs";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {Observable,Subscription} from "rxjs";
import {first, map} from "rxjs/operators";
import {environment} from "@environments/environment";
import {Result} from "@domain/common/http/result";
import {error} from "jquery";

/**
 * Méthode pour la création d'un loader pour la traduction
 *
 * @param http client HTTP
 */
function createStandardTranslateLoader(http: HttpClient) {
    //Création du loader
    return new TranslateHttpLoader(http,'./lang/i18n/','_std.json');
}

/**
 * Service de gestion de la recherche de pages
 */
@Injectable()
export class RechercheService {
    /** Map contenant toutes les suggestions de routes classées par tags */
    private mapRoutes: Map<string, Set<string>> = new Map<string, Set<string>>();

    /** Map contenant tous les résultats de recherche classés par routes */
    private mapResultats: Map<string, ResultatRecherche> = new Map<string, ResultatRecherche>();

    /** Session utilisateur active */
    private session: Session;

    /** Service de traduction sans valeurs personnalisées (traductions non personnalisées afin que les administrateurs puissent trouver ce qu'ils cherchent quelles que soient les personnalisations des clients) */
    private standardTranslateService: TranslateService;

    /** Liste des tags personnalisés fournis par le backend */
    private customTags: CustomTag[];

    /** Statut du service */
    private _isReady: boolean = false;
    get isReady(): boolean { return this._isReady; }

    /** Souscriptions */
    private subscriptions: Subscription[] = [];

    /**
     * Constructeur
     */
    constructor(private store: Store<AppState>, private router: Router, private http: HttpClient, private translateService: TranslateService) {
        //Récupération de la session active
        this.subscriptions.push(this.store.select<Session>(s => s.session).subscribe(session => {
            //Mémorisation de la session
            this.session = session;

            //Obtention d'un service de traduction standard
            this.standardTranslateService = this.getStandardTranslateService();

            //Obtention du référentiel de traduction standard
            this.subscriptions.push(this.standardTranslateService.getTranslation(this.translateService.currentLang).subscribe(() => {
                //Obtention de la liste des tags personnalisés
                this.subscriptions.push(this.fetchCustomTags().subscribe((customTags) => {
                    //Sauvegarde des tags personnalisés
                    this.customTags = customTags;

                    //Construction de la map des suggestions
                    this.buildSuggestionsMap();

                    //Le service est prêt à répondre aux recherches
                    this._isReady = true;
                }));
            }));
        }));
    }

    /**
     * Récupération des tags personnalisés
     */
    fetchCustomTags(): Observable<CustomTag[]> {
        //Appel au backend
        return this.http.get<Result>(`${environment.baseUrl}/controller/Recherche/getCustomTags`).pipe(first(),map(result => result.data?.customTags as CustomTag[]));
    }

    /**
     * Retourne la liste des suggestions de pages
     *
     * @param query requête tapée par l'utilisateur
     */
    getSuggestions(query: string): Promise<ResultatRecherche[]> {
        return new Promise<ResultatRecherche[]>((resolve) => {
            //Obtention des tags de la requête utilisateur
            const tagsFromQuery: string[] = RechercheService.formatTagListe(query, true);

            //Liste des tags qui matchent
            const matchingTags: string[] = [];

            //Parcours des tags potentiels
            for (const tag of this.mapRoutes.keys()) {
                //Si le tag potentiel contient un des mots fournis par l'utilisateur
                if (tagsFromQuery.some(t => tag.includes(t))) {
                    //Ajout à la liste des correspondances
                    matchingTags.push(tag);
                }
            }

            //Liste des routes à suggérer (anti-doublon)
            const resultatsRecherche: Map<string, ResultatRecherche> = new Map<string, ResultatRecherche>();

            //Parcours des tags qui matchent
            for (const matchingTag of matchingTags) {
                //Recupération des routes pour chaque tag
                const routes: Set<string> = this.mapRoutes.get(matchingTag);

                //Parcours des routes pour chaque tag
                for (const route of routes.values()) {
                    //Si la liste ne contient pas déjà le résultat
                    if (!resultatsRecherche.get(route)) {
                        //Récupération du résultat de recherche
                        const resultat: ResultatRecherche = this.mapResultats.get(route);

                        //Parcours des tags du résultat
                        for (const tag of resultat.listeTag) {
                            //Mise à jour du statut du tag
                            tag.matchingQuery = matchingTags.includes(tag.tag);
                        }

                        //Tri des tags par matching en premier
                        resultat.listeTag = resultat.listeTag.sort((x, y) => (x.matchingQuery === y.matchingQuery)? 0 : x.matchingQuery? -1 : 1);

                        //Stockage du nombre de tags qui matchent (pour le tri)
                        resultat['matchingTagsCount'] = resultat.listeTag.filter(t => t.matchingQuery).length;

                        //Reset de la sélection au clavier
                        resultat.isKeyboardSelected = false;

                        //Ajout à la liste des routes à suggérer
                        resultatsRecherche.set(route, resultat);
                    }
                }
            }

            //Tri puis retour de la liste (limitée à 6 éléments)
            resolve(Array.from(resultatsRecherche.values()).sort((x, y) => y['matchingTagsCount'] - x['matchingTagsCount']).slice(0, 6));
        });
    }

    /**
     * Construction de la map permettant de chercher des suggestions à partir de tags
     */
    private buildSuggestionsMap(): void {
        //Filtrage des menus admin et exclusion du bouton de recherche
        const menuItems: MenuItem[] = menu.filter(item => item.scope.includes(Scope.MENU_ADMIN) && item.libelle != RECHERCHE_MENU);

        //Extraction du tableau des routes Admin
        const routesAdmin: Routes = this.router.config.find(route => route.path === 'Admin').children;

        //Parcours des menus admin
        for (const menu of menuItems) {
            //Contrôles sur les menus pour limiter les risques d'oublis pendant les devs
            if (!menu.url) { throw new Error("URL manquante dans la définition du menu " + menu.libelle + " (fichier 'menu.ts')"); }

            //Récupération des routes enfants
            const routes: Routes = routesAdmin.find(r => r.path === menu.url.replace("Admin/", "")).children;

            //Si l'utilisateur a les droits pour cette route
            if (isRouteAllowed(this.router, this.session, menu.url)) {
                //Parcours des sous-menus admin
                for (const sousMenu of menu.children) {
                    //Contrôles sur les menus pour limiter les risques d'oublis pendant les devs
                    if (!sousMenu.url) { throw new Error("URL manquante dans la définition du sous-menu " + sousMenu.libelle + " (fichier 'menu.ts')"); }

                    //Si l'utilisateur a les droits pour cette route
                    if (isRouteAllowed(this.router, this.session, sousMenu.url)) {
                        //Récupération de la route correspondante
                        const sousRoute: Route = routes.find(r => r.path === sousMenu.url.replace(menu.url + "/", ""));

                        //Contrôles sur les onglets pour limiter les risques d'oublis pendant les devs
                        if (!(sousRoute.component as IComponentWithRoutedTabsBuilder<IComponentWithRoutedTabs>)?.listeOnglets) { throw new Error("Liste des onglets manquante dans la définition du composant " + sousRoute.component.name); }

                        //Récupération des onglets du composant
                        const onglets: Array<PageHeaderItem> = getListeOnglets(sousRoute.component as IComponentWithRoutedTabsBuilder<IComponentWithRoutedTabs>);

                        //Si on a bien récupéré des onglets
                        if (onglets) {
                            //Parcours des onglets
                            for (const onglet of onglets) {
                                //Contrôles sur les onglets pour limiter les risques d'oublis pendant les devs
                                if (!onglet.url) { throw new Error("URL manquante dans la définition des onglets pour le composant " + sousRoute.component.name); }

                                //Si l'utilisateur a les droits pour cette route
                                if (isRouteAllowed(this.router, this.session, onglet.url)) {
                                    //Création d'un résultat de recherche
                                    const resultatRecherche: ResultatRecherche = new ResultatRecherche({
                                        niveau1: menu.libelle,
                                        niveau2: sousMenu.libelle,
                                        niveau3: onglet.libelle,
                                        route: onglet.url
                                    });

                                    //Gestion d'un onglet
                                    this.handleOnglet(onglet, resultatRecherche);
                                }
                            }
                        }
                    }
                }
            }
        }

        //Parcours des tags personnalisés
        for (const tag of this.customTags) {
            //Tentative d'obtention du Set de route pour un tag donné
            let routes: Set<string> = this.mapRoutes.get(tag.tag);

            //Si le tag n'existait pas déjà
            if (!routes) {
                //On le créé
                routes = new Set<string>();
            }

            //Parcours des routes custom
            for (const route of tag.listeRoute) {
                //Ajout de la route
                routes.add(route);

                //Obtention du résultat existant pour la route
                const resultat = this.mapResultats.get(route);

                //Si aucun résultat n'est trouvé
                if (!resultat) {
                    throw error(`Aucun tag n'a été trouvé pour la route custom ${route}.`);
                }

                //Ajout du tag au résultat de recherche de la route
                resultat.listeTag.push({ tag: tag.tag } as Tag);
            }

            //Mise à jour de la Map avec les nouvelles valeurs de routes
            this.mapRoutes.set(tag.tag, routes);
        }
    }

    /**
     * Formatage des tags à partir d'une chaine de caractères arbitraire (requête ou traduction)
     *
     * @param mots ensemble de mots à convertir
     * @param isQuery true si formatage d'une requête (false par défaut)
     */
    private static formatTagListe(mots: string, isQuery: boolean = false): string[] {
        //Nettoyage puis découpage
        return mots
                //Nettoyage des extrémités au cas où
                .trim()
                //Forçage en minuscules
                .toLowerCase()
                //Remplacement des traits d'union par des espaces
                .replace('-',' ')
                //Normalisation unicode afin de pouvoir procéder à des comparaisons efficaces
                .normalize('NFD')
                //Suppression de tous les caractères diacritiques
                .replace(/[\u0300-\u036f]/g, '')
                //Gestion spécifique des libellés 'axe 5' et 'axe 6' afin qu'ils soient détectés comme un seul mot clé et non deux
                .replace("axe 5", "axe5")
                .replace("axe 6", "axe6")
                //Si isQuery est vrai, les mots d'un seul caractère sont supprimés, sinon les mots de 1 ou 2 caractères sont supprimés
                .replace((isQuery ? /(\b(\w)\b(\W|$))/g : /(\b(\w{1,2})\b(\W|$))/g),'')
                //Classement des mots dans un array en coupant au niveau des espaces
                .split(' ')
                //Suppression d'éventuels mots vides restants dans le tableau
                .filter(t => !!t);
    }

    /**
     * Instanciation d'un nouveau TranslateService dédié à la traduction sans custom
     */
    private getStandardTranslateService(): TranslateService {
        //Création d'un service dédié
        const translateService: TranslateService = new TranslateService(
            new TranslateStore(),
            createStandardTranslateLoader(this.http),
            this.translateService.compiler,
            this.translateService.parser,
            this.translateService.missingTranslationHandler
        );

        //Langue par défaut
        translateService.setDefaultLang("fr");

        //Retour du service
        return translateService;
    }

    /**
     * Gestion spécifique d'un onglet
     *
     * @param onglet                   onglet à gérer
     * @param resultatRecherche        résultat à affecter
     */
    private handleOnglet(onglet: PageHeaderItem, resultatRecherche: ResultatRecherche): void {
        //Récupération des tags avec leurs personnalisations éventuelles
        let tags: string[] = RechercheService.formatTagListe(this.translateService.instant(resultatRecherche.niveau1));
        tags = [ ...tags, ... RechercheService.formatTagListe(this.translateService.instant(resultatRecherche.niveau2)) ];
        tags = [ ...tags, ... RechercheService.formatTagListe(this.translateService.instant(resultatRecherche.niveau3)) ];
        tags = [ ...tags, ... RechercheService.formatTagListe(this.translateService.instant(onglet.libelle)) ];

        //Récupération des tags standards uniquement
        let tagsStandards: string[] = RechercheService.formatTagListe(this.standardTranslateService.instant(resultatRecherche.niveau1));
        tagsStandards = [ ...tagsStandards, ... RechercheService.formatTagListe(this.standardTranslateService.instant(resultatRecherche.niveau2)) ];
        tagsStandards = [ ...tagsStandards, ... RechercheService.formatTagListe(this.standardTranslateService.instant(resultatRecherche.niveau3)) ];
        tagsStandards = [ ...tagsStandards, ... RechercheService.formatTagListe(this.standardTranslateService.instant(onglet.libelle)) ];

        //Instanciation d'un Set pour dédoublonner l'ensemble
        const tagsSet: Set<string> = new Set<string>([...tags, ...tagsStandards]);

        //Itération sur la liste filtrée des tags
        for (const tag of tagsSet.values()) {
            //Tentative de récupération du set de routes correspondant au tag
            let routesSet: Set<string> = this.mapRoutes.get(tag);

            //Si le set n'existe pas encore
            if (!routesSet) {
                //On en créée un tout neuf
                routesSet = new Set<string>();
            }

            //Ajout de la route dans le set
            routesSet.add(onglet.url);

            //Mise à jour de la map des routes
            this.mapRoutes.set(tag, routesSet);

            //Ajout du tag à la liste des tags dans le résultat
            resultatRecherche.listeTag.push({
                tag,
                matchingQuery: false
            } as Tag);
        }

        //Si le résultat n'existe pas encore
        if (!this.mapResultats.get(onglet.url)) {
            //Ajout du résultat
            this.mapResultats.set(onglet.url, resultatRecherche);
        }
    }

    /**
     * Destruction du service
     */
    destroy(): void {
        //Résiliation des abonnements
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}

/**
 * Interface représentant un tag personnalisé et les routes qui lui sont associées
 */
interface CustomTag {
    tag: string;
    listeRoute: string[];
}

/**
 * Méthode de récupération de la liste des onglets via la méthode statique censée être implémentée sur le composant
 *
 * @param component composant dont on cherche à obtenir les onglets
 */
function getListeOnglets(component: IComponentWithRoutedTabsBuilder<IComponentWithRoutedTabs>): Array<PageHeaderItem> {
    //Retour de la liste statique des onglets
    return component.listeOnglets;
}