<nav class="navbar">
    <div class="brand-container" [ngClass]="{ toggled: isMenuToggled }">
        <ul class="nav navbar-nav navbar-left" >
            <li class="nav-item active">
                <div class="div-menu" [ngClass]="{'open': isOpen}" (click)="isOpen = !isOpen; menuToggle.emit(null); isMenuToggled = !isMenuToggled;">
                    <div class="boutton-menu"></div>
                </div>
            </li>
        </ul>
        <a class="brand" [routerLink]="['/']">
            <h1 [ngClass]="{'svg-h1': getLogo()?.isSvg}">
                <img [ngClass]="{'svg-img': getLogo()?.isSvg}" class="logo" [src]="getLogo().path" *ngIf="getLogo()"/>
            </h1>
        </a>
    </div>

    <statut-application *ngIf="isAdmin && isSessionUpToDate"></statut-application>
    <statut-application-user *ngIf="!isAdmin && isSessionUpToDate"></statut-application-user>

    <ul class="nav navbar-nav navbar-right">
        <li class="nav-item">
            <account-menu></account-menu>
        </li>
    </ul>

    <div *ngIf="isAdmin" recherche style="display: none;"></div>
</nav>
