<div class="with-floating-button">
    <div class="content" *ngIf="releveFacture">
        <page-header [title]="title" [listeItems]="listeTabItems" [extraInfo]="releveFacture.getStatut() | translate" [niveauAlerte]="isReleveValide ? NiveauAlerte.SUCCESS : releveFacture.listeAlertes?.niveau"
                     (onSelectedItemChange)="onSelectedItemChange($event)" (onGoBack)="onGoBack()" [isPending]="isSaving">
            <preview-alerte #alerte *ngFor="let alerte of releveFacture.listeAlertes?.listeAlertes" [alerte]="alerte" [translateAlerte]="true"></preview-alerte>
            <preview-alerte *ngIf="isReleveValide" [alerte]="alerteReleveValide"></preview-alerte>
        </page-header>

        <div *ngIf="selectedItem.code == 'RELEVE'">
            <releve
                    [releveFacture]="releveFacture"
                    [listeLots]="listeLots"
                    [listeFieldParam]="listeFieldParam"
                    [canModifier]="canModifier">
            </releve>
        </div>

        <div [hidden]="selectedItem.code != 'FACTURES'">
            <facture-list [releveFacture]="releveFacture" remoteActions="true"
                          (onFactureListeLoaded)="onFactureListeLoaded($event)"
                          (onFactureDeleted)="onFactureDeleted()"></facture-list>
        </div>

        <div *ngIf="isAdminOuSSA && selectedItem.code === 'FACTURES_ANOMALIE'">
            <facture-anomalie-list [releveFacture]="releveFacture"></facture-anomalie-list>
        </div>

        <div *ngIf="piecesJointesLoaded" [hidden]="selectedItem.code != 'PIECES_JOINTES'">
            <document-list [idObjet]="releveFacture.idFactureReleve" [idObjetForPJ]="releveFacture.idFactureReleve" [idCollaborateur]="0" [context]="'FACTURE_RELEVE'"
                           [settings]="settings" [canAddDocument]="!isAdminOuSSA" [canDeleteDocument]="!isAdminOuSSA"></document-list>
        </div>
    </div>
    <please-wait class="content" *ngIf="releveFacture === undefined"></please-wait>
    <floating-button [listeActions]="listeActions" [isPending]="isSaving"></floating-button>
</div>