/**
 * Enumération des actions Workflow
 */
export enum TypeAction {
    /** Enumération **/
    VALIDER = 1,
    REJETER = 2,
    INVALIDER = 3,
    ANNULER = 4,
    MODIFIER = 5,
    SUPPRIMER = 6,
    EMETTRE = 7,
    ARCHIVER = 8,
    COMPTABILISER = 9,
    COMPLETER = 10,
    ATTRIBUER = 13,
    RESTITUER = 14,
    CLOTURER = 20,
    DEFINIR_TEMPS = 1018
}

//Liste des actions workflows qui ne le font pas avancer dans le workflow
const ACTIONS_WF_STATIQUES = [TypeAction.MODIFIER, TypeAction.SUPPRIMER, TypeAction.COMPLETER,
    TypeAction.REJETER, TypeAction.INVALIDER, TypeAction.ANNULER, TypeAction.CLOTURER];
export { ACTIONS_WF_STATIQUES};

/**
 * Enumération des portées
 */
export enum TypePortee {
    NF = 'NF',
    OD = 'OD',
    FC = 'FC',
    AV = 'AV',
    OT = 'OT',
    VP = 'VP',
    OP = 'OP',
    DV = 'DV',
    //Portée lot comptable pour les menus ainsi que les settings
    CPT = 'CPT',
    //Portée "budget" pour le menu
    BGT = 'BUDGET',
    //Portée profil
    PRF = 'PRF',
    //Portée admin (pour la gestion des adresses)
    ADM = 'ADM'
}

/**
 * On merge un namespace dans l'énum pour lui ajouter des fonctions
 */
export namespace TypePortee {
    /**
     * Fonction renvoyant la classe de l'icone d'une portée
     *
     * @param typePortee la portée
     */
    export function getIconClass(typePortee: TypePortee): string {
        switch (typePortee) {
            case TypePortee.NF:
                return 'nio icon-note_de_frais'
            case TypePortee.OD:
                return 'nio icon-ordre_de_mission'
            case TypePortee.FC:
                return 'nio icon-facture'
            case TypePortee.AV:
                return 'nio icon-avance_2'
            case TypePortee.OT:
                return 'nio icon-ordre_de_mission_permanent'
            case TypePortee.OP:
                return 'nio icon-frais'
        }
    }
}

/**
 * Enumération des valeurs de la portée pour les différents controller
 */
export enum TypePorteeUrlController {
    NF = 'NDF',
    OD = 'OD',
    FC = 'Facture',
    AV = 'Avance',
    OT = 'OMPermanent'
}

/**
 * Enumeration des valeurs numériques des portées
 */
export enum TypePorteeInt {
    NF = 2,
    OD = 1,
    FC = 5,
    AV = 3,
    OT = 19,
    OP = 20,
    DV = 13
}
