import {TranslateService} from '@ngx-translate/core';
import {Filter,TypeComparaison} from '@domain/common/list-view';

import {AutocompleteOptions} from '../autocomplete';
import {SearchType} from "@domain/common/list-view/sorting";

/**
 * Options des prestations
 */
export default class PrestationOptions implements AutocompleteOptions {
    /**
     * Récupération de l'URL
     */
    url(filter: any) {
        //URL
        let url: string = `/controller/Settings/filtrePrestations/${filter?.idPays || 0}/${filter?.idTypeEntite || 0}/${filter?.typeObjet || ''}/${filter?.idObjet || 0}`
            + `?idPA=${filter?.idPA || '0'}`;

        if (filter?.isFacturePrevisionnelle) {
            url += `&isFacturePrevisionnelle=true`;
        }

        return url;
    }

    /**
     * Affichage d'un élément
     */
    displayItem(item: any) { return (item?.vehicule ? item.libelle + ' - ' + item?.vehicule.immatriculation : item?.libelle) || ''; }

    /**
     * Filtres disponibles
     */
    listeFilters(translateService: TranslateService): Array<Filter> {
        //Retour de la liste des filtres
        return [{
            title: translateService.instant('filter.libelle'),
            clef: 'libelle,libelleNettoye',
            typeComparaison: TypeComparaison[TypeComparaison.LIKE],
            isDefault: true
        }]
    }

    /**
     * Mode de recherche
     */
    searchType = SearchType.CONTAINS;
}