import { NgModule } from '@angular/core';
import { ShareModule } from "@share/share.module";
import { ReportingComponent } from "./reporting.component";
import { ReportingItemComponent } from "./reporting-item/reporting-item.component";
import { ReportingInfosGeneralitesComponent } from "./reporting-infos/generalites/reporting-infos-generalites.component";
import { ParametresComponent } from "./reporting-infos/parametres/parametres.component";
import { ReportingInfosComponent } from "./reporting-infos/reporting-infos.component";
import { DocumentModule } from "@components/document/document.module";
import { ReportingInfosFichierComponent } from "@components/admin/bibliotheque/reporting/reporting-infos/fichier/reporting-infos-fichier.component";
import { ReportParamItemComponent } from "@components/admin/bibliotheque/reporting/reporting-infos/parametres/report-param-item.component";
import { RoleItemComponent } from "@components/admin/bibliotheque/reporting/reporting-infos/parametres/role-item.component";
import { ReportParamModifyComponent } from "@components/admin/bibliotheque/reporting/reporting-infos/parametres/report-param-modify/report-param-modify.component";
import { ReportRoleCreateComponent } from "@components/admin/bibliotheque/reporting/reporting-infos/parametres/report-role-create/report-role-create.component";
import { PreviewAlerteModule } from "@share/component/alerte/preview-alerte.module";
import { Routes } from "@angular/router";
import { AdminGuardProvider } from "@core/security/admin-guard.provider";
import { DroitAdmin } from "@core/security/droit-admin";

export const bibliothequeReportingRoutes : Routes = [
    {
        path: 'Reporting',
        component: ReportingComponent,
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_REPORTING] }
    }, {
        path: '',
        pathMatch: 'full',
        redirectTo: 'Reporting'
    }, {
        path: '**',
        redirectTo: 'Reporting'
    }
];

@NgModule({
    imports: [ShareModule, DocumentModule, PreviewAlerteModule],
    declarations: [
        ReportingComponent,
        ReportingItemComponent,
        ReportParamItemComponent,
        RoleItemComponent,
        ReportingInfosComponent,
        ReportingInfosGeneralitesComponent,
        ReportingInfosFichierComponent,
        ParametresComponent,
        ReportParamModifyComponent,
        ReportRoleCreateComponent
    ],
    entryComponents: [ReportingItemComponent],
    exports: [
        ReportingComponent,
        ReportingInfosGeneralitesComponent,
        ReportingInfosFichierComponent,
        ParametresComponent
    ],
    providers: []
})
export class ReportingModule {
}
