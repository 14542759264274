import {Component} from "@angular/core";
import {IComponentWithRoutedTabs} from "@domain/admin/recherche/component-with-routed-tabs";
import {PageHeaderItem} from "@share/component/page-header/page-header";

/**
 * Composant concernant les types entités
 */
@Component({
    host: {'data-test-id': 'types-entites'},
    templateUrl: './types-entites.component.html'
})
export class TypesEntitesComponent implements IComponentWithRoutedTabs {
    /** Liste statique des onglets */
    public static listeOnglets: Array<PageHeaderItem> = [
        {
            code: undefined,
            libelle: 'menu.admin.typeEntite',
            url: 'Admin/Bibliotheque/TypeEntite'
        }
    ];

    /** Utilisé pour attendre le chargement de la première frame avant de charger la seconde */
    //TODO: A supprimer quand l'implémentation v10 sera terminée
    isFirstLoaded: boolean = false;

    /**
     * Méthode appelée lorsque l'iFrame a terminé un chargement
     * TODO: A supprimer quand l'implémentation v10 sera terminée
     */
    loaded() {
        //Si c'est le premier chargement
        if (!this.isFirstLoaded) {
            //Sinon basculement du flag
            this.isFirstLoaded = true;
        }
    }
}
