import {Component,OnInit} from '@angular/core';
import {ListView,TypeComparaison} from "@domain/common/list-view";
import {Motif} from "@domain/workflow/motif";
import {MotifListeItemComponent} from "@components/admin/workflow/motifs/motif-liste/motif-liste-item.component";
import {BehaviorSubject} from 'rxjs';
import {FloatingButtonAction,TypeAction} from '@share/component/floating-button/floating-button';
import {Router} from "@angular/router";
import {TypeAction as TypeActionWorkflow} from "@domain/workflow/workflow";
import {TypeProfil} from "@domain/user/user";
import {Sorting} from "@domain/common/list-view/sorting";
import {TranslateService} from "@ngx-translate/core";

/**
 * Liste des motifs
 *
 * @author Angeline Ha
 * @date 27/08/2024
 */
@Component({
  host: {'data-test-id': 'motif-liste'},
  selector: 'motif-liste',
  templateUrl: './motif-liste.component.html',
})
export class MotifListeComponent implements OnInit {
  /** Liste des motifs */
  listeMotifs: ListView<Motif,MotifListeItemComponent>;

  /** Liste des actions possibles */
  listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

  /**
   * Constructeur
   *
   * @param router Routeur Angular
   * @param translateService
   */
  constructor(private router: Router,
              private translateService: TranslateService) {
  }

  /**
   * Initialisation
   */
  ngOnInit() {
    //Initialisation de la liste des motifs
    this.listeMotifs = new ListView<Motif,MotifListeItemComponent>({
      uri: '/controller/WorkflowMotif/listeWorkflowMotif',
      title: this.translateService.instant('workflow.motifs.title'),
      component: MotifListeItemComponent,
      defaultOrder: 'libelle',
      isFilter: true,
      listeFilters: [
        {
          clef: 'libelle',
          title: this.translateService.instant('filter.libelle'),
          isDefault: true,
          typeComparaison: TypeComparaison[TypeComparaison.LIKE]
        }, {
          clef: 'lstAction',
          title: this.translateService.instant('global.liste.filtres.actions.actions'),
          typeComparaison: TypeComparaison[TypeComparaison.LIKE],
          listeValues: [
            {
              code: '%' + TypeActionWorkflow.ANNULER,
              libelle: this.translateService.instant('global.actions.annuler')
            }, {
              code: '%' + TypeActionWorkflow.ARCHIVER,
              libelle: this.translateService.instant('global.actions.archiver')
            }, {
              code: '%' + TypeActionWorkflow.CLOTURER,
              libelle: this.translateService.instant('global.actions.cloturer')
            }, {
              code: '%' + TypeActionWorkflow.EMETTRE,
              libelle: this.translateService.instant('global.actions.emettre')
            }, {
              code: '%' + TypeActionWorkflow.INVALIDER,
              libelle: this.translateService.instant('global.actions.invalider')
            }, {
              code: '%' + TypeActionWorkflow.REJETER,
              libelle: this.translateService.instant('global.actions.rejeter')
            }, {
              code: '%' + TypeActionWorkflow.VALIDER,
              libelle: this.translateService.instant('global.actions.valider')
            }]
        }, {
          clef: 'lstProfil',
          title: this.translateService.instant('filter.profil'),
          typeComparaison: TypeComparaison[TypeComparaison.LIKE],
          listeValues: [
            {
              code: '%' + TypeProfil.ASSISTANT,
              libelle: TypeProfil.getLibelle(this.translateService, TypeProfil.ASSISTANT)
            }, {
              code: '%' + TypeProfil.COLLABORATEUR,
              libelle: TypeProfil.getLibelle(this.translateService, TypeProfil.COLLABORATEUR)
            }, {
              code: '%' + TypeProfil.COMPTABLE,
              libelle: TypeProfil.getLibelle(this.translateService, TypeProfil.COMPTABLE)
            }, {
              code: '%' + TypeProfil.FOURNISSEUR,
              libelle: TypeProfil.getLibelle(this.translateService, TypeProfil.FOURNISSEUR)
            }, {
              code: '%' + TypeProfil.RESPONSABLE,
              libelle: TypeProfil.getLibelle(this.translateService, TypeProfil.RESPONSABLE)
            }
          ]
        }]
    });

    //Définition des colones de tri
    this.listeMotifs.columns = [
      { key: 'idMotif', title: 'filter.id' },
      { key: 'libelle', title: 'filter.libelle' }
    ];

    //Ajout du tri de la liste selon l'ordre voulu
    this.listeMotifs.sorting = new Sorting(this.listeMotifs.columns,this.listeMotifs.defaultOrder);

    //Initialisation des actions possibles
    this.listeActions.next([{
      type: TypeAction.PRIMARY,
      icone: 'nio icon-ajouter',
      libelle: 'global.actions.creer',
      doAction: () => this.creerMotif()
    }]);
  }

  /** Appel à la création d'un motif */
  creerMotif() {
    this.router.navigate(['Admin/Workflow/Motifs/Motifs/', 0]);
  }
}
