import {NgModule} from "@angular/core";
import {Routes} from "@angular/router";
import {ShareModule} from "@share/share.module";
import {DevisesModule} from './devises/devises.module';
import {bibliothequeInternationalisationRoutes,InternationalisationModule} from "./internationalisation/internationalisation.module";
import {AdminGuardProvider} from "@core/security/admin-guard.provider";
import {DeviseInfosComponent} from "@components/admin/bibliotheque/devises/devise-infos/devise-infos.component";
import {DevisesComponent} from "@components/admin/bibliotheque/devises/devises.component";
import {AdminBibliothequeComponent} from '@components/admin/bibliotheque/admin-bibliotheque.component';
import {InternationalisationComponent} from "@components/admin/bibliotheque/internationalisation/internationalisation.component";
import {GeographieComponent} from './geographie/geographie.component';
import {adminGeographieRoutes,GeographieModule} from "@components/admin/bibliotheque/geographie/geographie.module";
import {bibliothequeReportingRoutes, ReportingModule} from "@components/admin/bibliotheque/reporting/reporting.module";
import {ReportingInfosComponent} from "@components/admin/bibliotheque/reporting/reporting-infos/reporting-infos.component";
import {ReportingComponent} from "@components/admin/bibliotheque/reporting/reporting.component";
import {DroitAdmin} from "@core/security/droit-admin";
import {TypesEntitesComponent} from "@components/admin/bibliotheque/types-entites/types-entites.component";
import {BibliothequeNotificationsModule,bibliothequeNotificationsRoutes} from "@components/admin/bibliotheque/notifications/bibliotheque-notifications.module";
import {BibliothequeNotificationsComponent} from "@components/admin/bibliotheque/notifications/bibliotheque-notifications.component";
import {bibliothequeZuRoutes,ZonesUtilisateursModule} from "@components/admin/bibliotheque/zones-utilisateurs/zones-utilisateurs.module";
import {ZonesUtilisateursComponent} from "@components/admin/bibliotheque/zones-utilisateurs/zones-utilisateurs.component";
import {ZoneParametrableDetailComponent} from "@components/admin/bibliotheque/zones-utilisateurs/zones-parametrables/detail/zone-parametrable-detail.component";

export const adminBibliothequeRoutes: Routes = [
	{
		path: 'Devises/:idDevise',
		component: DeviseInfosComponent,
		canActivate: [AdminGuardProvider],
		data: {sousAdminCredentials: [DroitAdmin.DROIT_DEVISE_TAUX_CHANGE]}
	},{
		path: 'Devises',
		component: DevisesComponent,
		canActivate: [AdminGuardProvider],
		data: {sousAdminCredentials: [DroitAdmin.DROIT_DEVISE_TAUX_CHANGE]}
	},{
		path: 'Geographie',
		component: GeographieComponent,
		canActivate: [AdminGuardProvider],
		children: adminGeographieRoutes
	},{
		path: 'Internationalisation',
		component: InternationalisationComponent,
		canActivate: [AdminGuardProvider],
		children: bibliothequeInternationalisationRoutes
	},{
		path: 'Notification',
		component: BibliothequeNotificationsComponent,
		canActivate: [AdminGuardProvider],
		children: bibliothequeNotificationsRoutes
	},{
		path: 'Report/:idReport',
		component: ReportingInfosComponent,
		canActivate: [AdminGuardProvider],
		data: {sousAdminCredentials: [DroitAdmin.DROIT_REPORTING]}
	},{
		path: 'Reporting',
		component: ReportingComponent,
		canActivate: [AdminGuardProvider],
		children: bibliothequeReportingRoutes
	},{
		path: 'TypeEntite',
		component: TypesEntitesComponent,
		canActivate: [AdminGuardProvider]
	},{
        path: 'ZonesUtilisateurs',
		component: ZonesUtilisateursComponent,
		canActivate: [AdminGuardProvider],
		children: bibliothequeZuRoutes
	},{
		path: 'ZoneUtilisateur/:idZU',
		component: ZoneParametrableDetailComponent,
		canActivate: [AdminGuardProvider]
	},{
		path: '',
		redirectTo: 'Devises',
		pathMatch: 'full'
	},{
		path: '**',
		redirectTo: 'Devises'
	}
];

/**
 * Module des référentiels de la gestion des frais
 */
@NgModule({
	imports: [
		ShareModule,
		DevisesModule,
		GeographieModule,
		InternationalisationModule,
		ReportingModule,
		BibliothequeNotificationsModule,
		ZonesUtilisateursModule
	],
	declarations: [
		AdminBibliothequeComponent,
		TypesEntitesComponent,
	],
	exports: [
		AdminBibliothequeComponent
	],
	providers: []
})
export class AdminBibliothequeModule {
}
