<div class="lvi-content">
    <div class="avatar" [nSelector]="{ liste: liste,item: data,isNotSelectable: !isArchivageAutorise() }" [ngClass]="{'danger' : data.listeAlertes}">
        <i class="nio icon-comptabilisation" style="vertical-align: middle;"></i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a *ngIf="extraOptions.isClickable" [routerLink]="" (click)="navigateToLot(data?.idLot)">{{ data.idLot }} - {{ data.libelle || ('lot.liste.objetNonDefini' | translate) }}</a>
            <a *ngIf="!extraOptions.isClickable" [ngStyle]="{ 'cursor': 'default'}">{{ data.idLot }} - {{ data.libelle || ('lot.liste.objetNonDefini' | translate) }}</a>
        </div>
        <ul class="lvi-attrs">
            <li><strong [translate]="'lot.liste.date'"></strong>{{ data.date | date:'shortDate' }}</li>
            <li><strong [translate]="'lot.liste.periode'"></strong>{{ data.periode?.libelle }}</li>
            <li><strong [translate]="'lot.liste.note'"></strong>{{ data.nbNdf }}</li>
            <li><strong [translate]="'lot.liste.avance'"></strong>{{ data.nbAvance }}</li>
            <li><strong [translate]="'lot.liste.facture'"></strong>{{ data.nbFacture }}</li>
            <li *ngIf="data.listeAlertes" [ngClass]="{'back-danger' : data.listeAlertes.niveau === NiveauAlerte.ERROR, 'back-warning' : data.listeAlertes.niveau === NiveauAlerte.WARNING}">
                <strong [translate]="'workflow.alerte.title'"></strong><mat-icon [matTooltip]="data.listeAlertes.listeAlertes[0].message">comment</mat-icon>
            </li>
        </ul>
    </div>
    <div class="info">
        <div>
            <div [ngSwitch]="data.statutArchivage" class="statut">
                <i *ngSwitchCase="'EN_ATTENTE'" class="material-icons-outlined c-info">cloud_upload</i>
                <i *ngSwitchCase="'PARTIEL'" class="material-icons-outlined c-warning">cloud</i>
                <i *ngSwitchCase="'ECHEC'" class="material-icons-outlined c-danger">cloud_off</i>
                <i *ngSwitchCase="'SYNCHRONISE'" class="material-icons-outlined c-success">cloud_done</i>
            </div>
            <div *ngIf="settings?.deviseEntreprise" class="text-right text-secondary font-weight-bold">
                {{ data.montant | montant:settings.deviseEntreprise }}
            </div>
        </div>
    </div>
</div>
