import {Component,OnDestroy,OnInit} from '@angular/core';
import {PageHeaderItem} from '@share/component/page-header/page-header';
import {ComptabilitePlanComptableService} from "@components/admin/comptabilite/plan-comptable/comptabilite-plan-comptable.service";
import {IComponentWithRoutedTabs} from "@domain/admin/recherche/component-with-routed-tabs";
import {ActivatedRoute,Router} from "@angular/router";
import {Subscription} from "rxjs";

/**
 * Enum pour les différents onglets de la page Comptabilite / Plan comptable
 */
export enum Onglets {
    COMPTES_COMPTABLES = "Comptes_comptables",
    PARAMETRES = "Parametres"
}

/**
 * Page admin de Comptabilité / Plan comptable
 *
 * @author Angeline Ha
 * @date 29/02/2024
 */
@Component({
    host: {'data-test-id': 'plan-comptable'},
    templateUrl: './plan-comptable.component.html'
})
export class PlanComptableComponent implements OnInit, OnDestroy, IComponentWithRoutedTabs {
    /** Liste statique des onglets */
    public static listeOnglets: Array<PageHeaderItem> = [{
        code: Onglets.COMPTES_COMPTABLES,
        libelle: 'admin.comptabilite.planComptable.compteComptable.title.liste',
        url: 'Admin/Comptabilite/PlanComptable/ComptesComptables'
    }, {
        code: Onglets.PARAMETRES,
        libelle: 'admin.comptabilite.planComptable.parametres.title',
        url: 'Admin/Comptabilite/PlanComptable/Parametres'
    }];

    /** Liste des différents onglets disponibles dans le menu */
    listeTabItems: Array<PageHeaderItem> = PlanComptableComponent.listeOnglets;

    /** Onglet courant */
    selectedItem: PageHeaderItem;

    /** Référence vers l'enum des onglets pour utiliser dans le template */
    Onglet = Onglets;

    /** Index de l'onglet courant */
    selectedIndex: number;

    /** Souscription */
    private subscription: Subscription;

    /**
     * Constructeur
     *
     * @param router Router
     * @param activatedRoute Service de routing
     * @param planComptableService Service du plan comptable
     */
    constructor(private router: Router,
                private activatedRoute: ActivatedRoute,
                private planComptableService: ComptabilitePlanComptableService) {
    }

    /**
     * Initialisation du composant
     */
    ngOnInit(): void {
        //Définition des onglets
        this.listeTabItems = PlanComptableComponent.listeOnglets;

        this.subscription = this.activatedRoute.url.subscribe(() => {
            //Récupération de la route
            const firstChild = this.activatedRoute.snapshot.firstChild;

            //Extraction du path
            const path = firstChild.url[0].path;

            //On vérifie si on trouve l'onglet demandé via l'url
            const matching = this.listeTabItems.find(i => i.code === path);

            //Si on trouve bien l'onglet correspondant
            if (!!matching) {
                //on met à jour le composant header
                setTimeout(() => {
                    this.selectedItem = matching;
                    this.selectedIndex = this.listeTabItems.findIndex(i => i.code === path);
                });
            } else {
                //Sinon, on redirige sur le premier onglet et on met à jour le composant header
                setTimeout(() => {
                    this.selectedItem = this.listeTabItems[0];
                    this.selectedIndex = 0;
                });
            }
        });
    }

    /**
     * Changement d'onglet
     *
     * @param selectedItem Onglet sélectionné
     */
    onSelectedItemChange(selectedItem: PageHeaderItem): void {
        if (!this.selectedItem) {
            this.selectedItem = selectedItem;
        }
        if (selectedItem?.code !== this.selectedItem?.code) {
            this.selectedItem = selectedItem;
            this.router.navigateByUrl(selectedItem.url);
        }
    }

    /**
     * Indique si on est dans le détail d'un compte comptable
     */
    get isInDetail() {
        return this.planComptableService.isInDetailCompte;
    }

    /**
     * Destruction du composant
     */
    ngOnDestroy() {
        //Résiliation de l'abonnement
        this.subscription.unsubscribe();
    }
}
