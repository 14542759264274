<mat-card>
    <mat-card-title>
        <span [translate]="'admin.comptabilite.planComptable.compteComptable.usage.title'"></span>
    </mat-card-title>
    <mat-card-content>
        <div class="listview">
            <div class="listview-body">
                <div *ngFor="let usage of listeTypeUsage" (click)="usage.typeUsage != TypeUsage.VAL_DEFAUT && showListeUsage(usage.typeUsage);"
                     class="listview-item" [class]="usage.typeUsage != TypeUsage.VAL_DEFAUT ? 'clickable': 'cursor-default'"
                >
                    <div [class]="{'list-view-item-hover': usage.typeUsage != TypeUsage.VAL_DEFAUT}">
                        <div class="lvi-content">
                            <div class="avatar">
                                <mat-spinner *ngIf="(usage.count | async) === null; else affCount" class="c-white" diameter="28"></mat-spinner>
                                <ng-template #affCount>
                                    <span>{{ usage.count | async }}</span>
                                </ng-template>
                            </div>
                            <div class="lvi-body"><div class="title"><a [translate]="usage.libelle"></a></div></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>