import {Component,Input,OnInit} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {Compte} from "@domain/admin/comptabilite/compte";
import {ComptabilitePlanComptableService} from "../../../comptabilite-plan-comptable.service";
import {CompteComptableUsageListComponent} from "./compte-comptable-usage-list/compte-comptable-usage-list.component";
import {CompteUsageCompteur} from "@domain/admin/comptabilite/compte-usage/compte-usage-compteur";

/**
 * Composant d'affichage de l'onglet "Usage" d'un compte comptable
 *
 * @author Laurent Convert
 * @date 19/09/2024
 */
@Component({
	host: {'data-test-id': 'compte-comptable-usage'},
	selector: 'compte-comptable-usage',
	templateUrl: './compte-comptable-usage.component.html'
})
export class CompteComptableUsageComponent implements OnInit {
	/* Déclaration pour accès dans le template */
	protected readonly TypeUsage = TypeUsage;

	/** Compte comptable courant */
	@Input() compte: Compte;

	/** Liste des types d'usage d'un compte */
	listeTypeUsage: Array<CompteUsageCompteur> = new Array<CompteUsageCompteur>();

	/**
	 * Constructeur
	 *
	 * @param matDialog Gestionnaire de boîte de dialogue
	 * @param planComptableService Service du plan comptable
	 */
	constructor(private matDialog: MatDialog,private planComptableService: ComptabilitePlanComptableService) {
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Construction de la liste des usages pour chaque type
		Object.values(TypeUsage).forEach(typeUsage => {
			this.listeTypeUsage.push(new CompteUsageCompteur(typeUsage));
		});

		//Récupération du nombre d'usages pour chaque type
		this.planComptableService.countAllUsage(this.compte.numero).subscribe(usages => {
			//Mise à jour du compteur de chaque type
			this.listeTypeUsage.forEach(entry => {
				entry.setCount(usages[entry.typeUsage] ?? 0);
			});
		});
	}

	/**
	 * Affichage de la liste des usages correspondant au type fournit
	 *
	 * @param typeUsage Type d'usage à afficher
	 */
	showListeUsage(typeUsage: TypeUsage): void {
		this.matDialog.open(CompteComptableUsageListComponent,{
			data: {
				numero: this.compte.numero,
				typeUsage: typeUsage
			}
		});
	}
}

/**
 * Énumération des différents types d'usages d'un compte comptable
 */
export enum TypeUsage {
	VAL_DEFAUT = 'VAL_DEFAUT',
	UTILISATEUR = 'UTILISATEUR',
	FOURNISSEUR = 'FOURNISSEUR',
	PRESTATION = 'PRESTATION',
	TAXE = 'TAXE',
	MODE_REMB = 'MODE_REMB',
	CARTE_AFFAIRE = 'CARTE_AFFAIRE',
	AVANCE_COLLAB = 'AVANCE_COLLAB',
	AVANCE_DEVISE = 'AVANCE_DEVISE',
}
