<div class="fb-wrapper" [ngClass]="{ 'opened': isOpened }" *ngIf="((listeActions | async) | filterBy:getFilterFor(TypeAction[TypeAction.PRIMARY]))?.length || ((listeActions | async) | filterBy:getFilterFor(TypeAction[TypeAction.SECONDARY]))?.length">
    <ul [ngClass]="{'floatting-border': isShowBorder}" class="secondary" *ngIf="((listeActions | async) | filterBy:getFilterFor(TypeAction[TypeAction.SECONDARY]))?.length">
        <li *ngFor="let action of (listeActions | async) | filterBy:getFilterFor(TypeAction[TypeAction.SECONDARY])" [matTooltip]="getTooltip(action) | translate" matTooltipPosition="left" (click)="doAction(action)" [ngClass]="{disabled: isDisabled(action) || isPending}">
            <i [ngClass]="action.icone" [attr.aria-label]="(action.message ? action.message() : action.libelle) | translate">{{ action.iconeName }}</i>
            <span *ngIf="action.message">{{ action.message() }}</span><span *ngIf="!action.message" [translate]="action.libelle"></span>
        </li>
    </ul>
    <ul [ngClass]="{'floatting-border': isShowBorder}" class="primary" *ngIf="((listeActions | async) | filterBy:getFilterFor(TypeAction[TypeAction.PRIMARY]))?.length">
        <li *ngFor="let action of (listeActions | async) | filterBy:getFilterFor(TypeAction[TypeAction.PRIMARY])" [matTooltip]="getTooltip(action) | translate" matTooltipPosition="left" (click)="doAction(action)" [ngClass]="{disabled: isDisabled(action) || isPending}">
            <i [ngClass]="action.icone" [attr.aria-label]="(action.message ? action.message() : action.libelle) | translate">{{ action.iconeName }}</i>
            <span *ngIf="action.message">{{ action.message() }}</span><span *ngIf="!action.message" [translate]="action.libelle"></span>
        </li>
    </ul>
    <div class="fb-opener" [ngClass]="{ 'opened': isOpened }" matRipple (click)="isOpened = !isOpened;">
        <i class="zmdi zmdi-chevron-left" *ngIf="!isPending"></i>
        <mat-spinner *ngIf="isPending" diameter="30"></mat-spinner>
    </div>
</div>
