import {CompteUsage} from "./compte-usage";
import {EntityAttribute} from "@share/component/autocomplete/options/entity-attribute";

/**
 * Usages d'un compte comptable
 * Avance devise associée à un compte comptable
 *
 * @author Laurent Convert
 * @date 24/09/2024
 */
export class CompteUsageAvanceDevise implements CompteUsage {
	code: string;
	libelle: string;
	isActive: boolean;

	/**
	 * Constructeur
	 *
	 * @param dto Source
	 */
	constructor(dto: any) {
		Object.assign(this,dto);
	}

	/**
	 * Récupération de l'identifiant de l'objet
	 */
	getKey(): string {
		return this.code;
	}

	/**
	 * Affichage d'un élément
	 */
	displayItem(): string {
		return this.libelle;
	}

	/**
	 * Liste les propriétés de l'objet pour lesquelles il faut afficher un badge 'clé:valeur'
	 */
	listDisplayedAttributes(): Array<EntityAttribute> {
		return null;
	}
}