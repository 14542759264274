<div class="lvi-content">
    <div class="avatar">
        <i *ngIf="data.role.fonction === TypeProfil.COMPTABLE" class="nio icon-comptable"></i>
        <i *ngIf="data.role.fonction === TypeProfil.RESPONSABLE" class="nio icon-responsable"></i>
        <i *ngIf="data.role.fonction === TypeProfil.COLLABORATEUR" class="nio icon-collaborateurs"></i>
        <i *ngIf="data.role.fonction === TypeProfil.ASSISTANT" class="nio icon-assistant"></i>
        <i *ngIf="data.role.fonction === TypeProfil.SOUS_ADMINISTRATEUR" class="nio icon-sous_administrateur"></i>
        <i *ngIf="data.role.fonction === TypeProfil.FOURNISSEUR" class="nio icon-fournisseur"></i>
    </div>
    <div class="lvi-body" [ngClass]="{'clickable' : extraOptions.canModifier}">
        <div class="title">
            <a *ngIf="extraOptions.canModifier" (click)="openDelegation(data)">{{ TypeProfil.getLibelle(translateService, data.role.fonction) }}</a>
            <a *ngIf="!extraOptions.canModifier" class="unclickable">{{ TypeProfil.getLibelle(translateService, data.role.fonction) }}</a>
        </div>
        <ul class="lvi-attrs">
            <li>
                <strong [translate]="'admin.entreprise.utilisateurs.detail.generalites.habilitations.role'"></strong>
                <span>{{ data.role.libelle }}</span>
            </li>
            <li *ngIf="extraOptions.canShowDeleguant">
                <strong [translate]="'admin.entreprise.utilisateurs.detail.generalites.habilitations.delegant'"></strong>
                <span>{{ data.userDelegant | user }}</span>
            </li>
            <li *ngIf="extraOptions.canShowDelegue">
                <strong [translate]="'admin.entreprise.utilisateurs.detail.generalites.habilitations.delegue'"></strong>
                <span>{{ data.user | user }}</span>
            </li>
            <li>
                <strong [translate]="'admin.entreprise.utilisateurs.detail.generalites.habilitations.dateDebut'"></strong>
                <span>{{ data.dateDebut | date: 'shortDate' }}</span>
            </li>
            <li>
                <strong [translate]="'admin.entreprise.utilisateurs.detail.generalites.habilitations.dateFin'"></strong>
                <span>{{ data.dateFin | date: 'shortDate' }}</span>
            </li>
            <li>
                <strong [translate]="'admin.entreprise.utilisateurs.detail.generalites.habilitations.population'"></strong>
                <span>{{ data.population ? data.population.libelle : ('admin.entreprise.utilisateurs.detail.generalites.habilitations.tousLesCollabs' | translate) }}</span>
            </li>
            <li *ngIf="data.listeEnveloppes.length > 0">
                <strong [translate]="'admin.entreprise.utilisateurs.detail.generalites.habilitations.enveloppes'"></strong>
                <span>{{ data.listeEnveloppes.length }}</span>
            </li>
        </ul>
    </div>
</div>