import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { CreateNewReferentielComponent } from "@components/admin/entreprise/referentiels/create-new-referentiel.component";
import { ModifyReferentielComponent } from "@components/admin/entreprise/referentiels/modifyReferentiel/modify-referentiel.component";
import { ReferentielItemComponent } from "@components/admin/entreprise/referentiels/referentiel-item.component";
import { ReferentielDto } from "@domain/admin/entreprise/referentielDto";
import { Referentiel } from '@domain/admin/referentiels/referentiel';
import { Result, TypeCodeErreur } from "@domain/common/http/result";
import { ListView, TypeComparaison } from "@domain/common/list-view";
import { Sorting } from "@domain/common/list-view/sorting";
import { TranslateService } from "@ngx-translate/core";
import { ReferentielsService } from "@services/admin/referentiels/referentiels.service";
import { FloatingButtonAction, TypeAction } from "@share/component/floating-button/floating-button";
import { PageHeaderItem } from "@share/component/page-header/page-header";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, Subscription } from "rxjs";
import { IComponentWithRoutedTabs } from "@domain/admin/recherche/component-with-routed-tabs";
import * as _ from 'lodash';

/**
 * Enum pour les noms des différents onglets de la page Référentiels
 */
export enum Onglets {
    GENERALITES = "Généralités",
}

/**
 * Composant concernant les référentiels de l'entreprise
 */
@Component({
    host: {'data-test-id': 'entreprise-referentiels'},
    templateUrl: './entreprise-referentiels.component.html'
})
export class EntrepriseReferentielsComponent implements  OnInit, OnDestroy, IComponentWithRoutedTabs {
    /** Liste statique des onglets */
    public static listeOnglets: Array<PageHeaderItem> = [{
        code: Onglets.GENERALITES,
        libelle: 'admin.entreprise.referentiels.onglets.generalites',
        url: 'Admin/Entreprise/Referentiels'
    }];

    /** Liste des différents onglets disponibles dans le menu "Référentiels" de l'entreprise */
    listeTabItems: Array<PageHeaderItem>;

    /** Onglet courant */
    selectedItem: PageHeaderItem = EntrepriseReferentielsComponent.listeOnglets[0];

    /** Liste des actions possibles */
    listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

    /** Référence vers l'enum pour l'utiliser dans le template */
    Onglet = Onglets;

    /** Liste des référentiels, pour utiliser le composant ListViewComponent */
    list: ListView<ReferentielDto, ReferentielItemComponent>;

    /** Liste des souscriptions, pour faire un unsubscribe sur chacun lors de la destruction du composant */
    souscriptions: Subscription[] = [];

    /**
     * Constructeur
     *
     * @param translateService Service de traduction
     * @param toastrService Service pour afficher les messages de succès, d'erreurs, ...
     * @param matDialog Boite de dialogue permettant de créer un référentiel
     * @param referentielService Service pour récupérer les référentiels et en créer
     */
    constructor(
        protected translateService: TranslateService,
        private toastrService: ToastrService,
        private matDialog: MatDialog,
        private referentielService: ReferentielsService
    ) { }

    /**
     * Changement d'onglet
     *
     * @param selectedItem Onglet sélectionné
     */
    onSelectedItemChange(selectedItem: PageHeaderItem) {
        //Mise à jour de l'onglet sélectionné
        this.selectedItem = selectedItem;
    }

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        //Permet de générer la liste des différents onglets disponibles (ici il n'y a qu'un seul onglet : "Généralités")
        this.listeTabItems = EntrepriseReferentielsComponent.listeOnglets;

        //Création de la liste des référentiels
        this.list = new ListView<ReferentielDto, ReferentielItemComponent>({
            uri: `/controller/Referentiel/listeAllReferentiel?contextReferentiel=UTILISATEUR`,
            title: this.translateService.instant('admin.entreprise.referentiels.referentielTitle'),
            component: ReferentielItemComponent,
            extraOptions: {
                onSelect: this.onSelect.bind(this)
            },
            isFilter: true,
            defaultOrder: 'type',
            listeFilters: [
                {
                    //Filtre de type select sur le type
                    clef: 'type',
                    title: this.translateService.instant('admin.entreprise.referentiels.referentielFiltres.type'),
                    listeValues: [{
                        code: "C",
                        libelle: this.translateService.instant('admin.entreprise.referentiels.filtres.categorie')
                    },{
                        code: "E",
                        libelle: this.translateService.instant('admin.entreprise.referentiels.filtres.statutCollaborateur')
                    },{
                        code: "M",
                        libelle: this.translateService.instant('admin.entreprise.referentiels.filtres.motClef')
                    },{
                        code: "Q",
                        libelle: this.translateService.instant('admin.entreprise.referentiels.filtres.typePersonnel')
                    },{
                        code: "T",
                        libelle: this.translateService.instant('admin.entreprise.referentiels.filtres.titre')
                    }]
                },{
                    clef: 'libelle',
                    title: this.translateService.instant('admin.entreprise.referentiels.referentielFiltres.libelle'),
                    isDefault: true,
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                },{
                    clef: 'code',
                    title: this.translateService.instant('admin.entreprise.referentiels.referentielFiltres.code'),
                    isDefault: true,
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                },{
                    clef: 'ordre',
                    title: this.translateService.instant('admin.entreprise.referentiels.referentielFiltres.ordre'),
                    isDefault: true,
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                },
            ]
        });

        //Définition des colonnes de tri
        this.list.columns = [
            { key: 'type', title: 'admin.entreprise.referentiels.referentielFiltres.type' },
            { key: 'libelle', title: 'admin.entreprise.referentiels.referentielFiltres.libelle' },
            { key: 'code', title: 'admin.entreprise.referentiels.referentielFiltres.code' },
            { key: 'ordre', title: 'admin.entreprise.referentiels.referentielFiltres.ordre' },
        ];

        //Ajout du tri de la liste selon l'ordre voulu
        this.list.sorting = new Sorting(this.list.columns, "type");

        //Initialisation des actions possibles
        this.listeActions.next([{
            type: TypeAction.PRIMARY,
            icone: 'nio icon-ajouter',
            libelle: 'global.actions.creer',
            doAction: () => this.createNewReferentiel()
        }]);
    }

    /**
     * Méthode appelée lors de la création d'un nouveau référentiel
     */
    createNewReferentiel() {
        //Ouverture de la boite de dialogue pour la création d'un référentiel
        const dialog = this.matDialog.open(CreateNewReferentielComponent);

        //Lors de la fermeture de la boîte de dialogue, on crée le nouveau référentiel
        dialog.afterClosed().subscribe((referentiel: Referentiel) => {
            if (!!referentiel) {
                //Création du nouveau référentiel
                this.referentielService.saveReferentiel(referentiel).subscribe((res: Result) => {
                    if (res.codeErreur === TypeCodeErreur.NO_ERROR) {
                        //Si aucune erreur n'est renvoyée alors on affiche un message de succès
                        this.toastrService.success(this.translateService.instant('global.success.enregistrement'));
                        //On met à jour la liste affichée
                        this.list.refresh();
                    } else {
                        //Si une erreur renvoyée alors on affiche un message d'erreur
                        this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
                    }
                });
            }
        })
    }

    /**
     * Méthode appelée lors de la sélection d'un référentiel
     *
     * @param referentielSelected Référentiel sélectionné
     */
    onSelect(referentielSelected) {
        //Ouverture de la popin pour afficher les informations du référentiel, pour les modifier si besoin
        const dialog = this.matDialog.open(ModifyReferentielComponent, {
            data: {
                referentiel: _.cloneDeep(referentielSelected)
            }
        });

        //Quand la boîte de dialogue se ferme on met à jour le référentiel ou alors on le supprime
        dialog.afterClosed().subscribe((data: {isSave: boolean, referentiel: Referentiel}) => {
            if (!!data) {
                //Si lors de la fermeture de la boîte de dialogue la variable isSave est égale à true alors c'est que l'utilisateur sauvegarde le référentiel, sinon l'utilisateur le supprime
                if (data.isSave) {
                    this.referentielService.saveReferentiel(data.referentiel).subscribe((res: Result) => {
                        //Rafraichissement de la liste
                        this.list.refresh();

                        //Si le résultat renvoyé ne contient pas d'erreur
                        if (res.codeErreur === TypeCodeErreur.NO_ERROR) {
                            this.toastrService.success(this.translateService.instant('global.success.enregistrement'));
                        } else {
                            //Si le résultat renvoyé contient une erreur, alors on affiche un message d'erreur
                            this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
                        }
                    });
                } else {
                    //Suppression du référentiel
                    this.referentielService.deleteReferentiel(data.referentiel).subscribe((res: Result) => {
                        if (res.codeErreur === TypeCodeErreur.NO_ERROR) {
                            //Si le résultat renvoyé ne contient pas d'erreur alors on affiche un message de succès pour la suppression du référentiel
                            this.toastrService.success(this.translateService.instant('global.success.suppression'));
                            //Mise à jour de la liste affichée
                            this.list.refresh();
                        } else {
                            //Sinon on affiche un message d'erreur
                            this.toastrService.error(this.translateService.instant('global.errors.suppression'));
                        }
                    });
                }
            }
        })
    }

    /**
     * Destruction du composant
     */
    ngOnDestroy() {
        //On appelle unsubscribe sur chaque souscription, pour éviter les fuites de mémoire
        this.souscriptions.forEach(subscription => subscription.unsubscribe());
    }
}
