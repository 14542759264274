import { Component } from "@angular/core";

/**
 * Onglet circuits de validation
 *
 * @author Guillaume TRAVOT
 * @date 26/04/2024
 */
@Component({
    host: {'data-test-id': 'circuits-validation'},
    templateUrl: './circuits-validation.component.html'
})
export class CircuitsValidationComponent {}
