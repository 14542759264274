import {CompteUsage} from "./compte-usage";
import {EntityAttribute} from "@share/component/autocomplete/options/entity-attribute";
import {UserPipeTransform} from "@share/pipe/user.pipe";
import {TranslateService} from "@ngx-translate/core";

/**
 * Usages d'un compte comptable
 * Utilisateur associé à un compte comptable
 *
 * @author Laurent Convert
 * @date 24/09/2024
 */
export class CompteUsageUser implements CompteUsage {
	nom: string;
	prenom: string;
	matricule: string;
	actif: boolean;

	/**
	 * Constructeur
	 *
	 * @param dto Source
	 */
	constructor(dto: any) {
		Object.assign(this,dto);
	}

	/**
	 * Récupération de l'identifiant de l'objet
	 */
	getKey(): string {
		return this.matricule;
	}

	/**
	 * Affichage d'un élément
	 */
	displayItem(): string {
		//Affichage sans le matricule lequel sera affiché sous forme de tag
		return UserPipeTransform(   {nom: this.nom,prenom: this.prenom});
	}

	/**
	 * Liste les propriétés de l'objet pour lesquelles il faut afficher un badge 'clé:valeur'
	 *
	 * @param translateService Service de traduction
	 */
	listDisplayedAttributes(translateService: TranslateService): Array<EntityAttribute> {
		return [{
			clef: "filter.matricule",
			valeur: this.matricule
		},{
			clef: "filter.actif",
			valeur: translateService.instant(this.actif ? 'global.oui' : 'global.non')
		}];
	}
}