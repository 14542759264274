import {NgModule} from '@angular/core';
import {ShareModule} from "../../share/share.module";
import {DelegationsListComponent} from "./liste/delegations-list.component";
import {DelegationsListItemComponent} from "./liste/delegations-list-item.component";
import {DelegationsService} from "./delegations.service";
import {DelegationCreationComponent} from "./liste/creation/delegation-creation.component";
import {DelegationModule} from "@share/component/delegation/delegation.module";

@NgModule({
    imports: [
        ShareModule,
        DelegationModule
    ],
    declarations: [
        DelegationsListComponent,
        DelegationsListItemComponent,
        DelegationCreationComponent],
    providers: [
        DelegationsService
    ]
})
export class DelegationsModule {

}
