<h1 mat-dialog-title>
    <span [translate]="title"></span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content *ngIf="habilitation">
    <form #form="ngForm">
        <mat-tab-group #matTabGroup dynamicHeight>
            <mat-tab [label]="('admin.entreprise.utilisateurs.detail.generalites.habilitations.addHabilitationPopin.'+ (data.isDelegation ? 'delegation' : 'role')) | translate">
                <div class="row">
                    <custom-input
                            ngDefaultControl
                            id="role"
                            name="role"
                            customType="autocomplete"
                            [(ngModel)]="habilitation.role"
                            libelle="admin.entreprise.utilisateurs.detail.generalites.habilitations.addHabilitationPopin.role"
                            autocompleteType="role"
                            optionValue="idRole"
                            optionDisplay="libelle"
                            [idName]="'idRole'"
                            (onChange)="onRoleChange()"
                            [autocompleteFilter]="roleAutocompleteFilters"
                            [required]="true"
                            [readonly]="habilitation.used || data.isHistory || data.isDelegation">
                    </custom-input>
                    <custom-input
                            *ngIf="!isRoleCollab && !data.isDelegation"
                            ngDefaultControl
                            id="priorite"
                            name="priorite"
                            customType="select"
                            [(ngModel)]="habilitation.priorite"
                            [libelle]="'admin.entreprise.utilisateurs.detail.generalites.habilitations.addHabilitationPopin.priorite'"
                            [selectOptions]="listeTypePriorite"
                            optionValue="code"
                            optionDisplay="libelle"
                            [postTooltip]="(listeTypePriorite.length == 1 ? 'admin.entreprise.utilisateurs.detail.generalites.habilitations.addHabilitationPopin.prioTitulaireTooltip' : '') | translate"
                            [readonly]="habilitation.used || data.isHistory || listeTypePriorite.length == 1">
                    </custom-input>
                </div>
                <div class="row" *ngIf="isRoleBudgetaire()">
                    <label class="col-md-2 required" [class.has-error]="habilitation.listeEnveloppes?.length < 1">
                        <span [translate]="'admin.entreprise.utilisateurs.detail.generalites.habilitations.addHabilitationPopin.enveloppes'"></span>
                    </label>
                    <button mat-button color="primary" (click)="matTabGroup.selectedIndex = 1" style="margin-bottom: 0.5rem">
                        <span [translate]="'admin.entreprise.utilisateurs.detail.generalites.habilitations.addHabilitationPopin.nbEnveloppes.' + (habilitation.listeEnveloppes?.length > 1 ? 'plural' : 'one')" [translateParams]="{ nb: habilitation.idLienRoleUser ? listeEnveloppes?.data?.nbObjetsTotal || 0 : listeEnveloppesCreation?.data?.nbObjetsTotal || 0 }"></span>
                        <mat-icon class="c-danger" *ngIf="!habilitation.listeEnveloppes?.length">error_outline</mat-icon>
                    </button>
                </div>
                <div class="row" *ngIf="!isRoleCollab">
                    <custom-input
                            ngDefaultControl
                            id="population"
                            name="population"
                            customType="autocomplete"
                            [(ngModel)]="habilitation.population"
                            libelle="admin.entreprise.utilisateurs.detail.generalites.habilitations.addHabilitationPopin.population"
                            autocompleteType="population"
                            optionValue="idPopulation"
                            optionDisplay="libelle"
                            [displayIfNull]="'admin.entreprise.utilisateurs.detail.generalites.habilitations.tousLesCollabs' | translate"
                            (onChange)="onPopulationChange()"
                            [required]="isPopulationRequired()"
                            [readonly]="data.isHistory || data.isDelegation">
                    </custom-input>
                </div>
                <div class="row" *ngIf="!isRoleCollab">
                    <custom-input
                            ngDefaultControl
                            id="dateDebut"
                            name="dateDebut"
                            customType="date"
                            [ngModel]="habilitation.dateDebut"
                            [(customModel)]="habilitation.dateDebut"
                            libelle="admin.entreprise.utilisateurs.detail.generalites.habilitations.addHabilitationPopin.dateDebut"
                            [dateMin]="today"
                            [required]="!isRoleCollab"
                            [readonly]="habilitation.used || data.isHistory || data.isDelegation">
                    </custom-input>
                    <custom-input
                            ngDefaultControl
                            id="dateFin"
                            name="dateFin"
                            customType="date"
                            [ngModel]="habilitation.dateFin"
                            [(customModel)]="habilitation.dateFin"
                            libelle="admin.entreprise.utilisateurs.detail.generalites.habilitations.addHabilitationPopin.dateFin"
                            [dateMin]="habilitation.dateDebut"
                            [readonly]="data.isHistory">
                    </custom-input>
                </div>
                <!-- Délégué, affiché uniquement pour la délégation -->
                <row-data
                        *ngIf="data.isDelegation"
                        class="raw-disabled"
                        [dataGauche]="{
                            traduction: 'admin.entreprise.utilisateurs.detail.generalites.habilitations.delegue',
                            valeur:habilitation.user | user}"
                ></row-data>
            </mat-tab>
            <mat-tab [label]="'admin.entreprise.utilisateurs.detail.generalites.habilitations.addHabilitationPopin.enveloppes' | translate" *ngIf="isRoleBudgetaire()">
                <list-view *ngIf="listeEnveloppes" [liste]="listeEnveloppes"></list-view>
                <list-view #listeEnveloppesCreationComponent *ngIf="listeEnveloppesCreation" [liste]="listeEnveloppesCreation"></list-view>
            </mat-tab>
        </mat-tab-group>
    </form>
</div>
<div mat-dialog-actions *ngIf="!data.isHistory" [ngStyle]="matTabGroup?.selectedIndex === 1 && { 'justify-content': 'space-between' }">
    <div *ngIf="matTabGroup?.selectedIndex === 1 && this.data.canChangeEnveloppe">
        <button mat-stroked-button color="primary" *ngIf="isSelectAllDisplayed()" (click)="onUnselectAll()" [disabled]="isUnselectAllDisabled()">
            <span [translate]="'global.actions.toutDeselectionner'"></span></button>
        <button mat-stroked-button color="primary" *ngIf="isSelectAllDisplayed()" (click)="onSelectAll()" [disabled]="isSelectAllDisabled()">
            <span [translate]="'global.actions.toutSelectionner'"></span></button>
        <button mat-stroked-button color="warn" *ngIf="isRemoveSelectionDisplayed()" (click)="onRemoveSelected()">
            <span [translate]="'global.actions.retirerSelection'"></span></button>
    </div>
    <div>
        <button mat-stroked-button color="warn" *ngIf="!data.isCreation && !habilitation.used && !isRoleCollab" (click)="onDelete()" [disabled]="isPending">
            <span [translate]="'global.actions.supprimer'"></span>
        </button>
        <button mat-flat-button color="primary" (click)="onSave()" [disabled]="isFormInvalid() || isPending"><span [translate]="'global.actions.enregistrer'"></span></button>
    </div>
</div>
