import {ActivatedRouteSnapshot,CanActivate,Router,RouterStateSnapshot,UrlTree} from "@angular/router";
import {Observable} from "rxjs";
import {LoginService} from "@share/login/login.service";
import {Session} from "@domain/security/session";
import {TypeProfil} from "@domain/user/user";
import {Injectable} from "@angular/core";
import {findFirstPathAdminAutorisee} from "./role-admin-helpers";

/**
 * Gardien de l'accès au dashboard : accès interdit si c'est un admin ou sous-admin non "connecté en tant que"
 *
 * @author Laurent Convert
 * @date 16/09/2024
 */
@Injectable()
export class DashboardGuardProvider implements CanActivate {

	/**
	 * Constructeur
	 *
	 * @param loginService Service de gestion de la session et du login
	 * @param router Routeur Angular
	 */
	constructor(private loginService: LoginService,private router: Router) {
	}

	/**
	 * Activation du composant
	 */
	canActivate(route: ActivatedRouteSnapshot,state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		//Initialisation
		let session: Session;

		//Récupération de la session
		session = this.loginService.getSession();

		//Vérification que l'utilisateur est authentifié
		if (session?.isLogged) {
			//Vérification du profil de l'utilisateur (on n'utilise pas directement les propriétés isAdmin et isSousAdmin pour gérer le cas du "Connecté en tant que")
			if ([TypeProfil.SOUS_ADMINISTRATEUR,TypeProfil.ADMINISTRATEUR].includes(session?.user?.fonction)) {
				//L'utilisateur est admin / sous-admin : il n'a pas accès au dashboard, renvoi de la route vers la page d'accueil de l'admin
				return this.router.parseUrl(findFirstPathAdminAutorisee(this.router.config,session.isAdmin,session.user));
			} else {
				//L'utilisateur n'est pas admin / sous-admin : il a bien accès au dashboard
				return true;
			}
		} else {
			//L'utilisateur n'est pas authentifié : pas d'accès
			return false;
		}
	}
}