import {Component,OnDestroy,OnInit} from "@angular/core";
import {PageHeaderItem} from "@share/component/page-header/page-header";
import {TranslateService} from "@ngx-translate/core";
import {ListView} from "@domain/common/list-view";
import {FloatingButtonAction,TypeAction} from "@share/component/floating-button/floating-button";
import {LangueItemComponent} from "@components/admin/bibliotheque/internationalisation/langue-item/langue-item.component";
import {Langue} from "@domain/admin/bibliotheque/internationalisation/langue";
import {ActivatedRoute,Router} from "@angular/router";
import {BehaviorSubject,Subscription} from "rxjs";
import {IComponentWithRoutedTabs} from "@domain/admin/recherche/component-with-routed-tabs";
import {Session} from "@domain/security/session";
import {Store} from "@ngrx/store";
import {AppState} from "@domain/appstate";
import {filterFirstNotNull} from "@share/utils/rxjs-custom-operator";
import {isRouteAllowed} from "@core/security/role-admin-helpers";

/**
 * Enum pour les noms des différents onglets de la page Langues et de la page d'informations d'une langue
 */
export enum Onglets {
    LANGUES = "Langues",
    MESSAGES = "Messages",
    REFERENTIELS = "Referentiels"
}

/**
 * Composant concernant l'internationalisation
 */
@Component({
    host: {'data-test-id': 'internationalisation'},
    templateUrl: './internationalisation.component.html'
})
export class InternationalisationComponent implements OnInit, OnDestroy, IComponentWithRoutedTabs {
    /** Liste statique des onglets */
    public static listeOnglets: Array<PageHeaderItem> = [{
        code: Onglets.LANGUES,
        libelle: 'admin.bibliotheque.internationalisation.onglets.langues',
        url: 'Admin/Bibliotheque/Internationalisation/Langues'
    },{
        code: Onglets.MESSAGES,
        libelle: 'admin.bibliotheque.internationalisation.onglets.messages',
        url: 'Admin/Bibliotheque/Internationalisation/Messages'
    },{
        code: Onglets.REFERENTIELS,
        libelle: 'admin.bibliotheque.internationalisation.onglets.referentiels',
        url: 'Admin/Bibliotheque/Internationalisation/Referentiels'
    }];

    /** Index de l'onglet courant */
    selectedIndex: number;

    /** Liste des différents onglets disponibles dans le menu "Internationalisation" */
    listeTabItems: Array<PageHeaderItem> = InternationalisationComponent.listeOnglets;

    /** Onglet courant */
    selectedItem: PageHeaderItem = {
        ... InternationalisationComponent.listeOnglets[0],
        selected: true
    };

    /** Liste des actions possibles */
    listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

    /** Référence vers l'enum pour l'utiliser dans le template */
    Onglet = Onglets;

    /** Liste des langues, pour utiliser le composant ListViewComponent */
    liste: ListView<Langue, LangueItemComponent>;

    /** Session */
    private session: Session;

    /** Souscription */
    private subscription: Subscription;

    /** Indique si le header doit être affiché */
    isDisplayed: boolean;

    /**
     * Constructeur
     */
    constructor(
        protected translateService: TranslateService,
        private activatedRoute: ActivatedRoute,
        private store: Store<AppState>,
        public router: Router
    ) { }

    /**
     * Initialisation du composant
     */
    ngOnInit(): void {
        //Récupération de la session
        this.store.select(state => state.session).pipe(filterFirstNotNull()).subscribe((session: Session) => {
            //Stockage de la session
            this.session = session;

            //Filtrage des onglets autorisés
            this.listeTabItems = this.listeTabItems.filter(i => isRouteAllowed(this.router, this.session, i.url));

            this.subscription = this.activatedRoute.url.subscribe(() => {
                //Récupération de la route
                const firstChild = this.activatedRoute.snapshot.firstChild;

                //Extraction du path
                const path = firstChild.url[0].path;

                //On vérifie si on trouve l'onglet demandé via l'url
                const matching = this.listeTabItems.find(i => i.code === path);

                //Si on trouve bien l'onglet correspondant
                if (!!matching) {
                    //on met à jour le composant header
                    setTimeout(() => {
                        this.selectedItem = matching;
                        this.selectedIndex = this.listeTabItems.findIndex(i => i.code === path);
                        this.isDisplayed = true;
                    });
                } else {
                    //Sinon, on redirige sur le premier onglet et on met à jour le composant header
                    setTimeout(() => {
                        this.selectedItem = this.listeTabItems[0];
                        this.selectedIndex = 0;
                        this.isDisplayed = path != 'Langue';
                    });
                }
            });
        });
    }

    /**
     * Méthode appelée lors de la création d'une nouvelle langue
     */
    createLangue() {
        //Navigation vers le formulaire de création d'une langue
        this.router.navigate(['Admin/Bibliotheque/Internationalisation/Langue/0']);
    }

    /**
     * Changement d'onglet
     *
     * @param selectedItem Onglet sélectionné
     */
    onSelectedItemChange(selectedItem: PageHeaderItem): void {
        if (selectedItem?.code == Onglets.LANGUES) {
            //Définition de la liste des actions du bouton en bas à droite de l'écran
            this.listeActions.next([{
                type: TypeAction.PRIMARY,
                icone: 'nio icon-ajouter',
                libelle: 'global.actions.creer',
                doAction: () => this.createLangue(),
            }]);
        } else {
            //Purge de la liste des actions
            this.listeActions.next(null);
        }
        if (!this.selectedItem) {
            this.selectedItem = selectedItem;
        }
        if (selectedItem?.code !== this.selectedItem?.code) {
            this.selectedItem = selectedItem;
            this.router.navigateByUrl(selectedItem.url);
        }
    }

    /**
     * Destruction du composant
     */
    ngOnDestroy() {
        //Résiliation de l'abonnement
        this.subscription.unsubscribe();
    }
}
