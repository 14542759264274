import {ListeAlertes} from "../common/alerte/listeAlertes";
import {ListItem} from "../common/list-view/list-item";
import {User} from "../user/user";
import {Statut} from "../workflow/statut";
import {AbstractObjetWorkflow} from "../workflow/abstract-objet-workflow";
import {TypePortee} from "../workflow/workflow";
import {MapAction} from "../workflow/mapAction";
import {Devise} from "../settings/devise";
import {TranslateService} from "@ngx-translate/core";
import {TypeEntite} from "@domain/typeentite/typeEntite";

/**
 * Modèle représentant une NDF listée
 */
export class ListeNdf extends AbstractObjetWorkflow implements ListItem {
    idNDF: number;
    numeroPiece: string;
	statut: Statut;
	od: number;
	objet: string;
	typeEntite: TypeEntite;
	montantRemboursable: number;
    devise: Devise;
	dateMaj: Date;
    user: User;
	listeAlertes: ListeAlertes;
	listeHistoWorkflow: MapAction;
    omPermanent: number;
    budgetOk: boolean;

    constructor(dto: any) {
        super();
        if (dto) {
            this.idNDF = dto.idNDF
            this.numeroPiece = dto.numeroPiece;
            this.statut = dto.statut as Statut;
            this.od = dto.od;
            this.objet = dto.objet;
            this.typeEntite = dto.typeEntite;
            this.montantRemboursable = dto.montantRemboursable;
            this.devise = dto.devise;
            if (dto.dateMaj) { this.dateMaj = new Date(dto.dateMaj) }
            this.user = dto.user as User;
            this.listeAlertes = dto.listeAlertes as ListeAlertes;
            if (dto.listeHistoWorkflow) { this.listeHistoWorkflow = new MapAction(dto.listeHistoWorkflow); }
            this.omPermanent = dto.omPermanent;
            this.masque = dto.masque;
            this.budgetOk = dto.budgetOk;
        }
    }

    /** Indicateur d'affichage de l'élément */
    isDisplayed?: boolean;

    /** Indicateur de sélection de l'élément */
    isSelected?: boolean;

    /**
     * Récupération de l'identifiant de l'objet
     * @returns l'identifiant de l'objet
     */
    getKey(): number {
        return this.idNDF;
    }

    getId(): number {
        return this.idNDF;
    }

    getPortee(): TypePortee {
        return TypePortee.NF;
    }

    getListeAlertes(): ListeAlertes {
        return this.listeAlertes;
    }

    getMapAction(): MapAction {
        return this.listeHistoWorkflow;
    }

    setListeAlertes(listeAlertes: ListeAlertes): void {
        this.listeAlertes = listeAlertes;
    }

    /**
     * Getter pour le collaborateur
     */
    getCollaborateur(): String {
        if (!this.user) { return null; }
        return (this.user.nom ? this.user.nom + ' ' : '')
                + (this.user.prenom ? this.user.prenom : '');
    }

    /**
     * Getter pour la liste de rôles format texte
     */
    getRoles(): string {
        return this.getMapAction()?.roles?.map(r => r.libelle).join("\n");
    }

    /**
     * Getter pour le nombre de rôles
     */
    getRolesCount(): number {
        return this.getMapAction()?.roles?.length;
    }

    /**
     * Getter pour l'ID Collab
     */
    getIdCollab(): number {
        return this.user?.idUser;
    }

    /**
     * Getter pour l'intitulé de l'objet
     * @param translateService service de traduction
     */
    getLibelleObjet(translateService: TranslateService): string {
        return '' + (this.numeroPiece || translateService.instant('ndf.liste.numeroNonDefini')) + ' - ' + (this.objet || translateService.instant('ndf.liste.objetNonDefini'));
    }

    /**
     * Getter pour la validité de l'objet
     */
    isValid(): boolean {
        //Hardcodé en attendant l'implémentation des données calculées
        return true;
    }

    /**
     * Le budget de l'objet est-il conforme, afin d'autoriser une action workflow sur celui-ci
     */
    isBudgetOk(): boolean {
        return this.budgetOk;
    }
}
