import {Component} from "@angular/core";
import {IComponentWithRoutedTabs} from "@domain/admin/recherche/component-with-routed-tabs";
import {PageHeaderItem} from "@share/component/page-header/page-header";

/**
 * Onglets
 */
export enum Onglets {
    LIENS = 'Liens'
}

/**
 * Sous-menu personnalisation
 *
 * @author Guillaume TRAVOT
 * @date 26/06/2024
 */
@Component({
    host: {'data-test-id': 'personnalisation'},
    templateUrl: './personnalisation.component.html'
})
export class PersonnalisationComponent implements IComponentWithRoutedTabs {
    /** Liste statique des onglets */
    public static listeOnglets: Array<PageHeaderItem> = [
        {
            code: Onglets.LIENS,
            libelle: 'menu.admin.personnalisation',
            url: 'Admin/Parametre/Personnalisation/Liens'
        }
    ];
}
