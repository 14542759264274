import {Component} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {RegionItem} from "@domain/admin/bibliotheque/geographie/regionItem";
import {GeographieService} from "@services/admin/geographie/geographie.service";

/**
 * Composant d'une région dans la liste des régions
 *
 * @author Angeline Ha
 * @date 09/11/2023
 */
@Component({
    host: {'data-test-id': 'region-item'},
    selector: 'region-item',
    templateUrl: './region-item.component.html'
})
export class RegionItemComponent extends ListViewItem<RegionItem> {

    /**
     * Constructeur
     *
     * @param router Router
     * @param route  Route active
     * @param geographieService Service de géographie
     */
    constructor(private router: Router,
                private route: ActivatedRoute,
                private geographieService: GeographieService) {
        super();
    }

    /**
     * Affichage du détail d'une région
     */
    goToDetailRegion() {
        //Indique au service géographie qu'on affiche un détail
        this.geographieService.isShowDetail = true;

        //Navigue vers le détail
        this.router.navigate([`Admin/Bibliotheque/Geographie/Region/${this.data.idRegion}`]);
    }
}
