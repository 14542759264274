import {Component,OnInit} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {ZoneItem} from "@domain/admin/bibliotheque/geographie/zoneItem";
import {Router} from "@angular/router";
import {GeographieService} from "@services/admin/geographie/geographie.service";

/**
 * Composant d'affichage d'une zone dans une liste de zones
 *
 * @author Angeline Ha
 * @date 07/11/2023
 */
@Component({
	host: {'data-test-id': 'zone-item'},
	selector: 'zone-item',
	templateUrl: './zone-item.component.html'
})
export class ZoneItemComponent extends ListViewItem<ZoneItem> implements OnInit {

	/** Nombre de pays liés à la zone */
	nbPays: number;

	/**
	 * Constructeur
	 *
	 * @param router			Router
	 * @param geographieService	Service géographie
	 */
	constructor(private router: Router,
				private geographieService: GeographieService) {
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit(): void {
		//Récupération du nombre de pays liés à la zone
		this.geographieService.getNombrePaysByZone(this.data.idZone).subscribe(result => {
			this.nbPays = result.data.nombrePays;
		})
	}

	/**
	 * Affichage du détail d'un territoire
	 */
	goToZoneDetail() {
		//Indique au service géographie qu'on affiche un détail
		this.geographieService.isShowDetail = true;

		//Navigue vers le détail
		this.router.navigate([`Admin/Bibliotheque/Geographie/Zone/${this.data.idZone}`]);
	}

}
