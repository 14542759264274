import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Actions,Effect,ofType} from '@ngrx/effects';
import {Observable} from 'rxjs';
import {map,take} from 'rxjs/operators';
import {Store} from '@ngrx/store';

import * as sessionActions from '@reducers/session';
import {PROFIL_CHANGED,SESSION_EXPIRED,SESSION_FULFILLED} from '@reducers/session';
import {Action} from '@domain/action';
import {Session} from '@domain/security/session';
import {environment} from '@environments/environment';
import {AppState} from '@domain/appstate';
import {Result} from '@domain/common/http/result';
import {TypeProfil,User} from '@domain/user/user';
import * as _ from "lodash";
import * as settingsActions from "@reducers/settings";
import {WFCreationPossible} from '@domain/workflow/wf-creation-possible';
import {ActivatedRoute,Router} from "@angular/router";
import {LayoutService} from './layout.service';
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {LoginService} from "@share/login/login.service";
import {MatDialog,MatDialogRef} from "@angular/material/dialog";
import {PleaseWaitDialogComponent} from "@share/component/please-wait/please-wait-dialog.component";
import {findFirstPathAdminAutorisee} from "@core/security/role-admin-helpers";
import {LoginComponent} from "../login/login.component";

@Injectable()
export class LayoutEffects {
	/**
	 * Constructeur
	 */
	constructor(private actions$: Actions,
				private http: HttpClient,
				private store: Store<AppState>,
				private router: Router,
				private route: ActivatedRoute,
				private toastrService: ToastrService,
				private translateService: TranslateService,
				private layoutService: LayoutService,
				private loginService: LoginService,
				private dialogRef: MatDialog) {

	}

	/**
	 * Chargement de l'utilisateur
	 */
	@Effect({dispatch: false})
	loadUser$: Observable<Action<any>> = this.actions$.pipe(
		ofType(SESSION_FULFILLED,PROFIL_CHANGED),
		map((action: Action<any>) => {
			const session = (action.payload?.session || action.payload) as Session;
			const matDialogRef: MatDialogRef<PleaseWaitDialogComponent> = action.payload.matDialogRef;

			//Vérification de la connexion
			if (session.isLogged) {
				//Chargement de l'utilisateur
				this.http.post<Result>(`${environment.baseUrl}/controller/Dashboard/getUser`,null).pipe(
					take(1),
					map(result => result?.data)
				).subscribe({
					next: data => {
						let user: any;
						let creationsPossibles: any;
						let navigate: Promise<boolean>;

						//Lecture de l'utilisateur
						user = data?.user;

						//Lecture des créations possibles
						creationsPossibles = new WFCreationPossible(data?.creationsPossibles);

						//Mise à jour de l'utilisateur
						this.store.dispatch({
							type: sessionActions.UPDATE_USER,
							payload: <User>({
								...user,
								fonction: user.fonction,
								fonctionCollaborateur: user.listeFonction.indexOf(TypeProfil.COLLABORATEUR) != -1,
								fonctionAssistant: user.listeFonction.indexOf(TypeProfil.ASSISTANT) != -1,
								fonctionResponsable: user.listeFonction.indexOf(TypeProfil.RESPONSABLE) != -1,
								fonctionComptable: user.listeFonction.indexOf(TypeProfil.COMPTABLE) != -1,
								fonctionFournisseur: user.listeFonction.indexOf(TypeProfil.FOURNISSEUR) != -1,
								fonctionSousAdministrateur: user.listeFonction.indexOf(TypeProfil.SOUS_ADMINISTRATEUR) != -1,
								specificites: user.roles?.specificite ? _.uniq(user.roles.specificite.trim().split(/\s+/)) : [],
								creationsPossibles: creationsPossibles,
								listeLienRoleUsers: data.listeLienRoleUsers
							})
						});

						//Mise à jour du logo
						this.store.dispatch({
							type: sessionActions.UPDATE_LOGO_URL,
							payload: data?.logoUrl
						});

						//Chargement du paramétrage
						this.store.dispatch({
							type: settingsActions.LOAD_SETTINGS,
							payload: 'Global'
						});

						//Vérification du profil de l'utilisateur
						if (user.fonction == TypeProfil.SOUS_ADMINISTRATEUR) {
							//Définition de l'URL de la frame à charger
							this.layoutService.setFrameLocation('controller/Administration');
						} else if (!!this.layoutService.getFrameLocation()) {
							//Réinitialisation de la frame
							this.layoutService.resetFrameLocation();
						}

						//Vérification du mdp périmé
						if (session.isPasswordExpired) {
							//On retourne au login
							navigate = this.router.navigate(['Login']);
						} else if (session.redirect) {//Vérification de la présence d'une redirection post-login
							//Parse de l'url
							let urlTree = this.router.parseUrl(decodeURIComponent(session.redirect));

							//Navigation vers la cible de la redirection
							navigate = this.router.navigateByUrl(urlTree,{replaceUrl: true});
						} else if (session.isAdmin || user.fonction === TypeProfil.SOUS_ADMINISTRATEUR) {
							//Navigation vers la première page d'administration autorisée pour l'utilisateur
							navigate = this.router.navigate([findFirstPathAdminAutorisee(this.router.config,session.isAdmin,user)]);
						} else {
							//Quand on a fini de tout recharger, on va sur le Dashboard
							navigate = this.router.navigate(['Dashboard']);
						}

						//Si une popup d'attente a été fournie
						if (matDialogRef) {
							//Fermeture de la popup d'attente une fois la nouvelle route chargée
							navigate.then(success => matDialogRef.close());
						}

						//Suppression de la redirection le cas échéant
						this.store.dispatch({
							type: sessionActions.SESSION_RESET_REDIRECT
						});
					}
				});
			} else {
				//Retrait de l'utilisateur
				this.store.dispatch({
					type: sessionActions.UPDATE_USER,
					payload: null
				});

				//Réinitialisation de la frame
				this.layoutService.resetFrameLocation();

				//Si une popup d'attente a été fournie
				if (matDialogRef) {
					//Fermeture de la popup d'attente
					matDialogRef.close();
				}
			}

			//Retour de l'action
			return action;
		})
	);

	/**
	 * Gestion du layout suite à l'expiration de la session
	 */
	@Effect({dispatch: false})
	sessionExpired: Observable<Action<void>> = this.actions$.pipe(
		ofType(SESSION_EXPIRED),
		map((action: Action<void>) => {
			//Fermeture de toutes les popin potentiellement ouvertes
			this.dialogRef?.closeAll();

			//Vérification du composant actif pour la route courante pour déterminer si on est déjà sur la page de login (il y a plusieurs routes possibles pour le composant)
			const isOnLogin = this.route.firstChild.routeConfig.component == LoginComponent;

			//Reset de la session
			this.store.dispatch({
				type: sessionActions.SESSION_RESET,
				payload: {
					redirect: !isOnLogin ? this.router.url : null,
					//Ces 2 champs sont utilisés dans LoginService pour récupérer les infos de la session précédente, afin gérer la redirection en cas de changement d'utilisateur
					isAdmin: this.loginService.getSession()?.isAdmin,
					user: {login: this.loginService.getSession()?.user?.login}
				} as Session
			});

			//On vérifie que l'on n'est pas déjà sur la page de login
			if (!isOnLogin) {
				//Redirection vers la page de login
				this.router.navigate(['Login']);

				//Affichage d'un message à l'utilisateur
				this.toastrService.error(this.translateService.instant('global.errors.sessionExpiree'));
			}

			//Retour de l'action
			return action;
		})
	);
}