import {Component,OnInit} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {EntityAttribute} from "@share/component/autocomplete/options/entity-attribute";
import {TranslateService} from "@ngx-translate/core";
import {CompteUsage} from "@domain/admin/comptabilite/compte-usage/compte-usage";

/**
 * Composant d'affichage de la liste des usages d'un compte comptable
 *
 * @author Laurent Convert
 * @date 24/09/2024
 */
@Component({
	host: {'data-test-id': 'compte-comptable-usage-list-item'},
	selector: 'compte-comptable-usage-list-item',
	templateUrl: './compte-comptable-usage-list-item.component.html'
})
export class CompteComptableUsageListItemComponent<T extends CompteUsage> extends ListViewItem<T> implements OnInit {
	/** Liste des propriétés pour lesquelles il faut afficher un badge 'clé:valeur' **/
	attributes: Array<EntityAttribute>;

	/**
	 * Constructeur
	 *
	 * @param translateService Service de traduction
	 */
	constructor(private translateService: TranslateService) {
		super();
	}

	/**
	 * Handler de l'initialisation du composant
	 */
	ngOnInit() {
		this.attributes = this.data.listDisplayedAttributes ? this.data.listDisplayedAttributes(this.translateService) : null;
	}

}