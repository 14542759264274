import {Component,OnDestroy,OnInit} from "@angular/core";
import {PageHeaderItem} from "@share/component/page-header/page-header";
import {Store} from "@ngrx/store";
import {AppState} from "@domain/appstate";
import {Session} from "@domain/security/session";
import {ActivatedRoute,Router} from "@angular/router";
import {isRouteAllowed} from "@core/security/role-admin-helpers";
import {filterFirstNotNull} from "@share/utils/rxjs-custom-operator";
import {IComponentWithRoutedTabs} from "@domain/admin/recherche/component-with-routed-tabs";
import {Subscription} from "rxjs";

/**
 * Enum pour les noms des différents onglets de la page
 */
export enum Onglets {
    LISTE_STATUTS = "ListeStatuts",
    LISTE_ETATS = "ListeEtats"
}

/**
 * Sous-menu statuts
 *
 * @author Guillaume TRAVOT
 * @date 26/04/2024
 */
@Component({
    host: {'data-test-id': 'wf-statuts'},
    templateUrl: './wf-statuts.component.html'
})
export class WfStatutsComponent implements OnInit, OnDestroy, IComponentWithRoutedTabs {
    /** Liste statique des onglets */
    public static listeOnglets: Array<PageHeaderItem> = [
        {
            code: Onglets.LISTE_STATUTS,
            libelle: 'admin.workflow.statuts.onglets.statuts',
            url: 'Admin/Workflow/Statuts/ListeStatuts'
        }, {
            code: Onglets.LISTE_ETATS,
            libelle: 'admin.workflow.statuts.onglets.etats',
            url: 'Admin/Workflow/Statuts/ListeEtats'
        }
    ];

    /** Liste des différents onglets disponibles */
    listeTabItems: Array<PageHeaderItem> = WfStatutsComponent.listeOnglets;

    /** Onglet courant */
    selectedItem: PageHeaderItem;

    /** Référence vers l'enum des onglets pour l'utiliser dans le template */
    Onglet = Onglets;

    /** Index de l'onglet courant */
    selectedIndex: number;

    /** Indique si le header doit être affiché */
    isDisplayed: boolean;

    /** Session */
    private session: Session;

    /** Souscription */
    private subscription: Subscription;

    constructor(
        private router: Router,
        private store: Store<AppState>,
        private activatedRoute: ActivatedRoute,
    ) { }

    /**
     * Initialisation du composant
     */
    ngOnInit(): void {
        //Récupération de la session
        this.store.select(state => state.session).pipe(filterFirstNotNull()).subscribe((session: Session) => {
            //Stockage de la session
            this.session = session;

            //Filtrage des onglets autorisés
            this.listeTabItems = this.listeTabItems.filter(i => isRouteAllowed(this.router, this.session, i.url));

            this.subscription = this.activatedRoute.url.subscribe(() => {
                //Récupération de la route
                const firstChild = this.activatedRoute.snapshot.firstChild;

                //Extraction du path
                const path = firstChild.url[0].path;

                //On vérifie si on trouve l'onglet demandé via l'url
                const matching = this.listeTabItems.find(i => i.code === path);

                //Si on trouve bien l'onglet correspondant
                if (!!matching) {
                    //on met à jour le composant header
                    setTimeout(() => {
                        this.selectedItem = matching;
                        this.selectedIndex = this.listeTabItems.findIndex(i => i.code === path);
                        this.isDisplayed = true;
                    });
                } else {
                    //Sinon, on redirige sur le premier onglet et on met à jour le composant header
                    setTimeout(() => {
                        this.selectedItem = this.listeTabItems[0];
                        this.selectedIndex = 0;
                        this.isDisplayed = true;
                    });
                }
            });
        });
    }

    /**
     * Changement d'onglet
     *
     * @param selectedItem Onglet sélectionné
     */
    onSelectedItemChange(selectedItem: PageHeaderItem): void {
        if (!this.selectedItem) {
            this.selectedItem = selectedItem;
        }
        if (selectedItem?.code !== this.selectedItem?.code) {
            this.selectedItem = selectedItem;
            this.router.navigateByUrl(selectedItem.url);
        }
    }

    /**
     * Destruction du composant
     */
    ngOnDestroy() {
        //Résiliation de l'abonnement
        this.subscription.unsubscribe();
    }
}
