<div class="lvi-content">
    <div class="avatar">
        <i class="material-icons-outlined">chat_bubble_outline</i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a class="label" (click)="goToDetail()">{{ data.libelle }}</a>
        </div>
        <ul class="lvi-attrs">
            <li><strong [translate]="'workflow.motif.detail.modules'"></strong>{{ nombreModulesTrad }}</li>
            <li><strong [translate]="'workflow.motif.detail.profils'"></strong>{{ nombreProfilsTrad }}
            <mat-icon class="m-l-10 material-icons-outlined" [matTooltip]="profilsTrad">info</mat-icon></li>
            <li><strong [translate]="'workflow.motif.detail.actions'"></strong>{{ nombreActionsTrad }}
                <mat-icon class="m-l-10 material-icons-outlined" [matTooltip]="actionsTrad">info</mat-icon></li>
        </ul>
    </div>
    <div class="lvi-actions">
        <button mat-icon-button (click)="deleteMotif()" [matTooltip]="'global.actions.supprimer' | translate">
            <i class="nio icon-suppression"></i>
        </button>
    </div>
</div>