import {MenuItem,Scope} from '@domain/common/menu/menu-item';
import {Session} from '@domain/security/session';
import {TypeProfil} from '@domain/user/user';
import {TypePortee} from '@domain/workflow/workflow';
import * as sessionActions from './reducers/session';
import * as searchActions from './reducers/search';
import {TypeRoleOption} from "@domain/role/typeRoleOption.enum";
import {isRouteAllowed} from "@core/security/role-admin-helpers";

/**
 * Clé de traduction du bouton de recherche
 */
export const RECHERCHE_MENU = 'menu.admin.recherche';

/**
 * Structure du menu
 */
export const menu: Array<MenuItem> = [{
	libelle: 'profil.collaborateur',
	icone: 'nio icon-collaborateurs',
	scope: [Scope.PROFIL],
	isSelected: session => session?.user?.fonction == TypeProfil.COLLABORATEUR,
	canSee: (router,session) => session?.user?.fonctionCollaborateur,
	doAction: dispatch => dispatch({
		type: sessionActions.CHANGE_PROFIL,
		payload: TypeProfil.COLLABORATEUR
	})
},{
	libelle: 'profil.assistant',
	icone: 'nio icon-assistant',
	scope: [Scope.PROFIL],
	isSelected: session => session?.user?.fonction == TypeProfil.ASSISTANT,
	canSee: (router,session) => session?.user?.fonctionAssistant,
	doAction: dispatch => dispatch({
		type: sessionActions.CHANGE_PROFIL,
		payload: TypeProfil.ASSISTANT
	})
},{
	libelle: 'profil.responsable',
	icone: 'nio icon-responsable',
	scope: [Scope.PROFIL],
	isSelected: session => session?.user?.fonction == TypeProfil.RESPONSABLE,
	canSee: (router,session) => session?.user?.fonctionResponsable,
	doAction: dispatch => dispatch({
		type: sessionActions.CHANGE_PROFIL,
		payload: TypeProfil.RESPONSABLE
	})
},{
	libelle: 'profil.comptable',
	icone: 'nio icon-comptable',
	scope: [Scope.PROFIL],
	isSelected: session => session?.user?.fonction == TypeProfil.COMPTABLE,
	canSee: (router,session) => session?.user?.fonctionComptable,
	doAction: dispatch => dispatch({
		type: sessionActions.CHANGE_PROFIL,
		payload: TypeProfil.COMPTABLE
	})
},{
	libelle: 'profil.fournisseur',
	icone: 'nio icon-fournisseur',
	scope: [Scope.PROFIL],
	isSelected: session => session?.user?.fonction == TypeProfil.FOURNISSEUR,
	canSee: _ => false,
	doAction: dispatch => dispatch({
		type: sessionActions.CHANGE_PROFIL,
		payload: TypeProfil.FOURNISSEUR
	})
},{
	libelle: 'profil.sousAdministrateur',
	icone: 'nio icon-sous_administrateur',
	scope: [Scope.PROFIL],
	isSelected: session => session?.user?.fonction == TypeProfil.SOUS_ADMINISTRATEUR,
	canSee: (router,session) => session?.user?.fonctionSousAdministrateur,
	doAction: dispatch => dispatch({
		type: sessionActions.CHANGE_PROFIL,
		payload: TypeProfil.SOUS_ADMINISTRATEUR
	})
},{
	libelle: 'menu.missions',
	url: 'ListeOD',
	icone: 'nio icon-ordre_de_mission',
	scope: [Scope.MENU_USER],
	isSelected: (_,typePortee: TypePortee) => typePortee == TypePortee.OD,
	canSee: (router,session,settings) => settings?.odActif && session.user?.fonction !== TypeProfil.SOUS_ADMINISTRATEUR
},{
	libelle: 'menu.missionsPermanentes',
	url: 'ListeOMP',
	icone: 'nio icon-ordre_de_mission_permanent',
	scope: [Scope.MENU_USER],
	isSelected: (_,typePortee: TypePortee) => typePortee == TypePortee.OT,
	canSee: (router,session,settings) => settings?.otActif && session.user?.fonction !== TypeProfil.SOUS_ADMINISTRATEUR
},{
	libelle: 'menu.frais',
	url: 'ListeNDF',
	icone: 'nio icon-note_de_frais',
	scope: [Scope.MENU_USER],
	isSelected: (_,typePortee: TypePortee) => typePortee == TypePortee.NF,
	canSee: (router,session,settings) => settings?.ndfActif && session.user?.fonction !== TypeProfil.SOUS_ADMINISTRATEUR
},{
    libelle: 'menu.relevesFactures',
	url: 'ListeReleve',
	icone: 'nio icon-releve_de_facture',
	scope: [Scope.MENU_USER],
	isSelected: (_,typePortee: TypePortee) => typePortee == TypePortee.FC,
	canSee: (router,session,settings) => settings?.fcActif && [TypeProfil.COMPTABLE].includes(session.user?.fonction)
},{
	libelle: 'menu.factures',
	url: 'ListeFacture',
	icone: 'nio icon-releve_de_facture',
	scope: [Scope.MENU_USER],
	isSelected: (_,typePortee: TypePortee) => typePortee == TypePortee.FC,
	canSee: (router,session,settings) => settings?.fcActif && [TypeProfil.ASSISTANT,TypeProfil.COLLABORATEUR,TypeProfil.FOURNISSEUR,TypeProfil.RESPONSABLE].includes(session.user?.fonction)
},{
	libelle: 'menu.avances',
	url: 'ListeAvance',
	icone: 'nio icon-avance_2',
	scope: [Scope.MENU_USER],
	isSelected: (_,typePortee: TypePortee) => typePortee == TypePortee.AV,
	canSee: (router,session,settings) => settings?.avActif && session.user?.fonction !== TypeProfil.SOUS_ADMINISTRATEUR
},{
	libelle: 'menu.budget',
	url: 'Budget',
	icone: 'nio icon-budget',
	scope: [Scope.MENU_USER],
	isSelected: (_,typePortee: TypePortee) => typePortee == TypePortee.BGT,
	canSee: (router,session: Session,_) => [TypeProfil.RESPONSABLE,TypeProfil.COMPTABLE].includes(session.user?.fonction) && session.user?.specificites.includes(TypeRoleOption.SUIVI_BUDGETAIRE_DETAILLE.toString())
},{
	libelle: 'menu.vehicules',
	url: 'ListeVehicule',
	icone: 'nio icon-voiture',
	scope: [Scope.MENU_USER],
	isSelected: (_,typePortee: TypePortee) => typePortee == TypePortee.VP,
	canSee: (router,session: Session,_) => session.user?.fonction === TypeProfil.RESPONSABLE && session.user?.specificites.includes(TypeRoleOption.TVTS.toString())
},{
	libelle: 'menu.reporting',
	url: 'ListeReport',
	icone: 'nio icon-reporting',
	scope: [Scope.MENU_USER],
	canSee: (router,session: Session,_) => session.user?.fonction !== TypeProfil.SOUS_ADMINISTRATEUR
},{
	libelle: 'menu.lotCpt',
	url: 'ListeLot',
	icone: 'nio icon-comptabilisation',
	scope: [Scope.MENU_USER],
	isSelected: (_,typePortee: TypePortee) => typePortee == TypePortee.CPT,
	canSee: (router,session: Session,_) => session?.user?.fonction == TypeProfil.COMPTABLE
},{
	libelle: 'menu.admin.entreprise',
	icone: 'business',
	scope: [Scope.MENU_ADMIN],
	url: 'Admin/Entreprise',
	canSee: (router,session: Session,_) => isRouteAllowed(router, session, 'Admin/Entreprise'),
	children: [{
		libelle: 'menu.admin.analytique',
		url: 'Admin/Entreprise/Analytique',
		canSee: (router,session: Session,_) => isRouteAllowed(router, session, 'Admin/Entreprise/Analytique')
	},{
		libelle: 'menu.admin.description',
		url: 'Admin/Entreprise/Description',
		canSee: (router,session: Session,_) => isRouteAllowed(router, session, 'Admin/Entreprise/Description')
	},{
		libelle: 'menu.admin.populations',
		url: 'Admin/Entreprise/Populations',
		canSee: (router,session: Session,_) => isRouteAllowed(router, session, 'Admin/Entreprise/Populations')
	},{
		libelle: 'menu.admin.refEntreprise',
		url: 'Admin/Entreprise/Referentiels',
		canSee: (router,session: Session,_) => isRouteAllowed(router, session, 'Admin/Entreprise/Referentiels')
	},{
		libelle: 'menu.admin.utilisateurs',
		url: 'Admin/Entreprise/Utilisateurs',
		canSee: (router,session: Session,_) => isRouteAllowed(router, session, 'Admin/Entreprise/Utilisateurs')
	},{
		libelle: 'menu.admin.vehicules',
		url: 'Admin/Entreprise/Vehicules',
		canSee: (router,session: Session,_) => isRouteAllowed(router, session, 'Admin/Entreprise/Vehicules')
	}]
},{
	libelle: 'menu.admin.comptabilite',
	icone: 'nio icon-comptabilisation',
	scope: [Scope.MENU_ADMIN],
	url: 'Admin/Comptabilite',
	canSee: (router,session: Session,_) => isRouteAllowed(router, session, 'Admin/Comptabilite'),
	children: [{
		libelle: 'menu.admin.comptesBancaires',
		url: 'Admin/Comptabilite/ComptesBancaires',
		canSee: (router,session: Session,_) => isRouteAllowed(router, session, 'Admin/Comptabilite/ComptesBancaires'),
	},{
		libelle: 'menu.admin.exercicesEtPeriodes',
		url: 'Admin/Comptabilite/ExercicesPeriodes',
		canSee: (router,session: Session,_) => isRouteAllowed(router, session, 'Admin/Comptabilite/ExercicesPeriodes')
	},{
		libelle: 'menu.admin.modesDeRemboursement',
		url: 'Admin/Comptabilite/ModeRemboursement',
		canSee: (router,session: Session,_) => isRouteAllowed(router, session, 'Admin/Comptabilite/ModeRemboursement')
	},{
		libelle: 'menu.admin.planComptable',
		url: 'Admin/Comptabilite/PlanComptable',
		canSee: (router,session: Session,_) => isRouteAllowed(router, session, 'Admin/Comptabilite/PlanComptable')
	},{
		libelle: 'menu.admin.lotsComptables',
		url: 'Admin/Comptabilite/LotsComptables',
		canSee: (router,session: Session,_) => isRouteAllowed(router, session, 'Admin/Comptabilite/LotsComptables')
	}]
},{
	libelle: 'menu.admin.bibliotheque',
	icone: 'topic',
	scope: [Scope.MENU_ADMIN],
	url: 'Admin/Bibliotheque',
	canSee: (router,session: Session,_) => isRouteAllowed(router, session, 'Admin/Bibliotheque'),
	children: [{
		libelle: 'menu.admin.devises',
		url: 'Admin/Bibliotheque/Devises',
		canSee: (router,session: Session,_) => isRouteAllowed(router, session, 'Admin/Bibliotheque/Devises')
	},{
		libelle: 'menu.admin.geographie',
		url: 'Admin/Bibliotheque/Geographie',
		canSee: (router,session: Session,_) => isRouteAllowed(router, session, 'Admin/Bibliotheque/Geographie')
	},{
		libelle: 'menu.admin.internationalisation',
        url: 'Admin/Bibliotheque/Internationalisation',
		canSee: (router,session: Session,_) => isRouteAllowed(router, session, 'Admin/Bibliotheque/Internationalisation')
	},{
		libelle: 'menu.admin.notifications',
		url: 'Admin/Bibliotheque/Notification',
		canSee: (router,session: Session,_) => isRouteAllowed(router, session, 'Admin/Bibliotheque/Notification')
	},{
		libelle: 'menu.admin.reporting',
		url: 'Admin/Bibliotheque/Reporting',
		canSee: (router,session: Session,_) => isRouteAllowed(router, session, 'Admin/Bibliotheque/Reporting')
	},{
		libelle: 'menu.admin.typeEntite',
		url: 'Admin/Bibliotheque/TypeEntite',
		canSee: (router,session: Session,_) => isRouteAllowed(router, session, 'Admin/Bibliotheque/TypeEntite')
	},{
		libelle: 'menu.admin.zonesUtilisateur',
		url: 'Admin/Bibliotheque/ZonesUtilisateurs',
		canSee: (router,session: Session,_) => isRouteAllowed(router, session, 'Admin/Bibliotheque/ZonesUtilisateurs')
	}]
},{
	libelle: 'menu.admin.parametres',
	icone: 'build',
	scope: [Scope.MENU_ADMIN],
	url: 'Admin/Parametre',
	canSee: (router,session: Session,_) => isRouteAllowed(router, session, 'Admin/Parametre'),
	children: [{
		libelle: 'menu.admin.api',
		url: 'Admin/Parametre/API',
		canSee: (router,session: Session,_) => isRouteAllowed(router, session, 'Admin/Parametre/API')
	},{
		libelle: 'menu.admin.authentification',
		url: 'Admin/Parametre/Authentification',
		canSee: (router,session: Session,_) => isRouteAllowed(router, session, 'Admin/Parametre/Authentification')
	},{
		libelle: 'menu.admin.dematerialisation',
		url: 'Admin/Parametre/Demat',
		canSee: (router,session: Session,_) => isRouteAllowed(router, session, 'Admin/Parametre/Demat')
	},{
		libelle: 'menu.admin.mobilite',
		url: 'Admin/Parametre/Mobilite',
		canSee: (router,session: Session,_) => isRouteAllowed(router, session, 'Admin/Parametre/Mobilite')
	},{
		libelle: 'menu.admin.ordonnanceur',
		url: 'Admin/Parametre/Ordonnanceur',
		canSee: (router,session: Session,_) => isRouteAllowed(router, session, 'Admin/Parametre/Ordonnanceur')
	},{
		libelle: 'menu.admin.personnalisation',
		url: 'Admin/Parametre/Personnalisation',
		canSee: (router,session: Session,_) => isRouteAllowed(router, session, 'Admin/Parametre/Personnalisation')
	}]
},{
	libelle: 'menu.admin.frais',
	icone: 'nio icon-frais',
	scope: [Scope.MENU_ADMIN],
	url: 'Admin/Frais',
	canSee: (router,session: Session,_) => isRouteAllowed(router, session, 'Admin/Frais'),
	children: [{
		libelle: 'menu.admin.avances',
        url: 'Admin/Frais/Avances',
		canSee: (router,session: Session,_) => isRouteAllowed(router, session, 'Admin/Frais/Avances')
	},{
        libelle: 'menu.admin.factures',
        url: 'Admin/Frais/Factures',
		canSee: (router,session: Session,_) => isRouteAllowed(router, session, 'Admin/Frais/Factures')
	},{
		libelle: 'menu.admin.indemnites',
		url: 'Admin/Frais/Indemnites',
		canSee: (router,session: Session,_) => isRouteAllowed(router, session, 'Admin/Frais/Indemnites')
	},{
		libelle: 'menu.admin.notesDeFrais',
		url: 'Admin/Frais/Ndf',
		canSee: (router,session: Session,_) => isRouteAllowed(router, session, 'Admin/Frais/Ndf')
	},{
		libelle: 'menu.admin.prestation',
		url: 'Admin/Frais/Prestations',
		canSee: (router,session: Session,_) => isRouteAllowed(router, session, 'Admin/Frais/Prestations')
	},{
		libelle: 'menu.admin.refFrais',
		url: 'Admin/Frais/Referentiels',
		canSee: (router,session: Session,_) => isRouteAllowed(router, session, 'Admin/Frais/Referentiels')
	},{
		libelle: 'menu.admin.taxes',
		url: 'Admin/Frais/Taxes',
		canSee: (router,session: Session,_) => isRouteAllowed(router, session, 'Admin/Frais/Taxes')
	}]
},{
	libelle: 'menu.admin.voyage',
	icone: 'map',
	scope: [Scope.MENU_ADMIN],
	url: 'Admin/Voyages',
	canSee: (router,session: Session,_) => isRouteAllowed(router, session, 'Admin/Voyages'),
	children: [{
		libelle: 'menu.admin.fraisDagence',
		url: 'Admin/Voyages/FraisAgence',
		canSee: (router,session: Session,_) => isRouteAllowed(router, session, 'Admin/Voyages/FraisAgence')
	},{
		libelle: 'menu.admin.missions',
        url: 'Admin/Voyages/Missions',
		canSee: (router,session: Session,_) => isRouteAllowed(router, session, 'Admin/Voyages/Missions')
	},{
		libelle: 'menu.admin.refVoyages',
		url: 'Admin/Voyages/Referentiels',
		canSee: (router,session: Session,_) => isRouteAllowed(router, session, 'Admin/Voyages/Referentiels')
	},{
		libelle: 'menu.admin.travelHub',
		url: 'Admin/Voyages/TravelHub',
		canSee: (router,session: Session,_) => isRouteAllowed(router, session, 'Admin/Voyages/TravelHub')
	}]
},{
	libelle: 'menu.admin.workflow',
	icone: 'account_tree',
	scope: [Scope.MENU_ADMIN],
	url: 'Admin/Workflow',
	canSee: (router,session: Session,_) => isRouteAllowed(router, session, 'Admin/Workflow'),
	children: [{
		libelle: 'menu.admin.conditions',
		url: 'Admin/Workflow/Conditions',
		canSee: (router,session: Session,_) => isRouteAllowed(router, session, 'Admin/Workflow/Conditions')
	},{
		libelle: 'menu.admin.construction',
		url: 'Admin/Workflow/Construction',
		canSee: (router,session: Session,_) => isRouteAllowed(router, session, 'Admin/Workflow/Construction')
	},{
		libelle: 'menu.admin.controle',
		url: 'Admin/Workflow/Controle',
		canSee: (router,session: Session,_) => isRouteAllowed(router, session, 'Admin/Workflow/Controle')
	},{
		libelle: 'menu.admin.motifs',
		url: 'Admin/Workflow/Motifs',
		canSee: (router,session: Session,_) => isRouteAllowed(router, session, 'Admin/Workflow/Motifs')
	},{
		libelle: 'menu.admin.roles',
		url: 'Admin/Workflow/Roles',
		canSee: (router,session: Session,_) => isRouteAllowed(router, session, 'Admin/Workflow/Roles')
	},{
		libelle: 'menu.admin.statuts',
		url: 'Admin/Workflow/Statuts',
		canSee: (router,session: Session,_) => isRouteAllowed(router, session, 'Admin/Workflow/Statuts')
	}]
},{
	libelle: 'menu.admin.maintenance',
	icone: 'settings_suggest ',
	scope: [Scope.MENU_ADMIN],
	url: 'Admin/Maintenance',
	canSee: (router,session: Session,_) => isRouteAllowed(router, session, 'Admin/Maintenance'),
	children: [{
		libelle: 'menu.admin.compteAdministrateur',
		url: 'Admin/Maintenance/CompteAdmin',
		canSee: (router,session: Session,_) => isRouteAllowed(router, session, 'Admin/Maintenance/CompteAdmin')
	},{
		libelle: 'menu.admin.gestionDesLogs',
		url: 'Admin/Maintenance/GestionLogs',
		canSee: (router,session: Session,_) => isRouteAllowed(router, session, 'Admin/Maintenance/GestionLogs')
	},{
		libelle: 'menu.admin.migrations',
		url: 'Admin/Maintenance/Migrations',
		canSee: (router,session: Session,_) => isRouteAllowed(router, session, 'Admin/Maintenance/Migrations')
	}]
},{
	libelle: RECHERCHE_MENU,
	icone: 'search ',
	scope: [Scope.MENU_ADMIN],
	canSee: (router,session: Session,_) => session.isAdmin,
	doAction: dispatch => dispatch({
		type: searchActions.SEARCH_FROM_MENU,
		payload: true
	})
}
];
