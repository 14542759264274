import {Component,OnDestroy,OnInit} from '@angular/core';
import {ReportingService} from "./reporting.service";
import {first} from "rxjs/operators";
import {Report} from "@domain/reporting/report";
import {Subscription} from "rxjs";
import {Alerte,NiveauAlerte} from "@domain/common/alerte/alerte";
import {PageHeaderItem} from "@share/component/page-header/page-header";
import {TranslateService} from "@ngx-translate/core";

/**
 * Enum pour les noms des différents onglets
 */
export enum Onglets {
    REPORTING = "Reporting"
}

@Component({
    selector: 'reporting-list',
    templateUrl: './reporting-list.component.html'
})
export class ReportingListComponent implements OnInit, OnDestroy {

    /** Données */
    listeReporting: Report[];

    /** Liste des différents onglets disponibles */
    listeTabItems: Array<PageHeaderItem>;

    /** Nom du rapport en cours de génération (sinon null) */
    pendingReportName: string;

    /** Souscription */
    private subscription: Subscription;

    /** Alerte à afficher lorsqu'un rapport est en cours de génération  */
    alerte: Alerte;

    /**
     * Constructeur
     */
    constructor(private reportingService: ReportingService,protected translateService: TranslateService) {}

    /**
     * Initialisation
     */
    ngOnInit() {
        //Liste des onglets
        this.listeTabItems = [
            {
                code: Onglets.REPORTING,
                libelle: this.translateService.instant('report.title'),
                url: 'ListeReport'
            }
        ];

        //Chargement de la liste des rapports
        this.reportingService.loadListe().pipe(first()).subscribe({
            next: response => {
                //Mise à jour de la liste des rapports
                this.listeReporting = response;
            }
        });

        //Abonnement aux mises à jour de génération
        this.subscription = this.reportingService.pendingReportName$.subscribe(r => {
            //Sauvegarde du libellé du rapport en cours de génération
            this.pendingReportName = r;

            //Si le message comporte un libellé
            if (!!r) {
                //Création d'une alerte dédiée
                this.alerte = new Alerte({
                    niveau: NiveauAlerte.NO_CONTROL,
                    message: this.translateService.instant("report.execution.generationEnCours", { libelle: r })
                });
            } else {
                //Sinon réinitialisation de l'alerte
                this.alerte = undefined;
            }
        });

        //Vérification du statut de la génération
        this.reportingService.checkGenerationStatus();
    }

    /**
     * Affichage de la modal de paramétrage du rapport / Execution du rapport
     * @param idReport identifiant du rapport
     */
    executeReport(idReport: number) {
        //Si aucun rapport n'est en cours de génération
        if (!this.pendingReportName) {
            //Exécution
            this.reportingService.executeReportById(idReport);
        }
    }

    /**
     * Destruction du composant
     */
    ngOnDestroy() {
        //Résiliation
        this.subscription.unsubscribe();
    }
}
