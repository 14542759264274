import { NgModule } from '@angular/core';
import { VoyagesReferentielsCartesVoyageursInfosComponent } from "@components/admin/voyages/referentiels/cartes-voyageurs/cartes-voyageurs-infos/voyages-referentiels-cartes-voyageurs-infos.component";
import { VoyagesReferentielsCartesVoyageursItemComponent } from "@components/admin/voyages/referentiels/cartes-voyageurs/voyages-referentiels-cartes-voyageurs-item/voyages-referentiels-cartes-voyageurs-item.component";
import { VoyagesReferentielsCartesVoyageursComponent } from "@components/admin/voyages/referentiels/cartes-voyageurs/voyages-referentiels-cartes-voyageurs.component";
import { VoyagesReferentielsClassesInfosComponent } from "@components/admin/voyages/referentiels/classes/classes-infos/voyages-referentiels-classes-infos.component";
import { VoyagesReferentielsClassesItemComponent } from "@components/admin/voyages/referentiels/classes/voyages-referentiels-classes-item/voyages-referentiels-classes-item.component";
import { VoyagesReferentielsClassesComponent } from "@components/admin/voyages/referentiels/classes/voyages-referentiels-classes.component";
import { VoyagesReferentielsCompagniesInfosComponent } from "@components/admin/voyages/referentiels/compagnies/compagnie-infos/voyages-referentiels-compagnies-infos.component";
import { VoyagesReferentielsCompagniesItemComponent } from "@components/admin/voyages/referentiels/compagnies/voyages-referentiels-compagnies-item/voyages-referentiels-compagnies-item.component";
import { VoyagesReferentielsCompagniesComponent } from "@components/admin/voyages/referentiels/compagnies/voyages-referentiels-compagnies.component";
import { VoyagesReferentielsSynchronisationsComponent } from "@components/admin/voyages/referentiels/synchronisations/voyages-referentiels-synchronisations.component";
import { VoyagesReferentielsService } from "@services/admin/voyages/voyages-referentiels.service";
import { ShareModule } from "@share/share.module";
import { VoyagesReferentielsComponent } from "./voyages-referentiels.component";
import { Routes } from "@angular/router";
import { AdminGuardProvider } from "@core/security/admin-guard.provider";
import {DroitAdmin} from "@core/security/droit-admin";

export const voyagesReferentielsRoutes: Routes = [
    {
        path: 'Compagnies',
        component: VoyagesReferentielsCompagniesComponent,
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_TRAVEL_REFERENTIEL] }
    }, {
        path: 'CartesVoyageurs',
        component: VoyagesReferentielsCartesVoyageursComponent,
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_TRAVEL_REFERENTIEL] }
    }, {
        path: 'Classes',
        component: VoyagesReferentielsClassesComponent,
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_TRAVEL_REFERENTIEL] }
    }, {
        path: 'Synchronisations',
        component: VoyagesReferentielsSynchronisationsComponent,
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_TRAVEL_REFERENTIEL] }
    }, {
        path: '',
        redirectTo: 'Compagnies',
        pathMatch: 'full'
    },{
        path: '**',
        redirectTo: 'Compagnies'
    }
];

@NgModule({
    imports: [ShareModule],
    declarations: [
        VoyagesReferentielsComponent,
        VoyagesReferentielsCompagniesComponent,
        VoyagesReferentielsCompagniesItemComponent,
        VoyagesReferentielsCompagniesInfosComponent,
        VoyagesReferentielsCartesVoyageursComponent,
        VoyagesReferentielsCartesVoyageursItemComponent,
        VoyagesReferentielsCartesVoyageursInfosComponent,
        VoyagesReferentielsClassesComponent,
        VoyagesReferentielsClassesItemComponent,
        VoyagesReferentielsClassesInfosComponent,
        VoyagesReferentielsSynchronisationsComponent
    ],
    entryComponents: [
        VoyagesReferentielsCompagniesItemComponent,
        VoyagesReferentielsCartesVoyageursItemComponent,
        VoyagesReferentielsClassesItemComponent
    ],
    exports: [
        VoyagesReferentielsComponent
    ],
    providers: [VoyagesReferentielsService]
})
export class VoyagesReferentielsModule {
}
