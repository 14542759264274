import {Component,EventEmitter,Input,OnDestroy,OnInit,Output} from '@angular/core';
import {BehaviorSubject,forkJoin,Subject,Subscription} from 'rxjs';
import {debounceTime,first} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {SettingsGlobalState} from '@domain/settings/settings';
import {environment} from '@environments/environment';
import {FileUploader,FileWithProgress,InvalidType,ProgressType} from '@share/directive/file-uploader/file-uploader';
import {mapPorteeByContext} from './document';
import {TypeCodeErreur} from '@domain/common/http/result';
import {DocumentService} from "./document.service";
import {Document} from "@domain/document/document";

/**
 * Composant permettant d'ajouter des documents par drag 'n drop à une NDF
 *
 * @author Laurent Convert
 * @date 20/06/2024
 */
@Component({
    selector: 'document-dropper',
    host: {'data-test-id': 'document-dropper'},
    templateUrl: './document-dropper.component.html',
})
export class DocumentDropperComponent implements OnInit,OnDestroy {
    /** Upload de fichiers */
    fileUploader: FileUploader;

    /** Contexte d'utilisation des documents */
    @Input() context: string;

    /** Identifiant du collaborateur associé au document */
    @Input() idCollaborateur?: number;

    /** Indique si l'upload est lié à l'ocr */
    @Input() isOcr = false;

    /** Identifiant de l'objet associé au document */
    @Input() idObjet: number = 0;

    /** Paramétrage */
    @Input() settings: SettingsGlobalState;

    /** Origine de l'insertion du composant pour adapter le style */
    @Input() source: 'default' | 'ndf-list-frais' | 'saisie-frais' = 'default';

    /** Indique qu'il n'y a aucun fichier à traiter et que le composant peut être masqué */
    @Output() onHide: EventEmitter<void> = new EventEmitter();

    /** Évènement généré à la suite de l'upload d'un document */
    @Output() onDocumentUploaded: EventEmitter<FileWithProgress> = new EventEmitter<FileWithProgress>();

    /** Souscription à l'évènement de fin d'upload */
    private onDocumentUploadedSub: Subscription;

    /** Évènement généré à la suite de l'upload de tous les documents sélectionnés */
    @Output() onAllDocumentUploaded: EventEmitter<Array<Document>> = new EventEmitter<Array<Document>>();

    /** Indique que l'upload est en cours */
    isUploading: Subject<boolean> = new BehaviorSubject<boolean>(false);

    /** Évènement généré à la suite d'une error lors de l'upload d'un document */
    onDocumentError: EventEmitter<{file: FileWithProgress,invalidType: InvalidType}> = new EventEmitter<any>();

    /** Souscription à l'évènement de notification d'erreur */
    private onDocumentErrorSub: Subscription;

    /** Map des fichiers ajoutés à la queue permettant de savoir si leur traitement (upload + lien à l'objet) est terminé */
    private listeFilesUploaded: Map<FileWithProgress,boolean> = new Map();

    /** Permet de vérifier que tous les fichiers ont été traités */
    private onCheckDone = new Subject<any>();

    /** Souscription à l'évènement de vérification de fin de traitement */
    private onCheckDoneSub: Subscription;

    /** Nombre total de fichier */
    total: number = 0;

    /** Nombre de fichiers traités */
    nbUploaded: number = 0;

    /**
     * Constructeur
     */
    constructor(private toastrService: ToastrService,
                private translateService: TranslateService,
                private documentService: DocumentService) {}

    /**
     * Initialisation
     */
    ngOnInit() {
        //Définition de l'upload de documents
        this.initFileUploader();

        //Débounce pour éviter le cas où plusieurs fichiers se terminent en même temps
        this.onCheckDoneSub = this.onCheckDone.pipe(debounceTime(1000)).subscribe(() => {
            let isAllDone = true;

            //Parcours de la map pour vérifier si tous les upload sont terminés
            for (const isFileDone of this.listeFilesUploaded.values()) {
                if (!isFileDone) {
                    isAllDone = false;
                    break;
                }
            }

            //Tous les upload sont terminés
            if (isAllDone) {
                //Émission de l'évènement indiquant au composant parent les documents ajoutés avec succès
                this.onAllDocumentUploaded.emit(
                    Array.from(this.listeFilesUploaded.entries())
                        .filter(entry => entry[1] === true)
                        .map(entry => entry[0].result.data.document)
                );

                //Suppression des fichiers de la map
                this.listeFilesUploaded.clear();

                //Reset de l'indicateur d'upload en cours
                this.isUploading.next(false);
            }
        });

        //écoute de l'évènement de fin d'upload (+ association avec l'objet si hors création)
        this.onDocumentUploadedSub = this.onDocumentUploaded.subscribe(res => {
            //Mise à jour de la map
            this.listeFilesUploaded.set(res,true);

            //Vérification de la finalisation de l'upload
            this.onCheckDone.next();
        });

        //écoute de l'évènement de notification d'erreur serveur suite à l'upload
        this.onDocumentErrorSub = this.onDocumentError.subscribe(res => {
            //Suppression de la map
            this.listeFilesUploaded.delete(res.file);

            //Vérification de la finalisation de l'upload
            this.onCheckDone.next();
        });
    }

    /**
     * Destruction du composant
     */
    ngOnDestroy() {
        this.onDocumentUploadedSub?.unsubscribe();
        this.onDocumentErrorSub?.unsubscribe();
        this.onCheckDoneSub?.unsubscribe();
    }

    /**
     * Définition de l'upload de documents
     */
    initFileUploader(): void {
        this.fileUploader = {
            url: `${environment.baseUrl}/controller/Document/upload?idTypeDocument=0&portee=${mapPorteeByContext[this.context]}`
                + (this.idCollaborateur >= 0 ? `&idCollab=${this.idCollaborateur}`:'')
                + `&isOCR=${this.isOcr}`,
            onItemUploaded: this.onItemUploaded.bind(this),
            autoRemove: false,
            isCountDown: false,
        };
    }

    /**
     * Upload d'un fichier
     */
    uploadItem(idxFile: number) {
        //Upload du fichier
        forkJoin(this.fileUploader.uploadItem(idxFile)).pipe(first()).subscribe();
    }

    /**
     * Finalisation de l'upload d'un fichier
     */
    onItemUploaded(result: any,file: FileWithProgress) {
        //Indique que le fichier a été envoyé
        file.isUploaded = true;
        file.result = result;

        this.nbUploaded++;

        if (result.codeErreur == TypeCodeErreur.ERROR_SAVE) {
            file.isKo = true;
            //Si extension valide
            if(!result.data.isExtensionValide) {
                //On affiche le message d'erreur extension invalide
                this.toastrService.error(this.translateService.instant('global.errors.extensionInvalide', { fileName: file.name }));

                //Déclenchement de l'intercepteur de fichier invalide
                this.onDocumentError.emit( {file: file,invalidType: InvalidType.EXTENSION});
            }
            //Si taille invalide
            if(!result.data.isTailleValide) {
                //On affiche le message d'erreur taille invalide
                this.toastrService.error(this.translateService.instant('global.errors.tailleInvalide', { fileName: file.name }));

                //Déclenchement de l'intercepteur de fichier invalide
                this.onDocumentError.emit( {file: file,invalidType: InvalidType.SIZE});
            }
            //Si nom invalide
            if(!result.data.isNomValide) {
                //On affiche le message d'erreur nom invalide
                this.toastrService.error(this.translateService.instant('global.errors.nomInvalide', { fileName: file.name }));

                //Déclenchement de l'intercepteur de fichier invalide
                this.onDocumentError.emit( {file: file,invalidType: InvalidType.NAME});
            }
        } else if (result.codeErreur == TypeCodeErreur.ERROR_PERMISSION) {
            //On notifie de l'échec
            this.toastrService.error(this.translateService.instant('global.errors.permission'));

            //Déclenchement de l'intercepteur de fichier invalide
            this.onDocumentError.emit( {file: file,invalidType: InvalidType.CREDENTIAL});
        } else {
            //Document enregistré mais le nom a été modifié
            if(!!result.data.nomModifie) {
                //On affiche le message d'avertissement
                this.toastrService.warning(this.translateService.instant('global.errors.nomModifie', { fileName: file.name }));
            }

            //Emission de l'évènement de fin d'upload
            this.linkDocumentToObject(file);
        }
    }

    /**
     * Association du document avec l'objet
     *
     * @param file Fichier uploadé
     */
    linkDocumentToObject(file: FileWithProgress): void {
        const document: Document = file.result.data.document;

        if (document) {
            if (this.idObjet > 0) {
                //Liaison du document à son objet
                this.documentService.linkDocumentToObject(this.context,this.idObjet,document)
                    .subscribe(() => {
                        this.onDocumentUploaded.emit(file);
                    });
            } else {
                this.documentService.listeDocumentToLink.push(document);

                this.onDocumentUploaded.emit(file);
            }
        }
    }

    /**
     * Gestion du drop de fichiers
     */
    onFilesDrop(files: Array<any>) {
        let uploadedExtension;

        //Parcours des fichiers sélectionnés
        for (const file of files) {
            //Vérification du type de fichier
            if (file instanceof File) {
                //Vérification de la queue
                this.fileUploader.queue = this.fileUploader.queue || [];

                //Récupération de l'extension
                uploadedExtension = file.name.split('.').pop().toLowerCase();

                //Calcul de la taille en Ko
                const size = file.size / 1024;

                //Vérification de la taille max
                if (this.settings?.tailleMax != null && this.settings.tailleMax < size) {
                    //Si le fichier est trop gros
                    this.toastrService.error(this.translateService.instant('global.errors.tailleInvalide', { fileName: file.name }));
                } else if (this.settings && !this.settings.listeExtensions.some(extension => extension.toLowerCase() === uploadedExtension)) {
                    //Si extension invalide
                    //Affichage d'un message d'erreur
                    this.toastrService.error(this.translateService.instant('global.errors.fileBlocked',{ fileName: file.name }));
                } else {
                    //Ajout du fichier à la queue
                    this.fileUploader.queue.push(file);
                }
            }
        }
    }

    /**
     * Masquage
     */
    hide() {
        this.onHide.emit();
    }

    /**
     * Sortie de la zone de drag 'n drop
     */
    onDragLeave() {
        //Si aucun fichier ou qu'ils sont tous uploadés on peut masquer
        if (!this.fileUploader.queue || this.fileUploader.queue.every(val => val.isUploaded)) {
            //Masquage
            this.hide();
        }
    }

    /**
     * Upload des documents ajoutés à la queue
     */
    uploadAll() {
        //Récupération de l'index de chaque document n'ayant pas encore été uploadé
        const listeIndexFilesToUpload = this.fileUploader.queue?.map((f,idx) => {
            //Vérification de l'état
            if (!f.isUploaded && (f.ProgressType == null || f.ProgressType == ProgressType.None)) {
                return idx;
            } else {
                return null;
            }
        }).filter(val => val !== null);

        //S'il y a au moins 1 document à uploader
        if (listeIndexFilesToUpload?.length > 0) {
            //Mise à jour du total
            this.total += listeIndexFilesToUpload.length;

            //Mise à jour de l'indicateur d'upload en cours
            this.isUploading.next(true);

            //Upload de chaque fichier
            listeIndexFilesToUpload.forEach(idx => {
                //Mise à jour du statut d'upload en cours dans la map
                let fileWithProgress = this.fileUploader.queue[idx];
                this.listeFilesUploaded.set(fileWithProgress,false);

                //Upload du fichier
                this.uploadItem(idx);
            });
        } else if (this.total === 0) {
            //Rien à faire
            this.hide();
        }
    }
}
