import { Component } from "@angular/core";

/**
 * Onglet généralités
 *
 * @author Guillaume TRAVOT
 * @date 26/04/2024
 */
@Component({
    host: {'data-test-id': 'authentification-generalites'},
    templateUrl: './authentification-generalites.component.html'
})
export class AuthentificationGeneralitesComponent {}
